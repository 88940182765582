import React, { useEffect } from 'react'
import intl from 'react-intl-universal'
import { getSignedValue } from 'actions/files'
import Toggle from 'components/utils/Toggle'

let videoPositions = []

const Video = ({ videoUrl, hdVideoUrl, allowMediaDownload, sessionEditVideo }: { videoUrl: string, hdVideoUrl: string|null, allowMediaDownload: boolean, sessionEditVideo?: boolean}) => {
  const [quality, setQuality] = React.useState(localStorage.getItem('videoQuality') || 'sd')
  const _videoRef: any = React.useRef()

  useEffect(() => {
    if (_videoRef.current) {
      _videoRef.current.addEventListener('contextmenu', handleClick)
      _videoRef.current.addEventListener('timeupdate', handleProgress)

      if (videoPositions[videoUrl]) {
        _videoRef.current.currentTime = videoPositions[videoUrl]
        setTimeout(() => {
          _videoRef.current && _videoRef.current.play()
        }, 50)
      }
    }

    if (hdVideoUrl && !hdVideoUrl.includes('video_processing')) {
      localStorage.setItem('videoQuality', 'hd')
      setQuality('hd')
    } else {
      localStorage.setItem('videoQuality', 'sd')
      setQuality('sd')
    }

    return () => {
      if (_videoRef.current) {
        _videoRef.current.removeEventListener('contextmenu', handleClick)
        _videoRef.current.removeEventListener('timeupdate', handleProgress)
      }
    }
  }, [])

  const handleClick = (e) => {
    if (allowMediaDownload) return

    e.preventDefault()
    return false
  }

  const handleProgress = () => {
    if (!_videoRef.current) return

    videoPositions[videoUrl] = _videoRef.current.currentTime
  }

  const switchQuality = () => {
    const q = quality === 'sd' ? 'hd' : 'sd'
    const currentTime = _videoRef.current.currentTime
    localStorage.setItem('videoQuality', q)
    setQuality(q)
    const wasPaused = _videoRef.current.paused
    _videoRef.current.pause()
    _videoRef.current.currentTime = currentTime
    setTimeout(() => {
      _videoRef.current && !wasPaused && _videoRef.current.play()
    }, 50)
  }

  const signedValue = getSignedValue(videoUrl)
  const hdSignedValue = hdVideoUrl ? getSignedValue(hdVideoUrl) : signedValue

  function hasPolicyParameter(url) {
    const searchParams = new URLSearchParams(new URL(url).search)
    return searchParams.has("Policy") || url.includes('gnowbe-videos')
  }

  const hasPolicy = hasPolicyParameter(videoUrl)

  return (
    <div className="text-center bg-white">
      {hasPolicy ?
        <video key={quality} ref={_videoRef} style={{ width: '100%', height: sessionEditVideo ? '100%' : 400 }} controls controlsList={`${allowMediaDownload ? '' : 'nodownload'}`}>
          <source src={quality === 'hd' && hdSignedValue ? hdSignedValue : signedValue} />
          {intl.get('video_not_supported')}
        </video>
        :
        <div style={{ width: '100%', height: 400, background: 'black' }}>Loading</div>
      }

      {(hdSignedValue && !hdSignedValue.includes('video_processing') && !sessionEditVideo) &&
        <Toggle label={quality === 'sd' ? intl.get('video_hq_text') : intl.get('video_lq_text')} id="toggleVideoQuality" onChange={switchQuality} defaultChecked={quality === 'hd'} invertLabel={true} videoQualityToggle={true} />
      }
    </div>
  )
}

export default Video
