import gql from 'graphql-tag'
import { courseFragment } from 'graphql/fragments/Course'
import { Course } from 'graphql/schemas/course/Course'

type ToggleOrgCourseDistSelection = {
  excludeCompanyIds?: string[];
  onlyCompanyIds?: string[];
  searchText?: string;
}

export type ToggleOrgCourseDist = {
  addTo?: ToggleOrgCourseDistSelection;
  removeFrom?: ToggleOrgCourseDistSelection;
  organizationId: string;
}

type CourseDistributionParamsInput = {
  courseId: string;
  absoluteDeadline?: number;
  hideAssessmentsAfterCompleted?: boolean;
  manualReviewsSkipEndorsment?: boolean;
  relativeDeadline?: number;
  showHintsAfterAssessmentsCompleted?: boolean;
}

export type ToggleOrgsCourseDistributionVars = {
  courseId: string;
  organizations: ToggleOrgCourseDist[];
  distributionParams: CourseDistributionParamsInput;
  onlyVisibleInAnalyticsInMetrics: boolean;
}

export type ToggleOrgsCourseDistributionRes = {
  res: {
    companiesCount: number;
    companyIds: string[];
    course: Course;
    courseId: string;
  },
}

export const toggleOrgsCourseDistributionMutation = gql`
  ${courseFragment}

  mutation ToggleOrgsCourseDistribution ($courseId: String!, $organizations: [ToggleOrgCourseDist!]!, $distributionParams: CourseDistributionParamsInput!, $onlyVisibleInAnalyticsInMetrics: Boolean!) {
    res: toggleOrgsCourseDistribution (courseId: $courseId, organizations: $organizations, distributionParams: $distributionParams, onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics) {
      courseId
      companyIds
      companiesCount
      course {
        ...CourseFragment
      }
    }
  }
`
