import { ProgressReview, ProgressReviewStatus, Reviewer, Review } from '../review/Review'

export const userProgressFactory = (data?: Partial<UserProgress>): UserProgress => {
  return {
    id: data?.id || 'random',
    userId: data?.userId || '',
    companyId: data?.companyId || '',
    courseId: data?.courseId || '',
    timestamps: data?.timestamps || timestampsFactory(),
    reviewTimestamps: data?.reviewTimestamps || timestampsFactory(),

    hasAssessments: data?.hasAssessments || null,
    currentDay: data?.currentDay || null,
    startedAt: data?.startedAt || null,
    lastIncreasedAt: data?.lastIncreasedAt || null,
    lastForceIncreasedAt: data?.lastForceIncreasedAt || null,
    lastVisitAt: data?.lastVisitAt || null,
    lastActiveAt: data?.lastActiveAt || null,
    minutesSpent: data?.minutesSpent || null,
    completion: data?.completion || null,
    engagement: data?.engagement || null,
    numOfActions: data?.numOfActions || null,
    numOfCompletedActions: data?.numOfCompletedActions || null,
    numOfCompletedChapters: data?.numOfCompletedChapters || null,
    assessmentsGrade: data?.assessmentsGrade || null,
    assessmentsCompleted: data?.assessmentsCompleted || null,
    assessmentsRetakeNum: data?.assessmentsRetakeNum || null,
    notes: data?.notes || null,
    teamCurrentDay: data?.teamCurrentDay || null,
    review: data?.review || null,
  }
}

export type UserProgress = {
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  timestamps: Timestamps;
  reviewTimestamps: Timestamps;

  hasAssessments: boolean|null
  currentDay: number|null;
  startedAt: number|null;
  lastIncreasedAt: number|null;
  lastForceIncreasedAt: number|null;
  lastVisitAt: number|null;
  lastActiveAt: number|null;
  minutesSpent: number|null;
  completion: number|null;
  engagement: number|null;
  numOfActions: number|null;
  numOfCompletedActions: number|null;
  numOfCompletedChapters: number|null;
  assessmentsGrade: number|null;
  assessmentsCompleted: boolean|null;
  assessmentsRetakeNum: number|null;
  notes: string|null;
  teamCurrentDay: number|null;
  review: ProgressReview|null;
}

export const timestampsFactory = (data?: Partial<Timestamps>): Timestamps => {
  return {
    createdAt: data?.createdAt || 0,
    createdAtTree: data?.createdAtTree || 0,
    updatedAt: data?.updatedAt || 0,
    updatedAtTree: data?.updatedAtTree || 0,
  }
}

export type Timestamps = {
  createdAt: number;
  createdAtTree: number;
  updatedAt: number;
  updatedAtTree: number;
}

export type TimestampsUpdate = {
  createdAt: number|null;
  createdAtTree: number|null;
  updatedAt: number|null;
  updatedAtTree: number|null;
}

export type ProgressReviewUpdated = {
  userId: string;
  companyId: string;
  courseId: string;
  status: ProgressReviewStatus|null;
  addedReviewers: Reviewer[]|null;
  updatedReviewers: Reviewer[]|null;
  removedReviewers: string[]|null;
  addedReviews: Review[]|null;
  updatedReviews: Review[]|null;
  removedReviews: string[];
  assessmentScore: number|null;
  assessmentPassed: boolean|null;
}

export type ProgressReviewTimestampsUpdated = {
  userId: string;
  companyId: string;
  courseId: string;
  timestamps: TimestampsUpdate;
}

export type ProgressReviewNoChange = {
  dummy: number;
}