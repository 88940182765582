import React from 'react'
import autosize from 'autosize'
import intl from 'react-intl-universal'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import Tippy from '@tippyjs/react'
import { escapeHtml } from 'utils/functions'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getUserBasicQuery } from 'graphql/queries/user/getUser'
import { augmentExplain, augmentGreeting } from 'utils/utils'
import { UserProfile } from 'graphql/schemas/user/UserProfile'
import { Help } from './Help'
import { showAlert } from './Alert'
import { showSave } from './SavingIndicator'

interface SimpleEditableProps {
  id?: string;
  defaultValue: string;
  placeholder: string;
  isEditing: boolean;
  user: UserProfile;
  isConnected: boolean;
  textArea?: boolean;
  autoSize?: any;
  mandatory?: boolean;
  fullWidth?: boolean;
  style?: Object;
  divStyle?: Object;
  classname?: string;
  help?: string;
  maxLength?: number;
  type?: string;
  append?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?: (e) => void;
  update: (v: string) => void;
}

class Editable extends React.PureComponent<SimpleEditableProps, { value: string, focused: boolean }> {
  // private _field
  private _dirty = false
  private _isMounted = false
  private _timeout

  constructor(props) {
    super(props)

    this.state = { value: props.defaultValue || '', focused: false }

    this.save = this.save.bind(this)
    this.inputChange = this.inputChange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentDidUpdate() {
    if (this.props.isEditing) return

    this.setState({ value: this.props.defaultValue || '' })
  }

  componentWillUnmount() {
    this._isMounted = false
    clearTimeout(this._timeout)
  }

  inputChange(value: string) {
    this.setState({ value })
    this._dirty = true

    this._timeout = clearTimeout(this._timeout)
    this._timeout = setTimeout(() => {
      if (this.props.mandatory && value === '') {
        return showAlert(intl.get('error_empty_field'), 'danger')
      }
      this.save(false)
    }, 750)
  }

  save(blur = true) {
    blur && this.setState({ focused: false })
    if (!this._dirty) return
    if (this.props.mandatory && this.state.value === '') {
      return showAlert(intl.get('error_empty_field'), 'danger')
    }

    showSave()

    this.props.update(this.state.value)
    this._dirty = false
  }

  render() {
    const { id, autoSize, placeholder, fullWidth, isEditing, textArea, type, user, help, append, isConnected, classname, maxLength, disabled, divStyle, style, tooltip, onClick } = this.props
    const { value } = this.state


    const customStyle = {
      ...divStyle as React.CSSProperties
    };

    if (!isEditing) {
      const augmentedText = augmentExplain(
        augmentGreeting(value)
          .replace(/{{firstName}}|{{name}}/g, escapeHtml(user?.profile?.firstName || 'First'))
          .replace(/{{lastName}}/g, escapeHtml(user?.profile?.lastName || 'Last'))
          .replace(/{{fullName}}/g, escapeHtml(user?.profile?.fullName || 'First Last')),
      )
      return (
        <div className={classname ? classname : ''} style={customStyle}>
          {maxLength && augmentedText.length > maxLength
            ? `${augmentedText.substring(0, maxLength - 3)}...`
            : augmentedText
          }
        </div>
      )
    }

    if (fullWidth) {
      return (
        <>
          <div className="relative rounded-md shadow-sm w-full flex-1">
            <Tippy placement="right" content={tooltip} disabled={!tooltip}>
              <input
                id={id}
                type={type || 'text'}
                className={`${classname ? classname : ''} form-input border-team w-full`}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength || 500}
                disabled={!isConnected || disabled}
                style={style}
                onFocus={() => this.setState({ focused: true })}
                onChange={e => this.inputChange(e.target.value)}
                onBlur={() => this.save()}
              />
            </Tippy>
          </div>

          {help &&
            <Help
              path={help}
              hide={!this.state.focused}
            />
          }
        </>
      )
    }

    if (textArea) {
      return (
        <>
          <div className="relative rounded-md shadow-sm">
            <Tippy placement="right" content={tooltip} disabled={!tooltip}>
              <textarea
                id={id}
                ref={ref => autoSize && autosize(ref) || null}
                placeholder={placeholder}
                value={value}
                className={`${classname ? classname : ''} form-input border-team w-full`}
                disabled={!isConnected || disabled}
                onFocus={() => this.setState({ focused: true })}
                onChange={e => this.inputChange(e.target.value)}
                onBlur={() => this.save()}
                onClick={e => onClick && onClick(e) || null}
                style={style}
              />
            </Tippy>
          </div>

          {help &&
            <Help
              path={help}
            />
          }
        </>
      )
    }

    return (
      <>
        <div className="relative rounded-md shadow-sm" style={divStyle}>
          <Tippy placement="right" content={tooltip} disabled={!tooltip}>
            <input
              id={id}
              type="text"
              className={`${classname ? classname : ''} form-input ${fullWidth ? 'w-full' : ''} ${append ? 'pr-12' : ''} ${disabled ? 'bg-gray-100 border-gray-300' : 'border-team'}`}
              placeholder={placeholder}
              value={value}
              disabled={!isConnected || disabled}
              maxLength={maxLength || 500}
              style={style}
              onClick={e => onClick && onClick(e) || null}
              onFocus={() => this.setState({ focused: true})}
              onChange={e => this.inputChange(e.target.value)}
              onBlur={() => this.save()}
            />
          </Tippy>

          {append &&
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500">
                {append}
              </span>
            </div>
          }
        </div>

        {help &&
          <Help
            path={help}
            hide={!this.state.focused}
          />
        }
      </>

      // <span
      //   ref={ref => this._field = ref}
      //   className="editable-v2"
      //   onBlur={this.save}
      //   contentEditable
      //   suppressContentEditableWarning>
      //   {value}
      // </span>
    )
  }
}

export const SimpleEditable: React.ComponentClass<any> = compose(
  graphql(getAppStateQuery, {
    name: 'app',
  }),
  graphql(getUserBasicQuery, {
    skip: (props: any) => !props.app.appState.loggedInAs.uid,
    options: (props: any) => {
      return ({
        variables: {
          userId: props.app.appState.loggedInAs.uid,
        },
        errorPolicy: 'all',
      })
    },
  }),
)((p) => {
  if (!p.data || !p.data.user) {
    return <span>Loading</span>
  }

  const user: UserProfile = p.data.user
  const onlineStatus = true

  return (
    <Editable
      id={p.id}
      autoSize={p.autoSize}
      defaultValue={p.defaultValue}
      placeholder={p.placeholder}
      isEditing={p.isEditing}
      fullWidth={p.fullWidth}
      style={p.style}
      textArea={p.textArea}
      onClick={p.onClick}
      divStyle={p.divStyle}
      classname={p.classname}
      mandatory={p.mandatory}
      maxLength={p.maxLength}
      append={p.append}
      user={user}
      help={p.help}
      isConnected={onlineStatus}
      disabled={p.disabled}
      tooltip={p.tooltip}
      update={p.update}
    />
  )
})
