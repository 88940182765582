import React from 'react'
import { IconProps } from './CheckGnowbeIcon'

const EmptyCircleIcon = ({className}: IconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className} >
      <circle cx="10" cy="10" r="9.5" stroke="#A0A2A6"/>
    </svg>
  )
}

export default EmptyCircleIcon