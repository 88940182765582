import { gql } from '@apollo/client'

export const getUserChatChannelsQuery = gql`
  query GetUserChatChannels ($userId: String!) {
    userChannels: getUserChatChannels (userId: $userId) {
      id
      urn
      memberSince
      lastSeen
      lastMessage {
        id
        urn
        userId
        message
        createdAt
        user {
          id
          profile {
            id
            fullName
            imageUrl
          }
        }
      }
      otherUser {
        id
        profile {
          id
          fullName
          firstName
          lastName
          imageUrl
        }
      }
      course {
        id
        title
      }
    }
  }
`

export const getUserChatUnreadQuery = gql`
  query GetUserChatChannels ($userId: String!) {
    userChannels: getUserChatChannels (userId: $userId) {
      id
      urn
      lastSeen
      otherUser {
        id
      }
      lastMessage {
        id
        createdAt
      }
      course {
        id
        title
      }
    }
    editorChannels: getEditorChatChannels (userId: $userId) {
      id
      urn
      lastSeen
      otherUser {
        id
      }
      lastMessage {
        id
        createdAt
      }
      course {
        id
        title
      }
    }
  }
`

export const getChatChannelQuery = gql`
  query GetChatChannel ($urn: String!, $messagesLimit: Int!, $messagesOlderThen: Float) {
    chatChannel: getChatChannel (urn: $urn, messagesLimit: $messagesLimit, messagesOlderThen: $messagesOlderThen) {
      urn
      queryTimestamp
      messages {
        id
        urn
        userId
        message
        createdAt
        edited
        deleted
        user {
          id
          profile {
            id
            firstName
            lastName
            fullName
            imageUrl
          }
        }
      }
      members {
        userId
        user {
          id
          profile {
            id
            firstName
            lastName
            fullName
            imageUrl
          }
        }
      }
      course {
        id
        title
        imageUrl
      }
    }
  }
`
