/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { generateMongoId } from 'utils/random'

type ButtonProps = {
  id?: string;
  text: string;
  icon?: string;
  svgIcon?: JSX.Element;
  rightIcon?: string;
  type?: 'primary'|'danger'|'team'|'quote'|'edit'|'chat'|'sub'|'guide'|'ad'|'text'|'magic';
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'small'|'medium'|'large';
  tooltip?: string;
  onClick: (e) => void;
  svgRightIcon?: JSX.Element;
  showNewDot?: boolean;
  style?: React.CSSProperties | {};
}

export const Button = forwardRef(({ id, icon, svgIcon, rightIcon, text, type, className, fullWidth, disabled, size, tooltip, onClick, svgRightIcon, showNewDot, style }: ButtonProps, ref) => {


  const customStyle = {
    ...style
  }

  const cls = classNames({
    disabled,
    'inline-flex items-center justify-center': true,
    'btn-v2': true,
    'block w-full': fullWidth,
    [type && type || 'default']: true,
    'border-none hover:shadow-none hover:underline py-2': type === 'text',
    [className || '']: className,
    'text-sm px-4 py-2': size === 'small',
    'border border-deepgray text-deepgray': type === 'guide',
    'bg-transparent border-white text-white hover:bg-white hover:text-[#656CFF]': type === 'ad',
    'relative': showNewDot,
  })

  const handleClick = (e) => {
    if (disabled || !onClick) return null

    return onClick(e)
  }

  return (
    <Tippy placement="right" content={tooltip} disabled={!tooltip}>
      <button
        id={id || generateMongoId(8)}
        className={cls}
        disabled={disabled || false}
        onClick={handleClick}
        style={customStyle}>
        {showNewDot && <div className="rounded-full w-3.5 h-3.5 top-0 mt-[-0.2rem] -right-1.5 bg-coral absolute"/>}
        {icon && <i className={`icon ${icon} font-bold mr-2`} />}
        {svgIcon}

        {text}

        {rightIcon && <i className={`icon ${rightIcon} font-bold ml-2`} />}

        {svgRightIcon}
      </button>
    </Tippy>
  )
})
