import React, { useState, useRef, useEffect } from 'react'
import intl from 'react-intl-universal'
import { getSignedValue } from 'actions/files'
import { showModal } from 'components/utils/CustomModal'
import { copyToClipboard, isEmail } from 'utils/functions'
import { track } from 'utils/track'
import { showAlert } from 'components/utils/Alert'
import { useMutation } from '@apollo/client'
import { inviteCompanyUserMutation } from 'graphql/mutations/company/inviteCompanyUserMutation'
import { Button } from 'components/utils/Button'
import { getCompanyInvitationUrl } from 'graphql/schemas/company/Company'

interface CourseListItemProps {
  courseKey: string;
  isActive: boolean;
  imageUrl: string|null;
  companyId: string;
  courseId: string;
  completion: number;
  title: string;
  curatorName: string;
  currentDay: string;
  isEditor: boolean;
  status: string;
  isTemplate: boolean;
  isAgent?: boolean;
  isArchived?: boolean;
  isExpired?: boolean;
  expiryDate?: string|null;
  allowInvite?: boolean;
  accessCode?: string;
  graduationGrade: number;

  onCourseSelect: (course: { companyId: string, courseId: string }, goToDetails: boolean) => void;
  openCourseDetails: (course: { companyId: string, courseId: string }) => void;
}

export default class CourseListItem extends React.PureComponent<CourseListItemProps> {
  constructor(props) {
    super(props)

    this.onCourseButtonClick = this.onCourseButtonClick.bind(this)
    this.onCourseImageClick = this.onCourseImageClick.bind(this)
    this.onPeerInvitationClick = this.onPeerInvitationClick.bind(this)
  }

  onCourseButtonClick(e: React.MouseEvent<any>) {
    e.nativeEvent.preventDefault()
    this.props.onCourseSelect({ companyId: this.props.companyId, courseId: this.props.courseId }, this.props.completion === 0)
  }

  onCourseImageClick(e: React.MouseEvent) {
    e.nativeEvent.preventDefault()

    this.props.openCourseDetails({ companyId: this.props.companyId, courseId: this.props.courseId })
  }

  onPeerInvitationClick(e: React.MouseEvent) {
    e.nativeEvent.preventDefault()

    const modal = showModal({
      title: intl.get('home_group_invite'),
      component: (
        <PeerInvite
          isAgent={this.props.isAgent}
          companyId={this.props.companyId}
          onClose={() => modal.close()}
        />
      ),
      primaryText: intl.get('done_text'),
      secondaryButton: false,
    })
  }

  render() {
    const {
      courseKey,
      isActive,
      imageUrl,
      completion,
      title,
      curatorName,
      currentDay,
      isEditor,
      isArchived,
      status,
      allowInvite,
      isTemplate,
      isExpired,
    } = this.props

    const signedImageUrl = getSignedValue(imageUrl || '')

    return (
      <li key={courseKey} id={courseKey} className={isActive ? 'active-course' : ''}>
        <div
          className={`course-cover${(!signedImageUrl ? ' empty' : '')} ${isExpired ? 'grayscale opacity-75' : ''} relative`}
          style={{backgroundImage: `url('${signedImageUrl}')` }}
          onClick={this.onCourseImageClick}>

          {status &&
            <span className="redTag bottomLeft">
              {status}
            </span>
          }

          {/* <img
            src="/images/icon-add-participant.svg"
            className="absolute right-0 bottom-0 mr-3 mb-3"
            onClick={this.onPeerInvitationClick}
          /> */}
        </div>

        <input
          type="button"
          onClick={(e) => {
            if (isExpired) {
              return showModal({
                title: intl.get('course_expired_button'),
                content: intl.get('course_expired_text', { 0: this.props.expiryDate }),
                primaryText: intl.get('ok'),
                secondaryButton: false,
              })
            }
            this.onCourseButtonClick(e)
          }}
          className={`btn ${isExpired ? 'bg-gray-300 hover:bg-gray-300 hover:border-gray-300 cursor-not-allowed' : isActive ? 'btn-primary current' : 'btn-primary'}`}
          value={isExpired
            ? intl.get('course_expired_button')
              : isActive
                ? intl.get('left_program_active')
                : isArchived
                  ? intl.get('unarchive')
                  : completion > 0
                    ? completion >= this.props.graduationGrade
                      ? intl.get('open_course')
                      : intl.get('continue_course', { 0: completion })
                    : intl.get('start_now_course')
          } />

        <div className="course-info space-y-2">
          <div className="course-title">
            {title}
          </div>

          <div className="course-subtitle">{curatorName}</div>

          {completion !== 100
            ? currentDay && <div className="course-subtitle">{currentDay}</div>
            : <div className="course-subtitle text-medgray">{intl.get('program_completed')}</div>
          }

          {isEditor && (
            <div className="text-team">
              {intl.get('global_editable')}
              {isTemplate && <span className="ml-1 text-gray-600">(Template 📝)</span>}
            </div>
          )}

          {allowInvite &&
            <a href="#" className="flex items-center mt-1.5 text-lake" onClick={this.onPeerInvitationClick}>
              <img src="/images/icon-add-participant.svg" className="mr-2 -mt-1 h-4" alt={intl.get('home_group_invite')} />
              {intl.get('home_group_invite')}
            </a>
          }
        </div>
      </li>
    )
  }
}

export const PeerInvite = ({ companyId, isAgent, onClose }) => {
  const [email, setEmail] = useState('')
  const shareField = useRef<any>(null)
  const [peerInvite] = useMutation(inviteCompanyUserMutation)
  const [shareUrl, setShareUrl] = useState('')

  const selectField = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
    shareField.current && shareField.current.select()
  }

  useEffect(() => {
    if (isAgent) {
      fetchUrl()
    }
  }, [])

  const fetchUrl = async () => {
    const url = await getCompanyInvitationUrl(companyId)
    setShareUrl(url)
  }

  const sendInvite = async () => {
    if (!isEmail(email)) {
      return showAlert(intl.get('invalid_email_check'), 'danger')
    }
    const res = await peerInvite({ variables: { companyId, userIdOrEmail: email, noEmail: false }})
    if (!res.errors) {
      setEmail('')
      onClose && onClose()
      track({ event: '"Invite" Button Clicked In "Invite To Group" Screen' })
      return showAlert(intl.get('success'), 'success')
    }
  }

  return (
    <div>
      <h4>{intl.get('invite_by_email')}</h4>

      {isAgent
        ? <>
            <div className="mt-3">{intl.get('invite_by_email_subtitle')}</div>
            <div className="my-2">{intl.get('invite_by_email_description')}</div>
          </>
        : <>
            <div className="mt-3">{intl.get('invite_by_email_non_agent_subtitle')}</div>
            <div className="mt-3">{intl.get('invite_by_email_non_agent_description')}</div>
            <div className="mt-3 mb-4 text-sm">{intl.get('invite_by_email_non_agent_note')}</div>
          </>
      }

      <div className="flex mt-3 space-x-4">
        <input
          type="text"
          className="form-input flex-1"
          placeholder={intl.get('type_an_email_address_here')}
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Button text={intl.get('invite')} icon="icon-users-solid" type="primary" onClick={sendInvite} />
      </div>

      {isAgent &&
        <>
          <h4 className="mt-8">{intl.get('share_the_sign_up_link')}</h4>
          <div className="mt-3">{intl.get('share_the_sign_up_link_subtitle')}</div>
          <div className="my-2">{intl.get('share_the_sign_up_link_description')}</div>
          <div className="flex mt-3 space-x-4">
            <input
              ref={shareField}
              type="text"
              className="form-input flex-1 text-pretty"
              value={shareUrl}
              onClick={selectField}
              readOnly
            />

            <Button text={intl.get('global_copy_link')} icon="icon-paste-light" type="primary" onClick={() => {
              copyToClipboard(shareUrl)
              track({ event: '"Share" Button Clicked In "Invite To Group" Screen' })
            }} />
          </div>
        </>
      }
    </div>
  )
}
