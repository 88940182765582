import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@apollo/client'
import { Banner } from 'graphql/schemas/banner/Banner'
import { updateMetaDeep } from 'actions/users_actions'
import { getUserMetadata, GetUserMetadataRes } from 'graphql/queries/user/getUser'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { Button } from './Button'
import { track } from 'utils/track'
import { getUserNotificationBannersQuery, GetUserNotificationBannersRes } from 'graphql/queries/banner/getUserNotificationBanners'

const Banner = () => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)

  const { data: metaData } = useQuery<GetUserMetadataRes>(getUserMetadata, {
    variables: { userId: appData?.appState.loggedInAs.uid },
  })

  const { data: bannerData } = useQuery<GetUserNotificationBannersRes>(getUserNotificationBannersQuery)

  const [selectedValue, setSelectedValue] = useState<number|null>(null)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!banner || !appData?.appState.loggedInAs.uid) return

    track({
      event: 'Banner Shown',
      variables: {
        banner_id: banner.bannerId,
        user_id: appData?.appState.loggedInAs.uid,
        platform: 'gnowbe-web',
      },
    })
  }, [])

  if (!metaData || !visible || !bannerData?.banners || bannerData.banners.length === 0) return null

  const banners = bannerData.banners.filter(b => Number(new Date()) < b.expirationTime).filter(b => {
    const dismissedBanner = metaData?.user?.metadata?.general?.dismissedBanners?.[b.bannerId]
    return b.showToPlatform.includes('web') &&
      (!dismissedBanner // filter banners that are not dismissed
      || new Date() > dismissedBanner) // or if dismissed, the dismissed date is bigger than right now
  }) || []

  if (banners.length === 0) return null

  const banner = banners[0]
  const cta = banner.ctaButton
  const isRange = ['range10', 'range5'].includes(banner.type)

  const bannerCn = classNames({
    'w-full px-8 py-4 flex items-center justify-center text-deepgray gap-6 mb-4 -mt-4': true,
  })

  const handleCta = () => {
    if (!cta?.url) return

    track({
      event: 'Banner Clicked',
      variables: {
        banner_id: banner.bannerId,
        user_id: appData?.appState.loggedInAs.uid,
        platform: 'gnowbe-web',
      },
    })

    hideBanner()

    window.open(cta.url, '_blank')
  }

  const handleRangeCta = () => {
    track({
      event: 'Banner Answered',
      variables: {
        banner_id: banner.bannerId,
        user_id: appData?.appState.loggedInAs.uid,
        answer: selectedValue,
        platform: 'gnowbe-web',
      },
    })

    hideBanner()
  }

  const handleDismiss = () => {
    track({
      event: 'Banner Dismissed',
      variables: {
        banner_id: banner.bannerId,
        user_id: appData?.appState.loggedInAs.uid,
        platform: 'gnowbe-web',
      },
    })

    const dismissUntil = (Number(new Date()) + 1000 * 60 * 60 * 24 * banner.dismissForDays).toString()
    updateMetaDeep(`/dismissedBanners/${banner.bannerId}`, dismissUntil, true, 'dismissedBanners')
    // updateMeta(`/dismissedBanners/${banner.bannerId}`, dismissUntil, true)
    setVisible(false)
  }

  const hideBanner = () => {
    const dismissUntil = (Number(new Date()) + 1000 * 60 * 60 * 24 * 4880).toString() // ~13.37 years
    // updateMeta(`/dismissedBanners/${banner.bannerId}`, dismissUntil, true)
    updateMetaDeep(`/dismissedBanners/${banner.bannerId}`, dismissUntil, true, 'dismissedBanners')
    setVisible(false)
  }

  const rangeLen = isRange && Number(banner.type.slice(5)) + 1 || 0

  return (
    <div className={bannerCn} style={{ backgroundColor: banner.bannerColor }}>
      <div className="flex flex-col gap-1">
        <div className="ml-2">{banner.bannerText}</div>

        {isRange && (
          <div className="flex items-center justify-center mb-4"> {/* border border-deepgray divide-x divide-deepgray rounded */}
            <div className="relative flex gap-1">
              {Array(rangeLen).fill(undefined).map((i, a) => {
                if (banner.type === 'range5' && a === 0) return
                return (
                  // ${a === 0 ? 'rounded-l' : undefined} ${a === 10 ? 'rounded-r' : undefined}
                  <button key={a} className={`py-2.5 w-10 text-center border border-deepgray rounded ${(selectedValue === a && banner.bannerColor === '#FFFFFF') ? 'bg-gray-300' : selectedValue === a ? 'bg-white' : undefined}`} onClick={() => setSelectedValue(a)}>
                    {a}
                  </button>
                )
              })}

              <div className="absolute -bottom-5 left-0 right-0 flex justify-between text-sm">
                <span>{banner.minRangeCopy || 'Least likely'}</span>
                <span>{banner.maxRangeCopy || 'Most likely'}</span>
              </div>
            </div>

            <Button
              type="guide"
              className="ml-4 flex-shrink-0"
              text={cta?.label || 'Submit'}
              onClick={handleRangeCta}
              disabled={selectedValue === null}
            />
          </div>
        )}
      </div>

      {cta && !isRange && (
        <Button
          type="guide"
          className="flex-shrink-0"
          text={cta.label}
          onClick={handleCta}
        />
      )}

      <button onClick={handleDismiss}>
        <XMarkIcon className="absolute right-4 top-4 w-4 h-4" />
      </button>
    </div>
  )
}

export default Banner
