import { gql } from '@apollo/client'

export const appStateFragment = gql`
  fragment AppStateFragment on AppState {
    currentCompanyIdCourseId
    activeChapterId
    isHelpEnabled
    isEditing
    isCreatingCourse
    switchToAddedCourse
    isLoading
    isDuplicatingCourse
    isImportingPpt
    loggedInAs {
      uid
      email
      token
    }
    signatures
  }
`
