import { gql } from '@apollo/client'
import { progressReviewUpdatedFragment, progressReviewTimestampsUpdatedFragment } from 'graphql/fragments/Reviews'
import { userSubscriptionFragment } from 'graphql/fragments/UserSubscription'
import { progressTimestampsUpdatedFragment } from 'graphql/fragments/Progress'
import { courseEmbedDataUpdatedFragment, courseTimestampsUpdatedFragment } from 'graphql/fragments/Course'

export type UserSubscriptionChangesRes = {
  listenUserSubscriptionChanges: any;
}

export const listenUserSubscriptionChangesListener = gql`
  ${userSubscriptionFragment}
  ${progressTimestampsUpdatedFragment}
  ${courseTimestampsUpdatedFragment}
  ${courseEmbedDataUpdatedFragment}
  ${progressReviewUpdatedFragment}
  ${progressReviewTimestampsUpdatedFragment}

  subscription UserSubscriptionChanges ($companyId: String!, $courseId: String!, $userId: String!) {
    listenUserSubscriptionChanges (companyId: $companyId, courseId: $courseId, userId: $userId) {
      ...UserSubscriptionFragment
      ...ProgressTimestampsUpdatedFragment
      ...CourseTimestampsUpdatedFragment
      ...CourseEmbedDataUpdatedFragment
      ...ProgressReviewUpdatedFragment
      ...ProgressReviewTimestampsUpdatedFragment
    }
  }
`
