import { gql } from '@apollo/client'
import { purchaseSubscriptionFragment } from 'graphql/queries/user/getUser'

export type UserChangesRes = {
  listenUserChanges: any;
}

export const listenUserChangesSubscription = gql`
  ${purchaseSubscriptionFragment}

  subscription UserChanges ($userId: String!) {
    listenUserChanges (userId: $userId) {
      ... on UserUpdated {
        id
        user {
          id
          profile {
            id
            currentSubscription
            imageUrl
          }
          subscriptions
          archivedSubscriptions
          editorCourses
          editorCompanies
          favoritedSubscriptions
          agentCompanies
          managerOrganizations
          creatorInOrganizations
          memberOfOrganizations
          allowCourseCreation
          features
          roles
          purchases {
            subscriptions {
              ...PurchaseSubscriptionFragment
            }
          }

          editorCourseNotifications {
            userId
            courseId
            distributionStatusUpdates
          }
          pinnedCompanyIds
        }
      }

      ... on UserRemoved {
        id
      }
    }
  }
`
