import { gql } from '@apollo/client'

export const postFragment = gql`
  fragment PostFragment on Post {
    id
    userId
    companyId
    courseId
    chapterId
    actionId
    contentType
    title
    answer
    comment
    thumbnail
    commentsCount
    likesCount
    likedByMe
    createdAt
    lastCommentAt
    commentsLastSeenAt
    secondsUsed
    user {
      id
      profile {
        id
        firstName
        lastName
        fullName
        imageUrl
      }
    }
    chapter2 {
      id
      order
      title
    }
    action2 {
      id
      title
      description
      userAction
    }
  }
`

export const postNoEmbedFragment = gql`
  fragment PostNoEmbedFragment on PostNoEmbed {
    id
    userId
    companyId
    courseId
    chapterId
    actionId
    contentType
    title
    answer
    comment
    thumbnail
    commentsCount
    likesCount
    likedByMe
    createdAt
    lastCommentAt
    commentsLastSeenAt
    secondsUsed
  }
`
