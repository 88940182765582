import { Chapter } from '../chapter/Chapter'
import { Timestamps, UserProgress } from './UserProgress'

// export type EngagementProctorStatus = 'no_results'|'accepted'|'rejected'

// export type EngagementProctorResults = {
//   status: EngagementProctorStatus;
//   durationInMinutes: number;
//   startedAt: number;
//   endedAt: number;
//   score: number;
//   comment: string;
// }

export type UserEngagement = {
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  chapterId: string;
  engagement: number;
  assessmentsStartedAt: number;
  assessmentsEnded: boolean;
  timestamps: Timestamps;
  reviewTimestamps: Timestamps;
  // assessmentsHasProctoring?: boolean;
  // assessmentsProctorResults?: EngagementProctorResults;
}

export function chapterAssessmentsEnded(
  ch: Pick<Chapter, 'assessmentMode'>,
  eng: Pick<UserEngagement, 'assessmentsEnded'|'assessmentsStartedAt'>|null,
  progress: Pick<UserProgress, 'assessmentsCompleted'>|null,
) {
  if (progress?.assessmentsCompleted) return true
  if (!ch.assessmentMode?.timeLimit) return false
  if (!eng) return false
  return eng.assessmentsEnded ||
    (Date.now() > eng.assessmentsStartedAt + ch.assessmentMode.timeLimit)
}
