import React from 'react'
import intl from 'react-intl-universal'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import { useQuery } from '@apollo/client'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import { getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { getPostsForSubscription2Query } from 'graphql/queries/group/getPostsForSubscription'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetCurrentSubscriptionRes, GetUserAndActiveCourseResponse, getCurrentSubscriptionQuery, getUserAndActiveCourseQuery } from 'graphql/queries/user/getUser'
import { isAgent, User } from 'graphql/schemas/user/User'
import { Post } from 'graphql/schemas/group/Post'
import { MiniLoader } from 'components/utils/MiniLoader'
import InfoBox from 'components/utils/InfoBox'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { ShareDetails } from './ShareDetails'
import { SharesGrid } from './Grid'
import { Button } from 'components/utils/Button'
import { useNavigate } from 'react-router-dom'
import { withRouter } from 'components/utils/withRouterWrapper'

const ShareWrapper = ({ router }) => {
  const navigate = useNavigate()
  const { data: app } = useQuery<AppState>(getAppStateQuery)
  
  const [companyId, courseId] = app?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const shareId = router.params.shareId
  const [, , chapterId, actionId, userId] = shareId && shareId.split('-') || ['', '', '', '', '']


  const { data: sub, loading: userLoading, error: userError } = useQuery<GetCurrentSubscriptionRes>(getCurrentSubscriptionQuery, {
    variables: { userId: app?.appState.loggedInAs.uid || '' },
    errorPolicy: 'all',
    fetchPolicy: 'cache-only',
  })

  const isEditor = sub?.user?.editorCourses.includes(courseId)

  const { data: userData, refetch } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !app?.appState.currentCompanyIdCourseId,
    variables: { companyId, courseId, userId: app!.appState.loggedInAs.uid },
    errorPolicy: 'all',
  })

  const { data: items, loading: itemsLoading, error } = useQuery(getPostsForSubscription2Query, {
    variables: {
      companyId,
      courseId,
      chapterIds: [chapterId || ''],
      actionIds: [actionId],
      // userIds: [userId],
      limit: 100,
      sortBy: 'createdAt',
      sortDirection: 'desc',
      type: 'all',
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    skip: !router.params.shareId, 
  })

  const { data: chapters, loading: chaptersLoading } = useQuery(getChaptersForCourseQuery, {
    skip: !app || !userId || !app.appState.currentCompanyIdCourseId,
    variables: {
      companyId,
      courseId,
      returnAllAssessments: isEditor,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  })

  if (!shareId) return null

  if (!userData || userLoading
    || !sub
    || !items || !items.posts || itemsLoading
    || !chapters || chaptersLoading) {
    return <FullscreenLoader />
  }

  const user: User = sub?.user
  const itemsPost: Post[] = items?.posts?.posts || []
  const users: User[] = items?.posts?.users || []

  const share = itemsPost.find(s => s.companyId === companyId && s.courseId === courseId && s.chapterId === chapterId && s.actionId === actionId && s.userId === userId)
  const shareUser: Pick<User, 'id'|'profile'>|undefined = share && users.find(u => u.id === share.userId)
  const chapter = items?.posts?.chapters?.find(c => c.id === chapterId)
  const action = items?.posts?.actions?.find(a => a.id === actionId)

  const openShare = (share: Post) => {
    const str = [share.companyId, share.courseId, share.chapterId, share.actionId, share.userId].join('-')
    navigate(`/learn/share/${str}`)
  }

  const goBack = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    window.history.back()
  }

  if (!share) {
    return (
      <InfoBox
        emoji="🧐"
        title="We couldn't find the shared item you are looking for."
        text={
          <div className="space-x-3">
            <Button type="danger" text="Open Group" onClick={() => navigate('/learn/group')} />
            <Button type="primary" text="Open Activity" onClick={() => navigate('/activity')} />
          </div>
        }
      />
    )
  }

  return (
    <div className="container stick-container journey journey-single">
      <div className="w-full">
        <div className="bg-white mt-4 pt-3 pb-2 px-5 flex items-center rounded-sm shadow-lg">
          <a href="#" onClick={goBack}>
            <img src="/images/icon-back-red.png" className="inline mr-2 mb-1" />
            {intl.get('global_back')}
          </a>
        </div>
      </div>

      <div className="w-full mt-4">
        <ShareDetails
          userId={userId}
          share={share}
          chapter={chapter}
          action={action}
          shareUser={shareUser}
          details={true}
        />

        {!actionId.startsWith('POST') && (
          <QueryComponent
            companyId={companyId}
            courseId={courseId}
            chapterId={chapterId}
            actionId={actionId}
            userId={shareUser?.id || ''}
            isEditor={user.editorCourses.includes(share.courseId)}
            isAgent={isAgent(user, undefined, share.companyId)}
          />
        )}
      </div>
    </div>
  )
}

export const Share = withRouter(ShareWrapper)

const QueryComponent = ({ companyId, courseId, chapterId, actionId, userId, isEditor, isAgent }) => {
  const variables = {
    companyId,
    courseId,
    chapterIds: [chapterId || ''],
    actionIds: [actionId],
    limit: 100,
    sortBy: 'createdAt',
    sortDirection: 'desc',
    type: 'all',
  }
  const { data, loading, error } = useQuery(getPostsForSubscription2Query, { variables })

  const { data: chaptersData, loading: chaptersLoading } = useQuery(getChaptersForCourseQuery, { variables: {
    companyId,
    courseId,
    returnAllAssessments: isEditor,
  }})

  if (error) {
    return <div>Error</div>
  }

  if (loading || chaptersLoading) {
    return <MiniLoader />
  }

  const posts = { posts: data.posts.posts.filter(p => p.userId !== userId), users: data.posts.users }
  const users: Pick<User, 'id'|'profile'>[] = data.posts.users
  const chapters: Chapter[] = chaptersData.chapters

  return (
    <SharesGrid
      uid={userId}
      isAgent={isAgent}
      posts={posts}
      users={users}
      chapters={chapters}
      otherShares={true}
    />
  )
}
