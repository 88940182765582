import { gql } from '@apollo/client'
import { userSubscriptionFragment } from 'graphql/fragments/UserSubscription'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'

type Explorescreen = {
  categories: ExploreCategory[];
  defaultBundleId: string|null;
  freeTrialAllowedOnBundleIds: string[];
}

export type ExploreBundle = {
  bundleId: string;
  bundleTitle: string;
  isDefaultBundle: boolean;
  categories: ExploreCategory[];
  freeTrialAllowed: boolean;
  inappProductIds: string[];
  order: number;
}

type Explorescreen2 = {
  defaultBundleId: string|null;
  bundles: ExploreBundle[];
}

export type ExploreCategory = {
  id: string;
  title: string;
  order: number;
  subscriptions: UserSubscription[];
}

export type GetExplorescreenDataVars = {
  userId: string;
}

export type GetExplorescreenDataRes = {
  res: Explorescreen;
}

export type GetExplorescreenData2Res = {
  res: Explorescreen2;
}

export const getExplorescreenDataQuery = gql`
  ${userSubscriptionFragment}

  query GetExplorescreenData ($userId: String!) {
    res: getExplorescreenData (userId: $userId) {
      defaultBundleId
      freeTrialAllowedOnBundleIds
      categories {
        id
        title
        order
        subscriptions {
          ...UserSubscriptionFragment
        }
      }
    }
  }
`

export const getExplorescreenData2Query = gql`
  ${userSubscriptionFragment}

  query GetExplorescreenData2 ($userId: String!) {
    res: getExplorescreenData2 (userId: $userId) {
      defaultBundleId
      bundles {
        bundleId
        freeTrialAllowed
        bundleTitle
        isDefaultBundle
        inappProductIds {
          id
          billingCycle
          productId
        }
        order
        categories {
          id
          title
          order
          subscriptions {
            ...UserSubscriptionFragment
          }
        }
      }
    }
  }
`

export const getDefaultBundleIdQuery = gql`
  query GetExplorescreenData ($userId: String!) {
    res: getExplorescreenData (userId: $userId) {
      defaultBundleId
    }
  }
`

export const getFreeTrialsQuery = gql`
  query GetExplorescreenData ($userId: String!) {
    res: getExplorescreenData (userId: $userId) {
      freeTrialAllowedOnBundleIds
    }
  }
`

export type GetExploreCoursesRes = {
  res: {
    subscriptions: UserSubscription[];
    subscriptionsCount: number;
  };
}

export type GetExploreCoursesVars = {
  userId: string;
  categories?: string[];
  searchText?: string;
  limit?: number;
}

export const getExploreCoursesQuery = gql`
  ${userSubscriptionFragment}

  query GetExploreCourses ($userId: String!, $categories: [String!], $searchText: String, $limit: Float) {
    res: getExploreCourses (userId: $userId, categories: $categories, searchText: $searchText, limit: $limit) {
      subscriptions {
        ...UserSubscriptionFragment
      }
      subscriptionsCount
    }
  }
`
