import { gql } from '@apollo/client'

export type SyncScormRegistrationDataVars = {
  registrationId: string;
}

export type SyncScormRegistrationDataRes = {
  syncScormRegistrationData: {
    completed: boolean;
  };
}

export const syncScormRegistrationDataMutation = gql`
  mutation SyncScormRegistrationData ($registrationId: String!) {
    syncScormRegistrationData (registrationId: $registrationId) {
      completed
    }
  }
`
