import { gql } from '@apollo/client'
import { ChaptersUnlocked, CourseDifficulty } from 'graphql/schemas/course/Course'

export type CourseDuplicateVars = {
  courseId: string;
  courseDuplicate: {
    title?: string;
    curatorName?: string;
    curatorTitle?: string;
    curatorCompany?: string;
    curatorBio?: string;
    curatorPhotoUrl?: string;
    curatorChatEnabled?: boolean;
    category?: string;
    categoryText?: string;
    skills?: string[];
    difficulty?: CourseDifficulty;
    language?: string;
    titleEnglish?: string;
    chaptersUnlocked: ChaptersUnlocked;
  };
}

export const duplicateCourseMutation = gql`
  mutation DuplicateCourse ($courseId: String!, $courseDuplicate: CourseDuplicate!) {
    duplicateCourse (courseId: $courseId, courseDuplicate: $courseDuplicate) {
      id
      testCompanyId
    }
  }
`
