import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { shareLikeSeen } from 'actions/likes_actions'
import { augmentName, augmentExplain } from 'utils/utils'
import { Action } from 'graphql/schemas/action/Action'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { Post } from 'graphql/schemas/group/Post'
import { User } from 'graphql/schemas/user/User'
import { br2nl, createMarkup, nl2br, stripHtml, truncate } from 'utils/functions'
import { localeFormatDistance } from 'utils/dateFunctions'
import { getSignedValue } from 'actions/files'
import { ApolloProvider } from '@apollo/client'
import { client } from 'app'
import InputField from 'components/chat/InputField'
import LikesCounter from './LikesCounter'
import ProfilePictureWrapper from 'components/utils/ProfilePicture'
import { SimpleMessageList } from 'components/chat/SimpleMessageList'
import { showModal } from 'components/utils/CustomModal'
import { MiniPostLikes } from 'components/activity/PostLikes'
import { useNavigate } from 'react-router-dom'
import { history } from 'utils/history'

type ShareDetailsProps = {
  userId: string;
  share: Post;
  chapter?: Chapter;
  action?: Action;
  shareUser: Pick<User, 'id'|'profile'>|undefined;
  details?: boolean;
  onClose?: () => void;
}

export const ShareDetails = ({ userId, share, chapter, action, shareUser, details, onClose }: ShareDetailsProps) => {
  const navigate = useNavigate()

  const [showVideo, setShowVideo] = useState(false)
  const [commentsCount, setCommentsCount] = useState(share.commentsCount)

  const shareKey = { companyId: share.companyId, courseId: share.courseId, chapterId: share.chapterId, actionId: share.actionId, userId: share.userId }
  const shareUrn = `share-${share.companyId}-${share.courseId}-${share.chapterId}-${share.actionId}-${share.userId}`

  useEffect(() => {
    shareLikeSeen(share)
  }, [share.id])

  const replyTo = (e: React.SyntheticEvent<any>) => {
    e.preventDefault()

    const postKey = `${share.companyId}-${share.courseId}-${share.chapterId}-${share.actionId}-${share.userId}`
    const titleEncoded = encodeURIComponent(share.title)
    const textEncoded = `:${encodeURIComponent(share.contentType === 'photo' || share.contentType === 'post_image' ? share.comment.substring(0, 150) : share.answer.substring(0, 150))}`
    const imageUrlEncoded = share.contentType === 'photo' || share.contentType === 'post_image' ? `:${encodeURIComponent(share.answer)}` : ''
    const embedded = `{{post:${postKey}:${titleEncoded}${textEncoded}${imageUrlEncoded}}}`
    sessionStorage.setItem('replyTo', embedded)

    onClose && onClose()
    return history.navigate && history.navigate(`/chat/${share.userId}`)
  }

  const viewOtherShares = (e: React.SyntheticEvent<any>) => {
    e.preventDefault()
    const postKey = `${share.companyId}-${share.courseId}-${share.chapterId}-${share.actionId}-${share.userId}`
    return history.navigate && history.navigate(`/learn/share/${postKey}`)
  }

  const description = action?.description && createMarkup(nl2br(augmentName(augmentExplain(action.description), shareUser))) || null

  return (
    <div className="grid grid-cols-5 bg-deadyellow h-full shadow-lg" style={{ minHeight: 550 }}>
      <div className="col-span-3 bg-white overflow-y-auto h-full" style={{ maxHeight: 550 }}>
        {!details && (
          <div className="p-4 pb-3 md:hidden">
            <i className="icon icon-x" onClick={onClose} />
          </div>
        )}

        <ShareContentTypeDetails
          showVideo={showVideo}
          shareThumbnail={share.thumbnail}
          shareComment={share.comment}
          shareAnswer={share.answer}
          shareContentType={share.contentType}
          title={action?.title && augmentName(action.title, shareUser) || null}
          description={description}
          setShowVideo={setShowVideo}>
          <>
            {['photo', 'qa'].indexOf(share.contentType) >= 0 && chapter && action &&
              <PhotoQaDescription
                fullName={shareUser?.profile.fullName}
                actionTitle={augmentName(action.title, shareUser)}
                augmentedDescription={description}
              />
            }

            {share.contentType === 'word_puzzle' && (
              <div className="p-8"><strong>{shareUser?.profile.fullName}</strong> solved it in {share.secondsUsed || 0} seconds</div>
            )}
          </>
        </ShareContentTypeDetails>
      </div>

      <div className="col-span-2 border-l border-lightwarmgray p-4 flex flex-col min-h-0" style={{ maxHeight: 550 }}>
        <div className="flex flex-col h-full">
          <WhoIsSharing
            user={shareUser}
            shareUserFullName={shareUser?.profile.fullName || 'User deleted'}
            shareUserId={share.userId}
            shareCreateAt={share.createdAt}
          />

          <div className="border-t border-lightwarmgray my-4" />

          <Comments
            commentsCount={commentsCount}
            shareUrn={shareUrn}
            userId={userId}
          />

          <OtherShares
            viewOtherShares={viewOtherShares}
            hasDetails={!details && !share.title.startsWith('Post')}
          />

          <div className="border-t border-lightwarmgray mt-2 pt-4">
            <CommentsAndLikes
              shareKey={shareKey}
              shareUrn={shareUrn}
              details={details}
              share={share}
              likedByMe={share.likedByMe}
              likesCount={share.likesCount}
              commentsCount={commentsCount}
              showReplyTo={shareUser?.id !== userId}
              setCommentsCount={setCommentsCount}
              replyTo={replyTo}
              close={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ShareContentTypeDetails = ({ showVideo, shareThumbnail, setShowVideo, shareComment, shareAnswer, shareContentType, title, description, children }) => {
  const signedAnswer = shareAnswer && getSignedValue(shareAnswer) || null

  return (
    <div className="text-pretty mr-0 flex-1 h-full">
      {(() => {
        switch (shareContentType) {
          case 'photo':
            return <SharedVideo title={title} description={description} showVideo={showVideo} shareAnswer={shareAnswer} shareThumbnail={shareThumbnail} setShowVideo={setShowVideo} />
          case 'qa':
            return (
              <>
                {children}
                <div className="p-8 pt-0 text-sm" dangerouslySetInnerHTML={createMarkup(nl2br(shareAnswer))} />
              </>
            )
          case 'word_puzzle':
            return children

          case 'post_text':
            return (
              <>
                {children}
                <div className="p-8" dangerouslySetInnerHTML={createMarkup(nl2br(shareAnswer))} />
              </>
            )
          case 'post_video':
            return <PostedVideo showVideo={showVideo} shareAnswer={shareAnswer} shareThumbnail={shareThumbnail} setShowVideo={setShowVideo} />
          case 'post_image':
            return (
              <img className="w-full h-full object-cover rounded-l-sm" src={signedAnswer} />
            )
        }
      })()}
    </div>
  )
}

const PhotoQaDescription = ({ actionTitle, fullName, augmentedDescription }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      <div className="action mx-8 pt-8 py-4 border-b border-lightwarmgray mb-4 text-teak">
        <div className="title font-bold">
          {actionTitle}
        </div>

        {augmentedDescription && (
          <>
            <div className={`${expanded ? '' : 'truncate-overflow'} question my-2 leading-relaxed`} dangerouslySetInnerHTML={augmentedDescription} />

            <button className="text-sm" onClick={() => setExpanded(!expanded)}>{expanded ? intl.get('less') : intl.get('more')}</button>
          </>
        )}
      </div>

      <div className="mx-8 font-bold text-sm">{fullName}</div>
    </div>
  )
}

const CommentsAndLikes = ({ shareUrn, shareKey, commentsCount, details, share, likesCount, likedByMe, showReplyTo, setCommentsCount, replyTo, close }) => {
  return (
    <div className="share-like">
      <div className="flex mb-3">
        <div className="flex items-center">
          <LikesCounter
            key={share.id}
            shareKey={shareKey}
            defaultCount={likesCount}
            defaultLikedByMe={likedByMe}
            skipCheck={true}
            details={true}
          />

          {likesCount > 0 && (
            <div className="inline text-sm ml-1.5">
              <button className="hover:text-actions-multiple_choice" onClick={() => {
                close && close()
                showModal({
                  title: intl.get('activity_likes'),
                  component: <MiniPostLikes shareKey={shareKey} />,
                  hideFooter: true,
                })
              }}>
                ({intl.get('useraction_view').toLocaleLowerCase()})
              </button>
            </div>
          )}
        </div>

        <div className="flex-1 text-right text-sm">
          {showReplyTo && (
            <button className="inline-flex items-center hover:text-actions-multiple_choice" onClick={replyTo}>
              <i className="icon icon-envelope-light font-bold mr-2 text-lg -mt-1" />
              {intl.get('share_reply_private')}
            </button>
          )}
        </div>
      </div>

      <ApolloProvider client={client}>
        <InputField
          key={shareUrn}
          disableAutoFocus={window.innerWidth < 640}
          activeUrn={shareUrn}
          updateCounter={true}
          singleShare={details || false}
          classname="w-full rounded-md"
          onSubmit={() => setCommentsCount(commentsCount + 1)}
        />
      </ApolloProvider>
    </div>
  )
}

const SharedVideo = ({ showVideo, shareThumbnail, shareAnswer, title, description, setShowVideo }) => {
  const signedAnswer = shareAnswer && getSignedValue(shareAnswer) || null
  const signedThumb = shareThumbnail && getSignedValue(shareThumbnail) || null

  if (signedThumb) {
    return (
      <div className="photo relative h-full">
        {showVideo
          ? <video src={signedAnswer || ''} className="w-full h-full" controls>
              {intl.get('video_not_supported')}
            </video>
          : <div onClick={() => setShowVideo(true)} className="relative">
              <img src={signedThumb} className="w-full" />
              <img src="/images/play-button.png" style={sharingDetailsStyle.images} />
            </div>
        }
      </div>
    )
  }

  if (shareAnswer === 'https://be.gnowbe.com/video_processing_error') {
    return (
      <div className="p-5">Can't display video 😢</div>
    )
  }

  const descBox = (
    <div className="absolute bottom-0 left-0 right-0 opacity-0 group-hover:opacity-100 transition bg-deepgray bg-opacity-80 text-white p-4 space-y-3">
      {title && <div className="font-medium">{truncate(title, 280)}</div>}
      {description && <div className="text-sm" dangerouslySetInnerHTML={{ __html: nl2br(truncate(stripHtml(br2nl(description.__html)), 280)) }} />}
    </div>
  )

  return (
    <div className="relative w-full h-full group">
      <img src={signedAnswer} className="w-full h-full object-cover rounded-l-sm" />
      {description && descBox}
    </div>
  )
}

const PostedVideo = ({ showVideo, shareAnswer, shareThumbnail, setShowVideo }) => {
  const signedAnswer = getSignedValue(shareAnswer)
  const signedThumb = getSignedValue(shareThumbnail)

  return (
    <div className="h-full">
      {showVideo
        ? <video src={signedAnswer} className="h-full w-full" controls>
            {intl.get('video_not_supported')}
          </video>
        : <div
            className="photo relative h-full overflow-hidden"
            onClick={() => setShowVideo(true)}>
            <img src={signedThumb} className="w-full h-full object-cover rounded-l-sm" />
            <img src="/images/play-button.png" style={sharingDetailsStyle.images} />
          </div>
      }
    </div>
  )
}

const sharingDetailsStyle = {
  wrapper: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
  profileImg : { width: 45, height: 45, borderRadius: 45, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginRight: 10, backgroundColor: '#eee8d9' },
  images: { position: 'absolute' as 'absolute', left: '50%', marginLeft: '-33px', top: '50%', marginTop: '-33px' },
}

const WhoIsSharing = ({ user, shareUserId, shareUserFullName, shareCreateAt }) => {
  const navigate = useNavigate()
  return (
    <div className="author" style={sharingDetailsStyle.wrapper} onClick={() => navigate(`/profile/${shareUserId}`)}>
      <ProfilePictureWrapper
        userId={shareUserId}
        inUser={user}
        additionalStyle={{ ...sharingDetailsStyle.profileImg }}
      />

      <div className="name">
        {shareUserFullName
          ? <strong>{shareUserFullName.substring(0, 50) + (shareUserFullName.length > 53 ? '...' : '')}</strong>
          : <strong>User</strong>
        }

        <div className="time text-xs text-gray-600">
          <span className="time">{localeFormatDistance(shareCreateAt, Date.now())}</span>
        </div>
      </div>
    </div>
  )
}

const Comments = ({ commentsCount, shareUrn, userId }) => {
  return (
    <div className="comments flex-1 overflow-auto text-sm">
      {commentsCount > 0
        ? <ApolloProvider client={client}>
            <SimpleMessageList
              activeUrn={shareUrn}
              myUserId={userId}
            />
          </ApolloProvider>
        : <div className="text-gray-400 text-center mt-16">
            {intl.get('chat_no_comments')}
          </div>
      }
    </div>
  )
}

const OtherShares = ({ viewOtherShares, hasDetails }) => {
  if (!hasDetails) return null

  return (
    <div className="text-center uppercase text-sm border-t border-lightwarmgray mt-4 pt-3 mb-0.5 -mx-4">
      <button className="text-actions-multiple_choice font-medium hover:text-actions-audio" onClick={viewOtherShares}>
        {intl.get('journey_other_shares')}

        <i className="icon icon-angle-right-light big font-medium ml-2" />
      </button>
    </div>
  )
}
