import { gql } from '@apollo/client'

export const rewardFragment = gql`
  fragment RewardFragment on Reward {
    id
    courseId
    channel
    name
    title
    text
    imageUrl
    showMultipleTimes
    ratingOptionId
    # todo(rudik): fetch only for curators
    delay
    eventName
    eventData
  }
`

export const editorRewardFragment = gql`
  fragment EditorRewardFragment on Reward {
    delay
    eventName
    eventData
  }
`
