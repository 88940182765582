import { gql } from '@apollo/client'
import { Course } from 'graphql/schemas/course/Course'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { Action } from 'graphql/schemas/action/Action'
import { Reward } from 'graphql/schemas/action/Reward'

export type CourseUpdateBulk = {
  // courseId: string;
  // update: {
    courseId: string;
    courseUpdate: Course|{};
    chapters: { chapterId: string; chapterUpdate: Chapter }[]|[];
    actions: { actionId: string; actionUpdate: Action }[]|[];
    rewards: { rewardId: string; rewardUpdate: Reward }[]|[];
  // }|{};
}

export const updateCourseBulkMutation = gql`
  mutation UpdateCourseBulk ($courseId: String!, $update: CourseUpdateBulk!) {
    updateCourseBulk (courseId: $courseId, update: $update)
  }
`
