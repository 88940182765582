import React from 'react'
import intl from 'react-intl-universal'
import { JoinCompanyMutationRes } from 'graphql/mutations/company/joinCompanyMutation'
import { changeCurrentCourse } from 'actions/users_actions'
import { getSignedValue } from 'actions/files'
import { Button } from 'components/utils/Button'
import { useQuery } from '@apollo/client'
import { getCourseQuery } from 'graphql/queries/course/getCourse'

const JoinGroupModal = ({ data, courseId, hideModal }: { data: JoinCompanyMutationRes | undefined, courseId?: string|undefined, hideModal: () => void }) => {
  if (!data) return <div>{intl.get('global_something_went_wrong')}</div>

  const { data: courseData, loading, error } = useQuery(getCourseQuery, {
    skip: !courseId,
    variables: { courseId },
  })

  const dataCompanyCoursesSorted =  data.company.courses.sort((a, b) => a.title.localeCompare(b.title))

  if (courseId) {
    return (
      <div className="flex flex-col h-full bg-white divide-lightwarmgray">
        <div className="text-actions-multiple_choice text-center pt-4 font-bold">{intl.get('program_details_invited')}</div>
        <p className="text-actions-multiple_choice text-center pb-3">{intl.get('program_details_note_invited')}</p>

        <div className="flex-1 flex flex-col px-8 py-8 bg-deadyellow" style={{ height: 'calc(100% - 6rem)' }}>
          <div className="flex items-start mx-6">
            {courseData?.course?.imageUrl && (
              <div className="mr-4 rounded-md w-[88px] h-[88px] bg-gray-200 shrink-0">
                <img src={getSignedValue(courseData?.course?.imageUrl)} className="w-full h-full object-cover rounded-md" />
              </div>
            )}

            <div>
              <div className="text-xl font-bold mb-1">{courseData?.course?.title}</div>
              <span className="text-medgray font-medium">{data.company.groupName || data.company.name}</span>

              <div className="italic text-medgray mt-1 text-sm" dangerouslySetInnerHTML={{ __html: courseData?.course?.tagline }} />
            </div>
          </div>

          <div className="flex my-6">
            <div className="flex-1 text-center">
              <div className="uppercase text-lightgray mb-1 text-sm font-medium tracking-wide">{intl.get('minutes', { minutes: courseData?.course?.interactionTime }).toLocaleLowerCase()}</div>
              <div className="font-medium text-xl">{courseData?.course?.interactionTime}</div>
            </div>

            <div className="flex-1 text-center">
              <div className="uppercase text-lightgray mb-1 text-sm font-medium tracking-wide">{intl.get('sessions', { sessions: courseData?.course?.chaptersCount }).toLocaleLowerCase()}</div>
              <div className="font-medium text-xl">{courseData?.course?.chaptersCount}</div>
            </div>

            <div className="flex-1 text-center">
              <div className="uppercase text-lightgray mb-1 text-sm font-medium tracking-wide">{intl.get('actions_label').toLocaleLowerCase()}</div>
              <div className="font-medium text-xl">{courseData?.course?.actionsCount}</div>
            </div>
          </div>

          <Button type="primary" onClick={() => {
            changeCurrentCourse({ courseKey: { companyId: data.company.id, courseId: courseData?.course?.id }, landing: `/details/${courseData?.course?.id}` })
            hideModal()
          }} text={intl.get('start_now_course')} />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full bg-white divide-y divide-lightwarmgray">
      <div className="text-actions-multiple_choice text-center pt-4 pb-3 font-bold">{intl.get('group_details_invited')}</div>

      <div className="flex-1 flex flex-col pl-8 pr-4 py-5 bg-deadyellow" style={{ height: 'calc(100% - 6rem)' }}>
        <div className="mt-2">
          <div className="text-xl font-bold mb-1">{data.company.groupName || data.company.name}</div>
          <span className="text-teak font-medium">in {data.company.organizationName}</span>
        </div>

        <ul className="m-0 mt-4 divide-y divide-lightwarmgray flex-1 overflow-y-auto max-h-96 pr-4">
          {dataCompanyCoursesSorted.map((c) => {
            return (
              <div key={c.id} className="flex items-center py-4">
                {c.imageUrl ? <img src={getSignedValue(c.imageUrl)} className="w-14 h-14 mr-4 object-cover rounded-md" /> : <div className="w-14 h-14 mr-4 object-cover bg-gray-200 rounded-md" />}

                <div className="flex-1">
                  <div className="text-lg font-bold">{c.title}</div>
                  <div className="text-lightgray">
                    {c.interactionTime} {intl.get('minutes', { minutes: c.interactionTime }).toLocaleLowerCase()} &middot; {` `}
                    {c.chaptersCount} {intl.get('sessions', { sessions: c.chaptersCount }).toLocaleLowerCase()} &middot; {` `}
                    {c.actionsCount} {intl.get('actions_label').toLocaleLowerCase()}
                  </div>
                </div>

                <Button type="primary" onClick={() => {
                  changeCurrentCourse({ courseKey: { companyId: data.company.id, courseId: c.id }, landing: `/details/${c.id}` })
                  hideModal()
                }} text={intl.get('start_now_course')} />
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default JoinGroupModal
