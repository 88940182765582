import React from 'react'
import intl from 'react-intl-universal'
import { nl2br } from 'utils/functions'

export const ScormSuccess = ({ title }) => {
  const close = (e) => {
    e.preventDefault()
    const doc = document.getElementById('sliderSuccess')
    if (doc) {
      doc.classList.add('close')
    }
  }

  return (
    <div className="text-center" style={{ width: 450 }}>
      <h1 style={{ marginTop: -40 }}>
        {intl.get('scrom_completed_title')}
      </h1>

      <div
        className="text-2xl font-medium leading-9 text-white font-noto-sans mt-8"
        id="h2-assessment"
        dangerouslySetInnerHTML={{ __html: nl2br(intl.get('scorm_completed_subtitle', { 0: title })) }}
      />

      <div className="mt-12">
        <a href="#" onClick={close}>
          <img src="/images/icon-next-success.png" className="inline-block" />
        </a>
      </div>
    </div>
  )
}