import React from 'react'
import intl from 'react-intl-universal'
import { useQuery } from '@apollo/client'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { getUserQuery } from 'graphql/queries/user/getUser'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { logout } from 'actions/auth_actions'
import { showModal } from 'components/utils/CustomModal'
import { Button } from 'components/utils/Button'
import { trackButtonEnhanced } from 'utils/track'
import { useNavigate } from 'react-router-dom'
import Tippy from '@tippyjs/react'

export const UserMenu = ({ home, isActive, showPlanButtons, accentColor }: { home?: boolean, isActive: boolean, showPlanButtons: boolean, accentColor?: string | null }) => {
  const navigate = useNavigate()
  const { data } = useQuery<AppState>(getAppStateQuery)
  const { data: userData } = useQuery(getUserQuery, { variables: { userId: data?.appState.loggedInAs.uid || '' }, skip: !data?.appState?.loggedInAs?.uid })

  if (!data || !userData) return null

  const gotoRoute = (e, route) => {
    e.preventDefault()
    navigate(route)
  }

  const confirmLogout = (e) => {
    e.preventDefault()

    showModal({
      title: intl.get('log_out_everywhere_title').d('log out from everywhere?'),
      content: intl.get('log_out_everywhere_text').d('would you like to log out here or everywhere?'),
      primaryText: intl.get('log_out_everywhere_button_here'),
      secondaryText: intl.get('log_out_everywhere_button_everywhere'),
      primaryAction: () => logout(),
      secondaryAction: () => logout({ logoutFromAll: true }),
    })
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          {home
            ? <Tippy content={intl.get('global_account')}>
                <MenuButton aria-label="Profile">
                    <span onClick={() => {
                      trackButtonEnhanced({
                        button: 'User Menu',
                        onScreen: 'Home (Horizontal Menu)',
                      })
                    }} className={`icon icon-user-circle-light text-xl font-medium cursor-pointer text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}`} />
                </MenuButton>
              </Tippy>
            : <MenuButton className={`profile-btn ${isActive ? 'selected' : ''} outline-none`} aria-label="Profile">
                <span className="icon-navbar" />
              </MenuButton>
          }

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <MenuItems
              static
              className={`absolute w-56 ${home ? 'right-0 mt-4' : 'right-4 mt-12'} origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none`}>
              <div className="px-4 py-3 bg-deadyellow">
                <div className="text-sm leading-6">{intl.get('logged_in_as')}</div>
                <div className="text-sm font-medium leading-5 text-gray-900 truncate">
                  {userData?.user?.email || 'unknown@gnowbe.com'}
                </div>

                {showPlanButtons && (
                  <Button
                    text={intl.get('view_creator_plan')}
                    className="mt-2"
                    size="small"
                    fullWidth={true}
                    onClick={() => navigate('/settings#plans')}
                  />
                )}
              </div>

              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    onClick={e => gotoRoute(e, '/profile')}
                    className="flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {intl.get('home_my_profile')}
                  </a>
                </MenuItem>

                <MenuItem>
                  <a
                    href="#"
                    onClick={e => gotoRoute(e, '/settings')}
                    className="flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {intl.get('settings_text')}
                  </a>
                </MenuItem>

                <MenuItem>
                  <a
                    href="#"
                    onClick={e => gotoRoute(e, '/home/archive')}
                    className="flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {intl.get('archive')}
                  </a>
                </MenuItem>
{/*
                {hasDashboardAccess(userData.user) &&
                  <Menu.Item>
                    <a
                      href={BASE_DASHBOARD_URL}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="flex w-full px-4 py-2 items-center text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                      Dashboard <img src="/images/external-link-light.svg" className="h-3 inline-block ml-2" />
                    </a>
                  </Menu.Item>
                } */}
{/*
                {!HIDE_HELP.includes(process.env.BUILD || '') &&
                  <Menu.Item>
                    <a
                      href="https://gnowbe.zendesk.com/hc/en-us"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="flex w-full px-4 py-2 items-center text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                      {intl.get('home_help_center')} <img src="/images/external-link-light.svg" className="h-3 inline-block ml-2" />
                    </a>
                  </Menu.Item>
                } */}
              </div>

              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    onClick={confirmLogout}
                    className="flex justify-between w-full px-4 py-2 text-sm text-left hover:bg-gray-100 text-coral">
                    {intl.get('log_out')}
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}
