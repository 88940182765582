import React, { createRef, Dispatch, forwardRef, SetStateAction, useImperativeHandle, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { Wizard, Steps, Step } from 'react-albus'
import { ApolloProvider, useQuery } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { showDrawer } from 'components/utils/Drawer'
import { CourseForm, CoursePicker, TemplatePicker } from 'components/editor/course/New'
import { client } from 'app'
import { Button } from 'components/utils/Button'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { Popover } from 'react-tiny-popover'
import { isCreatorInWorkspace, User } from 'graphql/schemas/user/User'
import { OrderTypes } from 'components/home/HomePrograms'
import { showModal } from 'components/utils/CustomModal'
import { updateMeta } from 'actions/users_actions'
import { GetOrganizationsRes } from 'graphql/queries/organization/getOrganziations'
import MagicCreator from 'components/MagicCreator'
import { useWhiteLabel, WhiteLabelProvider } from 'context/whiteLabel'
import { isColorDark } from 'utils/functions'

type CreateCourseButtonProps = {
  navbar?: boolean;
  home?: boolean;
  fullWidth?: boolean;
  userId: string;
  allowCourseCreation?: boolean;
  isEditing: boolean;
  hideCreateCourseButton: boolean;
  showExploreAndLearnModal: () => void;
  creatorInOrganizationsLength: number;
  setActiveWorkspaceId: Dispatch<SetStateAction<string>>;
  orgData: GetOrganizationsRes|undefined;
  user?: User,
}

export const CreateCourseButton = (props: CreateCourseButtonProps) => {
  // const [isCreating, setIsCreating] = useState(false)
  // const [selectedTemplate, setSelectedTemplate] = useState(undefined)
  // const [isPpt, setIsPpt] = useState(false)
  const { whiteLabelData } = useWhiteLabel()
  const isWorkspaceCreator = isCreatorInWorkspace()
  const primaryColor = whiteLabelData.primaryColor
  const bgColor = whiteLabelData.bgColor

  const showTemplates = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // if (isCreating) {
    //   return alert('Please wait...')
    // }

    const drawer = showDrawer({
      footer: { hide: true },
      maxWidth: 'max-w-3xl',
      fromRight: true,
      onClose: () => {
        if (window.zE) {
          window.zE('webWidget', 'show')
        }
      },
      component: (
        <ApolloProvider client={client}>
          <WhiteLabelProvider>
            <Wizard render={({ step }) => (
              <TransitionGroup className="flex-1 h-full overflow-y-auto sm:overflow-y-hidden sm:overflow-hidden bg-deadyellow">
                <CSSTransition
                  key={step.id}
                  classNames="fade"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  timeout={{ enter: 400, exit: 400 }}>
                  <Steps>
                    <Step
                      id="new"
                      render={({ push }) => (
                        <CoursePicker
                          orgData={props.orgData}
                          onNew={() => {
                            push('scratch')
                          }}
                          onMagic={() => {
                            push('magic')
                          }}
                          onPowerpoint={() => {
                            push('powerPoint')
                          }}
                          onTemplate={() => push('templates')}
                          onScorm={() => push('scorm')}
                          onClose={drawer?.current.close}
                        />
                      )}
                    />

                      <Step
                        id="templates"
                        render={({ push, previous }) => (
                          <TemplatePicker
                            onPrevious={previous}
                            onClose={drawer?.current.close}
                          />
                        )}
                      />

                    <Step
                      id="magic"
                      render={({ previous }) => (
                        <MagicCreator
                          typeOfCreator="course"
                          onPrevious={previous}
                          showZendeskWidget={() => {
                            if (window.zE) {
                              window.zE('webWidget', 'show')
                            }
                          }}
                          closeModal={drawer?.current.close}
                          whiteLabelData={whiteLabelData}
                        />
                      )}
                    />

                    <Step
                      id="scratch"
                      render={({ previous }) => (
                        <CourseForm
                          creatorInOrganizationsLength={props.creatorInOrganizationsLength}
                          isPowerpoint={false}
                          onPrevious={previous}
                          onClose={drawer?.current.close}
                        />
                      )}
                    />

                    <Step
                      id="powerPoint"
                      render={({ previous }) => (
                        <CourseForm
                          creatorInOrganizationsLength={props.creatorInOrganizationsLength}
                          isPowerpoint={true}
                          onPrevious={previous}
                          onClose={drawer?.current.close}
                        />
                      )}
                    />

                    <Step
                      id="scorm"
                      render={({ previous, push }) => (
                        <CourseForm
                          creatorInOrganizationsLength={props.creatorInOrganizationsLength}
                          isScorm={true}
                          isPowerpoint={false}
                          onPrevious={previous}
                          onClose={drawer?.current.close}
                        />
                      )}
                    />
                  </Steps>
                </CSSTransition>
              </TransitionGroup>
            )} />
          </WhiteLabelProvider>
        </ApolloProvider>
      ),
    })
  }

  if (props.hideCreateCourseButton) {
    return null
  }

  if (props.home) {
    return (
      <Button
        id="create-program-button"
        type="team"
        text={intl.get('create')}
        icon="icon-times-light rotate-45 inline-block"
        style={primaryColor ? { '--primary-color': primaryColor } : {}}
        className={`ml-0 md:ml-3 ${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'} ${primaryColor === bgColor && 'shadow-md'}`}
        fullWidth={!!props.fullWidth}
        onClick={(e) => {
          if (props.allowCourseCreation === false || props.creatorInOrganizationsLength === 0) {
            props.showExploreAndLearnModal()
          }
          const enabledOrgs = props.orgData?.res.organizations.filter(o => !o.disabled) || []
          if (!isWorkspaceCreator && enabledOrgs.some(o => o.product === 'CR' || o.product === 'FT') && props.creatorInOrganizationsLength !== 0) {
            const modal = showModal({
              title: '',
              component: (
                <div className="pt-4 pb-2">
                <img className="mt-4 mx-auto rounded-md h-64" src="/images/gnowbe-create-workspace.png" />
                <div className="my-4 font-noto-sans text-2xl text-center font-bold">You do not have permissions to create in this workspace.</div>
                <p>Please change to a workspace where you can create or create your own workspace.</p>
                <div className="space-y-4 mt-4 text-right">
                  <div className="space-x-4">

                    <Button type="guide" text="Cancel" onClick={() => modal.close()}/>

                    <Button type="primary" text="Switch" onClick={() => {
                      const firstFTorCR = enabledOrgs.find(o => (o.product === 'CR' || o.product === 'FT') && props.user?.creatorInOrganizations.includes(o.id))
                      if (firstFTorCR) {
                        props.setActiveWorkspaceId(firstFTorCR.id)
                        updateMeta('activeWorkspaceId', firstFTorCR.id, true)
                        track({
                          event: 'Workspace Changed',
                          variables: {
                            onScreen: 'Home',
                            changedToWorkspaceId: firstFTorCR.id,
                          }
                        })
                        modal.close()
                      }
                    }}/>

                  </div>
                </div>
              </div>
              ),
              onlyContent: true,
            })
            return null
          }
          if (isWorkspaceCreator) {
            trackButton({
              button: 'create_program',
              on_screen: 'home',
            })
            trackButtonEnhanced({
              button: '+ Create',
              onScreen: 'Home',
            })
            showTemplates(e)
          }
        }}
      />
    )
  }

  if (props.navbar) {
    return (
      <a className="new-course-btn flex" onClick={showTemplates}>
        <span className="icon-navbar" />
        <span className="navbar-label hidden xl:inline">{intl.get('create_new_course')}</span>
        <span className="navbar-label hidden md:inline xl:hidden">{intl.get('create')}</span>
      </a>
    )
  }

  // if (props.home) {
  //   return (
  //     <Button
  //       id="create-program-button"
  //       type="team"
  //       text={intl.get('create_program')}
  //       icon="icon-times-light rotate-45 inline-block"
  //       fullWidth={!!props.fullWidth}
  //       onClick={(e) => {
  //         trackButton({
  //           button: 'create_program',
  //           on_screen: 'home',
  //         })
  //         showTemplates(e)
  //       }}
  //     />
  //   )
  // }

  if (props.navbar) {
    return (
      <a className="new-course-btn flex" onClick={showTemplates}>
        <span className="icon-navbar" />
        <span className="navbar-label hidden xl:inline">{intl.get('create_new_course')}</span>
        <span className="navbar-label hidden md:inline xl:hidden">{intl.get('create')}</span>
      </a>
    )
  }

  return (
    <div
      onClick={showTemplates}
      className="button-new">
        <span>{intl.get('new_course').d('New Program')}</span>
        {/* {isCreating
          ? <MiniLoader />
          : <span>{intl.get('new_course').d('New Program')}</span>
        } */}
    </div>
  )
}

const Filter = forwardRef(({ defaultSortOrder, scrollToCompany, updateSort, updateFilter }: { defaultSortOrder: any, scrollToCompany: any, updateSort: any, updateFilter: any }, ref) => {
  const [filter, setFilter] = React.useState('')
  const [order, setOrder] = React.useState(defaultSortOrder)
  const _timeout = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    clearFilter: () => {
      setFilter('')
    },
  }))

  const changeFilter = (filter) => {
    setFilter(filter)
    _timeout.current = clearTimeout(_timeout.current)
    _timeout.current = setTimeout(() => {
      updateFilter(filter)
    }, 250)
  }

  const changeOrder = (order) => {
    updateSort(order)
    setOrder(order)
  }

  return (
    <div className={`course-filter p-4 border-b border-b-lightbrown z-10 bg-deadyellow ${order !== 'default' ? 'mb-4' : ''}`}>
      <div className="flex items-center">
        <div className="relative flex-1">
          <input
            type="text"
            className="w-full filter form-input"
            placeholder={intl.get('filter_programs_hint')}
            value={filter}
            onChange={e => changeFilter(e.target.value)}
          />

          {filter && (
            <span className="icon-x" onClick={(e) => {
              e.stopPropagation()
              setFilter('')
              changeFilter('')
              scrollToCompany()
            }} />
          )}
        </div>

        <div className="relative filter-button ml-2">
          <CourseSortingMenu filter={filter} order={order} newOrder={changeOrder} />
        </div>
      </div>
    </div>
  )
})

export const CourseSortingMenu = ({ filter, order, newOrder }: { filter: string, order: OrderTypes, newOrder: (order: OrderTypes) => void }) => {
  const [hasChanged, setHasChanged] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { whiteLabelData } = useWhiteLabel()

  const changeOrder = (order: OrderTypes) => {
    newOrder(order)

    if (hasChanged) return
    setHasChanged(true)
  }

  let icon = 'icon-sort'
  if (order === 'az') icon = 'icon-sort-alpha-down-light'
  if (order === 'za') icon = 'icon-sort-alpha-up-light'
  if (order === '09') icon = 'icon-sort-numeric-down-light'
  if (order === '90') icon = 'icon-sort-numeric-up-light'

  let sortText = intl.get('sort_by')
  if (hasChanged && (order === 'az' || order === 'za')) sortText = intl.get('sort_program_title')
  if (hasChanged && (order === '09' || order === '90')) sortText = intl.get('completion')
  if (hasChanged && (order === 'recup' || order === 'recdown')) sortText = filter !== 'explore' ? intl.get('program_filter_recent') : intl.get('sort_newest')
  if (hasChanged && (order === 'catup' || order === 'catdown')) sortText = intl.get('sort_category')

  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      positions={['bottom', 'top']}
      align="start"
      content={() => (
        <Transition
          show={isOpen}
          appear={true}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="z-10 w-40 custom-dropdown mt-1">
            <button
              className={`flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${order === 'recup' || order === 'recdown' ? 'font-bold' : ''}`}
              onClick={() => {
                setIsOpen(false)
                changeOrder('recdown')
              }}>
              {filter !== 'explore' ? intl.get('program_filter_recent') : intl.get('sort_newest')}
            </button>

            <button
              className={`flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${order === 'az' || order === 'za' ? 'font-bold' : ''}`}
              onClick={() => {
                setIsOpen(false)
                changeOrder('az')
              }}>
              {intl.get('sort_program_title')}
            </button>

            {filter !== 'explore' && (
              <button
                className={`flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${order === '09' || order === '90' ? 'font-bold' : ''}`}
                onClick={() => {
                  setIsOpen(false)
                  changeOrder('09')
                }}>
                {intl.get('completion')}
              </button>
            )}

            <button
              className={`flex justify-between w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${order === 'catup' || order === 'catdown' ? 'font-bold' : ''}`}
              onClick={() => {
                setIsOpen(false)
                changeOrder('catup')
              }}>
              {intl.get('sort_category')}
            </button>
          </div>
        </Transition>
      )}>
      <button
        className={`text-sm px-3 py-2 rounded-md border font-medium hover:border-actions-multiple_choice ${whiteLabelData.accentColor ? `border-[var(--accent-color)] text-[var(--accent-color)]` : 'border-medgray text-medgray'} ${isOpen ? 'bg-lightwarmgray' : ''}`}
        aria-label="Sort" onClick={() => setIsOpen(!isOpen)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
        </svg>

        {sortText}

        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 text-sm inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
    </Popover>
  )
}
