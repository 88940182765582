import { gql } from '@apollo/client'
import { Chapter } from 'graphql/schemas/chapter/Chapter'

export const assessmentModeFragment = gql`
  fragment AssessmentModeFragment on AssessmentMode {
    enabled
    randomized
    limit
    choicesRandomized
    timeLimit
    # proctoring
  }
`

type AssessmentModeInput = {
  enabled: boolean;
  randomized: boolean;
  limit: number|null;
  choicesRandomized: boolean|null;
  timeLimit: number|null;
  // proctoring: boolean|null;
}

type ChapterUpdate = {
  order?: number;
  title?: string;
  description?: string;
  imageUrl?: string;
  imageUrlAlt?: string;
  videoUrl?: string;
  videoUrl_720p?: string;
  videoUrlThumbnail?: string;
  videoUrlAlt?: string;
  isSequential?: boolean;
  tagline?: string;
  disabledUntilDay?: number;
  assessmentMode?: AssessmentModeInput
  morningPush?: string;
  morningEmail?: string;
}

export type UpdateChapterVars = {
  companyId: string;
  courseId: string;
  chapterId: string;
  chapterUpdate: ChapterUpdate;
}

export type UpdateChapterRes = {
  updateChapter: Chapter;
}

export const updateChapterMutation = gql`
  ${assessmentModeFragment}

  mutation UpdateChapter ($companyId: String, $courseId: String!, $chapterId: String!, $chapterUpdate: ChapterUpdate!) {
    updateChapter (companyId: $companyId, courseId: $courseId, chapterId: $chapterId, chapterUpdate: $chapterUpdate, returnAllAssessments: true) {
      id
      courseId
      order
      title
      description
      imageUrl
      imageUrlAlt
      videoUrl
      videoUrl_720p
      videoUrlThumbnail
      videoUrlAlt
      isSequential
      disabledUntilDay
      morningPush
      morningEmail
      assessmentMode {
        ...AssessmentModeFragment
      }
    }
  }
`
