import { useEffect } from 'react'
// import intl from 'react-intl-universal'
// import { Dialog, Transition } from '@headlessui/react'
import { listenToAuthChangesChangesSubscription } from 'graphql/subscriptions/user/listenToAuthChanges'
import { client } from 'app'
import { logout } from 'actions/auth_actions'
// import { APP_TITLE } from 'constants/General'

export default function () {
  // const [open, setOpen] = React.useState(false)
  useEffect(() => {
    const observer = client.subscribe({
      query: listenToAuthChangesChangesSubscription,
    })
    const subscription = observer.subscribe(({ data }) => {
      if (data?.status?.reasonCode === 'inactivity') {
        subscription.unsubscribe()
        logout({ reason: 'sessionExpired' })
        // setOpen(true)
      }
    })
    return () => subscription.unsubscribe()
  }, [])
  // const close = () => location.reload()
  return null
  // return (
  //   <Transition show={open} as={React.Fragment}>
  //     <Dialog
  //       as="div"
  //       id="modal-inactivity"
  //       className="fixed inset-0 z-10 overflow-y-auto"
  //       static
  //       open={open}
  //       onClose={() => null}>
  //       <div className="min-h-screen px-4 text-center">
  //         <Transition.Child
  //           as={React.Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0">
  //           <Dialog.Overlay className="fixed inset-0" />
  //         </Transition.Child>
  //         <span
  //           className="inline-block h-screen align-middle"
  //           aria-hidden="true">
  //           &#8203;
  //         </span>
  //         <Transition.Child
  //           as={React.Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0 scale-95"
  //           enterTo="opacity-100 scale-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100 scale-100"
  //           leaveTo="opacity-0 scale-95">
  //           <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-2xl">
  //             <Dialog.Title
  //               as="span"
  //               className="text-lg font-medium leading-6 text-gray-900">
  //               {intl.get('pls_log_in')}
  //             </Dialog.Title>
  //             <div className="mt-3">
  //               <p className="text-sm text-gray-500">
  //                 {intl.get('user_logout_reason_inactivity', { 0: APP_TITLE[process.env.BUILD || 'production'], 1: APP_TITLE[process.env.BUILD || 'production'] })}
  //               </p>
  //             </div>
  //             <div className="mt-4 w-full flex flex-row justify-end">
  //               <button
  //                 type="button"
  //                 className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-primary-lakeblue border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-lakeblue"
  //                 onClick={close}>
  //                 {intl.get('activity_login_login_text')}
  //               </button>
  //             </div>
  //           </div>
  //         </Transition.Child>
  //       </div>
  //     </Dialog>
  //   </Transition>
  // )
}
