import { gql } from '@apollo/client'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { userSubscriptionFragment } from 'graphql/fragments/UserSubscription'

export type StartExploreCourseVars = {
  userId: string;
  companyId: string;
  courseId: string;
  bundleIds: string[];
}

export type StartExploreCourseRes = {
  res: UserSubscription;
}

export const startExploreCourseMutation = gql`
  ${userSubscriptionFragment}

  mutation StartExploreCourse ($userId: String!, $companyId: String!, $courseId: String!, $bundleIds: [String!]!) {
    res: startExploreCourse (userId: $userId, companyId: $companyId, courseId: $courseId, bundleIds: $bundleIds) {
      ...UserSubscriptionFragment
    }
  }
`
