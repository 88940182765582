import { gql } from '@apollo/client'
import { progressFragment } from 'graphql/fragments/Progress'

export const getUserAssessmentsResultsQuery = gql`
  ${progressFragment}

  query GetUserSubscription ($companyId: String!, $courseId: String!, $userId: String!) {
    getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      progress {
        ...ProgressFragment
      }
    }
  }
`
