import intl from 'react-intl-universal'
import { produce } from 'immer'
import { client } from 'app'
import * as logger from 'logger'
import { showAlert } from 'components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { getPostsForSubscription2Query } from 'graphql/queries/group/getPostsForSubscription'
import { checkHttpStatus, getAuthTokens } from 'actions/utils'
import { ChatMessage } from 'graphql/schemas/chat/Chat'
import { getChatChannelQuery } from 'graphql/queries/chat/getChat'
import { refetch } from 'utils/functions'
import { track } from 'utils/track'

export function newChatMsg(urn: string, message: string, updateCounter?: boolean, from: string = 'default') {
  return new Promise((res, rej) => {
    const token = localStorage.getItem('token')
    refetch(`${BASE_API_URL}/api/v1/chat`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify({ urn, message }),
    })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        showAlert(`Error - ${response.error.message}`, 'danger')
        return res({ error: true })
      }

      // if we are showing comments counter on share
      if (!updateCounter) return res({ error: false })

      const [pos, companyId, courseId, chapterId, actionId, userId] = urn.split('-')

      let variables: any = {
        companyId,
        courseId,
        limit: 50,
        sortBy: 'createdAt',
        sortDirection: 'desc',
        type: 'all',
      }

      if (from === 'single') {
        variables = {
          companyId,
          courseId,
          chapterIds: [chapterId || ''],
          actionIds: [actionId],
          userIds: [userId],
          limit: 100,
          sortBy: 'createdAt',
          sortDirection: 'desc',
          type: 'all',
        }
      }

      const data = client.readQuery({
        variables,
        query: getPostsForSubscription2Query,
      })

      if (!data || !data['posts'] || !data['posts'].posts) return
      if (!data.posts.posts.some(p => p.companyId === companyId && p.courseId === courseId && p.chapterId === chapterId && p.actionId === actionId && p.userId === userId)) return

      client.writeQuery({
        variables,
        query: getPostsForSubscription2Query,
        data: produce(data, (draft) => {
          const index = draft.posts.posts.findIndex(p => p.companyId === companyId && p.courseId === courseId && p.chapterId === chapterId && p.actionId === actionId && p.userId === userId)
          if (index !== -1) {
            const post = draft.posts.posts[index]
            post.commentsCount = (post.commentsCount || 0) + 1
            post.lastCommentAt = Number(new Date()) - 1
            post.commentsLastSeenAt = Number(new Date())
          }
        }),
      })

      track({
        event: 'Group Share Commented',
        variables: {
          company_id: companyId,
          programId: courseId,
        },
      })
      res({ error: false })
    })
    .catch((err) => {
      res({ error: true })
      logger.error(`chats_actions: newChatMsg error: ${err.message}`, err)
    })
  })
}

export function removeChatMsg(urn: string, message: ChatMessage) {
  const data = client.readQuery({
    query: getChatChannelQuery,
    variables: {
      urn,
      messagesLimit: 46,
    },
  })

  const updateMessage = (text: string, deleted: boolean) => {
    client.writeQuery({
      query: getChatChannelQuery,
      variables: {
        urn,
        messagesLimit: 46,
      },
      data: produce(data , (draft) => {
        const index = draft.chatChannel.messages.findIndex(m => m.id === message.id)
        if (index !== -1) {
          draft.chatChannel.messages[index].message = text
          draft.chatChannel.messages[index].deleted = deleted
        }
      }),
    })
  }

  if (data) {
    updateMessage(intl.get('message_deleted'), true)
  }

  const oldMessage = data?.chatChannel.messages.find(m => m.id === message.id) || null
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/chat/urn/${urn}/message/${message.id} `, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      oldMessage && updateMessage(oldMessage.message, false)
      return showAlert(`Error - ${response.error.message}`, 'danger')
    }
    showAlert(intl.get('message_deleted'), 'success')
  })
  .catch((err) => {
    oldMessage && updateMessage(oldMessage.message, false)
    logger.error(`chats_actions: removeChatMsg error: ${err.message}`, err)
  })
}

export function chatRead(urn: string) {
  const token = localStorage.getItem('token')
  refetch(`${BASE_API_URL}/api/v1/chat/read`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({urn}),
  })
  .then(response => checkHttpStatus(response))
  .then(response => response.json())
  .then(async (response) => {
    if (response.error) {
      return showAlert(`Error - ${response.error.message}`, 'danger')
    }
  })
  .catch((error) => {
    const err = Error(error)
    logger.error(`chats_actions: chatRead error: ${err.message} (${err.stack})`, err)
  })
}
