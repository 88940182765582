import intl from 'react-intl-universal'
import jwtDecode from 'jwt-decode'
import { BASE_API_URL, GNOWBE_SOURCE } from '../constants/General'
import { getAuthTokens, checkHttpStatus } from './utils'
import { client } from 'app'
import { getUserAnonymitySettingsQuery } from 'graphql/queries/user/getUser'
import { produce } from 'immer'
import { refetch } from 'utils/functions'
import { slog } from 'utils/dateFunctions'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { showAlert } from 'components/utils/Alert'
import { showModal } from 'components/utils/CustomModal'
import { UpsellModal } from 'components/modals/UpsellModal'
import React from 'react'

export function changePrivacy(companyId: string, newPrivacy: Object, newVal: string[]) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)

  const clientData: any = client.readQuery({
    query: getUserAnonymitySettingsQuery,
    variables: {
      userId: authToken.uid,
    },
  })

  if (clientData) {
    client.writeQuery({
      query: getUserAnonymitySettingsQuery,
      variables: {
        userId: authToken.uid,
      },
      data: produce(clientData, (draft) => {
        draft.user.anonymitySettings = draft.user.anonymitySettings
        .filter(s => s.companyId !== companyId)
        .concat([{ companyId, anonymitySettings: newVal, __typename: 'UserCompanyAnonymitySettings' }])
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      data: clientData,
      query: getUserAnonymitySettingsQuery,
      variables: {
        userId: authToken.uid,
      },
    })
  }

  refetch(`${BASE_API_URL}/api/v1/user/anonymity_settings`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify(newPrivacy),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
  })
}

export function inviteByEmail(companyId: string, email: string) {
  return new Promise<{ error: boolean, reason?: string }>((res, rej) => {
    const app: AppState|null = client.readQuery({
      query: getAppStateQuery,
    })
    if (!app) {
      slog.e('app is empty, exiting.')
      return
    }
    refetch(`${BASE_API_URL}/api/v1/companies/${companyId}/invite_by_email`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${app.appState.loggedInAs.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify({
        email,
      }),
    })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if(response.error && response.error.message.includes('Organization reached')) {
        const modal = showModal({
          title: intl.get('home_group_invite'),
          component: <UpsellModal type="try_starter" track="learner_limit" close={() => modal.close()} />,
          onlyContent: true,
          className: 'add-action-modal',
        })
        res({ error: true, reason: "close modal" })
        return modal
      }
      if (response.error) {
        return showAlert(intl.get('invitation_not_sent'), 'danger')
        rej({ error: true })
      }
      showAlert(intl.get('invitation_sent'), 'success')
      res({ error: false })
    })
    .catch((err) => {
      showAlert(intl.get('invitation_not_sent'), 'danger')
      rej({ error: true })
    })
  })
}
