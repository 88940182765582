/* eslint-disable react/display-name */
import React, { Fragment, memo, useEffect } from 'react'
import intl from 'react-intl-universal'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { updateMeta } from 'actions/users_actions'
import { updateAppCache } from 'app'
import { UpsellModal } from 'components/modals/UpsellModal'
import { Button } from 'components/utils/Button'
import { showModal } from 'components/utils/CustomModal'
import { useAppState } from 'graphql/hooks/useAppState'
import { CreateFreeTierOrganizationVars, createFreeTierOrganizationMutation } from 'graphql/mutations/organization/createFreeTierOrganization'
import { getCompanyQuery } from 'graphql/queries/company/getCompany'
import { GetOrganizationsRes, GetOrganizationsVars, getOrganizationsQuery } from 'graphql/queries/organization/getOrganziations'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { isSandboxOrganization, organizationProductName } from 'graphql/schemas/organization/Organization'
import { hideMarketing, isCreatorInWorkspace } from 'graphql/schemas/user/User'
import { uniqBy } from 'ramda'
import { track } from 'utils/track'
import { useNavigate } from 'react-router-dom'
import { useWhiteLabel } from 'context/whiteLabel'
import { isColorDark } from 'utils/functions'

const WorkspaceSelector = ({activeWorkspaceId, setActiveWorkspaceId }) => {
  const appData = useAppState()
  const navigate = useNavigate()
  const { whiteLabelData } = useWhiteLabel()
  const primaryColor = whiteLabelData.primaryColor
  const bgColor = whiteLabelData.bgColor
  const isPrimaryColorDark = isColorDark(primaryColor)

  const { data: orgData, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.loggedInAs.uid },
  })

  const [createOrg] = useMutation<{ res: string }, CreateFreeTierOrganizationVars>(createFreeTierOrganizationMutation)
  const activeOrg = orgData?.res.organizations.find(o => o.id === activeWorkspaceId) || orgData?.res.organizations[0]

  const [getOrgIdFromCompany, {data: orgIdFromCompany, loading, error}] = useLazyQuery(getCompanyQuery, {
    onCompleted: (data) => {
      if ((data.company.organizationId !== activeWorkspaceId) && (orgData && (orgData?.res.organizationsCount > 1))) {
        setActiveWorkspaceId(data.company.organizationId)
        updateMeta('activeWorkspaceId', data.company.organizationId)
        updateAppCache('deeplinkCompanyId', '')
        const modal = showModal({
          title: ' ',
          component: (
            <div>
              <div className="text-center">
                <img src="/images/gnowbe-workspace.png" alt="gnowbe-workspace" className="w-72 h-36" />
              </div>
              <div className="mt-5 mb-2">
                {intl.get('workspace_switched', {0: data.company.organizationName})}
              </div>
              <div>
              {intl.getHTML('workspace_you_can_change')}
              </div>
            </div>
          ),
          primaryText: intl.get('got_it'),
          primaryAction: () => modal.close(),
          secondaryButton: false,
        })
        track({
          event: 'Workspace Changed',
          variables: {
            onScreen: 'Deeplink Clicked',
            changedToWorkspaceId: data.company.organizationId,
          }
        })
      }
    },
  })

  useEffect(() => {
    if (appData?.deeplinkCompanyId) {
      getOrgIdFromCompany({
        variables: {
          companyId: appData?.deeplinkCompanyId,
        },
      })
    }
  }, [appData?.deeplinkCompanyId, orgData?.res.organizationsCount])

  useEffect(() => {
    refetch()
  }, [subData?.user.memberOfOrganizations],
  )

  const handleWorkspaceChange = (orgId: string) => {
    setActiveWorkspaceId(orgId)
    updateMeta('activeWorkspaceId', orgId, true)
    if (!isWorkspaceCreator) {
      navigate('/home')
    }
    track({
      event: 'Workspace Changed',
      variables: {
        onScreen: 'Workspace Selector',
        changedToWorkspaceId: orgId,
      }
    })
  }

  const isWorkspaceCreator = isCreatorInWorkspace()
  const sandboxWorkspace = orgData?.res.organizations.find(isSandboxOrganization)
  const learnerWorkspaces = uniqBy(o => o.id, orgData?.res.organizations.filter(o => !subData?.user.managerOrganizations.includes(o.id)) || [])
  const creatorWorkspaces = uniqBy(o => o.id, orgData?.res.organizations.filter(o => !learnerWorkspaces.map(l => l.id).includes(o.id)) || [])
  const isActiveOrgSandbox = activeOrg?.id === sandboxWorkspace?.id

  const learnerWorkspacesWithoutSandbox = learnerWorkspaces.filter(org => !(org.name.toLowerCase().includes('sandbox community') || org.name.toLowerCase().includes('test groups')))
  const creatorWorkspacesWithoutSandbox = creatorWorkspaces.filter(org => !(org.name.toLowerCase().includes('sandbox community') || org.name.toLowerCase().includes('test groups')))

  return (
    <div className="w-full px-4 mt-4">
      {activeOrg && (
        <Menu as="div" className="relative mb-2">
          {({ open }) => (
            <>
              <div id="workspace-selector">
                <MenuButton className={`w-full flex flex-row gap-3 items-center p-2 border rounded-md text-left ${(((activeOrg.product === 'CR' || activeOrg.product === 'FT') && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) || isActiveOrgSandbox) ? `${whiteLabelData.primaryColor ? 'border-[var(--primary-lighter-color)]' : 'border-workspace/30 hover:bg-workspace/20'}` : 'border-gray-200 hover:bg-gray-100'}
                ${open && (((activeOrg.product === 'CR' || activeOrg.product === 'FT') && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) || isActiveOrgSandbox)
                ? `${whiteLabelData.primaryColor ? 'bg-[var(--primary-color)]' : 'bg-workspace/20'}`
                : (!open && (((activeOrg.product === 'CR' || activeOrg.product === 'FT') && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) || isActiveOrgSandbox))
                  ? `${whiteLabelData.primaryColor ? 'bg-[var(--primary-color)]' : 'bg-workspace/8'}`
                  : open
                    ? 'bg-gray-100 text-deepgray'
                    : 'bg-gray-50 text-deepgray'}`}
                title={activeOrg.name}>
                  <div className={`flex items-center justify-center w-12 h-12 rounded-full ${(((activeOrg.product === 'CR' || activeOrg.product === 'FT') && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) || isActiveOrgSandbox) ? 'bg-workspace/30' : 'bg-gray-200'} font-bold text-xl`}>
                    {activeOrg.customPalette?.dashboardLogo && <img src={activeOrg.customPalette?.dashboardLogo} className="rounded-full w-full h-full logo-container bg-white" />}
                    {!activeOrg.customPalette?.dashboardLogo && activeOrg.name.substring(0, 1)}
                  </div>

                  <div className="flex-1">
                    <div className="flex">
                      {/* crown icon */}
                      {(activeOrg.product === 'CR' && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) && <div className="my-auto mr-1">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.58333 1.98214C8.9 1.79464 9.11111 1.45446 9.11111 1.07143C9.11111 0.479464 8.61389 0 8 0C7.38611 0 6.88889 0.479464 6.88889 1.07143C6.88889 1.45714 7.1 1.79464 7.41667 1.98214L5.825 5.05179C5.57222 5.53929 4.91667 5.67857 4.475 5.33839L2 3.42857C2.13889 3.24911 2.22222 3.02679 2.22222 2.78571C2.22222 2.19375 1.725 1.71429 1.11111 1.71429C0.497222 1.71429 0 2.19375 0 2.78571C0 3.37768 0.497222 3.85714 1.11111 3.85714C1.11667 3.85714 1.125 3.85714 1.13056 3.85714L2.4 10.5911C2.55278 11.4054 3.28889 12 4.15 12H11.85C12.7083 12 13.4444 11.408 13.6 10.5911L14.8694 3.85714C14.875 3.85714 14.8833 3.85714 14.8889 3.85714C15.5028 3.85714 16 3.37768 16 2.78571C16 2.19375 15.5028 1.71429 14.8889 1.71429C14.275 1.71429 13.7778 2.19375 13.7778 2.78571C13.7778 3.02679 13.8611 3.24911 14 3.42857L11.525 5.33839C11.0833 5.67857 10.4278 5.53929 10.175 5.05179L8.58333 1.98214Z" fill="#FFB637"/>
                        </svg>
                      </div>}
                      <div className={`font-bold line-clamp-1 ${isPrimaryColorDark && 'text-zinc-50'}`}>
                        {activeOrg.name}
                       </div>
                    </div>
                    {subData?.user.managerOrganizations.some(org => org === activeOrg?.id) && <div className={`text-sm ${primaryColor && isPrimaryColorDark ? 'text-zinc-50' : primaryColor ? 'text-[#535353]' : 'text-gray-500'} flex`}>
                      {organizationProductName(activeOrg.product)} &middot; <PencilIcon className="w-3 h-3 my-auto mx-1" /> {activeOrg.usedLicenses}
                    </div>
                    }
                  </div>

                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`w-4 h-4 ${isPrimaryColorDark && 'fill-zinc-50'}`}>
                      <path d="M362.7 203.9l-159.1 144c-6.125 5.469-15.31 5.469-21.44 0L21.29 203.9C14.73 197.1 14.2 187.9 20.1 181.3C26.38 174.4 36.5 174.5 42.73 180.1L192 314.5l149.3-134.4c6.594-5.877 16.69-5.361 22.62 1.188C369.8 187.9 369.3 197.1 362.7 203.9z"/>
                    </svg>
                  </div>
                </MenuButton>
                {(activeOrg.product === 'CR' && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) && <Button
                  text={'Add licenses'}
                  className={`w-full mt-3 ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
                  style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
                  onClick={() => {
                    const modal =  showModal({
                      title: intl.get('gnowbe_dashboard'),
                      component: <UpsellModal track="curator_limit" type="license" hideFeatures={true} close={() => modal.close()} />,
                      hideFooter: true,
                    })
                  }} />}
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <MenuItems className="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none max-h-96 overflow-y-auto">
                  <div className="py-1">

                      {(subData?.user && !hideMarketing(subData.user)) && <div className="px-3 py-1">
                        <Button
                          type="team"
                          text={`+ ${intl.get('create_workspace')}`}
                          style={primaryColor ? { '--primary-color': primaryColor } : {}}
                          className={`w-full ${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'} ${primaryColor === bgColor && 'shadow-md'}`}
                          onClick={() => {
                            if (!appData?.loggedInAs.uid) return

                            createOrg({
                              variables: {
                                organizationName: `${subData?.user.profile.fullName}'s Workspace'`,
                                userId: appData.loggedInAs.uid,
                              },
                              onCompleted: (data) => {
                                setActiveWorkspaceId(data.res)
                                updateMeta('activeWorkspaceId', data.res, true)
                                track({
                                  event: 'Workspace Created',
                                  variables: {
                                    onScreen: 'Workspace Selector',
                                    changedToWorkspaceId: data.res,
                                  }
                                })
                              },
                              onError: error => console.log(error),
                            })
                          }}
                        />
                      </div>}

                    {sandboxWorkspace && (
                      <>
                        <div className="text-sm font-bold mx-3 my-1.5 text-gray-400">Sandbox workspace</div>
                        <WorkspaceItem org={sandboxWorkspace} isSandbox={true} isManager={false} handleWorkspaceChange={handleWorkspaceChange} />
                      </>
                    )}

                    {creatorWorkspaces.length > 0 && (
                      <>
                        <div className="text-sm font-bold mx-3 my-1.5 text-gray-400">Creator workspaces</div>
                        {creatorWorkspacesWithoutSandbox.map(org => (
                          <WorkspaceItem key={org.id} org={org} isSandbox={false} isManager={true} handleWorkspaceChange={handleWorkspaceChange} />
                        ))}
                      </>
                    )}

                    {learnerWorkspaces.length > 0 && (
                      <>
                        <div className="text-sm font-bold mx-3 my-1.5 text-gray-400">Learner workspaces</div>
                        {learnerWorkspacesWithoutSandbox.map(org => (
                          <WorkspaceItem key={org.id} org={org} isSandbox={false} isManager={false} handleWorkspaceChange={handleWorkspaceChange} />
                        ))}
                      </>
                    )}
                  </div>
                </MenuItems>
              </Transition>
            </>
          )}
        </Menu>
      )}

      {(activeOrg?.product === 'FT' && subData?.user.managerOrganizations.some(org => org === activeOrg?.id)) && (
        <Button
          type="primary"
          text="Upgrade"
          className="w-full bg-gray-200 border-gray-200"
          svgIcon={<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" viewBox="0 0 576 512"><path fill="#FFD700" d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>}
          onClick={() => {
            const modal =  showModal({
              component: <UpsellModal track="try_starter" type="try_starter" workspaceId={activeOrg.id} close={() => modal.close()} />,
              hideHeader: true,
              hideFooter: true,
            })
          }}
        />
      )}
    </div>
  )
}

export default WorkspaceSelector

const WorkspaceItem = memo((props: { org, isManager, handleWorkspaceChange, isSandbox }) => {
  const { whiteLabelData } = useWhiteLabel()
  return (
    <MenuItem>
      <button
        className={`text-left w-full flex p-2 gap-3 ${props.isManager || props.isSandbox ? `${whiteLabelData.primaryColor ? 'hover:bg-[var(--primary-lighter-color)]' : 'hover:bg-workspace/20'}` : 'hover:bg-gray-100'} items-center`}
        onClick={() => props.handleWorkspaceChange(props.org.id)}>
        <div className={`flex items-center justify-center w-12 h-12 rounded-full ${props.isManager || props.isSandbox ? 'bg-workspace/30' : 'bg-gray-200'} font-bold text-xl`}>
          {props.org.customPalette?.dashboardLogo && <img src={props.org.customPalette?.dashboardLogo} className="rounded-full w-full h-full logo-container bg-white" />}
          {!props.org.customPalette?.dashboardLogo && props.org.name.substring(0, 1)}
        </div>

        <div className="flex-1">
          <div className="flex">
            {(props.isManager && props.org.product === 'CR') && <div className="my-auto mr-1">
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.58333 1.98214C8.9 1.79464 9.11111 1.45446 9.11111 1.07143C9.11111 0.479464 8.61389 0 8 0C7.38611 0 6.88889 0.479464 6.88889 1.07143C6.88889 1.45714 7.1 1.79464 7.41667 1.98214L5.825 5.05179C5.57222 5.53929 4.91667 5.67857 4.475 5.33839L2 3.42857C2.13889 3.24911 2.22222 3.02679 2.22222 2.78571C2.22222 2.19375 1.725 1.71429 1.11111 1.71429C0.497222 1.71429 0 2.19375 0 2.78571C0 3.37768 0.497222 3.85714 1.11111 3.85714C1.11667 3.85714 1.125 3.85714 1.13056 3.85714L2.4 10.5911C2.55278 11.4054 3.28889 12 4.15 12H11.85C12.7083 12 13.4444 11.408 13.6 10.5911L14.8694 3.85714C14.875 3.85714 14.8833 3.85714 14.8889 3.85714C15.5028 3.85714 16 3.37768 16 2.78571C16 2.19375 15.5028 1.71429 14.8889 1.71429C14.275 1.71429 13.7778 2.19375 13.7778 2.78571C13.7778 3.02679 13.8611 3.24911 14 3.42857L11.525 5.33839C11.0833 5.67857 10.4278 5.53929 10.175 5.05179L8.58333 1.98214Z" fill="#FFB637"/>
              </svg>
            </div>}
            <div className="font-bold line-clamp-1" title={props.org.name}>
              {props.org.name}
            </div>
          </div>
          {props.isManager &&
          <div className="text-sm text-gray-500 flex">
            {organizationProductName(props.org.product)} &middot; <PencilIcon className="w-3 h-3 my-auto mx-1" />{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-3 h-3 inline-block"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z"/></svg>*/} {props.org.usedLicenses}
          </div>}
        </div>
      </button>
    </MenuItem>
  )
})
