import React, { Fragment, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { Transition } from '@headlessui/react'
import { useQuery } from '@apollo/client'
import { NotificationSchema } from 'graphql/schemas/activity/Notification'
import { getUserChatUnreadQuery } from 'graphql/queries/chat/getChat'
import { ChatChannelInfo } from 'graphql/schemas/chat/Chat'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { AppState } from 'graphql/schemas/app/State'
import { activitySeen, refreshNotifications } from 'actions/users_actions'
import { Notifications } from 'components/home/Notifications'
import { Popover } from 'react-tiny-popover'
import { trackButtonEnhanced } from 'utils/track'
import Tippy from '@tippyjs/react'

interface ActivityIconProps {
  active?: boolean;
  label?: string;
  hasActivity?: boolean;
  companyId?: string;
  courseId?: string;
  userId?: string;
  mobile?: boolean;
  classname?: string;
  mini?: boolean;
  home?: boolean;
  accentColor?: string | null;

  onClick?: (e: React.SyntheticEvent<any>) => void;
}

const Messages = (props: ActivityIconProps) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data, error, loading } = useQuery(getUserChatUnreadQuery, { variables: { userId: appData!.appState.loggedInAs.uid }, skip: !appData?.appState.loggedInAs.uid })

  const channels: ChatChannelInfo[] = data?.chat?.userChannels || []
  const editorChannels: ChatChannelInfo[] = data?.chat?.editorChannels || []
  const chats = channels.concat(editorChannels)
  let hasMessages = chats
    .filter(c => !c.urn.endsWith('-null') && c.otherUser && c.lastMessage)
    .filter(c => !c.lastSeen || (c.lastMessage && c.lastSeen && (c.lastMessage.createdAt > c.lastSeen))).length || 0

  if (error || loading || !hasMessages || hasMessages <= 0) {
    hasMessages = 0
  }

  if (props.home) {
    return (
      <Tippy content={intl.get('global_inbox')}>
        <div id="asdfas" className="inline-block relative">
          <button className={`icon icon-comment-alt-light text-xl font-medium cursor-pointer ${props.accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'} transition-none`} onClick={props.onClick} />

          {hasMessages > 0 ? <span className="w-3.5 h-3.5 inline-block bg-coral rounded-full absolute -right-0.5 -mr-1 top-0" /> : null}
        </div>
      </Tippy>
    )
  }

  return (
    <button className={`messages-btn relative ${props.mobile ? 'mobile' : ''} ${props.classname || ''} ${(props.active ? ' selected' : '')}`} onClick={props.onClick}>
      <span className={`icon-navbar ${props.mobile && !props.classname ? 'w-6' : ''}`} />
      {props.label && <span className="navbar-label messages inline-block">{props.label}</span>}
      {hasMessages > 0 ? <span className={`w-3.5 h-3.5 inline-block bg-coral rounded-full absolute ${props.mobile ? 'top-0 --mt-0.5 left-3.5' : 'top-2.5 left-6'}`} /> : null}
    </button>
  )
}

class Activity extends React.Component<ActivityIconProps, { seen: boolean, num: number }> {
  private _interval
  private _isMounted

  constructor(props) {
    super(props)
    this.state = { seen: true, num: 0 }
  }

  componentDidMount() {
    if (this.props.mobile && window.innerWidth >= 640) return
    if (!this.props.mobile && window.innerWidth < 640) return

    this.checkNotifications()

    this._interval = setInterval(() => {
      this.checkNotifications()
    }, 30 * 1000) // 15 seconds

    this._isMounted = true
  }

  componentWillUnmount() {
    this._interval = clearInterval(this._interval)

    this._isMounted = false
  }

  async checkNotifications() {
    const { companyId, courseId } = this.props
    if (!companyId || !courseId) return

    const a: any = await refreshNotifications({ companyId, courseId })
    this.setState({ seen: a.seen, num: (a.payload?.activeSubscription || []).concat(a.payload?.inactiveSubscriptions || []).concat(a.payload?.editorNotifications || []).length || 0 })
  }

  render() {
    if (this.props.mini) {
      return (
        <MiniActivityMenu
          home={this.props.home}
          seen={this.state.seen}
          num={this.state.num}
          accentColor={this.props.accentColor}
        />
      )
    }

    if (this.props.mobile) {
      return (
        <button className={`flex-1 flex-col activity-btn ${this.props.mobile ? 'mobile' : ''} ${this.props.classname || ''} ${(this.props.active ? ' selected' : '')}`} onClick={this.props.onClick}>
          <span className="icon-navbar relative">
            {this.state.seen ? <span className="w-3.5 h-3.5 inline-block bg-coral rounded-full absolute right-0 -mr-1 top-0 -mt-1" /> : null}
          </span>

          <span className="navbar-label activity block text-sm">{intl.get('tab_item_title_activity')}</span>
        </button>
      )
    }

    return (
      <button className={`activity-btn ${(this.props.active ? ' selected' : '')}`} onClick={this.props.onClick}>
        <span className="icon-navbar" />
        <span className="navbar-label activity">{intl.get('tab_item_title_activity')}</span>
        {this.state.seen ? <span className="w-3.5 h-3.5 inline-block bg-coral rounded-full absolute right-1.5 top-2.5" /> : null}
      </button>
    )
  }
}

export const MessagesIcon = Messages
export const ActivityIcon = Activity

const MiniActivityMenu = ({ home, seen, num, accentColor }: {home?: boolean, seen: boolean, num?: number, accentColor?: string | null}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showDot, setShowDot] = useState(!seen)

  useEffect(() => {
    setShowDot(!seen)
  }, [seen])

  const toggle = () => {
    setIsOpen(!isOpen)
    setShowDot(false)
    activitySeen()
  }

  useEffect(() => {
    if (isOpen) {
      trackButtonEnhanced({
        button: 'Notifications Bell',
        onScreen: 'Home (Horizontal Menu)',
      })
    }
  }, [isOpen])

  return (
    <Popover
      isOpen={isOpen}
      positions={['left', 'right']}
      padding={10}
      align="start"
      onClickOutside={() => setIsOpen(false)}
      containerClassName="z-40"
      content={() => (
        <Transition
          show={true}
          appear={true}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="w-96 absolute mt-12 right-0 custom-dropdown -mr-8">
            <Notifications onOpen={() => setIsOpen(false)} />
          </div>
        </Transition>
      )}>
        {!home
          ? <button className="activity-btn outline-none" aria-label="Activity" onClick={toggle}>
              <span className="icon-navbar" />

              {showDot
                ? <span className="w-3.5 h-3.5 text-[10px] text-white px-1 inline-block bg-coral rounded-full absolute right-1.5 top-2.5">
                    {/* {num} */}
                  </span>
                : null
              }
            </button>
          : <Tippy content={intl.get('global_notifications')}>
              <div className="inline-block relative">
                <button className={`icon icon-bell-light text-xl font-medium cursor-pointer ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'} transition-none`} aria-label="Activity" onClick={toggle} />

                {showDot
                  ? <span className="w-3.5 h-3.5 text-[10px] text-white px-1 inline-block bg-coral rounded-full absolute right-0 -mr-1 top-0">
                      {/* {num} */}
                    </span>
                  : null
                }
              </div>
            </Tippy>
        }
      </Popover>
  )
}
