import { gql } from '@apollo/client'
import { chapterFragment } from 'graphql/fragments/Chapter'

export const duplicateChapterMutation = gql`
  ${chapterFragment}

  mutation DuplicateChapter ($fromCourseId: String!, $fromChapterId: String!, $toCourseId: String!, $chapterUpdate: ChapterUpdate!) {
    duplicateChapter (fromCourseId: $fromCourseId, fromChapterId: $fromChapterId, toCourseId: $toCourseId, chapterUpdate: $chapterUpdate) {
      ...ChapterFragment
    }
  }
`
