import { gql } from '@apollo/client'

export type GetTranslatorLimitPerOrganizationRes = {
  res: {
    addon: string;
    title: string;
    description: string;
    price: number;
    freeCredits: number;
    status: 'active' | 'inactive';
    count: number;
    limit: number;
  }
}

export type GetTranslatorLimitPerOrganizationVars = {
  organizationId: string;
}

export const getTranslatorLimitPerOrganizationQuery = gql`
  query GetTranslatorLimitPerOrganization ($organizationId: String!) {
    res: getTranslatorLimitPerOrganization (organizationId: $organizationId){
      addon
      title
      description
      price
      count
      limit
      freeCredits
      status
    }
  }
`

