import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'

export const createActionMutation = gql`
  ${actionFragment}

  mutation CreateAction ($courseId: String!, $chapterId: String!, $actionCreate: ActionCreate!) {
    createAction (courseId: $courseId, chapterId: $chapterId, actionCreate: $actionCreate) {
      ...ActionFragment
    }
  }
`
