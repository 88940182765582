import React, { useState, useEffect, useRef, memo } from 'react'
import intl from 'react-intl-universal'
import { useLazyQuery } from '@apollo/client'
import { SyncScormRegistrationDataRes, SyncScormRegistrationDataVars, syncScormRegistrationDataMutation } from 'graphql/mutations/scorm/syncScormRegistrationData'
import { client } from 'app'
import { parse } from 'utils/functions'
import { showSlider, hideSlider } from './utils/Slider'
import { ScormSuccess } from './sliders/Scorm'
import { getCourseTitleQuery } from 'graphql/queries/course/getCourse'

export const ScormWindow = memo(({ currentSubscription }: { currentSubscription: string|undefined }) => {
  const [companyId, courseId] = currentSubscription && currentSubscription.split('-') || [null, null]
  const [scormLoading, setScormLoading] = useState(true)
  const [scormComplete, setScormComplete] = useState(false)
  const [getCourse, { data }] = useLazyQuery(getCourseTitleQuery)
  const _called = useRef<any>(false)
  useEffect(() => {
    if (courseId) {
      getCourse({ variables: { courseId }})
      if (scormComplete && data) {
        showSlider(
          'scorm-success-slider',
          'success',
          <ScormSuccess title={data.course.title} />,
          () => hideSlider('scorm-success-slider')
        )
      }
    }
  }, [scormComplete, data])
  const query = parse(window.location.href, true)
  if (!_called.current) {
    _called.current = true
    client.mutate<SyncScormRegistrationDataRes, SyncScormRegistrationDataVars>({
      mutation: syncScormRegistrationDataMutation,
      variables: {
        registrationId: query.registrationId,
      },
    })
    .then((res) => {
      setScormLoading(false)
      setScormComplete(res.data?.syncScormRegistrationData.completed || false)
    })
    .catch((err) => {
      setScormLoading(false)
    })
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-white">
      {scormLoading
        ? <div>Loading...</div>
        : <div id="main-container">
            <div>You may now close this window.</div>
            <div className="mt-4 text-center">
              <button className="btn btn-primary" onClick={() => window.close()}>{intl.get('close')}</button>
            </div>
          </div>
      }
    </div>
  )
})
