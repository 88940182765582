import React from 'react'
import intl from 'react-intl-universal'
import { getChatChannelQuery } from '../../graphql/queries/chat/getChat'
import { useQuery } from '@apollo/client'
import { listenChatChannelChangesSubscription } from '../../graphql/subscriptions/chat/listenChatChannelChange'
import { MiniLoader } from '../utils/MiniLoader'
import { chatRead, removeChatMsg } from '../../actions/chats_actions'
import { ChatMessage } from 'graphql/schemas/chat/Chat'
import { localeFormatDistanceStrict } from 'utils/dateFunctions'
import { produce } from 'immer'
import { augmentEmbeddedUser } from 'utils/utils'

export const SimpleMessageList = ({ activeUrn, myUserId }: { activeUrn: string; myUserId: string }) => {
  React.useEffect(() => {
    chatRead(activeUrn)
  }, [activeUrn])

  const scrollMessageListToBottom = () => {
    const comments = document.getElementsByClassName('comments')[0]
    if (comments) {
      comments.scrollTop = comments.scrollHeight
    }
  }

  const deleteMessage = (e: React.MouseEvent, msg: ChatMessage) => {
    removeChatMsg(msg.urn, msg)
  }

  const { data, loading, error, subscribeToMore } = useQuery(getChatChannelQuery, { variables: { urn: activeUrn, messagesLimit: 500 }, fetchPolicy: 'cache-and-network' })

  let unsub

  const memoizedCallback = React.useCallback(() => {
    unsub = subscribeToMore({
      document: listenChatChannelChangesSubscription,
      variables: { urn: activeUrn, messagesLimit: 500 },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        if (!prev) return

        const newMessage = subscriptionData.data.listenChatChannelChanges
        if (newMessage.urn === activeUrn && newMessage.__typename === 'ChatMessageAdded') {
          return produce(prev, (draft) => {
            (draft.chatChannel?.messages || []).push(newMessage.message)
          })
        }
      },
    })
  }, [data])

  React.useEffect(() => {
    memoizedCallback()
    return () => unsub && unsub()
  }, [activeUrn])

  React.useEffect(() => {
    scrollMessageListToBottom()
  }, [data])

  if (loading) {
    return <MiniLoader />
  }

  if (error) {
    return <div>`Error! ${error.message}`</div>
  }

  if (!data) {
    return <div>No data</div>
  }


  return (
    <div className="chat-messages simple">
      {data.chatChannel.messages.slice().sort((a, b) => a.createdAt - b.createdAt).map((msg: ChatMessage, i) => {
        const augmentedMessage = augmentEmbeddedUser(msg.message)
        return (
          <div key={msg.id} className="simple-message mb-2">
            <strong className="mr-1">{msg.user?.profile.fullName || 'User deleted'}</strong> {augmentedMessage}

            <div>
              <span className="text-xs text-gray-700 ml-0">{localeFormatDistanceStrict(msg.createdAt, Date.now())}</span>

              {msg.user?.id === myUserId && msg.message !== intl.get('message_deleted') && (
                <i className="icon-trash-alt-light text-sm cursor-pointer ml-2" onClick={e => deleteMessage(e, msg)} />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
