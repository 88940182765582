import React from 'react'
import { CoverEditable } from 'components/utils/CoverEditable'
import { DEFAULT_MIMETYPES, getSignedValue } from 'actions/files'

type ChapterImageProps = {
  courseId: string;
  imageUrl?: string|null;
  videoUrl?: string|null;
  videoUrl_720p?: string|null;
  allowMediaDownload: boolean;
  isEditing: boolean;
  update: (f: string, v: string) => void;
}

export const ChapterImage = ({ courseId, imageUrl, videoUrl, videoUrl_720p, isEditing, allowMediaDownload, update }: ChapterImageProps) => {
  if (!imageUrl && !videoUrl && !isEditing) return null

  return (
    <CoverEditable
      target={`course:${courseId}`}
      defaultImageUrl={imageUrl}
      defaultVideoUrl={videoUrl}
      videoUrl_720p={videoUrl_720p}
      field={videoUrl ? 'videoUrl' : 'imageUrl'}
      allowMediaDownload={allowMediaDownload}
      isEditing={isEditing}
      mimetypes={DEFAULT_MIMETYPES}
      update={update}
    />
  )
}
