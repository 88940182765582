import { showAlert } from 'components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { checkHttpStatus, getAuthTokens } from 'actions/utils'
import { Course } from 'graphql/schemas/course/Course'
import { br2nl, camelCase, stripHtml, realWordCount, unescapeHtml } from 'utils/functions'
import { trim } from 'ramda'
import { CourseUpdateBulk } from 'graphql/mutations/course/updateCourseBulk'

function prepareForCsv(text) {
  let replaced = text && text
    .replace(/(\r|\n)/g, ' ===== ')
    .replace(/(\<div\>\<br\>\<\/div\>)/g, ' ##### ')
    .replace(/(\<ul\>)/g, ' ;;;;; ')
    .replace(/(\<ol\>)/g, ' ::::: ')
    .replace(/(\<\/li\>\<li\>)/g, ' +++++ ')
    .replace(/(\<\/ul\>)/g, ' ;;/;; ')
    .replace(/(\<\/ol\>)/g, ' ::/:: ')
    .replace(/%20/g, ' %%%%% ')
    || ''
  replaced = replaced && stripHtml(replaced)
    .replace(/"/g, '~~')
    .replace(/&nbsp;/g, '')
    .replace(/’/, '\'')
    || ''

  if (!replaced) return ''

  return `"${encodeURIComponent(replaced)}"`
}

export function prepareForImport(text: string, field?: string) {
  const replaced = text && text
    .replace(/ ?===== ?/g, field && field === 'placeholderAnswer' ? '\n' : '<br>')
    .replace(/ ?##### ?/g, '<div><br></div>')
    .replace(/ ?;;;;; ?/g, '<ul><li>')
    .replace(/ ?::::: ?/g, '<ol><li>')
    .replace(/ ?;;\/;; ?/g, '</li></ul>')
    .replace(/ ?::\/:: ?/g, '</li></ol>')
    .replace(/ ?\+\+\+\+\+ ?/g, '</li><li>')
    .replace(/ ?%%%%% ?/g, '%20')
    .replace(/~~/g, '"')
    || ''

  if (!replaced) return ''

  return replaced.replace(/^"(.*)"$/, '$1')
}

// missing(rudik)
export function exportCourse(courseId: string) {
  const token = getAuthTokens()

  fetch(`${BASE_API_URL}/api/v1/editor/course/${courseId}/export`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.errro) {
      showAlert(`Error — ${response.error.message}`, 'danger')
      // logger.error('editorial/courses: exportCourse response: ' + response.error.message, response.error)
    }
    else {
      showAlert('Program successfully exported.')
      let count = 0
      let csv = 'id,field,english,translation\r\n'
      const course = response.data.courses[0]

      // const exported = `<table>\n<tr><td>textbox</td><td>english</td><td>translation</td></tr>\n`
      // + `<tr><td>program title</td><td>${course.title}</td><td></td></tr>\n`
      // + `<tr><td>program tagline</td><td>${course.tagline}</td><td></td></tr>\n`
      // + `<tr><td>program description</td><td>${course.description.replace('<div><br></div>', '<br>')}</td><td></td></tr>\n`
      // + `<tr><td>curator name</td><td>${course.curatorName}</td><td></td></tr>\n`
      // + `<tr><td>curator title</td><td>${course.curatorTitle}</td><td></td></tr>\n`
      // + `<tr><td>curator description</td><td>${course.curatorBio}</td><td></td></tr>\n`
      // + `</table>`

      csv += `${course._id},course.title,${prepareForCsv(course.title)},\r\n`
      + `${course._id},course.tagline,${prepareForCsv(course.tagline)},\r\n`
      + `${course._id},course.description,${prepareForCsv(course.description)},\r\n`
      + `${course._id},course.curatorName,${prepareForCsv(course.curatorName)},\r\n`
      + `${course._id},course.curatorTitle,${prepareForCsv(course.curatorTitle)},\r\n`
      + `${course._id},course.curatorBio,${prepareForCsv(course.curatorBio)},\r\n`

      count += realWordCount(course.title).length
      + realWordCount(course.tagline).length
      + realWordCount(course.description).length
      + realWordCount(course.curatorName).length
      + realWordCount(course.curatorTitle).length
      + realWordCount(course.curatorBio).length

      // let exportedChapters = `<table>\n`
      response.data.chapters.slice().sort((a, b) => a.order - b.order).map((c) => {
        csv += `${c._id},chapter.title,${prepareForCsv(c.title)},\r\n`
        + `${c._id},chapter.description,${prepareForCsv(c.description)},\r\n`
        + `${c._id},chapter.morningEmail,${prepareForCsv(c.morningEmail)},\r\n`
        + `${c._id},chapter.morningPush,${prepareForCsv(c.morningPush)},\r\n`
      //     exportedChapters += `<tr><td colspan="3" style="text-align: center">Start of Session</td></tr>\n`
      //     + `<tr><td>textbox</td><td>english</td><td>translation</td></tr>\n`
      //     + `<tr><td>session title</td><td>${c.title}</td><td></td></tr>\n`
      //     + `<tr><td>session description</td><td>${c.description}</td><td></td></tr>\n`

      // exportedChapters += `<tr><td colspan="3"></td></tr>`
      // + `</table>`

      count += realWordCount(c.title).length
      + realWordCount(c.description).length
      + realWordCount(c.morningEmail).length
      + realWordCount(c.morningPush).length

      // let exportedActions = `<table>\n`
      response.data.actions.slice().sort((a, b) => a.order - b.order).filter(a => a.chapterId === c._id).map((a) => {
        // const plc = a.placeholderAnswer && a.placeholderAnswer.replace(/(\r|\n)/g, '<br>') || ''
        // const tt = a.title && a.title.replace(/(\r|\n)/g, '<br>') || ''
      //     exportedActions += `<tr><td colspan="3" style="text-align: center">${camelCase(a.contentType)}</td></tr>\n`
      //     + `<tr><td>textbox</td><td>english</td><td>translation</td></tr>\n`
      //     + `<tr><td>action title</td><td>${a.title}</td><td></td></tr>\n`
      //     + `<tr><td>action description</td><td>${a.description || ''}</td><td></td></tr>\n`
      //     // + `<tr><td>placeholder answer</td><td>${a.placeholderAnswer}</td><td></td></tr>\n`
        csv += `${a._id},action.title,${prepareForCsv(a.title)},\r\n`
        + `${a._id},action.description,${prepareForCsv(a.description)},\r\n`
        + `${a._id},action.placeholderAnswer,${prepareForCsv(a.placeholderAnswer)},\r\n`

        count += realWordCount(a.title).length
        + realWordCount(a.description).length
        + realWordCount(a.placeholderAnswer).length

          // if (a.contentType === 'multiple_choice' && a.placeholderAnswer) {
          //   a.placeholderAnswer.split('\n')
          //   .filter(option => option.trim() !== '')
          //   .map((optionStr, index) => {
          //     const [text, hint, id] = optionStr.split('|')
          //     csv += `${a._id},action.options,${a.title},\r\n`
          //     // exportedActions += `<tr><td>Option ${index + 1}</td><td>${text}</td><td></td></tr>\n`
          //     // + `<tr><td>Option ${index + 1} feedback</td><td>${hint}</td><td></td></tr>\n`
          //   })
          // }
          // else if (a.contentType === 'qa' && a.placeholderAnswer) {
          //   exportedActions += `<tr><td>placeholder answer</td><td>${a.placeholderAnswer}</td><td></td></tr>\n`
          // }

          const rewards = response.data.courseRewards.filter(r => r.eventData === a._id)
          if (rewards) {
            rewards.map((r) => {
              // const text = r.text && r.text.replace(/(\r|\n)/g, '<br>') || ''
              csv += `${r._id},reward.title,${prepareForCsv(r.title)},\r\n`
              + `${r._id},reward.text,${prepareForCsv(r.text)},\r\n`
              // exportedActions += `<tr><td colspan="3" style="text-align: center">${r.channel === 'inapp' ? 'In app message' : r.channel === 'rating' ? 'Star rating' : 'Unknown'}</td></tr>\n`
              // + `<tr><td>textbox</td><td>english</td><td>translation</td></tr>\n`
              // + `<tr><td>reward title</td><td>${r.title}</td><td></td></tr>\n`
              // + `<tr><td>reward text</td><td>${r.text}</td><td></td></tr>\n`
              count += realWordCount(r.title).length
              + realWordCount(r.text).length

            })
          }
      })
    })

    csv += `total word count,${count},`

      // exportedActions += `<tr><td colspan="3"></td></tr>`
      // + `</table>`

      // let exportedRewards = `<table>\n<tr><td>textbox</td><td>english</td><td>translation</td></tr>\n`
      // response.data.courseRewards.map((r) => {
      //     exportedRewards
      // })
      // exportedRewards += `</table>`
      // console.log(exportedRewards)

      const link = document.createElement('a')
      link['download'] = `${(course && course.title ? course.title.replace(' ', '_') : 'Course_without_title')}_(exported)_${(new Date()).toISOString().slice(0, 10).replace(/-/g, '')}.csv`
      link.href = `data:text/csv;charset=utf-8,%EF%BB%BF${csv}`
      link.click()
    }
  })
  .catch((error) => {
    const err = Error(error)
    console.log(err)
    showAlert('Network Error — Please try again in a while.', 'danger')
  //   logger.warn('editorial/courses: exportCourse catch: ' + err.message + ' (' + err.stack + ')', err)
  })
//   }
}

export function importCourse(data) {
  const lines = data.split('\n').filter(l => l)
  const obj: CourseUpdateBulk = {
    courseId: '',
    courseUpdate: {},
    chapters: [],
    actions: [],
    rewards: [],
  }
  lines.map((l) => {
    const [id, scopedField, translation] = l.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
    if (id === 'id' || !scopedField) return
    const [scope, field] = scopedField.split('.')
    const trimId = trim(id)
    if (scopedField.includes('course')) {
      if (!obj.courseId) { obj.courseId = trimId }
      obj.courseUpdate[field] = prepareForImport(trim(unescapeHtml(translation)))
    }
    else if (scopedField.includes('chapter')) {
      if (!trim(translation)) return
      let chapter = obj.chapters.find(c => c.chapterId === trimId)
      if (!chapter) {
        obj.chapters = obj.chapters.concat({
          chapterId: trimId,
          chapterUpdate: {
            [field]: prepareForImport(trim(unescapeHtml(translation))),
          },
        } as any)
      }
      else {
        chapter = {
          chapterId: trimId,
          chapterUpdate: {
            ...chapter.chapterUpdate,
            [field]: prepareForImport(trim(unescapeHtml(translation))),
          },
        }
        obj.chapters = obj.chapters.filter(c => c.chapterId !== trimId).concat(chapter)
      }
    }
    else if (scopedField.includes('action')) {
      if (!trim(translation)) return
      let action = obj.actions.find(c => c.actionId === trimId)
      let value = prepareForImport(trim(unescapeHtml(translation)), field)
      if (value && value.startsWith('"+')) value = value.substring(1)
      if (!action) {
        obj.actions = obj.actions.concat({
          actionId: trimId,
          actionUpdate: {
            [field]: value,
          },
        } as any)
      }
      else {
        action = {
          actionId: trimId,
          actionUpdate: {
            ...action.actionUpdate,
            [field]: value,
          },
        }
        obj.actions = obj.actions.filter(c => c.actionId !== trimId).concat(action)
      }
    }
    else if (scopedField.includes('reward')) {
      if (!trim(translation)) return
      let reward = obj.rewards.find(c => c.rewardId === trimId)
      if (!reward) {
        obj.rewards = obj.rewards.concat({
          rewardId: trimId,
          rewardUpdate: {
            [field]: prepareForImport(trim(unescapeHtml(translation))),
          },
        } as any)
      }
      else {
        reward = {
          rewardId: trimId,
          rewardUpdate: {
            ...reward.rewardUpdate,
            [field]: prepareForImport(trim(unescapeHtml(translation))),
          },
        }
        obj.rewards = obj.rewards.filter(c => c.rewardId !== trimId).concat(reward)
      }
    }
  })
  return {
    courseId: obj.courseId,
    update: obj,
  }
}

// export function importCourse(data: { courses: List<Course>, chapters: List<Chapter>, actions: List<Action>, coursesRewards: List<Reward> }) {
//   const courseId = generateMongoId()
//   return (dispatch: IDispatch<any>, getState: GnowbeStateFunc) => {
//     const state = getState()
//     fetch(`${BASE_API_URL}/api/v1/editor/course/import`, {
//       method: 'post',
//       headers: {
//         'Authorization': 'Bearer ' + state.auth.token,
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'x-gnowbe-source': 'gnowbe-web 1.0.0'
//       },
//       body: JSON.stringify(data)
//     })
//     .then(response => checkHttpStatus(response, true))
//     .then(response => response.json())
//     .then(response => {
//       if (response.error) {
//         showAlert(`Error — ${response.error.message}`, 'danger')
//         // logger.error('editorial/courses: importCourse response: ' + response.error.message, response.error)
//       }
//       else {
//         showAlert('Program successfully imported.')
//       }
//     })
//     .catch(error => {
//       const err = Error(error)
//       showAlert(`Network Error — Please try again in a while.`, 'danger')
//       logger.warn('editorial/courses: importCourse catch: ' + err.message + ' (' + err.stack + ')', err)
//     })
//   }
// }
