import { gql } from '@apollo/client'

export type DeleteChapterVars = {
  courseId: string;
  chapterId: string;
}

export type DeleteChapterRes = {
  deleteChapter: {
    id: string;
    courseId: string;
    __typename: string;
  };
  __typename: string;
}

export const deleteChapterMutation = gql`
  mutation DeleteChapter ($courseId: String!, $chapterId: String!) {
    deleteChapter (courseId: $courseId, chapterId: $chapterId) {
      id
      courseId
    }
  }
`
