import jwtDecode from 'jwt-decode'
import { showAlert } from '../components/utils/Alert'
import { BASE_API_URL, BASE_BE_GNOWBE_URL, BASE_URL, LOCALSTORAGE_VARS, GNOWBE_SOURCE } from '../constants/General'
import { checkHttpStatus, removeAuthTokens, getAuthTokens } from './utils'
import * as logger from '../logger'
import { refetch } from 'utils/functions'
import { resetSegment } from 'utils/track'
import { generateMongoId } from 'utils/random'

export type LogoutReason = 'sessionExpired'

export async function logout({ reason, logoutFromAll }: { reason?: LogoutReason; logoutFromAll?: boolean } = {}) {
  const token = getAuthTokens()
  await refetch(`${BASE_API_URL}/api/v1/auth/logout${logoutFromAll ? '?endAllSessions=true' : ''}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then((res) => {
    if (res.status >= 400) {
      logger.error(`Error while logging out: session on backend was not ended: call to /api/v1/auth/logout failed: ${res.status}`)
    }
  })
  .catch((err) => {
    logger.error('Error while logging out: session on backend was not ended: call to /api/v1/auth/logout returned error', err)
  })

  removeAuthTokens()
  resetSegment()
  // TODO: unsub from stuff

  LOCALSTORAGE_VARS.forEach((v) => {
    localStorage.removeItem(v)
  })

  window.location.pathname = `/auth/login${reason ? `?reasonCode=${reason}` : ''}`
}

export function getLoginUrl(redirectTo: string, opts?: {email?: string}) {
  const build = process.env.BUILD || 'production'
  // eslint-disable-next-line no-param-reassign
  const redirectUrl = new URL(redirectTo)
  const searchParams = new URLSearchParams(redirectUrl.search)
  const sso = searchParams.get('sso')
  searchParams.delete('continueAs')
  searchParams.delete('sso')
  redirectUrl.search = searchParams.toString()
  redirectTo = redirectUrl.href
  let append = !['development', 'staging', 'production'].includes(build) ? `&wl=${build}` : ''
  const accessCodeMatch = /joinGroup\/([a-z0-9\-\_]+)/i.exec(redirectTo)
  if (accessCodeMatch) {
    append += `&accessCode=${accessCodeMatch[1]}`
    const courseId = /courseId=([a-z0-9]+)/i.exec(redirectTo)?.at(1)
    if (courseId) {
      append += `&courseId=${courseId}`
    }
  }
  if (opts?.email) {
    append += `&email=${encodeURIComponent(opts.email)}`
  }
  if (sso) {
    append += `&sso=${sso}`
  }
  const referralMatch = /referral\/([a-zA-Z0-9]+)/i.exec(redirectTo)
  if (referralMatch) {
    append += `&referral=${referralMatch[1]}`
  }
  const url = `${BASE_BE_GNOWBE_URL}/signup/login?distinctId=${generateMongoId(9)}&rememberMe=true${append}`
    + `&redirectTo=${encodeURIComponent(redirectTo)}`
  return url
}

export function switchAccount() {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/auth/logout?logoutParent=false`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  removeAuthTokens()
  const redirectTo = `${BASE_URL}/auth/login`
  const loginUrl = getLoginUrl(redirectTo)
  window.location.assign(loginUrl)
}

export function changePassword(newPassword) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/auth/change_password`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      newPassword,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      showAlert('Password successfully updated.')
    }
    else {
      showAlert(response.error.message, 'danger')
    }
  })
  .catch((err) => {
  })
}

export function changeEmail(newEmail: string) {
  return new Promise((res, rej) => {
    const token = getAuthTokens()
    const authToken: any = jwtDecode(token)

    refetch(`${BASE_API_URL}/api/v1/auth/change_email`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify({
        newEmail,
      }),
    })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        showAlert(response.data.message)
        res({ error: false })
      }
      else {
        showAlert(`Error - ${response.error.message}.`, 'danger')
        res({ error: true })
      }
    })
    .catch((err) => {
      showAlert(`Unable to update email address (${err.message}).`, 'danger')
      res({ error: true })
    })
  })
}

export function removeAccount() {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/users/authenticated`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      logout()
      setTimeout(() => {
        showAlert('Account successfully deleted.')
      }, 500)
    }
    else {
      showAlert(`Unable to delete account (${response.error.message}.`, 'danger')
    }
  })
  .catch((err) => {
    showAlert(`Unable to delete account (${err.error}).`, 'danger')
  })
}

export function getAccessToken() {
  return new Promise<{ error: boolean, token?: string, message?: string }>((res, rej) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/auth/get_auth_token`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        if (response.data.token) {
          return res({ error: false, token: response.data.token })
        }
        return res({ error: true, message: 'No token received' })
      }
      res({ error: true, message: response.error.message })
      logger.error('Unable to get auth token', response.error.message)
    })
    .catch((err) => {
      rej({ error: true, message: err.error.message })
      logger.error('Unable to get auth token', err.error)
    })
  })
}
