import { gql } from '@apollo/client'
import { Organization, OrganizationResponseType, getOrganizationFragment, getOrganizationFragmentName } from 'graphql/schemas/organization/Organization'

export const getOrganizationsQuery = gql`
  query GetOrganization ($organizationId: String!) {
    res: getOrganization (organizationId: $organizationId) {
      id
      name
      logoUrl
      usedLicenses
      featureFlags
      product
      usedLicenses
      disabled
    }
  }
`
export type GetOrganizationVars = { organizationId: string }
export type GetOrganizationRes = { res: Organization }
export const getOrganizationQuery = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  query GetOrganization($organizationId: String!) {
    res: getOrganization(organizationId: $organizationId) {
      ...${getOrganizationFragmentName(types)}
    }
  }
`