import React, { memo, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { Help } from 'components/utils/Help'
import { showModal } from 'components/utils/CustomModal'
import { SimpleEditable } from 'components/utils/SimpleEditable'
import { APP_TITLE } from 'constants/General'
import { Button } from 'components/utils/Button'
import { useWhiteLabel } from 'context/whiteLabel'
import { isColorDark } from 'utils/functions'

type DailyMessagingProps = {
  id: string;
  morningEmail: string;
  morningPush: string;

  update: (field: string, value: string) => void;
}

export default class DailyMessaging extends React.Component<DailyMessagingProps, { morningEmail: string, morningPush: string, focused: boolean }> {
  private _timeout

  constructor(props) {
    super(props)
    this.state = { morningEmail: props.morningEmail || '', morningPush: props.morningPush || '', focused: false }

    this.updatePush = this.updatePush.bind(this)
    this.updateEmail = this.updateEmail.bind(this)
  }

  updatePush(value: string) {
    this.setState({ morningPush: value })
  }

  updateEmail(value: string) {
    this.setState({ morningEmail: value })
  }

  // showHelp(help: string) {
  //   this.setState({ firehelp: this.state.firehelp.add(help) })
  // }

  // hideHelp(help: string) {
  //   this.setState({ firehelp: this.state.firehelp.remove(help) })
  // }

  render() {
    // const { roles } = this.props
    // const { chapter } = this.state
    const { id, update } = this.props
    const { morningEmail, morningPush } = this.state

    return (
      <div className="my-4">
        <div className="bg-yellow-200 rounded-md p-4 mb-4 font-medium">
          {intl.getHTML('daily_message_description')}
        </div>
        <div className="form-group push-notification">
          <label>{intl.get('chapter_daily_push')}</label>
          <DailyPush
            message={morningPush}
            update={e => update('morningPush', e)}
          />
        </div>

        <div className="form-group email-message">
          <DailyEmail
            email={morningEmail}
            update={e => update('morningEmail', e)}
          />
        </div>
      </div>
    )
  }
}

const DailyPush = memo(({ message, update }: { message: string, update: (e: string) => void }) => {
  const original = message
  const [msg, setMsg] = useState(message)

  useEffect(() => {
    setMsg(message)
  }, [message])

  return (
    <>
      <SimpleEditable
        maxLength={160}
        placeholder={`${intl.get('chapter_daily_push')} (160 characters max)`}
        defaultValue={msg}
        isEditing={true}
        fullWidth={true}
        help="firehelp_chapter_dailyPush"
        update={v => update(v)}
      />

      <Button
        text={intl.get('clear_push')}
        className="mt-3"
        disabled={!msg}
        onClick={(e) => {
          showModal({
            title: intl.get('global_are_you_sure_header'),
            content: intl.get('clear_push_notification'),
            primaryText: intl.get('yes'),
            primaryAction: (p) => {
              if (!p) {
                update('')
              }
            },
            secondaryText: intl.get('no'),
          })
        }}
      />
    </>
  )
})

const DailyEmail = ({ email, update }) => {
  const { whiteLabelData } = useWhiteLabel()
  const primaryColor = whiteLabelData.primaryColor
  const parseEmail = (email) => {
    return {
      subject: email.split('\n')[0] || '',
      body: email.split('\n').slice(1).join('\n') || '',
    }
  }

  const [mail, setMail] = useState({
    subject: parseEmail(email).subject,
    body: parseEmail(email).body,
    dirty: false,
  })

  useEffect(() => {
    setMail({
      subject: parseEmail(email).subject,
      body: parseEmail(email).body,
      dirty: false,
    })
  }, [email])

  return (
    <>
      <div className="form-group email-component">
        <label>{`${intl.get('daily_email_subject')}`} {!mail.subject && mail.body && <span className="color-red">{intl.get('mandatory')}</span>}</label>
        <SimpleEditable
          maxLength={500}
          placeholder={`${intl.get('daily_email_subject')}...`}
          defaultValue={mail.subject}
          isEditing={true}
          fullWidth={true}
          update={(v) => {
            setMail({
              ...mail,
              dirty: true,
              subject: v,
            })
          }}
        />

        <label>{`${intl.get('daily_email_message')}`} {mail.subject && !mail.body && <span className="color-red">{intl.get('mandatory')}</span>}</label>
        <SimpleEditable
          maxLength={9500}
          placeholder={`${intl.get('daily_email_message')}...`}
          defaultValue={mail.body}
          isEditing={true}
          textArea={true}
          update={(v) => {
            setMail({
              ...mail,
              dirty: true,
              body: v,
            })
          }}
        />
      </div>

      <Button
        type="primary"
        style={primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}}
        className={`mr-3 ${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'}`}
        text={intl.get('global_save')}
        disabled={!mail.dirty || (mail.subject && !mail.body || !mail.subject && mail.body)}
        onClick={(e) => {
          setMail({ ...mail, dirty: false })
          update(`${mail.subject}\n${mail.body}`)
        }}
      />

      <Button
        text={intl.get('clear_email')}
        disabled={!email.trim()}
        onClick={(e) => {
          showModal({
            title: intl.get('global_are_you_sure_header'),
            content: intl.get('clear_email_message'),
            primaryText: intl.get('yes'),
            primaryAction: (p) => {
              if (!p) {
                update('')
              }
            },
            secondaryText: intl.get('no'),
          })
        }}
      />

      <Help
        path="firehelp_chapter_dailyEmail"
        variables={{ 0: APP_TITLE[process.env.BUILD || 'production'] }}
        hide={false}
      />
    </>
  )
}
