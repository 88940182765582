import { gql } from '@apollo/client'

export const listenMcStatsForCourseChangesSubscription = gql`
  subscription ListenMcStatsForCourseChanges ($companyId: String!, $courseId: String!) {
    stats: listenMcStatsForCourseChanges (companyId: $companyId, courseId: $courseId) {
      id
      companyId
      courseId
      chapterId
      actionId
      totalUsers
      answers {
        answerId
        numOfAnswers
      }
    }
  }
`
