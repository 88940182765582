// This is copied from Meteor so we generate same random strings for mogo ids

const UNMISTAKABLE_CHARS = "23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz"

export function generateMongoId(charsCount = 17) {
  if (charsCount === undefined) charsCount = 17
  const id = randomString(charsCount, UNMISTAKABLE_CHARS)
  return id
}

export function generateEasyRandomPassword(charsCount = 6, numbers = true) {
  if (charsCount === undefined) charsCount = 6
  const alphabetLetters = 'abcdefghijkmnopqrstuvwxyz'
  const alphabetNumbers = '123456789'
  const digits: any[] = []
  for (let i = 0; i < charsCount; i++) {
    digits[i] = numbers ? choice(i%2 === 0 ? alphabetLetters : alphabetNumbers) : choice(alphabetLetters)
  }
  return digits.join('')
}

function randomString(charsCount, alphabet) {
  const digits: any[] = []
  for (let i = 0; i < charsCount; i++) {
    const asdf = choice(alphabet)
    digits[i] = asdf
  }
  return digits.join('')
}

function choice(arrayOrString) {
  const index = Math.floor(fraction() * arrayOrString.length)
  if (typeof arrayOrString === "string")
    return arrayOrString.substring(index, index + 1)
  else
    return arrayOrString[index]
}

let alea: any
function fraction() {
  if (typeof window !== 'undefined' && window.crypto && window.crypto.getRandomValues) {
    var array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] * 2.3283064365386963e-10; // 2^-32
  } else {
    if (!alea) {
      const windowExists = typeof window !== 'undefined'
      const seeds = [new Date, windowExists && window.innerHeight ? window.innerHeight : Math.random(), windowExists && window.innerWidth ? window.innerWidth : Math.random(), windowExists && window.navigator && window.navigator.userAgent ? window.navigator.userAgent : Math.random(), Math.random()]
      alea = Alea.apply(null, seeds as any)
    }
    return alea()
  }
}




// see http://baagoe.org/en/wiki/Better_random_numbers_for_javascript
// https://github.com/nquinlan/better-random-numbers-for-javascript-mirror
// for a full discussion and Alea implementation.
var Alea = function () {
  function Mash() {
    var n = 0xefc8249d;

    var mash: any = function(data) {
      data = data.toString();
      for (var i = 0; i < data.length; i++) {
        n += data.charCodeAt(i);
        var h = 0.02519603282416938 * n;
        n = h >>> 0;
        h -= n;
        h *= n;
        n = h >>> 0;
        h -= n;
        n += h * 0x100000000; // 2^32
      }
      return (n >>> 0) * 2.3283064365386963e-10; // 2^-32
    };

    mash.version = 'Mash 0.9';
    return mash;
  }

  return (function (args) {
    var s0 = 0;
    var s1 = 0;
    var s2 = 0;
    var c = 1;

    if (args.length == 0) {
      args = [+new Date];
    }
    var mash = Mash();
    s0 = mash(' ');
    s1 = mash(' ');
    s2 = mash(' ');

    for (var i = 0; i < args.length; i++) {
      s0 -= mash(args[i]);
      if (s0 < 0) {
        s0 += 1;
      }
      s1 -= mash(args[i]);
      if (s1 < 0) {
        s1 += 1;
      }
      s2 -= mash(args[i]);
      if (s2 < 0) {
        s2 += 1;
      }
    }
    mash = null;

    var random: any = function () {
      var t = 2091639 * s0 + c * 2.3283064365386963e-10; // 2^-32
      s0 = s1;
      s1 = s2;
      return s2 = t - (c = t | 0);
    };
    random.uint32 = function () {
      return random() * 0x100000000; // 2^32
    };
    random.fract53 = function () {
      return random() +
        (random() * 0x200000 | 0) * 1.1102230246251565e-16; // 2^-53
    };
    random.version = 'Alea 0.9';
    random.args = args;
    return random;

  } (Array.prototype.slice.call(arguments)));
};