import { gql } from '@apollo/client'

export type McStatsForCourse = {
  stats: {
    companyId: string;
    courseId: string;
    chapterId: string;
    actionId: string;
    totalUsers: number;
    answers: {
      answerId: string;
      numOfAnswers: number;
    }[];
  };
}

export const getMcStatsForCourseQuery = gql`
  query GetMcStatsForCourse ($companyId: String!, $courseId: String!) {
    stats: getMcStatsForCourse (companyId: $companyId, courseId: $courseId) {
      id
      companyId
      courseId
      chapterId
      actionId
      totalUsers
      answers {
        answerId
        numOfAnswers
      }
    }
  }
`
