import { gql } from '@apollo/client'

export const getCourseTemplatesQuery = gql`
  query GetCourseTemplates ($type: CourseTemplateType!) {
    templates: getCourseTemplates2 (type: $type) {
      courses {
        id
        title
        imageUrl
        curatorName
        curatorTitle
        curatorCompany
        curatorPhotoUrl
        chaptersUnlocked
        category
        categoryText
        difficulty
        skills
        tags
        language
        chaptersCount
        actionsCount
        interactionTime
      }
      canCreateScormCourse
    }
  }
`

export type GetCourseCategoriesQueryRes = {
  categories: {
    color: string;
    description: string;
    id: string;
    imageUrl: string;
    ordernumber: number;
    title: string;
  }[];
}

export const getCourseCategoriesQuery = gql`
  query GetCourseCategories {
    categories: getCourseCategories {
      color
      description
      id
      imageUrl
      order
      title
    }
  }
`
