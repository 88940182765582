import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'
import { Action } from 'graphql/schemas/action/Action'

export type StartChapterAssessmentsVars = {
  companyId: string;
  courseId: string;
  chapterId: string;
  returnAllAssessments?: boolean;
}

export type StartChapterAssessmentsRes = {
  startChapterAssessments: Action[];
}

export const startChapterAssessmentsMutation = gql`
  ${actionFragment}

  mutation StartChapterAssessments ($companyId: String!, $courseId: String!, $chapterId: String!, $returnAllAssessments: Boolean) {
    startChapterAssessments (companyId: $companyId, courseId: $courseId, chapterId: $chapterId, returnAllAssessments: $returnAllAssessments) {
      ...ActionFragment
    }
  }
`
