import React from 'react'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import classNames from 'classnames'
import { useMutation } from '@apollo/client'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import IfHasRole from 'components/auth/IfHasRole'
import { getDaysFromEpoch2 } from 'utils/functions'
import { deleteChapterMutation } from 'graphql/mutations/chapter/deleteChapter'
import { showAlert } from 'components/utils/Alert'
import { showModal } from 'components/utils/CustomModal'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { updateAppCache } from 'app'
import { useNavigate } from 'react-router-dom'

interface ChapterListItemProps {
  completed: boolean;
  companyId: string;
  active: boolean;
  enabled: boolean;

  id: string;
  order: number;
  title: string;
  courseId: string;
  disabledUntilDay: number|null;
  streak: boolean;

  currentDay: number;
  engagement: number;
  unlocked: boolean;
  isEditing: boolean;
  actionsLeft: number;
  tag: string;
  hasCertificate: boolean;

  onClick: () => void;
}

const today = getDaysFromEpoch2(new Date())
const DragHandle = <span className="dragChapterHandle">☰</span>

const ChapterListItem = React.memo((props: ChapterListItemProps) => {
  const navigate = useNavigate()
  const handleChapterChange = (e: React.MouseEvent<HTMLLIElement>) => {
    e.nativeEvent.preventDefault()

    if (props.isEditing || props.enabled) {
      const parsedStorage = JSON.parse(localStorage.getItem('currentChapters') || '{}')
      parsedStorage[props.courseId] = props.id
      localStorage.setItem('currentChapters', JSON.stringify(parsedStorage))
      updateAppCache('activeChapterId', props.id)
      props.onClick()
      return true
    }

    if (!props.isEditing && !props.enabled && props.streak) {
      return showModal({
        title: intl.get('session_locked_title'),
        content: intl.get('streak_locked_text'),
        primaryText: intl.get('streak_locked_button') ,
        primaryAction: () => navigate('/learn'),
        secondaryText: intl.get('ok'),
      })
    }

    if (!props.isEditing && !props.enabled) {
      return showModal({
        title: intl.get('session_locked_title'),
        content: intl.get('session_locked_text'),
        secondaryButton: false,
        primaryText: intl.get('ok'),
      })
    }

    return false
  }

  const liClassName = classNames({
    chapter: true,
    'active-chapter': props.active,
    'enabled-chapter': props.enabled || props.isEditing,
  })

  const chaterTitleClassName = classNames({
    active: props.active,
    'chapter-title': true,
    enabled: (props.enabled || props.isEditing) && !props.active,
  })

  const circleClassName = classNames({
    active: props.active,
    completed: props.completed,
    circle: true,
    enabled: props.enabled || props.isEditing,
  })

  const chapterNumberClassName = classNames({
    active: props.active,
    'chapter-number': true,
  })

  return (
    <li onClick={handleChapterChange} style={props.isEditing ? { cursor: 'grab' } : undefined} className={liClassName}>
      <div className={chapterNumberClassName}>
        {/* {hasCertificate ? <img src="/images/icon-diploma-white.svg" style={{ width: 20, marginRight: 6, display: 'inline-block', marginLeft: -26, marginTop: -3 }} /> : null} */}
        {intl.get('journey_session_number', { 0: props.order })} {props.isEditing && props.disabledUntilDay && props.disabledUntilDay > today && <span className="icon-lock-solid text-sm text-coral inline-block ml-1" />}
        {!props.isEditing && props.tag && <span className="ml-1 text-coral font-bold">{props.tag}</span>}
        {!props.isEditing && !props.tag && props.enabled && props.engagement && props.engagement >= 50 && props.engagement < 100 && <span className="ml-1 text-team font-bold">{intl.get('actions_to_go', { actions_to_go: props.actionsLeft })}</span> || null}
      </div>

      <div className={chaterTitleClassName}>
        {props.isEditing
          ? <Bars3Icon className="w-6 h-6 inline-block text-team cursor-grab mr-1" />
          : <div className={circleClassName}>
              <div className="circle-fill" style={{ height: `${props.engagement}%` }} />
            </div>
        }

        {props.title}
      </div>

      {props.isEditing &&
        <div className="team-icon" onClick={e => e.stopPropagation()}>
          <IfHasRole role="editor">
            <Dropdown
              id={props.id}
              order={props.order}
              courseId={props.courseId}
              chapterId={props.id}
              chapterOrder={props.order}
              chapterTitle={props.title}
            />
          </IfHasRole>
        </div>
      }

      {/* {!props.isEditing && props.tag &&
        <span className="redTag topRight">{props.tag}</span>
      } */}
      <hr/>
    </li>
  )
})

export default ChapterListItem

const Dropdown = ({ id, order, courseId, chapterId, chapterOrder, chapterTitle }: { id: string, order: number, courseId: string, chapterId: string, chapterOrder: number, chapterTitle: string }) => {
  const [deleteChapter] = useMutation(deleteChapterMutation, { variables: { courseId, chapterId }})
  const copyChapter = () => {
    localStorage.setItem('copiedChapter', JSON.stringify({ fromCourseId: courseId, fromChapterId: chapterId, chapterTitle: chapterTitle }))
    showAlert('Session copied!')
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton className="dropdown-toggle btn-edit btn-edit-primary focus:outline-none focus:ring focus:border-blue-300 rounded-md" aria-label="Session options" />

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <MenuItems
              static
              className="z-10 absolute w-40 right-0 mt-4 custom-dropdown">
              <div className="text-sm leading-6 px-4 pt-2 text-gray-400 tracking-wide">{intl.get('global_chapter')}</div>

              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    onClick={copyChapter}
                    className="flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {intl.get('global_copy')}
                  </a>
                </MenuItem>

                {order > 1 && (
                  <MenuItem>
                    <a
                      href="#"
                      onClick={() => {
                        showModal({
                          title: intl.get('chapter_tooltip_remove'),
                          content: intl.get('chapter_confirm_remove_text', { 0: chapterOrder, 1: chapterTitle }),
                          destructive: true,
                          primaryText: intl.get('global_delete'),
                          primaryAction: () => {
                            deleteChapter({
                              variables: {
                                courseId,
                                chapterId,
                              },
                              optimisticResponse: {
                                __typename: 'Mutation',
                                deleteChapter: {
                                  id,
                                  courseId,
                                  __typename: 'ChapterId',
                                },
                              },
                              update: (proxy, { data: { deleteChapter } }) => {
                                const app: any = proxy.readQuery({ query: getAppStateQuery })
                                const user: any = proxy.readQuery({
                                  query: getCurrentSubscriptionQuery,
                                  variables: {
                                    userId: app.appState.loggedInAs.uid,
                                  },
                                })
                                const cc = user.user.profile.currentSubscription
                                const [companyId, courseId] = cc && cc.split('-') || ['', '']
                                const data: any = proxy.readQuery({
                                  query: getChaptersForCourseQuery,
                                  variables: {
                                    companyId,
                                    courseId,
                                    returnAllAssessments: true,
                                  },
                                })
                                if (!data) return

                                const chapter = data.chapters.find(c => c.id === deleteChapter.id)
                                if (!chapter) return

                                proxy.writeQuery({
                                  query: getChaptersForCourseQuery,
                                  variables: {
                                    companyId,
                                    courseId,
                                    returnAllAssessments: true,
                                  },
                                  data: produce(data, (draft) => {
                                    draft.chapters = draft.chapters.filter(c => c.id !== deleteChapter.id)
                                  }),
                                })
                              },
                            })
                          },
                        })
                      }}
                      className="flex justify-between w-full px-4 py-1.5 text-sm text-coral hover:text-white hover:bg-coral transition-none">
                      {intl.get('global_delete')}
                    </a>
                  </MenuItem>
                )}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}
