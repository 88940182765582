import jwtDecode from 'jwt-decode'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import { client, updateAppCache } from 'app'
import { showAlert } from 'components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { getUserBasicQuery, getUserDisabledNotificationsQuery, getUserMetadata, getUserProfile, getUserQuery, getUserSubs, GQLUserResponse, getCurrentSubscriptionQuery, GetUserSubs, SubMinimal } from 'graphql/queries/user/getUser'
import { NotificationSchema } from 'graphql/schemas/activity/Notification'
import * as logger from 'logger'
import { FilepickerMetadata } from 'models/file_models'
import { CourseKeyData, getCourseKey } from 'models/utils'
import { track } from 'utils/track'
import { slog } from 'utils/dateFunctions'
import { checkHttpStatus, getAuthTokens } from './utils'
import { getCloudfrontSignatures } from './files'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { AppState } from 'graphql/schemas/app/State'
import { tick } from './courses_actions'
import { setCurrentLanguage } from 'utils/lokalisation'
import { getHomescreenData } from 'graphql/queries/home/getHomescreenData'
import { HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
import { subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { isCourseExpired } from 'graphql/schemas/course/Course'
import { expand, refetch } from 'utils/functions'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'
import { history } from 'utils/history'

const isInSubscriptions = (courseKey: CourseKeyData, subs) => {
  return subs.some(s => !!s && s.companyId === courseKey.companyId && s.courseId === courseKey.courseId)
}

const reportCourseChange = async (switchTo: CourseKeyData, token: string) => {
  slog.i('Calling API with', switchTo)
  const res = await refetch(`${BASE_API_URL}/api/v1/user/current_course/${switchTo.companyId}-${switchTo.courseId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  const response = await checkHttpStatus(res, true)
  const json = await response.json()
  return !json.error
}

const trackCourseChange = (courseKey: CourseKeyData) => {
  track({
    event: 'Program Opened',
    variables: {
      company_id: courseKey.companyId,
      course_id: courseKey.courseId,
    },
  })

  track({
    event: 'Program Opened',
    variables: {
      company_id: courseKey.companyId,
      programId: courseKey.courseId,
    },
  })
}

const updateAppState = (state: AppState, courseKey: CourseKeyData, enableEditing: boolean) => {
  const newState = produce(state, (draft) => {
    draft.appState.currentCompanyIdCourseId = `${courseKey.companyId}-${courseKey.courseId}`
    draft.appState.isCreatingCourse = false
    draft.appState.switchToAddedCourse = false
    draft.appState.isDuplicatingCourse = false
    draft.appState.isImportingPpt = false
    draft.appState.isLoading = false
    draft.appState.isEditing = enableEditing
  })
  client.writeQuery({
    query: getAppStateQuery,
    data: newState,
  })
}

let hasNoAvailableProgram = false
let switchTimer
let switchCounter = 0

export async function changeCurrentCourse({ courseKey, landing, enableEditing, courseId }: { courseKey?: CourseKeyData; landing?: string | null; enableEditing?: boolean; courseId?: string } = {}) {
  if (hasNoAvailableProgram || switchCounter >= 3) return

  // const navigate = useNavigate()
  // const location = useLocation()
  switchCounter = switchCounter + 1

  switchTimer = clearTimeout(switchTimer)
  switchTimer = setTimeout(() => {
    switchCounter = 0
  }, 1000)

  let switchTo = courseKey
  let switched = false
  let redirectTo = landing || '/learn'

  slog.i('Changing program to...', switchTo)

  const app: AppState | null = client.readQuery({ query: getAppStateQuery })
  if (!app) {
    slog.e('app is empty, exiting.')
    return
  }

  if (app.appState.currentCompanyIdCourseId === `${courseKey?.companyId}-${courseKey?.courseId}`) {
    if (enableEditing) {
      updateAppCache('isEditing', 'true')
    }
    return history.navigate && history.navigate(redirectTo)
  }

  const allSubs = await client.query<GetUserSubscriptions2Res, GetUserSubscriptionsVars>({
    query: getUserSubscriptions2Query,
    variables: { userId: app.appState.loggedInAs.uid, archived: false },
  })

  if (app.appState.isEditing) {
    slog.i('Disabling edit mode...')
    updateAppCache('isEditing', false)
  }

  if (!allSubs || !allSubs.data || !allSubs.data.response) {
    slog.e('allSubs is empty, exiting.')
    return
  }

  const clientData = client.readQuery<HomescreenData>({ query: getHomescreenData })
  const recentCourses = clientData?.homescreenData?.courseCategories.find(c => c.id === 'recent')
  const unexpiredCourses = allSubs.data.response.subscriptions.filter((s) => {
    const company = allSubs.data.response.companies.find(c => c.id === s.companyId)
    const course = allSubs.data.response.courses.find(c => c.id === s.courseId)
    const distributionParams = company?.subsDistributionParams.find(d => d.companyId === company.id && d.courseId === course?.id) || subsDistributionParamsFactory()
    const isExpired = isCourseExpired({
      absoluteDeadline: distributionParams.absoluteDeadline,
      startedAt: s.progress?.startedAt || 0,
      relativeDeadline: distributionParams.relativeDeadline,
    })
    if (!isExpired) return s
  })
  const availableRecentCourses = recentCourses?.subscriptions.filter(Boolean).filter(s => unexpiredCourses.some(c => c.id === s.id))
  const availableCourses = availableRecentCourses?.length
    ? availableRecentCourses
    : unexpiredCourses?.length
      ? unexpiredCourses
      : []

  if (availableCourses.length <= 0) {
    return hasNoAvailableProgram = true
  }

  const sortedAvailableCourses = availableCourses.slice().sort((a, b) => (b.progress?.lastActiveAt || 0) - (a.progress?.lastActiveAt || 0)) || []

  // when removing the active course we send courseId
  if (courseId) {
    const lastActiveCourse = sortedAvailableCourses.find(c => c.courseId !== courseId)
    if (lastActiveCourse) {
      switchTo = { companyId: lastActiveCourse.companyId || '', courseId: lastActiveCourse.courseId || '' }
      slog.w('Switching after deleting active program', switchTo)
    }
  }

  // when we don't get any program to switch to
  if (!switchTo && !courseId) {
    const lastActiveCourse = sortedAvailableCourses[0]
    // const randomCourseFromSubs = randomSample(sortedAvailableCourses)
    switchTo = { companyId: lastActiveCourse.companyId || '', courseId: lastActiveCourse.courseId || '' }
    slog.w('I didn\'t get any course to switch to, so I decided to switch to', switchTo)
  }

  // if by now we don't have anything to switch to we stop here
  if (!switchTo) return

  if (!app.appState.isCreatingCourse && !app.appState.switchToAddedCourse && !isInSubscriptions(switchTo, allSubs.data.response.subscriptions)) {
    slog.e('Not creating a course, and not subscribed to the specified course, exiting.')
    alert(`${intl.get('course_not_found_title')}\n\n${intl.get('course_not_found_text')}`)
    return history.navigate && history.navigate('/learn')
  }

  await getCloudfrontSignatures(switchTo)

  if (landing !== null) {
    const user: GQLUserResponse | null = client.readQuery({
      query: getCurrentSubscriptionQuery,
      variables: {
        userId: app.appState.loggedInAs.uid,
      },
    })

    if (!user) {
      slog.e('user is empty, exiting.')
      return
    }

    if (!app.appState.isCreatingCourse) {
      slog.i('Not creating a course, optimistically updating cache...')
      updateAppState(app, switchTo, !!enableEditing)
      switched = true
    }

    try {
      const remoteChange = await reportCourseChange(switchTo, app.appState.loggedInAs.token)
      if (remoteChange) {
        trackCourseChange(switchTo)

        if (!!app.appState.isCreatingCourse) {
          redirectTo = `/details/${switchTo.courseId}`
          slog.s('Completed creating new program.')
        }

        if (!switched) {
          slog.i('Didn\'t optimistically update, updating now...')
          updateAppState(app, switchTo, app.appState.switchToAddedCourse ? false : app.appState.isCreatingCourse)
          switched = true
        }

        if (location.pathname !== redirectTo) {
          history.navigate && history.navigate(redirectTo)
        }

        updateAppCache('switchToAddedCourse', false)
        updateAppCache('isCreatingCourse', false)

        slog.s('Done!')
      }
      else {
        history.navigate && history.navigate(landing ? landing : '/learn')
        logger.error('API prevented course change, rolling back to previous course.', app.appState.loggedInAs.uid)
        client.writeQuery({
          query: getAppStateQuery,
          data: app.appState,
        })
      }
    }
    catch (err) {
      const error = Error(err as any)
      slog.e('Caught error, switching back to old sub...')
      logger.error(`users_actions: changeCurrentCourse error: ${error.message}`, err)
      client.writeQuery({
        query: getAppStateQuery,
        data: app.appState,
      })
    }
  }
}

export async function remoteChangeCurrentCourse(courseKey: CourseKeyData) {
  const app: AppState | null = client.readQuery({ query: getAppStateQuery })
  if (!app || app.appState.isCreatingCourse || app.appState.switchToAddedCourse) return

  if (app.appState.currentCompanyIdCourseId === getCourseKey(courseKey)) {
    return slog.w('Received REMOTE course change request but is same than local.')
  }

  slog.w('REMOTE')
  slog.i('Changing program to...', courseKey)
  await getCloudfrontSignatures(courseKey)
  slog.i('Updating cache...')
  updateAppState(app, courseKey, false)
  tick(courseKey)
  slog.s('Done!')
}

/**
 * ACTIVITY
 */

export function refreshNotifications(data: CourseKeyData) {
  if (!data) return

  return new Promise((resolve, reject) => {
    const url = `${BASE_API_URL}/api/v1/activity/notifications/${data.companyId}-${data.courseId}?includeEditorNotifications=true&includeInactiveSubscriptions=true&groupedLikes=true`
    const token = getAuthTokens()
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(res => checkHttpStatus(res, true))
      .then(res => res.json())
      .then(async (res) => {
        if (!res.data) return

        const notifications: NotificationSchema[] = res.data.activeSubscription
        const editorNotifications: NotificationSchema[] = res.data.editorNotifications
        const inactiveNotifications: NotificationSchema[] = res.data.inactiveSubscriptions
        const payload = Object.assign({
          editorNotifications,
          activeSubscription: notifications,
          inactiveSubscriptions: inactiveNotifications,
        }, data)
        if (res.error) {
          showAlert(`Error - ${res.error.message}`, 'danger')
          resolve({ error: true, loading: false })
        }
        resolve({ payload, seen: res.data.seen, error: false, loading: false })
      })
      .catch((err) => {
        resolve({ error: true, loading: false })
        console.error('ERROR on refreshNotifications', err)
      })
  })
}

export async function activitySeen() {
  const token = getAuthTokens()
  fetch(`${BASE_API_URL}/api/v1/activity/seen?includeInactiveSubscriptions=true&includePrivateChatNotifications=true&includeEditorNotifications=true`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        showAlert(`Error - ${response.error.message}`, 'danger')
      }
    })
    .catch((err) => {
      logger.error(`users_actions: activitySeen error: ${err.message}`, err)
    })
}

export function clearCourseNotifications(data: CourseKeyData) {
  return new Promise((res, rej) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/activity/notifications/clear/${data.companyId}-${data.courseId}?privateChatNotifications=false&editorNotifications=false`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          showAlert(`Error - ${response.error.message}`, 'danger')
          rej()
        }
        else {
          showAlert('Notifications Cleared', 'success')
          res({ error: false })
        }
      })
  })
}

export function clearNotifications(isEditor: boolean) {
  return new Promise((res, rej) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/activity/notifications/clear/?allSubscriptions=true&privateChatNotifications=true&editorNotifications=${isEditor ? 'true' : 'false'}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          showAlert(`Error - ${response.error.message}`, 'danger')
          rej()
        }
        else {
          showAlert('Notifications Cleared', 'success')
          res({ error: false })
        }
      })
  })
}

export function clearNotificationsById(id: string) {
  return new Promise((res, rej) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/activity/notifications/clearbyid/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          showAlert(`Error - ${response.error.message}`, 'danger')
          rej()
        }
        else {
          showAlert('Notifications Cleared', 'success')
          res({ error: false })
        }
      })
  })
}

// for private chat notifications POST /notifications/clear/?privateChatNotifications=true&editorNotifications=false
// for editor chat notifications POST /notifications/clear/:companyIdCourseId?privateChatNotifications=false&editorNotifications=true

/**
 * SETTINGS
 */

export function updateUserPhoto(data: FilepickerMetadata) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/user/photo/data`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify(data),
  })
    .then(res => checkHttpStatus(res, true))
    .then(res => res.json())
    .then((res) => {
      if (!res.error) {
        showAlert('Photo successfully updated.', 'success')
      }
    })
    .catch((err) => {
      logger.error(`users_actions: updateUserPhoto error: ${err.message}`, err)
    })
}

export function deleteUserPhoto() {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/user/photo`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        showAlert(`Error - ${response.error.message}`, 'danger')
      }
    })
    .catch((err) => {
      logger.error(`users_actions: deleteUserPhoto error: ${err.message}`, err)
    })
}

export function changeUserName(data: any) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)
  refetch(`${BASE_API_URL}/api/v1/user/name`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify(data),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        showAlert('Name successfully updated.', 'success')
        const clientData: any = client.readQuery({
          query: getUserBasicQuery,
          variables: {
            userId: authToken.uid,
          },
        })

        if (!clientData) return

        client.writeQuery({
          query: getUserBasicQuery,
          variables: {
            userId: authToken.uid,
          },
          data: produce(clientData, (draft) => {
            draft.user.profile.firstName = data.firstName
            draft.user.profile.lastName = data.lastName
          }),
        })
      }
      else {
        showAlert(`Error - ${response.error.message}`, 'danger')
      }
    })
    .catch((err) => {
      logger.error(`users_actions: changeUserName error: ${err.message}`, err)
    })
}

export function updatePhonenumber(phoneNumber: string) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)
  refetch(`${BASE_API_URL}/api/v1/user/phone_number`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      phoneNumber,
    }),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        showAlert('Phone number successfully updated.')
        const clientData: any = client.readQuery({
          query: getUserQuery,
          variables: {
            userId: authToken.uid,
          },
        })
        if (!clientData) return
        client.writeQuery({
          query: getUserQuery,
          variables: {
            userId: authToken.uid,
          },
          data: produce(clientData, (draft) => {
            draft.user.phoneNumber = phoneNumber
          }),
        })
      } else {
        showAlert(`Error: ${response.error.message}.`, 'danger')
      }
    })
    .catch((err) => {
      showAlert(`Unable to update phone number (${err.message}).`, 'danger')
    })
}

export function updateLanguage(language: string, notify?: boolean) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/user/language`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      language,
    }),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        setCurrentLanguage(language)
        window.location.reload()
      } else {
        showAlert(`Error: ${response.error.message}.`, 'danger')
      }
    })
    .catch((err) => {
      showAlert(`Unable to update language (${err.message}).`, 'danger')
    })
}

export function updateMeta(key: string, value: string, skipAlert?: boolean) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)
  refetch(`${BASE_API_URL}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        !skipAlert && showAlert('Metadata successfully updated.')
        const clientData = client.readQuery({
          query: getUserMetadata,
          variables: {
            userId: authToken.uid,
          },
        })
        const newData = expand(key, value)

        const newProducedData = produce(clientData, (draft) => {
          if (!!draft.user.metadata?.general) {
            draft.user.metadata.general = {
              ...draft.user.metadata.general,
              ...newData,
              __typename: 'UserMetaData',
            }
          } else {
            draft.user.metadata = {
              ...(draft.user.metadata || {}),
              general: {
                ...newData,
                __typename: 'UserMetaData',
              },
              __typename: 'User',
            }
          }
        })

        client.writeQuery({
          query: getUserMetadata,
          variables: {
            userId: authToken.uid,
          },
          data: newProducedData,
        })
      } else {
        const error = Error(response.error)
        showAlert(`Error 1: ${error.message}.`, 'danger')
      }
    })
    .catch((err) => {
      showAlert(`Error 2: ${Error(err).message}`, 'danger')
    })
}

export function updateMetaDeep(key: string, value: string, skipAlert?: boolean, deepKey?: string) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)

  refetch(`${BASE_API_URL}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (!response.error) {
        !skipAlert && showAlert('Metadata successfully updated.')
        const clientData = client.readQuery({
          query: getUserMetadata,
          variables: {
            userId: authToken.uid,
          },
        })
        const newData = expand(key, value)

        const newProducedData = produce(clientData, (draft) => {
          if (deepKey && !!draft.user.metadata?.general?.[deepKey]) {
            draft.user.metadata.general[deepKey] = {
              ...draft.user.metadata.general[deepKey],
              ...newData[deepKey],
              lastChanges: Date.now(),
            }
          } else if (!!draft.user.metadata?.general) {
            draft.user.metadata.general = {
              ...draft.user.metadata.general,
              ...newData,
              __typename: 'UserMetaData',
            }
          } else {
            draft.user.metadata = {
              ...(draft.user.metadata || {}),
              general: {
                ...newData,
                __typename: 'UserMetaData',
              },
              __typename: 'User',
            }
          }
        })

        client.writeQuery({
          query: getUserMetadata,
          variables: {
            userId: authToken.uid,
          },
          data: newProducedData,
        })
      } else {
        const error = Error(response.error)
        showAlert(`Error 1: ${error.message}.`, 'danger')
      }
    })
    .catch((err) => {
      showAlert(`Error 2: ${Error(err).message}`, 'danger')
    })
}

export function updateTimezone() {
  const token = getAuthTokens()
  if (!token) return

  const timezone = -Math.round((new Date().getTimezoneOffset() / 60))
  refetch(`${BASE_API_URL}/api/v1/user/timezone/${timezone}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
    .then(response => checkHttpStatus(response, true))
    .catch((err) => {
      logger.error('updateTimezone error', err.message)
    })
}

export function toggleNotification(courseKey: string, enabled: boolean) {
  const token = getAuthTokens()
  const decodedToken: any = jwtDecode(getAuthTokens())
  refetch(`${BASE_API_URL}/api/v1/user/push_notifications/${courseKey}/${enabled ? 'on' : 'off'}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        return showAlert(`Error - ${response.error.message}`, 'danger')
      }

      const clientData: any = client.readQuery({
        query: getUserDisabledNotificationsQuery,
        variables: {
          userId: decodedToken.uid,
        },
      })

      if (!clientData) return

      const newArray = !enabled
        ? Array.from(new Set(clientData.user.disabledSubsNotifications.concat([courseKey])))
        : Array.from(new Set(clientData.user.disabledSubsNotifications.filter(n => n !== courseKey)))

      client.writeQuery({
        query: getUserDisabledNotificationsQuery,
        variables: {
          userId: decodedToken.uid,
        },
        data: produce(clientData, (draft) => {
          draft.user.disabledSubsNotifications = newArray
        }),
      })
    })
    .catch((err) => {
      logger.error(`users_actions: toggleNotification error: ${err.message}`, err)
    })
}

export function toggleGlobalNotification(id: string, enabled: boolean) {
  const token = getAuthTokens()
  const decodedToken: any = jwtDecode(getAuthTokens())
  refetch(`${BASE_API_URL}/api/v1/user/disabled_notifications`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      global: {
        [id]: !enabled,
      },
    }),
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        return showAlert(`Error - ${response.error.message}`, 'danger')
      }

      const clientData: any = client.readQuery({
        query: getUserDisabledNotificationsQuery,
        variables: {
          userId: decodedToken.uid,
        },
      })

      if (!clientData) return

      const newArray = !enabled
        ? Array.from(new Set(clientData.user.disabledNotifications.concat([id])))
        : Array.from(new Set(clientData.user.disabledNotifications.filter(n => n !== id)))

      client.writeQuery({
        query: getUserDisabledNotificationsQuery,
        variables: {
          userId: decodedToken.uid,
        },
        data: produce(clientData, (draft) => {
          draft.user.disabledNotifications = newArray
        }),
      })
    })
    .catch((err) => {
      logger.error(`users_actions: toggleGlobalNotification error: ${err.message}`, err)
    })
}

export function connect_social(accessToken, redirect = '/') {
  return new Promise((res, rej) => {
    const token = getAuthTokens()
    const decoded: Object = jwtDecode(accessToken)
    refetch(`${BASE_API_URL}/api/v1/auth/provider/connect_provider`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        Authorization2: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          res('error')
          showAlert(response.error.message, 'danger')
        }
        else {
          res('success')
          showAlert(`Successfully connected with ${(decoded['providerName'] === 'facebook' ? 'Facebook' : decoded['providerName'] === 'linkedin' ? 'LinkedIn' : decoded['providerName'])}.`)
        }
      })
      .catch((err) => {
        showAlert(err.error.message, 'danger')
        rej('error')
      })
  })
}

export function disconnect_social(provider: string, id: string) {
  return new Promise((res, rej) => {
    if (!provider) return

    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/auth/provider/disconnect_provider`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify({
        provider: {
          id,
          name: provider,
        },
      }),
    })
      .then(response => checkHttpStatus(response, true))
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          showAlert(response.error.message, 'danger')
          rej('error')
        }
        else {
          showAlert(`Successfully disconnected from ${(provider === 'facebook' ? 'Facebook' : provider === 'linkedin' ? 'LinkedIn' : provider)}.`)
          res('success')
        }
      })
      .catch((err) => {
        showAlert(err.error.message, 'danger')
        rej('error')
      })
  })
}

export function toggleCompletedCourseVisibility(courseKey: string, visible: string) {
  const token = getAuthTokens()
  const authToken: any = jwtDecode(token)
  const [companyId, courseId] = courseKey.split('-')
  refetch(`${BASE_API_URL}/api/v1/user/subscriptions/completed/${courseKey}/toggle_visibility/${visible}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
    .then(response => checkHttpStatus(response, true))
    .then(response => response.json())
    .then((response) => {
      if (response.error) {
        showAlert(response.error.message, 'danger')
      }
      else {
        showAlert(`Program successfully ${visible === 'on' ? 'displayed' : 'hidden'}.`)
        const clientData = client.readQuery({
          query: getUserProfile,
          variables: {
            userId: authToken.uid,
          },
        })
        client.writeQuery({
          query: getUserProfile,
          variables: {
            userId: authToken.uid,
          },
          data: produce(clientData, (draft) => {
            const index = draft.user.completedCourses.findIndex(c => c.companyId === companyId && c.courseId === courseId)
            if (index !== -1) draft.user.completedCourses[index].invisible = visible === 'off' ? true : false
          }),
        })
      }
    })
    .catch((err) => {
      console.log(err)
      showAlert(err.error.message, 'danger')
    })
}

export function fetchParentNotifications(): any {
  return new Promise((resolve, reject) => {
    const url = `${BASE_API_URL}/api/v1/activity/notifications?includeEditorNotifications=true&includeInactiveSubscriptions=true&groupedLikes=true`
    const token = getAuthTokens()
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(res => checkHttpStatus(res, true))
      .then(res => res.json())
      .then(async (res) => {
        if (!res.data) return

        const notifications: NotificationSchema[] = res.data.activeSubscription
        const editorNotifications: NotificationSchema[] = res.data.editorNotifications
        const inactiveNotifications: NotificationSchema[] = res.data.inactiveSubscriptions
        const privateChatNotifications: NotificationSchema[] = res.data.privateChatNotifications
        const payload = {
          editorNotifications,
          privateChatNotifications,
          activeSubscription: notifications,
          inactiveSubscriptions: inactiveNotifications,
        }
        if (res.error) {
          showAlert(`Error - ${res.error.message}`, 'danger')
          resolve({ error: true, loading: false })
        }
        resolve({ payload, error: false, loading: false })
      })
      .catch((err) => {
        resolve({ error: true, loading: false })
        console.error('ERROR on refreshNotifications', err)
      })
  })
}

export function fetchChildNotifications(cmpId, crsId): any {
  return new Promise((resolve, reject) => {
    const url = `${BASE_API_URL}/api/v1/activity/notifications/${cmpId}-${crsId}?includePrivateChatNotifications=false&includeInactiveSubscriptions=false&includeEditorNotifications=false`
    const token = getAuthTokens()
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
      .then(res => checkHttpStatus(res, true))
      .then(res => res.json())
      .then(async (res) => {
        if (!res.data) return

        const notifications: NotificationSchema[] = res.data.activeSubscription
        const editorNotifications: NotificationSchema[] = res.data.editorNotifications
        const inactiveNotifications: NotificationSchema[] = res.data.inactiveSubscriptions
        const privateChatNotifications: NotificationSchema[] = res.data.privateChatNotifications
        const payload = {
          editorNotifications,
          privateChatNotifications,
          activeSubscription: notifications,
          inactiveSubscriptions: inactiveNotifications,
        }
        if (res.error) {
          showAlert(`Error - ${res.error.message}`, 'danger')
          resolve({ error: true, loading: false })
        }
        resolve({ payload, error: false, loading: false })
      })
      .catch((err) => {
        resolve({ error: true, loading: false })
        console.error('ERROR on refreshNotifications', err)
      })
  })
}
