export function tokenize(value: string) {
  if (!value) return

  const text = value
    .replace(/{{explain:(.*?):(.*?)}}/g, ($0, $1, $2) => `<abbr title="${decodeURIComponent($2)}">${decodeURIComponent($1)}</abbr>`)
    // .replace(/{{(name|firstName|lastName|fullName)}}/g, '<span title="name">$1</span>')
  return text
}

export function untokenize(value: string) {
  if (!value) return

  const text = value
    .replace(/<abbr title="(.*?)">(.*?)<\/abbr>/g, ($0, $1, $2) => `{{explain:${encodeURIComponent($2)}:${encodeURIComponent($1)}}}`)
    // .replace(/<span title="name">(.*?)<\/span>/g, '{{$1}}')
    // this doesn't work correctly, not sure why
  return text
}
