import { gql } from '@apollo/client'

export type GetCompanyType = {
  company: {
    id: string;
    organizationId: string;
  },
}

export const getCompanyQuery = gql`
  query GetCompanyQuery ($companyId: String!) {
    company: getCompany (companyId: $companyId) {
      id
      organizationId
      organizationName
    }
  }
`
