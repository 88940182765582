import Tippy from '@tippyjs/react'
import React from 'react'

const Lock = ({ className, tooltip }: { className: string, tooltip?: string }) => {
  return (
    <Tippy placement="right" content={tooltip} disabled={!tooltip}>
      <svg aria-hidden="true" focusable="false" className={className} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M384 223.1L368 224V144c0-79.41-64.59-144-144-144S80 64.59 80 144V224L64 223.1c-35.35 0-64 28.65-64 64v160c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64v-160C448 252.7 419.3 223.1 384 223.1zM256 383.1c0 17.67-14.33 32-32 32s-32-14.33-32-32v-32c0-17.67 14.33-32 32-32s32 14.33 32 32V383.1zM304 224h-160V144C144 99.88 179.9 64 224 64s80 35.88 80 80V224z"></path></svg>
    </Tippy>
  )
}

export default Lock
