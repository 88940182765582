import React from 'react'
import { createPortal } from 'react-dom'
import intl from 'react-intl-universal'

declare var window: any
declare var document: any
let x

if (!window.x) {
  x = {}
}

x.Selector = {}
x.Selector.getSelected = function () {
  let t = ''
  if (window.getSelection) {
    t = window.getSelection()
  } else if (document.getSelection) {
    t = document.getSelection()
  } else if (document.selection) {
    t = document.selection.createRange().text
  }
  return t
}

class PopoverMenu extends React.Component<{ isEditing: boolean, addToNotes?: (selection: string) => void, children }, { selectedText: any, toolsStyle: any }> {
  private _textSelectionTimeout
  private _isMounted

  constructor(props) {
    super(props)

    this.state = { toolsStyle: { opacity: 0, left: -9999, top: 0 }, selectedText: '' }

    this.addToNotes = this.addToNotes.bind(this)
    this.onSelectionChange = this.onSelectionChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onBlur)
    this._isMounted = true
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onBlur)
    this._isMounted = false
    clearTimeout(this._textSelectionTimeout)
  }

  onSelectionChange() {
    const selectedText = x.Selector.getSelected()
    const selection	= window.getSelection()

    this._textSelectionTimeout = clearTimeout(this._textSelectionTimeout)
    this._textSelectionTimeout = setTimeout(() => {
      if (selection.rangeCount) {
        const range = selection.getRangeAt(0)
        const clientRects = range.getClientRects()

        // to add scrolled distance
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft

        if (selectedText !== '' && clientRects[0]) {
          const center = clientRects[0].left + scrollLeft + clientRects[0].width / 2
          this._isMounted && this.setState({ selectedText: selection.toString(), toolsStyle: { left: center - 57, top: clientRects[0].top + scrollTop - 40 } })
        }
        else {
          this._isMounted && this.setState({ selectedText: '', toolsStyle: { left: this.state.toolsStyle.left, top: this.state.toolsStyle.top } })
        }
      }
      else {
        this._isMounted && this.setState({ selectedText: '', toolsStyle: { left: this.state.toolsStyle.left, top: this.state.toolsStyle.top } })
      }
    }, 0)
  }

  addToNotes(e) {
    e.preventDefault()
    this.props.addToNotes && this.props.addToNotes(this.state.selectedText)
    this._isMounted && this.setState({ selectedText: '', toolsStyle: { left: this.state.toolsStyle.left, top: this.state.toolsStyle.top } })
  }

  onBlur(e) {
    const addToNotes = document.getElementById('add-to-notes')
    const tools = document.getElementById('select-tools')
    if (
      // adding one check per button might not be the best
      addToNotes && !addToNotes.contains(e.target) &&
      tools && !tools.contains(document.activeElement)) {
      this._isMounted && this.setState({ selectedText: '', toolsStyle: { left: this.state.toolsStyle.left, top: this.state.toolsStyle.top } })
    }
  }

  render() {
    return (
      <div className="popover-menu" onMouseUp={this.onSelectionChange}>
        {!this.props.isEditing &&
          <SelectTools
            show={this.state.selectedText !== ''}
            left={this.state.toolsStyle.left}
            top={this.state.toolsStyle.top}
            addToNotes={this.addToNotes}
          />
        }

        {this.props.children}
      </div>
    )
  }
}

export default PopoverMenu

const SelectTools = ({ show, left, top, addToNotes }: { show: boolean, left: number, top: number, addToNotes?: (e) => void }) => {  
  if (!show) {
    return null
  }

  return <>
    {
      createPortal(
        <ul id="select-tools" className="select-tools" style={{ left, top }}>
          <span className="arrow" />
          <li id="add-to-notes" onClick={e => addToNotes && addToNotes(e)}>{intl.get('add_to_notes')}</li>
        </ul>,
        document.getElementById('app'),
      )
    }
  </>
}
