import React from 'react'
import { createRoot } from 'react-dom/client'

export function showSlider(id: string, classNames: string, content: any, onClick?: () => void, callback?: () => void) {
  const container = document.getElementById('main-container')
  const onRef = (ref) => {
    setTimeout(() => {
      const slider = document.getElementById(id)
      slider && slider.classList.remove('close')
      callback && callback()
    }, 50)
  }
  if (container) {
    const div = document.createElement('div')
    const root = createRoot(div)
    div.setAttribute('id', `slider-${id}`)
    container.appendChild(div)
    if (!onClick) {
      onClick = () => null
    }
    root.render(
      <Slider ref={onRef} id={id} classNames={classNames} onClick={onClick}>
        {content}
      </Slider>
    )
  }
}

export function hideSlider(id: string) {
  const container = document.getElementById('main-container')
  const sliderContainer = document.getElementById(`slider-${id}`)
  const slider = document.getElementById(id)
  slider && slider.classList.add('close')
  setTimeout(() => {
    container && sliderContainer && container.contains(sliderContainer) && container.removeChild(sliderContainer)
  }, 500)
}

class Slider extends React.Component<{ id: string, classNames: string, children: any, onClick: () => void }, {}> {
  render() {
    const { id, classNames, onClick } = this.props
    return (
      <div id={id} className={`slider close ${classNames}`} onClick={onClick}>
        {this.props.children}
      </div>
    )
  }
}
