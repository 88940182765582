import { gql } from '@apollo/client'
import { Company } from 'graphql/schemas/company/Company';
import { Organization } from 'graphql/schemas/organization/Organization'

export type GetUserOrganizationCompanies2Vars = {
  organizationId: string;
  userId: string;
}

export type GetUserOrganizationCompanies2Res = {
  organization: {
    id: string;
    companies: {
      company: Company;
    }[];
  }
}

export const getUserOrganizationCompanies2Query = gql`
  query GetUserOrganizationCompanies2 ($organizationId: String!, $userId: String!) {
    organization: getUserOrganizationCompanies2 (organizationId: $organizationId, userId: $userId) {
      id
      companies {
        company {
          id
          name
          companyType
        }
      }
    }
  }
`
