import React, { useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import intl from 'react-intl-universal'
import { getSignedValue } from 'actions/files'
import { BASE_URL } from 'constants/General'
import { GetScormLaunchLinkRes, GetScormLaunchLinkVars, getScormLaunchLinkMutation } from 'graphql/mutations/scorm/createLaunchLink'
import { Course } from 'graphql/schemas/course/Course'
import OpenInApp from 'components/OpenInApp'
import { augmentNameBasic } from 'utils/utils'
import { UserProfile } from 'graphql/schemas/user/User'

export const ScormProgram = ({ companyId, course, userProfile }: { companyId: string, course: Course, userProfile: UserProfile }) => {
  const signedUrl = course.imageUrl && getSignedValue(course.imageUrl) || ''
  const ref = useRef<any>(null)
  const augmentedDescription = augmentNameBasic(course.description || undefined)(userProfile)

  const openRequestedPopup = (url: string) => {
    ref.current = window.open(
      url,
      'ScormProgram',
      'resizable,scrollbars,status,width=1024,height=600'
    )
  }

  const [getScormLaunchLink, { data, loading }] = useMutation<GetScormLaunchLinkRes, GetScormLaunchLinkVars>(getScormLaunchLinkMutation, {
    variables: {
      companyId,
      courseId: course.id,
      redirectUrl: `${BASE_URL}/scorm/register`,
    },
    onCompleted: (data) => {
      openRequestedPopup(data.getScormLaunchLink)
    },
  })

  return (
    <>
      <OpenInApp />

      <div className="stick-container px-4 md:px-6">
        <section className="session-block mt-0 sm:mt-4 text-center">
          <h2 className="text-teak text-base uppercase font-noto-sans tracking-wider mt-4 sm:mt-0">
            {intl.get('scorm_hosted_on_gnowbe')}
          </h2>

          <h1 className="mt-6 sm:mt-8 text-2xl sm:text-3xl">{course.title}</h1>

          {signedUrl && (
            <div className="image-holder w-48 h-48 sm:w-60 sm:h-60 mx-auto">
              <img
                className="image w-full h-full"
                src={signedUrl}
              />
            </div>
          )}

          {course.description && <div className="text-left mt-8 break-words session-description" dangerouslySetInnerHTML={{ __html: augmentedDescription }} />}
        </section>

        <section className="mx-4 sm:mx-0">
          <div className="action-item rounded-md shadow-lg px-8 py-6 sm:px-12 sm:py-8" tabIndex={-1} onClick={() => getScormLaunchLink()}>
            <div className="flex">
              <aside className="action-type w-3/12 sm:w-2/12">
                <span className="type-name text-teak">{intl.get('useraction_scorm')}</span>
              </aside>

              <section className="action-content w-9/12 sm:w-10/12">
                <span className="action-title">
                  {loading
                    ? 'Loading SCORM program...'
                    : 'Play SCORM program'
                  }
                </span>
              </section>
            </div>

            <div className="mt-4">
              <img src="/images/buttons/btn-scorm-play.svg" className="mx-auto" />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
