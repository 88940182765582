import { gql } from '@apollo/client'

export type GetSupportedLanguagesForTranslationRes = {
  supportedLanguages: {
    name: string;
    code: string;
  }[];
}

export const getSupportedLanguagesForTranslationQuery = gql`
  query GetSupportedLanguagesForTranslation {
    supportedLanguages: getSupportedLanguagesForTranslation {
      name
      code
    }
  }
`