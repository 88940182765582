export const showSave = () => {
  let _timeout

  const pill = document.getElementById('saving-pill')
  if (!pill) return

  pill.classList.add('opacity-100')

  _timeout = clearTimeout(_timeout)
  _timeout = setTimeout(() => {
    pill.classList.remove('opacity-100')
  }, 1500)
}
