import { gql } from '@apollo/client'
// import { engagementProctorResultsFragment } from 'graphql/queries/user/getEngagementsForSubscription'
import { Timestamps } from 'graphql/schemas/user/UserProgress'

export type ListenEngagementsForSubscriptionChangesRes = {
  __typename: string;
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  chapterId: string;
  engagement: number;
  assessmentsStartedAt: number;
  assessmentsEnded: number;
  // assessmentsHasProctoring: boolean;
  // assessmentsProctorResults: any;
  timestamps: Timestamps;
}

//   ${engagementProctorResultsFragment}

export const listenEngagementsForSubscriptionChangesSubscription = gql`

  subscription ListenEngagementsForSubscriptionChanges ($companyId: String!, $courseId: String!, $userId: String!, $version: Float!) {
    listenEngagementsForSubscriptionChanges (companyId: $companyId, courseId: $courseId, userId: $userId, version: $version) {
      ... on EngagementTimestampsUpdated {
        id
        companyId
        courseId
        chapterId
        timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
      }

      ... on EngagementUpdated {
        id
        userId
        companyId
        courseId
        chapterId
        engagement
        assessmentsStartedAt
        assessmentsEnded
        # assessmentsHasProctoring
        # assessmentsProctorResults {
        #  ...EngagementProctorResultsFragment
        # }
      }
    }
  }
`
