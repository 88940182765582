import { gql } from '@apollo/client'
import { postFragment } from 'graphql/fragments/Post'

enum PostType {
  all,
  directPost,
  share,
}

enum PostsSortField {
  createdAt,
}

enum SortDirection {
  asc,
  desc,
}

export interface GQLPostVariables {
  companyId: string;
  courseId: string;
  limit: number;
  skip: number;
  postType: PostType;
  sortBy: PostsSortField;
  sortDirection: SortDirection;
}

export const getPostsForSubscriptionQuery = gql`
  ${postFragment}

  query GetPostsForSubscription (
    $companyId: String!, $courseId: String!, $limit: Float!,
    $sortBy: PostsSortField!, $sortDirection: SortDirection!,
    $chapterIds: [String!], $actionIds: [String!], $userIds: [String!],
    $type: PostType, $lastId: String, $lastSortByValue: Float ) {
    posts: getPostsForSubscription (companyId: $companyId, courseId: $courseId, chapterIds: $chapterIds, actionIds: $actionIds, userIds: $userIds, type: $type, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, lastId: $lastId, lastSortByValue: $lastSortByValue) {
      ...PostFragment
    }
  }
`

export const getPostsForSubscription2Query = gql`
  query GetPostsForSubscription(
    $companyId: String!,
    $courseId: String!,
    $limit: Float!,
    $sortBy: PostsSortField!,
    $sortDirection: SortDirection!,
    $chapterIds: [String!],
    $actionIds: [String!],
    $userIds: [String!],
    $type: PostType,
    $lastId: String,
    $lastSortByValue: Float) {
    posts: getPostsForSubscription2 (companyId: $companyId, courseId: $courseId, chapterIds: $chapterIds, actionIds: $actionIds, userIds: $userIds, type: $type, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, lastId: $lastId, lastSortByValue: $lastSortByValue) {
      posts {
        id
        userId
        companyId
        courseId
        chapterId
        actionId
        contentType
        title
        answer
        comment
        thumbnail
        commentsCount
        likesCount
        likedByMe
        createdAt
        lastCommentAt
        commentsLastSeenAt
        secondsUsed
      }
      users {
        id
        profile {
          id
          firstName
          lastName
          fullName
          imageUrl
        }
      }
      chapters {
        id
        order
        title
      }
      actions {
        id
        title
        order
        userAction
        descriptionShort
      }
    }
  }
`

export const getPostsCountForSubscriptionQuery = gql`
  query GetPostsCount ($courseId: String!, $companyId: String!) {
    meta: getPostsCountsForSubscription (courseId: $courseId, companyId: $companyId) {
      posts
      contributors
    }
  }
`

export const getPostsLikesForSubscriptionQuery = gql`
  fragment PostUserLikeFragment on PostLike {
    id
    likedAt
    userIdLiked
    userLiked {
      id
      profile {
        id
        firstName
        lastName
        fullName
        imageUrl
      }
    }
  }

  query GetPostsLikes ($companyId: String!, $courseId: String!, $chapterId: String!, $actionId: String!, $userId: String!, $limit: Float!, $olderThan: Float) {
    likes: getPostLikes (companyId: $companyId, courseId: $courseId, chapterId: $chapterId, actionId: $actionId, userId: $userId, limit: $limit, olderThan: $olderThan) {
      ...PostUserLikeFragment
    }
  }
`

export const exportPostsForSubscriptionQuery = gql`
  query ExportPostsForSubscription ($companyId: String!,
    $courseId: String!,
    $limit: Float!,
    $sortBy: PostsSortField!,
    $sortDirection: SortDirection!,
    $chapterIds: [String!],
    $actionIds: [String!],
    $userIds: [String!],
    $type: PostType,
    $lastId: String,
    $lastSortByValue: Float) {
      exportPostsForSubscription (companyId: $companyId , courseId: $courseId, chapterIds: $chapterIds, actionIds: $actionIds, userIds: $userIds, type: $type, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, lastId: $lastId, lastSortByValue: $lastSortByValue) {
        availableAt
        emailTo
        exportId
      }
    }
` 