import jwtDecode from 'jwt-decode'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import { client } from 'app'
import { showAlert } from 'components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { getNotesQuery, getProgressQuery } from 'graphql/queries/user/updateProgress'
import * as logger from 'logger'
import { CourseKeyData } from 'models/utils'
import { checkHttpStatus, getAuthTokens } from './utils'
import { getUserAssessmentsResultsQuery } from 'graphql/queries/user/getUserAssessmentsResults'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res } from 'graphql/queries/user/getUserSubscriptions'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { showModal } from 'components/utils/CustomModal'
import { AppState } from 'graphql/schemas/app/State'
import { camelCase, nl2br, refetch } from 'utils/functions'
import { track } from 'utils/track'
import { HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
import { getHomescreenData } from 'graphql/queries/home/getHomescreenData'
import { changeCurrentCourse } from './users_actions'
import { GQLStudyForSubscription2Response, resetStudyQuery } from 'graphql/queries/user/getStudyForSubscription'
import { userProgressFactory } from 'graphql/schemas/user/UserProgress'
import { useNavigate } from 'react-router-dom'
import { history } from 'utils/history'

export function tick(data: CourseKeyData) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/progress/${data.companyId}-${data.courseId}/tick`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(res => checkHttpStatus(res, true))
  .then((res) => {
    if (!res.ok) {
      // sometimes you can have this thing as your current program (how?)
      // if this is the case, tick will return 401
      // if tick returns 401 and this is your current program, find another one and switch
      // problem: what happens if you have no available program?
    }
  })
  .catch((err) => {
    logger.error(`courses_actions: tick error: ${err.message}`, err)
  })
}

export function unlockNextDay(subId: string, nextDay: number) {
  const token = getAuthTokens()
  const [companyId, courseId] = subId.split('-')
  const authTokenPayload: any = token ? jwtDecode(token) : {}
  const query = {
    query: getProgressQuery,
    variables: {
      companyId,
      courseId,
      userId: authTokenPayload.uid,
    }
  }
  const clientData: any = client.readQuery(query)

  if (clientData) {
    client.writeQuery({
      ...query,
      data: produce(clientData, (draft) => {
        if (draft?.getUserSubscription?.progress) {
          draft.getUserSubscription.progress.currentDay = nextDay
        }
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      ...query,
      data: clientData,
    })
  }

  refetch(`${BASE_API_URL}/api/v1/progress/${subId}/next`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      currentDay: nextDay,
    }),
  })
  .then(res => checkHttpStatus(res, false))
  .then(res => res.json())
  .then((res) => {
    if (res.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`unlockNextDay error: ${err.message}`, err)
  })
}

export function archiveCourse(subId: string, toggle: boolean) {
  const navigate = history.navigate || useNavigate()
  const clientData: AppState|null = client.readQuery({ query: getAppStateQuery })
  const homeData: HomescreenData|null = client.readQuery({ query: getHomescreenData })
  if (!clientData) return

  return new Promise((resolve, reject) => {
    const [companyId, courseId] = subId.split('-')
    refetch(`${BASE_API_URL}/api/v1/user/subscriptions/${subId}/${toggle ? 'archive' : 'unarchive'}`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${clientData.appState.loggedInAs.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
    .then(res => checkHttpStatus(res, false))
    .then(res => res.json())
    .then(async (res) => {
      if (!res.error) {
        const query = {
          query: getUserSubscriptions2Query,
          variables: {
            userId: clientData.appState.loggedInAs.uid,
            archived: true,
          },
        }

        track({
          event: `Program ${toggle}` ? 'Archived' : 'Unarchived',
          variables: {
            company_id: companyId,
            course_id: courseId,
          },
        })

        if (!toggle) {
          showModal({
            title: intl.get('program_unarchive_success'),
            icon: 'icon-check-light text-green-500',
            content: nl2br(intl.get('success_unarchiving_program')),
            primaryText: intl.get('return_home'),
            primaryAction: () => navigate('/home'),
            secondaryText: camelCase(intl.get('close')),
          })
        }

        resolve({ error: false })

        if (!toggle) {
          const archivedData = await client.query<GetUserSubscriptions2Res>(query)
          const newData = produce(archivedData.data, (draft) => {
            const index = draft.response.subscriptions.findIndex(s => s.companyId === companyId && s.courseId === courseId)
            if (index !== -1) draft.response.subscriptions.splice(index, 1)
          })

          client.writeQuery({
            ...query,
            data: newData,
          })
        }
        else {
          const archivedData = await client.query<GetUserSubscriptions2Res>(query)
          const newData = produce(archivedData.data, (draft) => {
            const index = draft.response.subscriptions.findIndex(s => s.companyId === companyId && s.courseId === courseId)
            if (index !== -1) draft.response.subscriptions.splice(index, 1)
          })

          client.writeQuery({
            ...query,
            data: newData,
          })

          const recentCourses = homeData?.homescreenData.courseCategories.find((c) => c.id === 'recent')
          if (subId === clientData.appState.currentCompanyIdCourseId && recentCourses) {
            const recentCourse = recentCourses.subscriptions.filter(s => !(s.companyId === companyId && s.courseId === courseId))[1]
            if (!recentCourse) return

            changeCurrentCourse({ courseKey: { companyId: recentCourse.companyId, courseId: recentCourse.courseId }, landing: '/home' })
          }
        }
        if (toggle) {
          showModal({
            title: intl.get('program_archive_success'),
            icon: 'icon-check-light text-green-500',
            content: intl.get('success_archiving_program'),
            primaryText: intl.get('return_home'),
            primaryAction: () => navigate('/home'),
            secondaryButton: false,
          })
        }
      }
      else {
        showAlert('Error', 'danger')
        resolve({ error: true })
      }
    })
    .catch((error) => {
      const err = Error(error)
      showAlert(`Error - ${err.message}`, 'danger')
      logger.error(`archiveCourse error: ${err.message}`, err)
      resolve({ error: true })
    })
  })
}

export function unsubscribeFromCourse(data: CourseKeyData) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/user/unsubscribe/${data.companyId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, false))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      showAlert(`Request Error — ${response.error}`, 'danger')
    } else {
      showAlert('Successfully unsubscribed from the program.')
    }
  })
  .catch((error) => {
    const err = Error(error)
    showAlert(`Network Error — ${err.message} — Please try again in a while.`)
    logger.warn(`courses_actions: unsubscribeFromCourse error: ${err.message}`, err)
  })
}

export function saveNote(answer: string, data: CourseKeyData, showTip?: boolean) {
  const navigate = history.navigate || useNavigate()
  const token = getAuthTokens()
  const authTokenPayload: any = token ? jwtDecode(token) : {}

  refetch(`${BASE_API_URL}/api/v1/studies/notes_saved`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId: data.companyId,
      courseId: data.courseId,
      notes: answer,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      const error = Error(response.error)
      return showAlert(`Request Error — ${error.message}`)
    }

    const clientData: any = client.readQuery({
      query: getNotesQuery,
      variables: {
        companyId: data.companyId,
        courseId: data.courseId,
        userId: authTokenPayload.uid,
      },
    })

    if (!clientData) return

    client.writeQuery({
      query: getNotesQuery,
      variables: {
        companyId: data.companyId,
        courseId: data.courseId,
        userId: authTokenPayload.uid,
      },
      data: produce(clientData, (draft) => {
        draft.getUserSubscription.progress.notes = answer
      }),
    })

    if (showTip) {
      showModal({
        title: 'Hey',
        content: intl.get('dialog_notes_message'),
        primaryText: intl.get('dialog_notes_positive_text'),
        primaryAction: () => navigate('/learn/notes'),
        secondaryText: intl.get('dialog_notes_negative_text'),
      })
    }

    showAlert(intl.get('notes_saved'))
  })
  .catch((error) => {
    const err = Error(error)
    showAlert(`Network Error — ${err.message} — Please try again in a while.`)
    logger.warn(`courses_actions: saveNote error: ${err.message}`, err)
  })
}

export function resetUserStudy(study: { companyId: string, courseId: string, userId: string }) {
  const data = client.readQuery<GQLStudyForSubscription2Response>({
    query: resetStudyQuery,
    variables: {
      companyId: study.companyId,
      courseId: study.courseId,
      userId: study.userId,
    },
  })

  if (data) {
    client.writeQuery({
      query: resetStudyQuery,
      variables: {
        companyId: study.companyId,
        courseId: study.courseId,
        userId: study.userId,
      },
      data: produce(data, (draft) => {
        draft.sub.progress = userProgressFactory({ id: `${study.companyId}-${study.courseId}-${study.userId}` })
        draft.study = draft.study.map((s) => {
          return {
            ...s,
            viewed: 0,
            answer: null,
            reminder: null,
          }
        })
      }),
    })
  }

  const revert = () => {
    if (!data) return

    client.writeQuery({
      data,
      query: resetStudyQuery,
      variables: {
        companyId: study.companyId,
        courseId: study.courseId,
        userId: study.userId,
      },
    })
  }

  return new Promise((resolve, reject) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/studies/${study['companyId']}-${study['courseId']}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
    .then(response => checkHttpStatus(response, true))
    .then(response => {
      if (!response) {
        resolve({ error: false })
      }
      return response.json()
    })
    .then((response) => {
      if (response.error) {
        showAlert(`Unable to remove study - ${response.error.message}`)
        resolve({ error: true })
        return revert()
      }
      localStorage.removeItem('gnowbe_seen_modals')
      showAlert('Answers removed', 'success')
      resolve({ error: false })
    })
    .catch((err) => {
      resolve({ error: true })
      revert()
      showAlert('Error clearing answers', 'danger')
      logger.error(`courses_actions: resetUserStudy error: ${err.message}`, err)
      console.error('ERROR on resetUserStudy', err)
    })
  })
}

export function resetUserAssessments(data: { companyId: string, courseId: string }, redirect?: boolean) {
  const token = getAuthTokens()
  const authTokenPayload: any = token ? jwtDecode(token) : {}

  const clientData: any = client.readQuery({
    query: getUserAssessmentsResultsQuery,
    variables: {
      companyId: data.companyId,
      courseId: data.courseId,
      userId: authTokenPayload.uid,
    },
  })

  if (clientData) {
    client.writeQuery({
      query: getUserAssessmentsResultsQuery,
      variables: {
        companyId: data.companyId,
        courseId: data.courseId,
        userId: authTokenPayload.uid,
      },
      data: produce(clientData, (draft) => {
        draft.getUserSubscription.progress.assessmentsCompleted = false
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      query: getUserAssessmentsResultsQuery,
      variables: {
        companyId: data.companyId,
        courseId: data.courseId,
        userId: authTokenPayload.uid,
      },
      data: clientData,
    })
  }

  if (redirect) {
    history.navigate && history.navigate('/learn')
  }

  refetch(`${BASE_API_URL}/api/v1/studies/${data.companyId}-${data.courseId}/assessments`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(res => checkHttpStatus(res, true))
  .then(res => res.json())
  .then((res) => {
    if (res.error) {
      revert()
      return showAlert(res.error.message, 'danger')
    }
  })
  .catch((err) => {
    revert()
    showAlert(err.message, 'danger')
    logger.error(`courses_actions: resetUserAssessments error: ${err.message}`, err)
  })
}
