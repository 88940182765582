import React, { useEffect } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import Routes from 'constants/Routes'
import { produce } from 'immer'
import { detect } from 'detect-browser'
import { useQuery, useSubscription } from '@apollo/client'
import { Toaster } from 'react-hot-toast'
import { checkMeta, debounce, doArrow, getMeta, isChild } from 'utils/functions'
import { track } from 'utils/track'
import { slog } from 'utils/dateFunctions'
import { getUserAndActiveCourseQuery, getCurrentSubscriptionQuery, GetUserAndActiveCourseResponse, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'
import { User } from 'graphql/schemas/user/User'
import { Course, isCourseExpired } from 'graphql/schemas/course/Course'
import { client } from 'app'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { updateMeta, changeCurrentCourse, updateMetaDeep } from 'actions/users_actions'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { listenUserChangesSubscription, UserChangesRes } from 'graphql/subscriptions/user/listenUserChanges'
import { AppState } from 'graphql/schemas/app/State'
import { tick } from 'actions/courses_actions'

import { showModal } from './utils/CustomModal'
import { getCloudfrontSignatures } from 'actions/files'
import { subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { ListenHomescreenDataChangesRes, ListenHomescreenDataChangesVars, listenHomescreenDataChangesListener, HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'
import { getCurrentLanguage } from 'utils/lokalisation'
import { getHomescreenData } from 'graphql/queries/home/getHomescreenData'
import { listenUserSubscriptionsChangesSubscription, UserSubscriptionsChanges } from 'graphql/subscriptions/course/listenUserSubscriptionsChanges'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'
import SessionTimeout from './utils/SessionTimeout'
import ChecklistTour from './explore/ChecklistTour'
import { Button } from './utils/Button'
import { TourProvider } from '@reactour/tour'
import { WhiteLabelProvider } from 'context/whiteLabel'

type LayoutProps = {
  app: AppState;
  subscription: UserSubscription | undefined;
  course: Course | undefined;
  user: User | undefined;
  progress: UserProgress | undefined | null;
}

type LayoutState = {
  render?: boolean;
  visibleCourseList?: boolean;
  visibleChapterList?: boolean;
  supportedBrowser?: boolean;
  showJoyrides?: boolean;
  showLogo?: boolean;
  showNavButtons?: boolean;
  popoverHeight?: 'small' | 'medium' | 'big' | 'biggest';
  isChecklistTourOpen: boolean;
  showChecklist: boolean;
  tourStep: number;
}

window['subs'] = []

export const OpenChecklistTourContext = React.createContext({
  isChecklistTourOpen: false,
  setIsChecklistTourOpen: (isOpen: boolean) => {},
  setShowChecklist: (show: boolean) => {},
})

class Layout extends React.Component<LayoutProps, LayoutState> {
  private _isMounted = false
  private _firstLoad = true
  private _tickCalledAt = new Map<string, number>()

  constructor(props: LayoutProps) {
    super(props)
    this.state = { visibleChapterList: false, visibleCourseList: false, showJoyrides: false, render: false, showLogo: false, supportedBrowser: true, showNavButtons: false, popoverHeight: undefined, isChecklistTourOpen: false, showChecklist: true, tourStep: 0 }
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
    this.toggleHelp = this.toggleHelp.bind(this)
    this.updateMeta = this.updateMeta.bind(this)
    this.detectBrowser = this.detectBrowser.bind(this)
    this.showNavButtons = this.showNavButtons.bind(this)
    this.popoverHeight = this.popoverHeight.bind(this)
    this.isChecklistTourOpenFunction = this.isChecklistTourOpenFunction.bind(this)
    this.showChecklistFunction = this.showChecklistFunction.bind(this)
    this.setTourStep = this.setTourStep.bind(this)
  }

  componentDidMount() {
    this.detectBrowser()
    this._isMounted = true
    document.addEventListener('click', this.handlePageClick)
    window.addEventListener('resize', debounce(this.handleWindowResize, 250))

    setTimeout(() => {
      this._isMounted && this.setState({ render: true })
    }, 10000)

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.setAttribute('lang', getCurrentLanguage() || 'en')
    if (!checkMeta(this.props.user?.metadata, '/general/tour/gnowbeChecklistTour')) {
      updateMetaDeep('/tour/gnowbeChecklistTour', 'false', true, 'tour')
    }
  }

  async componentDidUpdate() {
    if (!this._firstLoad) return
    if (!this.props.subscription) return

    const { subscription, course } = this.props
    const distributionParams = subscription?.company?.subsDistributionParams.find(c => c.courseId === course?.id) || subsDistributionParamsFactory()
    const isExpired = isCourseExpired({
      absoluteDeadline: distributionParams.absoluteDeadline,
      startedAt: subscription?.progress?.startedAt || 0,
      relativeDeadline: distributionParams.relativeDeadline,
    })

    if (isExpired) {
      return changeCurrentCourse()
    }

    await getCloudfrontSignatures(subscription)

    if (subscription) {
      const subId = `${subscription.companyId}-${subscription.courseId}`
      const lastCalledAt = this._tickCalledAt.get(subId)
      if (!lastCalledAt || lastCalledAt < (Date.now() - 30 * 60 * 1000)) {
        tick({ companyId: subscription.companyId, courseId: subscription.courseId })

        track({
          event: 'Gnowbe Opened',
          variables: {
            company_id: subscription.companyId,
            course_id: subscription.courseId,
          },
        })

        this._tickCalledAt.set(subId, Date.now())
      }
    }

    this._firstLoad = false
  }

  // tslint:disable-next-line: function-name
  UNSAFE_componentWillReceiveProps(newProps: LayoutProps) {
    if (!newProps || !newProps.app) return

    if (!newProps.app.appState.isEditing) {
      if (newProps.course?.denyContentCopy) {
        document.addEventListener('copy', this.preventDefault)
        document.addEventListener('cut', this.preventDefault)
      }
      else {
        document.removeEventListener('copy', this.preventDefault)
        document.removeEventListener('cut', this.preventDefault)
      }
    }
    else {
      document.removeEventListener('copy', this.preventDefault)
      document.removeEventListener('cut', this.preventDefault)
    }

    // Switch background faster if swithing from Gnowbefy to Gnowbe
    if (this.props.app.appState.isEditing && !newProps.app.appState.isEditing) {
      document.body.classList.add('fast')
      setTimeout(() => {
        document.body.classList.remove('fast')
      }, 2000)
    }

    setTimeout(() => {
      this._isMounted && this.setState({ showLogo: true })
    }, 3000)
  }

  componentWillUnmount() {
    this._isMounted = false

    document.removeEventListener('click', this.handlePageClick)
    document.removeEventListener('copy', this.preventDefault)
    document.removeEventListener('cut', this.preventDefault)
    window.removeEventListener('resize', debounce(this.handleWindowResize, 250))
  }

  preventDefault(e) {
    if (isChild(e.target, 'text-pretty')) return

    e.preventDefault()
  }

  detectBrowser() {
    const browser = detect()

    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      // case 'edge': LEGACY EDGE
      case 'edge-chromium':
      case 'edge-ios':
      case 'safari':
      case 'ios':
      case 'fxios': // firefox ios
      case 'crios': // chrome ios
        // supported
        break

      default:
        this.setState({ supportedBrowser: false })
    }
  }

  showNavButtons(isFirst: boolean) {
    this.setState({ showNavButtons: isFirst })
  }

  popoverHeight(height: 'small' | 'medium' | 'big' | 'biggest') {
    this.setState({ popoverHeight: height })
  }

  isChecklistTourOpenFunction(isOpen: boolean) {
    this.setState({ isChecklistTourOpen: isOpen })
  }

  showChecklistFunction(show: boolean) {
    this.setState({ showChecklist: show })
  }

  setTourStep(tourStep: number) {
    this.setState({ tourStep })
  }

  handlePageClick(event) {
    const modalFooterContainer = document.querySelector('.modal-footer')
    if (modalFooterContainer && modalFooterContainer.contains(event.target)) {
      return
    }
  }

  handleWindowResize() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  toggleHelp() {
    this.setState({ showJoyrides: !this.props.app.appState.isHelpEnabled })
  }

  updateMeta(j: string, s: string) {
    updateMeta(j, s)
  }

  isNewRoute = (route) => {
    if (route.includes('/home')) return true
    if (route.startsWith('/details') || route.startsWith('/learn')) return true

    return false
  }

  render() {
    const mainContentCn = classNames({
      'main-content pb-0': true,
      unsupported: !this.state.supportedBrowser,
      editing: this.props.app.appState.isEditing,
    })

    const popoverHeightCl = classNames({
      'min-h-[120px] min-w-[350px] z-[999999]': true,
      'h-48': this.state.popoverHeight === 'medium',
      'h-64': this.state.popoverHeight === 'big',
      'h-80': this.state.popoverHeight === 'biggest',
    })

    const white = '#FFFFFF'

    const styles = {
      popover: (base, state) => ({
        ...base,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        color: '#1B585E',
        '--reactour-accent': '#000',
        ...doArrow(state.position, state.verticalAlign, state.horizontalAlign, white),
      }),
      badge: base => ({
        ...base,
        position: 'absolute',
        left: '30px',
        bottom: '16px',
        top: 'auto',
        background: 'none',
        padding: 0,
        boxShadow: 'none',
        color: '#101010',
        fontFamily: 'NotoSans, NeueHaasUnicaPro, sans-serif',
        fontSize: '.85rem',
      }),
      controls: base => ({
        ...base,
        justifyContent: 'flex-end',
        position: 'absolute',
        right: '30px',
        bottom: '25px',
        top: 'auto',
        marginTop: '0',
        '> button': {
          margin: '0 5px',
        },
      }),
      dot: base => ({
        display: 'none',
      }),
      close: base => ({
        ...base,
        top: '15px !important',
        right: '15px',
        color: '#101010',
      }),
    }

    const showAreYouSureToExitModal = (setCurrentStep?, setIsOpen?, currentStep?) => {
      showModal({
        title: 'Are you sure?',
        component: <div>If you exit now, you will have to restart this tour when you return</div>,
        className: 'z-[999999]',
        primaryText: intl.get('ok'),
        primaryAction: () => {
          setCurrentStep(0)
          this.isChecklistTourOpenFunction(true)
        },
        secondaryAction: () => {
          setCurrentStep(currentStep)
          setIsOpen(true)
        },
      })
    }

    return (
      <WhiteLabelProvider>
        <OpenChecklistTourContext.Provider value={{
          isChecklistTourOpen: this.state.isChecklistTourOpen,
          setIsChecklistTourOpen: this.isChecklistTourOpenFunction,
          setShowChecklist: this.showChecklistFunction,
        }
          }>
          {!this.state.supportedBrowser && (
            <div className="save-indicator cursor-pointer w-48 bg-coral" onClick={() => {
              showModal({
                title: intl.get('error_dialog_title'),
                component: <div>{intl.getHTML('unsupported_browser')}</div>,
                primaryText: intl.get('ok'),
                secondaryButton: false,
              })
            }}>
              Unsupported Browser
            </div>
          )}

          <div id="main-container" className="main-container">
            <div id="main-content" className={mainContentCn}>
              <Routes />
              <TourProvider
                steps={[]}
                styles={styles}
                badgeContent={({ totalSteps, currentStep }) => <>Step {currentStep + 1} of {totalSteps}</>}
                maskClassName="opacity-65 z-[999998]"
                position="right"
                disableKeyboardNavigation={['esc']}
                disableFocusLock={true}
                disableDotsNavigation={true}
                disableInteraction={true}
                scrollSmooth={true}
                onClickClose={
                  ({ setIsOpen, setCurrentStep, currentStep }) => {
                    setIsOpen(false)
                    showAreYouSureToExitModal(setCurrentStep, setIsOpen, currentStep)
                  }
                }
                onClickMask={
                  ({ setIsOpen }) => {
                    setIsOpen(true)
                  }
                }
                className={popoverHeightCl}
                prevButton={({
                  currentStep,
                  setCurrentStep,
                }) => {
                  if (currentStep === 0) return null

                  return (
                    <Button
                      onClick={() => {
                        setCurrentStep(currentStep - 1)
                      }}
                      size="large"
                      text="Previous"
                      type="text"
                      disabled={this.state.showNavButtons}
                      className="hidden"
                    />
                  )
                }}
                nextButton={({
                  currentStep,
                  stepsLength,
                  setIsOpen,
                  setCurrentStep,
                }) => {
                  const last = currentStep === stepsLength - 1
                  return (
                    <Button
                      onClick={(e) => {
                        if (last) {
                          setIsOpen(false)
                          this.setTourStep(this.state.tourStep + 1)
                          this.isChecklistTourOpenFunction(true)
                          setCurrentStep(0)
                        } else {
                          setCurrentStep(s => (s === [].length - 1 ? 0 : s + 1))
                        }
                      }}
                      size="small"
                      text={last ? 'Got it!' : 'Next'}
                      type="sub"
                      disabled={this.state.showNavButtons}
                      className={`${this.state.showNavButtons && 'hidden'}`}
                    />
                  )
                }}>
                {this.state.showChecklist && <ChecklistTour
                  user={this.props.user}
                  hideNavButtons={this.showNavButtons}
                  setPopoverHeight={this.popoverHeight}
                  isChecklistTourOpen={this.isChecklistTourOpenFunction}
                  showChecklist={this.showChecklistFunction}
                  tourStep={this.state.tourStep}
                  setTourStep={this.setTourStep}
                />}
              </TourProvider>
            </div>

            {process.env.BUILD === 'FINEXIS' && (
              <a href="https://schoolofimpact.com/impactu/help/" target="_blank" rel="noopener noreferrer" className="cursor-pointer fixed bottom-0 right-0 mb-4 mr-6 bg-lake text-gray-700 font-bold px-6 py-3 rounded-full text-lg">
                Help
              </a>
            )}
          </div>

          <Toaster />

          <SessionTimeout />
        </OpenChecklistTourContext.Provider>

      </WhiteLabelProvider>


    )
  }
}

let loaded = false
let timer

const LayoutWrapper = () => {
  const { data: appData, loading: appLoading } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: subData, subscribeToMore: subscribeToCurrentSubscriptionChanges } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  useSubscription<ListenHomescreenDataChangesRes, ListenHomescreenDataChangesVars>(
    listenHomescreenDataChangesListener, {
      skip: !appData,
      fetchPolicy: 'network-only',
      shouldResubscribe: true,
      variables: {
        userId: appData!.appState.loggedInAs.uid,
        returnInitial: true,
        oldQueryStateId: '',
        includeExplore: true,
        organizationId: activeWorkspaceId,
      },
      onData: ({ client, data: { data: data2 } }) => {
        if (!data2) {
          return
        }

        if (data2?.listenHomescreenDataChanges.__typename === 'Homescreen') {
          client.writeQuery({
            query: getHomescreenData,
            data: {
              homescreenData: data2?.listenHomescreenDataChanges,
            },
          })
        }

        if (data2?.listenHomescreenDataChanges.__typename === 'HomescreenChanges') {
          const home = client.readQuery<HomescreenData>({
            query: getHomescreenData,
          })

          const newData = produce(home, (draft) => {
            if (!draft) return

            if (data2.listenHomescreenDataChanges.updatedCompanyCategories.length > 0) {
              data2.listenHomescreenDataChanges.updatedCompanyCategories.forEach((cmp) => {
                const updatedCompanyCategoryIndex = draft.homescreenData.companyCategories.findIndex(c => c.id === cmp.id)
                if (updatedCompanyCategoryIndex !== -1) {
                  draft.homescreenData.companyCategories[updatedCompanyCategoryIndex].companies = cmp.companies
                }
              })
            }

            if (data2.listenHomescreenDataChanges.updatedCourseCategories.length > 0) {
              data2.listenHomescreenDataChanges.updatedCourseCategories.forEach((upd) => {
                const updatedCourseCategoryIndex = draft.homescreenData.courseCategories.findIndex(c => c.id === upd.id)
                if (updatedCourseCategoryIndex !== -1) {
                  draft.homescreenData.courseCategories[updatedCourseCategoryIndex].subscriptions = upd.subscriptions
                }
              })
            }
          })

          client.writeQuery({
            query: getHomescreenData,
            data: newData,
          })
        }
      },
    },
  )

  useSubscription<UserSubscriptionsChanges>(
    listenUserSubscriptionsChangesSubscription, {
    skip: !appData,
    variables: { archived: false, userId: appData?.appState.loggedInAs.uid },
    onData: async ({ data: { data: newSubscriptionsData } }) => {
      const { data: subs } = await client.query<GetUserSubscriptions2Res, GetUserSubscriptionsVars>({
        query: getUserSubscriptions2Query,
        variables: { userId: appData?.appState.loggedInAs.uid || '', archived: false },
      })

      const addedSubs = newSubscriptionsData?.listenUserSubscriptionsChanges.addedSubs || []
      const removedSubs = newSubscriptionsData?.listenUserSubscriptionsChanges.removedSubs || []
      const updatedSubs = newSubscriptionsData?.listenUserSubscriptionsChanges.updatedSubs || []

      if (removedSubs.length > 0) {
        return client.writeQuery({
          query: getUserSubscriptions2Query,
          variables: { userId: appData?.appState.loggedInAs.uid || '', archived: false },
          data: produce(subs, (draft) => {
            draft.response.subscriptions = draft.response.subscriptions.filter(s => !removedSubs.some(c => c.id === s.id))
          }),
        })
      }

      if (addedSubs.length > 0) {
        if (appData?.appState.isCreatingCourse || appData?.appState.switchToAddedCourse) {
          slog.i('Received a course we have to switch to...')
          changeCurrentCourse({ courseKey: { companyId: addedSubs[0].companyId || '', courseId: addedSubs[0].courseId || '' } })
        }

        if (subs.response.subscriptions.some(s => s.id === addedSubs[0].id)) return

        const newSubs = produce(subs, (draft) => {
          draft.response.subscriptions = draft.response.subscriptions.concat(addedSubs)
        })

        return client.writeQuery({
          query: getUserSubscriptions2Query,
          variables: { userId: appData?.appState.loggedInAs.uid || '', archived: false },
          data: newSubs,
        })
      }

      // bug when readding a program in the dashboard
      // we get updatedSubs instead of addedSubs
      if (updatedSubs.length > 0) {
        const newSubs = subs.response.subscriptions.map((sub) => {
          const index = updatedSubs.findIndex(s => s.id === sub.id)
          if (index === -1) return sub
          return produce(sub, (draft) => {
            if (updatedSubs[index].progress) {
              draft.progress = updatedSubs[index].progress
            }
          })
        })

        const newCourses = subs.response.courses.map((course) => {
          const index = updatedSubs.findIndex(s => s.courseId === course.id)
          if (index === -1) return course
          return produce(course, (draft) => {
            const course = newSubscriptionsData?.listenUserSubscriptionsChanges.courses.find(c => c.id === updatedSubs[index].courseId)
            if (course) {
              // tslint:disable-next-line: no-parameter-reassignment
              draft = course
            }
          })
        })

        return client.writeQuery({
          query: getUserSubscriptions2Query,
          variables: { userId: appData?.appState.loggedInAs.uid || '', archived: false },
          data: produce(subs, (draft) => {
            draft.response.subscriptions = newSubs
            draft.response.courses = newCourses
          }),
        })
      }
    },
  },
  )

  const { data: userData, refetch } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !companyId || !courseId || !subData || subData.user.subscriptions.length <= 0,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  useEffect(() => {
    const userSub = subscribeToCurrentSubscriptionChanges<UserChangesRes>({
      document: listenUserChangesSubscription,
      variables: { userId: subData?.user.id || '' },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev

        // const changes = subscriptionData.data.listenUserChanges
        // if (changes.user?.profile && subData?.user?.profile.currentSubscription !== changes.user?.profile.currentSubscription && !appData?.appState.isEditing) {
        //   const [companyId, courseId] = changes.user.profile.currentSubscription.split('-')
        //   remoteChangeCurrentCourse({ companyId, courseId })
        // }

        return prev
        // updating this here updates the cache too quickly and remoteChange thinks nothing changed
        // return produce(prev, (draft) => {
        //   draft.user.profile.currentSubscription = changes.user.profile.currentSubscription
        // })
      },
    })

    return () => {
      userSub()
    }
  }, [])

  const currentCompanyIdCourseId = appData?.appState.currentCompanyIdCourseId || subData?.user.profile.currentSubscription || ''
  const subscriptions = subData?.user?.subscriptions || []
  if (!subscriptions.some(s => s === currentCompanyIdCourseId)) {
    slog.w(`I don\'t have ${currentCompanyIdCourseId} as a subscription...`)
  }

  timer = clearTimeout(timer)
  timer = setTimeout(() => {
    if (!loaded) {
      userData && refetch()
    }
  }, 10000)

  if (!appData || appLoading || !subData) {
    return <FullscreenLoader />
  }

  loaded = true
  timer = clearTimeout(timer)

  const app: AppState = appData
  const user: User = subData.user

  return (
    <Layout
      app={app}
      user={user}
      course={userData?.course}
      subscription={userData?.subscription}
      progress={userData?.subscription.progress}
    />
  )
}

export default LayoutWrapper
