import { gql } from '@apollo/client'
import { studyReviewUpdatedFragment, studyReviewTimestampsUpdatedFragment } from 'graphql/fragments/Reviews'

export type StudyForSubscriptionChangesRes = {
  listenStudyForSubscriptionChanges: any;
}

export const listenStudyForSubscriptionChangesSubscription = gql`
  ${studyReviewUpdatedFragment}
  ${studyReviewTimestampsUpdatedFragment}

  subscription StudyForSubscriptionChanges ($companyId: String!, $courseId: String!, $userId: String!) {
    listenStudyForSubscriptionChanges (companyId: $companyId, courseId: $courseId, userId: $userId, version: 2) {

      ... on StudyViewedUpdated {
        id
        companyId
        courseId
        chapterId
        actionId
        viewed
      }

      ... on StudyAnswerUpdated {
        id
        companyId
        courseId
        chapterId
        actionId
        answer
        answeredAt
        # isCorrect
        thumbnail
        comment
        isAssessmentCorrect
        tries
        retriesBlocked
        secondsUsed
      }

      ... on StudyCompletionUpdated {
        id
        companyId
        courseId
        chapterId
        actionId
        completedAt
      }

      ... on StudyRemoved {
        id
        companyId
        courseId
        chapterId
        actionId
      }

      ... on StudyTimestampsUpdated {
        id
        companyId
        courseId
        chapterId
        actionId
        timestampsUpdated: timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
      }

      ... on StudyShared {
        id
        userId
        companyId
        courseId
        chapterId
        actionId
        sharedAt
      }

      ... on StudyUnshared {
        id
        userId
        companyId
        courseId
        chapterId
        actionId
        unsharedAt
      }

      ... on StudyShareLiked {
        id
        companyId
        courseId
        chapterId
        actionId
        userIdLiked
        likedAt
      }

      ... on StudyShareUnliked {
        id
        companyId
        courseId
        chapterId
        actionId
        userIdLiked
      }

      ... on StudyShareCommented {
        id
        companyId
        courseId
        chapterId
        actionId
        messageId
        message
        messageAt
        userIdCommented
      }

      ... on StudyShareCommentRemoved {
        id
        companyId
        courseId
        chapterId
        actionId
        messageId
        newLastMessage
        userIdCommented
      }

      ...StudyReviewUpdatedFragment
      ...StudyReviewTimestampsUpdatedFragment
    }
  }
`
