import React, { memo } from 'react'
import { useQuery, ApolloProvider  } from '@apollo/client'
import { getUserBasicQuery } from 'graphql/queries/user/getUser'
import ErrorBoundary from './ErrorBoundary'
import { getSignedValue } from 'actions/files'
import { client } from 'app'
import { UserBasic } from 'graphql/schemas/user/User'
import { MiniLoader } from './MiniLoader'

type ProfilePictureProps = {
  userId: string;
  inUser?: UserBasic;
  additionalStyle?: any;
  additionalClass?: any;
  onClick?: any;
}

const ProfilePicture = memo(({ userId, inUser, additionalStyle, additionalClass, onClick }: ProfilePictureProps) => {
  const { data, loading, error } = useQuery(getUserBasicQuery, { variables: { userId }, skip: !!inUser })

  if (!inUser && (!data || error)) {
    return (
      <div className={`${additionalClass} profile-img`} onClick={onClick ? onClick : () => null} />
    )
  }

  if (loading) {
    return <MiniLoader />
  }

  const user: UserBasic = inUser || data.user
  const signedImageUrl = user?.profile?.imageUrl && getSignedValue(user.profile.imageUrl) || ''

  return (
    <ErrorBoundary errorElement={<div>Photo</div>}>
      <div
        className={`${additionalClass || ''} profile-img flex items-center justify-center font-bold text-sm`}
        style={{ ...(additionalStyle || {}), backgroundImage: `url(${(signedImageUrl)})` }}
        onClick={onClick ? onClick : () => null}>
          {!signedImageUrl && `${(user?.profile?.firstName || 'U').substring(0, 1).toUpperCase()}${(user?.profile?.lastName || 'U').substring(0, 1).toUpperCase()}`}
      </div>
    </ErrorBoundary>
  )
})

const ProfilePictureWrapper = (props) => {
  return (
    <ApolloProvider client={client}>
      <ProfilePicture
        {...props}
      />
    </ApolloProvider>
  )
}

export default ProfilePictureWrapper
