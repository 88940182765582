import { gql } from '@apollo/client'
import { companyFragment } from './Company'
import { userSubscriptionFragment } from './UserSubscription'

export const homescreenCourseCategoryFragment = gql`
  ${userSubscriptionFragment}

  fragment HomescreenCourseCategoryFragment on HomescreenCourseCategory {
    id
    order
    title
    subscriptions {
      ...UserSubscriptionFragment
    }
  }
`

export const homescreenFragment = gql`
  ${homescreenCourseCategoryFragment}
  ${companyFragment}

  fragment HomescreenFragment on Homescreen {
    courseCategories {
      ...HomescreenCourseCategoryFragment
    }
    companyCategories {
      id
      order
      title
      companies {
        ...CompanyFragment
      }
    }
    queryStateId
  }
`

export const homescreenChangesFragment = gql`
  ${homescreenCourseCategoryFragment}

  fragment HomescreenChangesFragment on HomescreenChanges {
    updatedCourseCategories {
      ...HomescreenCourseCategoryFragment
    }
    updatedCompanyCategories {
      id
      order
      title
      companies {
        ...CompanyFragment
      }
    }
    queryStateId
  }
`
