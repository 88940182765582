import { useQuery } from '@apollo/client'
import { useAppState } from 'graphql/hooks/useAppState'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { getMeta } from 'utils/functions'

export const useActiveWorkspaceId = () => {
  const appState = useAppState()
  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState?.loggedInAs.uid || '' },
  })

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')
  return activeWorkspaceId
}
