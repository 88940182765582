import { gql } from '@apollo/client'

export const timestampsFragment = gql`
  fragment TimestampsFragment on Timestamps {
    createdAt
    createdAtTree
    updatedAt
    updatedAtTree
  }
`

export const timestampsUpdateFragment = gql`
  fragment TimestampsUpdateFragment on TimestampsUpdate {
    createdAt,
    createdAtTree,
    updatedAt,
    updatedAtTree
  }
`
