import intl from 'react-intl-universal'
import { curry } from 'ramda'

import { User } from 'graphql/schemas/user/User'
import { Action } from 'graphql/schemas/action/Action'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { stripHtml } from 'utils/functions'
import { getSignedValue } from 'actions/files'
import { generateMongoId } from './random'
// import { openDB } from 'idb/with-async-ittr'
import { openDB } from 'idb'


export const augmentExplain = (text: string|undefined) => {
  if (!text) return ''

  let result = text
  const matches = (text.match(/{{explain:.*?}}/gi) || [])
  matches.forEach((match) => {
    const tuple = match.replace(/[{}]/gi, '').split(':')
    const [source, text, popupText, imageUrl] = tuple
    // if you hover over an explanation fast enough,
    // an instance will stay in the dom because the id changes (react rerenders 3 times)!
    const randomId = generateMongoId(6)
    let popupTextEscaped = stripHtml(decodeURIComponent(popupText))
    if (popupTextEscaped) {
      popupTextEscaped = popupTextEscaped.replace(/"/g, '&quot;')
    }
    result = result.replace(match, `<abbr class="explainer" data-id="${randomId}" data-content="${popupTextEscaped}"${imageUrl && ` data-image="${getSignedValue(decodeURIComponent(imageUrl))}"` || ''}>${decodeURIComponent(text)}</abbr>`)
  })
  return result
}

export const augmentName = curry((text: string|undefined, user: User|Pick<User, 'id'|'profile'>|any) => {
  if (!text) return ''

  return text
    .replace(/{{firstName}}|{{name}}/gi, user?.profile.firstName || '')
    .replace(/{{lastName}}/gi, user?.profile.lastName || '')
    .replace(/{{fullName}}/gi, user?.profile.fullName || '')
})

export const augmentNameBasic = curry((text: string|undefined, profile: { firstName: string|null, lastName: string|null, fullName: string|null }|undefined) => {
  if (!text) return ''

  return text
    .replace(/{{firstName}}|{{name}}/gi, profile?.firstName || '')
    .replace(/{{lastName}}/gi, profile?.lastName || '')
    .replace(/{{fullName}}/gi, profile?.fullName || '')
})

export const augmentEmbeddedUser = (text: string) => {
  if (!text) return ''

  return text.replace(/{{user:([^\:\}]+):([^\:\}]+?)}}/gi, (match, p1, p2) => {
    return decodeURIComponent(p2)
  })
}

export const augmentGreeting = (text: string) => {
  if (!text) return ''

  const hour = (new Date()).getHours()
  let greeting
  if (hour >= 5 && hour < 12) {
    greeting = [intl.get('good_morning')]
  } else if (hour >= 12 && hour < 16) {
    greeting = [intl.get('good_afternoon')]
  } else if (hour >= 16 && hour < 23) {
    greeting = [intl.get('good_evening')]
  } else {
    greeting = [intl.get('hello')]
  }

  const greet = greeting[Math.floor(Math.random() * greeting.length)]

  return text.replace(/{{greeting}}/gi, greet)
}

export const augmentTitle = curry((text: string|undefined, actions: Action[]) => {
  if (!text) return ''

  return text.replace(/{{action:title:([^\:\}]+):([^\:\}]+?)}}/gi, (substr) => {
    const [src, prop, chapterId, actionId] = substr.replace(/[{}]/gi, '').split(':')
    if (src === 'action' && prop === 'title') {
      const action = actions.find(a => a.chapterId === chapterId && a.id === actionId)

      if (action?.title) {
        return action.title
      }

      return '[action not available]'
    }

    return substr
  })
})

export const augmentStudy = curry((text: string|undefined, studies: UserStudy[], chapterOrder: number) => {
  if (!text) return ''

  return text.replace(/{{user:(qa|answer):([^\:\}]+):([^\:\}]+?)}}/gi, (substr) => {
    const [src, prop, chapterId, actionId] = substr.replace(/[{}]/gi, '').split(':')
    const actionUrl = `/learn/${chapterId}/${actionId}`
    if (src === 'user' && (prop === 'answer' || prop === 'qa')) {
      const study = studies.find(s => s.chapterId === chapterId && s.actionId === actionId)

      if (study?.answer) {
        return `<a href="#" data-reference=${actionUrl}>${study.answer}</a>`
      }

      return chapterOrder
        ? `<a href="#" data-reference=${actionUrl}>${intl.get('reference_answer_not_complete', { 0: chapterOrder })}</a>`
        : '[this answer was copied from an unknown session]'
    }

    return substr
  })
})

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`)
}

export const removeFileFromIndexedDB = async (key) => {
  const db = await openDB('gnowbe-idb-2', 1)
  const tx = db.transaction('files', 'readwrite')
  const store = tx.objectStore('files')

  try {
    await store.delete(key)
  } catch (error) {
    throw error
  }
}

export const saveFileToIndexedDB = async (file, key) => {
  const db = await openDB('gnowbe-idb-2', 1, {
    upgrade(db) {
      const store = db.createObjectStore('files', {
        keyPath: 'name',
        autoIncrement: true,
      })
      store.createIndex('name', 'name')
    },
  })

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async () => {
      const fileData = reader.result
      const tx = db.transaction(['files'], 'readwrite')
      const store = tx.objectStore('files')

      try {
        const addedFile = await store.put({
          name: key,
          data: fileData,
        })
        resolve(addedFile)
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export const retrieveFileFromIndexedDB = async (key) => {
  const db = await openDB('gnowbe-idb-2', 1)
  const tx = db.transaction('files', 'readonly')
  const store = tx.objectStore('files')

  try {
    const fileData = await store.get(key)

    if (fileData) {
      const { name, data } = fileData

      const file = new File([data], name, { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })

      return file
    } else {
      return null
    }
  } catch (error) {
    throw error;
  }
}