import { gql } from '@apollo/client'

type Feature = 'feature-locked-send-push-email-notification'|
'feature-locked-add-digital-certificates'|
'feature-locked-assessment-basic'|
'feature-locked-gnowbe-shop'|
'feature-locked-customization'|
'notification-personalized-email-communication'|
'feature-locked-integrations'|
'feature-locked-assessment-pro'|
'feature-locked-customize-certificates'|
'feature-locked-custom-user-attributes'|
'feature-locked-scorm'|
'feature-locked-sso'|
'feature-locked-automatic-language-translations';

export type SendUserLockedFeatureEmailVars = {
  feature: Feature;
  organizationId: string;
}

export const sendUserLockedFeatureEmailMutation = gql`
  mutation SendUserLockedFeatureEmail($feature: String!, $organizationId: String!) {
    sendUserLockedFeatureEmail(feature: $feature, organizationId: $organizationId)
  }
`
