import { BASE_API_URL, GNOWBE_SOURCE } from '../constants/General'
import { checkHttpStatus, getAuthTokens } from './utils'
import { refetch } from 'utils/functions'

export function chapterViewed(companyIdCourseId: string, chapterId: string) {
  const token = getAuthTokens()
  if (companyIdCourseId && chapterId) {
    refetch(`${BASE_API_URL}/api/v1/progress/${companyIdCourseId}/chapter/${chapterId}/viewed`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
    })
    .then(response => checkHttpStatus(response, true))
    .catch((err) => {
      console.error(err)
    })
  }
}
