import React from 'react'
import ErrorBoundary from 'components/utils/ErrorBoundary'
import { getSignedValue } from 'actions/files'

export class ReplyToBox extends React.PureComponent<{}> {
  clearReply = () => {
    sessionStorage.removeItem('replyTo')
    this.forceUpdate()
  }

  render() {
    const replyTo = sessionStorage.getItem('replyTo')

    if (!replyTo) {
      return null
    }

    const [, postKey, titleEncoded, textEncoded, imageUrlEncoded] = replyTo && replyTo.replace(/\{\{(.*?)\}\}/, '$1').split(':') || [, , , , ,];
    let replyToDisplay

    if (replyTo) {
      replyToDisplay = <span>{decodeURIComponent(textEncoded || '')}</span>
      if (textEncoded === '' && imageUrlEncoded) {
        replyToDisplay = <img src={getSignedValue(decodeURIComponent(imageUrlEncoded))} className="w-24" />
      }
    }

    return (
      <ErrorBoundary errorElement={<div>Error</div>}>
        <div className="reply-to-box">
          <div>
            <img src="/images/icon-reply.png" />
          </div>

          <div className="ml-4 flex-1">
            <div className="mb-2 uppercase font-bold text-sm">
              Replying to
            </div>

            <div>
              {replyToDisplay}
            </div>
          </div>

          <div>
            <i tabIndex={-1} className="icon icon-x cursor-pointer" onClick={this.clearReply} />
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}
