import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'

export const updateActionMutation = gql`
  ${actionFragment}

  mutation UpdateAction ($courseId: String!, $chapterId: String!, $actionId: String!, $actionUpdate: ActionUpdate!) {
    updateAction (courseId: $courseId, chapterId: $chapterId, actionId: $actionId, actionUpdate: $actionUpdate) {
      ...ActionFragment
    }
  }
`

export const updateActionOrderMutation = gql`
  mutation UpdateAction ($courseId: String!, $chapterId: String!, $actionId: String!, $actionUpdate: ActionUpdate!) {
    updateAction (courseId: $courseId, chapterId: $chapterId, actionId: $actionId, actionUpdate: $actionUpdate) {
      id
      order
    }
  }
`
