import { gql } from '@apollo/client'
import { billingServiceFragment } from 'graphql/schemas/organization/Organization'

export const subsDistributionParamsFragment = gql`
  fragment SubsDistributionParamsFragment on CourseDistributionParams {
    id
    companyId
    courseId
    manualReviewsSkipEndorsment
    showHintsAfterAssessmentsCompleted
    hideAssessmentsAfterCompleted
    absoluteDeadline
    relativeDeadline
    dueDate
  }
`

export const companyFragment = gql`
  ${subsDistributionParamsFragment}
  ${billingServiceFragment}

  fragment CompanyFragment on Company {
    id
    name
    groupName
    accessCodes
    organizationId
    organizationName
    companyType
    certificateSigner
    usersCount
    invitedUsersCount
    membersListEnabled
    greetingsDisabled
    wallDisabled
    peerInvitationMode
    disabled
    subsDistributionParams {
      ...SubsDistributionParamsFragment
    }
    organization {
      id
      product
      featureFlags
      allowUserAnonymity
      billingService {
        ...BillingServiceFragment
      }
    }
  }
`

export const companyBasicFragment = gql`
  fragment CompanyBasicFragment on CompanyBasic {
    id
    name
    companyType
  }
`
