import { gql } from '@apollo/client'
import { chapterFragment } from 'graphql/fragments/Chapter'

type ChapterCreate = {
  order: number;
  title: string;

  description?: string;
  imageUrl?: string;
  imageUrlAlt?: string;
  videoUrl?: string;
  videoUrl_720p?: string;
  videoUrlThumbnail?: string;
  videoUrlAlt?: string;
  isSequential?: boolean;
  tagline?: string;
  disabledUntilDay?: number;
  morningPush?: string;
  morningEmail?: string;
}

export const createChapterMutation = gql`
  ${chapterFragment}

  mutation CreateChapter ($courseId: String!, $chapterCreate: ChapterCreate!) {
    createChapter (courseId: $courseId, chapterCreate: $chapterCreate) {
      ...ChapterFragment
    }
  }
`
