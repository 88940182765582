import React, { useRef, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { BASE_URL } from 'constants/General'
import { Button } from './Button'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export default function () {
  const [hash, setHash] = useState('')
  const [open, setOpen] = useState(false)
  const prevHash = usePrevious(hash)

  useEffect(() => {
    if (prevHash && prevHash !== hash) {
      setOpen(true)
    }
    const interval = setInterval(() => {
      fetch(`${BASE_URL}/version.txt`, { cache: 'no-store' }).then(async (v) => {
        if (!v.ok) {
          console.log('error version checking', v.status)
          return
        }
        const test = await v.text()
        if (test !== hash) {
          setHash(test)
        }
        return
      }).catch((error) => {
        console.error('error version checking', error)
      })
    }, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [hash, open])

  if (!open) return null

  return (
    <div className="bg-blue-50 p-4">
      <div className="flex w-1/2 mx-auto">
        <div className="shrink-0">
          <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700 pb-0">
            <strong>{intl.get('update_ready_1')}</strong><br/>{intl.get('update_ready_2')}
          </p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6 pb-0">
            <Button type="primary" text="Reload" onClick={() => location.reload()} />
          </p>
        </div>
      </div>
    </div>
  )
}
