import React from 'react'
import { Button } from 'components/utils/Button'
import { updateMeta } from 'actions/users_actions'
import intl from 'react-intl-universal'

const AnnouncingWorkspaces = ({ close }) => {
  return (
    <div className="pt-4 pb-2">
      <img className="mt-4 mx-auto rounded-md h-64" src="/images/gnowbe-create-workspace.png" />
      <div className="my-4 font-noto-sans text-2xl text-center font-bold">{intl.get('announcing_workspaces')}</div>
      <div>{intl.getHTML('announcing_workspaces_text_link')}</div>
      <div className="space-y-4 mt-4 text-center">
        <div>
          <Button type="primary" text="Check it out!" onClick={() => {
            close()
            updateMeta('workspaceExplained', 'true', true)
          }} />
        </div>
      </div>
    </div>
  )
}

export default AnnouncingWorkspaces