import React from 'react'
import { IconProps } from './CheckGnowbeIcon'

const MagicCreditsIcon = ({className}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 26" height="70" width="50" className={className}>
      <g clipPath="url(#clip0_214_37)">
        <path fill="#915FEE" d="M8 5C3.58 5 0 8.58 0 13C0 17.42 3.58 21 8 21C12.42 21 16 17.42 16 13C16 8.58 12.42 5 8 5ZM11 11.5H9V16.5H7V11.5H5V10H8H11V11.5ZM19.25 4.75L22 6L19.25 7.25L18 10L16.75 7.25L14 6L16.75 4.75L18 2L19.25 4.75ZM19.25 18.75L22 20L19.25 21.25L18 24L16.75 21.25L14 20L16.75 18.75L18 16L19.25 18.75Z"/>
      </g>
      <path fill="#915FEE" d="M9 21C13.4183 21 17 17.4183 17 13C17 8.58172 13.4183 5 9 5C4.58172 5 1 8.58172 1 13C1 17.4183 4.58172 21 9 21ZM9.09293 10.49C9.27293 10.39 9.47626 10.34 9.70293 10.34C10.0029 10.34 10.2863 10.3833 10.5529 10.47C10.8196 10.55 11.0829 10.6533 11.3429 10.78L11.9329 9.26C11.5663 9.08667 11.1929 8.96 10.8129 8.88C10.4329 8.8 10.0696 8.76 9.72293 8.76C9.16293 8.76 8.66626 8.85 8.23293 9.03C7.80626 9.21 7.44626 9.46667 7.15293 9.8C6.8596 10.1267 6.63626 10.5167 6.48293 10.97C6.33626 11.4167 6.26293 11.91 6.26293 12.45C6.26293 13.17 6.3796 13.8067 6.61293 14.36C6.84626 14.9067 7.2096 15.3333 7.70293 15.64C8.19626 15.9467 8.8296 16.1 9.60293 16.1C9.97626 16.1 10.3263 16.0667 10.6529 16C10.9863 15.94 11.3096 15.84 11.6229 15.7V14.07C11.3229 14.1967 11.0229 14.3033 10.7229 14.39C10.4296 14.4767 10.1263 14.52 9.81293 14.52C9.4596 14.52 9.16626 14.4433 8.93293 14.29C8.6996 14.13 8.52626 13.8967 8.41293 13.59C8.2996 13.2833 8.24293 12.9067 8.24293 12.46C8.24293 12.1267 8.27626 11.83 8.34293 11.57C8.4096 11.31 8.50626 11.09 8.63293 10.91C8.7596 10.7233 8.91293 10.5833 9.09293 10.49Z" clipRule="evenodd" fillRule="evenodd"/>
      <defs>
        <clipPath id="clip0_214_37">
          <rect transform="translate(14 1)" fill="white" height="24" width="9"/>
        </clipPath>
      </defs>
    </svg>
   )
}

export default MagicCreditsIcon