const logSubscriptions: any[] = []
const infoSubscriptions: any[] = []
const warnSubscriptions: any[] = []
const errorSubscriptions: any[] = []
const criticalSubscriptions: any[] = []
declare var console: any

export function log(message: string, ...restOfParams: any[]) {
  console.log.apply(console, arguments)
  logSubscriptions.forEach((c: any) => c(message, restOfParams))
}

export function info(message: string, ...params: any[]) {
  console.info.apply(console, arguments)
  infoSubscriptions.forEach((c: any) => c(message, params))
}

export function warn(message: string, ...params: any[]) {
  console.warn.apply(console, arguments)
  warnSubscriptions.forEach((c: any) => c(message, params))
}

export function error(message: string, ...params: any[]) {
  console.error.apply(console, arguments)
  errorSubscriptions.forEach((c: any) => c(message, params))
}

export function critical(message: string, ...params: any[]) {
  console.error.apply(console, arguments)
  criticalSubscriptions.forEach((c: any) => c(message, params))
}

export function subscribe(logLevel: string, callback: (message: string, ...params: any[]) => void) {
  switch (logLevel) {
    case 'log':
    case 'debug':
      logSubscriptions.push(callback)
      break
    case 'info':
      infoSubscriptions.push(callback)
      break
    case 'warn':
      warnSubscriptions.push(callback)
      break
    case 'error':
      errorSubscriptions.push(callback)
      break
    case 'critical':
      criticalSubscriptions.push(callback)
      break
  }
}
