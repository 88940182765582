import colors from 'constants/Colors'
import icons from 'constants/Icons'
import React from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import { sm } from 'utils/breakpoints'

const { RestartIcon, UndoIcon, SendIcon } = icons

const useBotCustomStyles = ({
  intent,
  conversation,
}: {
  intent: string | null
  conversation: unknown
}) => {
  const { width } = useWindowDimensions()
  const isSm = sm(width)

  const topBarExternalStyles = {
    ...topBarStyles,
    rightButtonStyle: {
      width: 40,
      height: 40,
      borderWidth: isSm ? 0 : 1,
      borderRadius: 8,
      borderColor: colors.neutral300,
    },
    leftButtonStyle: {
      width: 40,
      height: 40,
      borderWidth: isSm ? 0 : 1,
      borderRadius: 8,
      borderColor: colors.neutral300,
    },
    rightActiveButtonStyle: {
      borderColor: colors.red400,
      borderWidth: isSm ? 0 : 2,
    },
    containerStyle: {
      height: 71,
      paddingHorizontal: isSm ? 5 : 30,
    },
    toggleStyle: {
      toggleBackgroundActiveStyle: {
        backgroundColor: colors.mint500,
      },
    },
    additionalButtonsContainerStyles: {
      top: 62,
      right: isSm ? 20 : 38,
      width: isSm ? width - 40 : undefined,
    },
    buttons: {
      restart: {
        buttonContainerStyle: {
          backgroundColor: colors.neutral0,
          borderWidth: isSm ? 0 : 1,
          borderRadius: 8,
          height: 40,
          borderColor: colors.neutral300,
          paddingHorizontal: 10,
        },
        disabledButtonContainerStyle: {
          opacity: 0.5,
        },
        disabled: !intent,
        buttonTextStyle: {
          fontSize: 16,
          fontWeight: '500',
          color: colors.neutral900,
          display: isSm ? 'none' : 'block',
        },
        startIcon: (
          <RestartIcon width={24} height={24} color={colors.neutral900} />
        ),
      },
      undo: {
        startIcon: (
          <UndoIcon width={24} height={24} color={colors.neutral900} />
        ),
        disabledButtonContainerStyle: {
          opacity: 0.5,
        },
        disabled: !(conversation as { canUndo: boolean }).canUndo,
        buttonContainerStyle: {
          backgroundColor: colors.neutral0,
          borderWidth: isSm ? 0 : 1,
          borderRadius: 8,
          borderColor: colors.neutral300,
          height: 40,
          paddingHorizontal: 10,
        },
        buttonTextStyle: {
          fontSize: 16,
          fontWeight: '500',
          color: colors.neutral900,
          display: isSm ? 'none' : 'block',
        },
      },
    },
  }

  const intentSelectExternalStyles = {
    containerStyle: {
      paddingHorizontal: 7,
      marginTop: 20,
      height: 60,
      borderWidth: 1,
      borderRadius: 16,
      borderColor: colors.neutral300,
      maxWidth: 476,
      width: Math.min(476, width - 40),
      alignSelf: 'center',
      zIndex: 1,
      backgroundColor: colors.neutral0,
    },
    buttonTextStyle: {
      color: colors.red500,
      marginRight: 10,
      overflow: 'hidden',
      display: 'auto',
    },
    ...intentSelectStyles,
  }

  const conversationStepExternalStyles = {
    conversationContainerStyle: {
      paddingHorizontal: 20,
      maxWidth: '100%',
      width: Math.min(800, width - 40),
      marginHorizontal: 'auto',
    },
    formStyle: {
      display: 'flex' as 'flex',
      flexDirection: 'row' as 'row',
      justifyContent: 'space-between' as 'space-between',
      alignItems: 'flex-end' as 'flex-end',
      maxWidth: '100%',
      width: Math.min(800, width - 40),
      flexWrap: 'wrap' as 'wrap',
    },
    buttons: {
      send: {
        startIcon: (
          <SendIcon width={24} height={24} color={colors.neutral900} />
        ),
        buttonContainerStyle: {
          backgroundColor: colors.mint400,
          padding: 5,
          marginLeft: -45,
          marginBottom: 10,
        },
        buttonTextStyle: {
          display: 'none' as 'none',
          textAlign: 'center' as 'center',
        },
      },
      chip: {
        buttonContainerStyle: {
          backgroundColor: colors.neutral200,
          borderWidth: 1,
          borderColor: colors.mint700,
          borderRadius: 24,
          paddingHorizontal: 16,
          paddingVertical: 8,
        },
        buttonTextStyle: {
          fontSize: 16,
          lineHeight: 24,
          fontWeight: '600' as '600',
          color: colors.mint800,
          padding: 0,
        },
      },
      restart: {
        buttonContainerStyle: {
          display: (intent ? 'flex' : 'none') as 'flex' | 'none',
          backgroundColor: 'rgb(94 195 197)',
          borderColor: 'rgb(94 195 197)',
          borderRadius: 6,
          borderWidth: 1,
          color: 'rgb(16 16 16) !important',
          fontSize: 16,
          fontWeight: '700' as '700',
          lineHeight: 24,
          padding: '.75rem 1.5rem !important',
        },
      },
    },
    textStyle: {
      color: colors.neutral800,
      fontSize: 24,
      lineHeight: 36,
      fontWeight: '500',
      textAlign: 'left',
    },
    inputContainerStyle: {
      display: 'flex',
      width: 'auto',
      flex: 1,
      alignSelf: 'stretch',
      alignItems: 'stretch',
    },
    inputStyle: {
      minHeight: 54,
      borderRadius: 8,
      borderColor: colors.mint600,
      backgroundColor: colors.neutral100,
      paddingLeft: 16,
      paddingRight: 58,
    },
  }

  return {
    topBarExternalStyles,
    intentSelectExternalStyles,
    conversationStepExternalStyles,
  }
}

const topBarStyles = StyleSheet.create({
  containerStyle: {
    height: 71,
    paddingHorizontal: 30,
  },
  leftIconStyle: {
    width: 20,
    height: 20,
  },
  rightActiveIconStyle: { color: colors.red500, width: 20, height: 20 },
  rightIconStyle: { width: 20, height: 20 },
})

const intentSelectStyles = StyleSheet.create({
  buttonStyle: {
    flex: 1,
    borderColor: colors.red400,
    borderWidth: 2,
    borderRadius: 8,
    alignSelf: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  buttonStartIconStyle: {
    color: colors.red500,
    width: 20,
    height: 20,
  },
  menuStyle: {
    bottom: 65,
    top: 'auto',
  },
  menuItemIconActiveStyle: {
    color: colors.red500,
  },
  menuItemIconStyle: { width: 20, height: 20, color: 'black' },
  menuItemTextActiveStyle: {
    color: colors.red500,
  },
})

export default useBotCustomStyles
