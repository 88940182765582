import React from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import { likeShare, ShareKeyData } from 'actions/likes_actions'

type LikesCounterProps = {
  shareKey: ShareKeyData;
  defaultCount: number;
  defaultLikedByMe: boolean;
  skipCheck?: boolean;
  details?: boolean;
}

const LikesCounter = ({ shareKey, defaultCount, defaultLikedByMe, skipCheck, details }: LikesCounterProps) => {
  const [count, setCount] = React.useState(defaultCount)
  const [likedByMe, setLikedByMe] = React.useState(defaultLikedByMe)
  const _timeout: any = React.useRef()

  React.useEffect(() => {
    setCount(defaultCount)
    setLikedByMe(defaultLikedByMe)
  }, [defaultCount, defaultLikedByMe])

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()

    const newState = !likedByMe

    if (likedByMe) {
      setCount(count - 1)
    }
    else {
      setCount(count + 1)
    }

    setLikedByMe(newState)

    _timeout.current = clearTimeout(_timeout.current)
    _timeout.current = setTimeout(() => {
      if (newState !== defaultLikedByMe || skipCheck) {
        likeShare(shareKey, newState)
      }
    }, 250)
  }

  const cn = classNames({
    'icon-heart-light font-bold': !likedByMe,
    'icon-heart-solid': likedByMe,
    '-mt-0.5': true,
  })

  const acn = classNames({
    red: likedByMe,
    'flex items-center space-x-1.5 likes-counter': true,
  })

  return (
    <div className="inline-flex items-center">
      <button className={acn} onClick={handleClick}>
        <span className={cn} />
        <span className="counter text-sm">{count} {details ? intl.get('activity_likes').toLocaleLowerCase() : null}</span>
      </button>
    </div>
  )
}

export default LikesCounter
