import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'

export const duplicateActionMutation = gql`
  ${actionFragment}

  mutation DuplicateAction ($fromCourseId: String!, $fromChapterId: String!, $fromActionId: String!, $toCourseId: String!, $toChapterId: String!, $actionUpdate: ActionUpdate!, $duplicateRewards: Boolean!) {
    duplicateAction (fromCourseId: $fromCourseId, fromChapterId: $fromChapterId, fromActionId: $fromActionId, toCourseId: $toCourseId, toChapterId: $toChapterId, actionUpdate: $actionUpdate, duplicateRewards: $duplicateRewards) {
      ...ActionFragment
    }
  }
`
