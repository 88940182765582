import { HIDE_HELP, languageDefault, languagesSet } from 'constants/General'
import intl from 'react-intl-universal'
const enLang = require('common/locales/en.json')
require('intl/locale-data/jsonp/en.js')

declare const require
declare const navigator: any
declare const zE

let currentLanguage = localStorage.getItem('currentLanguage')

export function normalizeLanguage(languages: Set<string>, lang: string|null|undefined) {
  if (!lang) return null
  const lang2 = lang.toLowerCase().replace('-', '_')
  if (languages.has(lang2)) return lang2
  const lang3 = lang2.split('_')[0]
  if (languages.has(lang3)) return lang3
  return null
}

export function getBrowserLanguage(): string|null {
  return navigator.language || navigator.browserLanguage || (navigator.languages || ['en'])[0] || null
}

export function getCurrentLanguage() {
  return currentLanguage
}

export function setCurrentLanguage(lang: string) {
  currentLanguage = lang
  localStorage.setItem('currentLanguage', lang)
}

async function init(currentLocale: string) {
  // tslint:disable-next-line: no-parameter-reassignment
  window['__locale__'] = currentLocale

  const locales = {
    en: enLang,
    bg: '',
    da: '',
    de: '',
    ko: '',
    nl: '',
    zh: '',
    ja: '',
    es: '',
    fr: '',
    id: '',
    pt: '',
    sl: '',
    it: '',
    my: '',
    ru: '',
    no: '',
    sv: '',
    hu: '',
    pl: '',
    th: '',
    vi: '',
    ms: '',
    tw: '',
  }

  // https://github.com/davidhouweling/intl-js-bundles/blob/master/intl-optimal-solution/client/entry.jsx
  switch (currentLocale) {
    case 'bg':
      require.ensure([
        'common/locales/bg.json',
        'intl/locale-data/jsonp/bg.js',
      ], (require) => {
        locales.bg = require('common/locales/bg.json')
        require('intl/locale-data/jsonp/bg.js')
      }, 'lang-bg')
      break
    case 'de':
      require.ensure([
        'common/locales/de.json',
        'intl/locale-data/jsonp/de.js',
      ], (require) => {
        locales.de = require('common/locales/de.json')
        require('intl/locale-data/jsonp/de.js')
      }, 'lang-de')
      break
    case 'da':
      require.ensure([
        'common/locales/da.json',
        'intl/locale-data/jsonp/da.js',
      ], (require) => {
        locales.da = require('common/locales/da.json')
        require('intl/locale-data/jsonp/da.js')
      }, 'lang-da')
      break
    case 'ko':
      require.ensure([
        'common/locales/ko.json',
        'intl/locale-data/jsonp/ko.js',
      ], (require) => {
        locales.ko = require('common/locales/ko.json')
        require('intl/locale-data/jsonp/ko.js')
      }, 'lang-ko')
      break
    case 'nl':
      require.ensure([
        'common/locales/nl.json',
        'intl/locale-data/jsonp/nl.js',
      ], (require) => {
        locales.nl = require('common/locales/nl.json')
        require('intl/locale-data/jsonp/nl.js')
      }, 'lang-nl')
      break
    case 'zh':
      require.ensure([
        'common/locales/zh.json',
        'intl/locale-data/jsonp/zh.js',
      ], (require) => {
        locales.zh = require('common/locales/zh.json')
        require('intl/locale-data/jsonp/zh.js')
      }, 'lang-zh')
      break
    case 'ja':
      require.ensure([
        'common/locales/ja.json',
        'intl/locale-data/jsonp/ja.js',
      ], (require) => {
        locales.ja = require('common/locales/ja.json')
        require('intl/locale-data/jsonp/ja.js')
      }, 'lang-ja')
      break
    case 'es':
      require.ensure([
        'common/locales/es.json',
        'intl/locale-data/jsonp/es.js',
      ], (require) => {
        locales.es = require('common/locales/es.json')
        require('intl/locale-data/jsonp/es.js')
      }, 'lang-es')
      break
    case 'id':
      require.ensure([
        'common/locales/id.json',
        'intl/locale-data/jsonp/id.js',
      ], (require) => {
        locales.id = require('common/locales/id.json')
        require('intl/locale-data/jsonp/id.js')
      }, 'lang-id')
      break
    case 'pt':
      require.ensure([
        'common/locales/pt.json',
        'intl/locale-data/jsonp/pt.js',
      ], (require) => {
        locales.pt = require('common/locales/pt.json')
        require('intl/locale-data/jsonp/pt.js')
      }, 'lang-pt')
      break
    case 'sl':
      require.ensure([
        'common/locales/sl.json',
        'intl/locale-data/jsonp/sl.js',
      ], (require) => {
        locales.sl = require('common/locales/sl.json')
        require('intl/locale-data/jsonp/sl.js')
      }, 'lang-sl')
      break
    case 'it':
      require.ensure([
        'common/locales/it.json',
        'intl/locale-data/jsonp/it.js',
      ], (require) => {
        locales.it = require('common/locales/it.json')
        require('intl/locale-data/jsonp/it.js')
      }, 'lang-it')
      break
    case 'fr':
      require.ensure([
        'common/locales/fr.json',
        'intl/locale-data/jsonp/fr.js',
      ], (require) => {
        locales.fr = require('common/locales/fr.json')
        require('intl/locale-data/jsonp/fr.js')
      }, 'lang-fr')
      break
    case 'my':
      require.ensure([
        'common/locales/my.json',
        'intl/locale-data/jsonp/my.js',
      ], (require) => {
        locales.my = require('common/locales/my.json')
        require('intl/locale-data/jsonp/my.js')
      }, 'lang-my')
      break
    case 'ru':
      require.ensure([
        'common/locales/ru.json',
        'intl/locale-data/jsonp/ru.js',
      ], (require) => {
        locales.ru = require('common/locales/ru.json')
        require('intl/locale-data/jsonp/ru.js')
      }, 'lang-ru')
      break
    case 'no':
      require.ensure([
        'common/locales/no.json',
        'intl/locale-data/jsonp/nn-NO.js',
      ], (require) => {
        locales.no = require('common/locales/no.json')
        require('intl/locale-data/jsonp/nn-NO.js')
      }, 'lang-no')
      break
    case 'sv':
      require.ensure([
        'common/locales/sv.json',
        'intl/locale-data/jsonp/sv.js',
      ], (require) => {
        locales.sv = require('common/locales/sv.json')
        require('intl/locale-data/jsonp/sv.js')
      }, 'lang-sv')
      break
    case 'hu':
      require.ensure([
        'common/locales/hu.json',
        'intl/locale-data/jsonp/hu.js',
      ], (require) => {
        locales.hu = require('common/locales/hu.json')
        require('intl/locale-data/jsonp/hu.js')
      }, 'lang-hu')
      break
    case 'pl':
      require.ensure([
        'common/locales/pl.json',
        'intl/locale-data/jsonp/pl.js',
      ], (require) => {
        locales.pl = require('common/locales/pl.json')
        require('intl/locale-data/jsonp/pl.js')
      }, 'lang-pl')
      break
    case 'th':
      require.ensure([
        'common/locales/th.json',
        'intl/locale-data/jsonp/th.js',
      ], (require) => {
        locales.th = require('common/locales/th.json')
        require('intl/locale-data/jsonp/th.js')
      }, 'lang-th')
      break
    case 'vi':
      require.ensure([
        'common/locales/vi.json',
        'intl/locale-data/jsonp/vi.js',
      ], (require) => {
        locales.vi = require('common/locales/vi.json')
        require('intl/locale-data/jsonp/vi.js')
      }, 'lang-vi')
      break
    case 'ms':
      require.ensure([
        'common/locales/ms.json',
        'intl/locale-data/jsonp/ms.js',
      ], (require) => {
        locales.ms = require('common/locales/ms.json')
        require('intl/locale-data/jsonp/ms.js')
      }, 'lang-ms')
      break
    case 'tw':
      require.ensure([
        'common/locales/tw.json',
        'intl/locale-data/jsonp/zh-Hant-TW.js',
      ], (require) => {
        locales.tw = require('common/locales/tw.json')
        require('intl/locale-data/jsonp/zh-Hant-TW.js')
      }, 'lang-tw')
      break
  }

  await intl.init({
    currentLocale,
    locales,
    fallbackLocale: 'en',
  }).then(() => {
    if (currentLocale.startsWith('ar')) {
      const html = document.documentElement.setAttribute('dir', 'rtl')
    }
  })
  .catch((err) => {
    // TODO: rudik - do you need to write in Rollbar here?
    console.log(err)
  })

  if (currentLocale === 'ko') {
    zE(() => {
      zE.setLocale('ko')
    })
  }

  if (HIDE_HELP.includes(process.env.BUILD || '') || window.innerWidth <= 640) {
    zE(() => {
      zE.hide()
    })
  }
}

/** This needs to be called before initial render */
export async function initLocalization(userLanguage: string|null|undefined) {
  // tslint:disable-next-line: no-parameter-reassignment
  userLanguage = normalizeLanguage(languagesSet, userLanguage)
  const currentLanguage = normalizeLanguage(languagesSet, getCurrentLanguage())

  if (userLanguage) {
    if (userLanguage !== currentLanguage) {
      setCurrentLanguage(userLanguage)
    }
    await init(userLanguage)
    return
  }

  const browserLanguage = normalizeLanguage(languagesSet, getBrowserLanguage())
  const newCurrentLanguage = currentLanguage || browserLanguage || languageDefault
  if (currentLanguage !== newCurrentLanguage) setCurrentLanguage(newCurrentLanguage)
  await init(newCurrentLanguage)
}
