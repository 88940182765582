import { Action } from 'graphql/schemas/action/Action'
import { getDaysFromEpoch2 } from 'utils/functions'
import { ChaptersUnlocked } from '../course/Course'
import { Timestamps } from '../user/UserProgress'

export type AssessmentMode = {
  enabled: boolean;
  randomized: boolean;
  limit: number|null;
  choicesRandomized: boolean|null;
  timeLimit: number|null;
  // proctoring: boolean|null;
}

export const chapterFactory = (data?: Partial<Chapter>): Chapter => {
  return {
    id: data?.id || 'random',
    courseId: data?.courseId || 'random',
    order: data?.order || 1,
    title: data?.title || 'random',
    actions: data?.actions || [],
    isSequential: data?.isSequential || false,
    morningPush: data?.morningPush || 'random',
    morningEmail: data?.morningEmail || 'random',
    actionsCount: data?.actionsCount || 0,
    timestamps: data?.timestamps || {
      createdAt: 0,
      createdAtTree: 0,
      updatedAt: 0,
      updatedAtTree: 0,
    },
    assessmentMode: data?.assessmentMode || null,
    disabledUntilDay: data?.disabledUntilDay || null,
    description: data?.description || null,
    imageUrl: data?.imageUrl || null,
    imageUrlAlt: data?.imageUrlAlt || null,
    videoUrl: data?.videoUrl || null,
    videoUrl_720p: data?.videoUrl_720p || null,
    videoUrlThumbnail: data?.videoUrlThumbnail || null,
    videoUrlAlt: data?.videoUrlAlt || null,
    tagline: data?.tagline || null,
  }
}

export type Chapter = {
  id: string;
  courseId: string;
  order: number;
  title: string;
  actions: Action[];
  isSequential: boolean;
  morningPush: string;
  morningEmail: string;
  actionsCount: number;
  timestamps: Timestamps;

  assessmentMode: AssessmentMode|null;
  disabledUntilDay: number|null;
  description: string|null;
  imageUrl: string|null;
  imageUrlAlt: string|null;
  videoUrl: string|null;
  videoUrl_720p: string|null;
  videoUrlThumbnail: string|null;
  videoUrlAlt: string|null;
  tagline: string|null;
}

export type ChapterBasic = {
  id: string;
  courseId: string;
  order: number;
  title: string;
  description: string|null;
  descriptionShort: string|null;
  imageUrl: string|null;
  imageUrlAlt: string|null;
  actionsCount: number;
  disabledUntilDay: number|null;
}

export const isChapterLocked = ({ currentDay, mode, chapter }: { currentDay: number, mode: ChaptersUnlocked, chapter: Chapter|ChapterBasic|undefined }) => {
  const today = getDaysFromEpoch2(new Date())
  if (!chapter || chapter.order === 1) return false
  if (chapter.disabledUntilDay && chapter.disabledUntilDay > today) return true
  if (mode === 'unlocked') return false
  if (chapter.order > currentDay) return true
  return false
}
