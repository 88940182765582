import { ArrowLeftIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from './utils/Button'
import intl from 'react-intl-universal'
import { ContentType } from 'graphql/schemas/action/Action'
import Tippy from '@tippyjs/react'
import MagicWandSparklesIcon from './icons/MagicWandSparkles'
import MagicCreditsIcon from './icons/MagicCredits'
import { track, trackAiCreation, trackAiFileUpload, trackButtonEnhanced } from 'utils/track'
import { getPresignedUrl, PresignedUrl } from 'actions/files'
import { useAppState } from 'graphql/hooks/useAppState'
import { BASE_API_URL } from 'constants/General'
import { showModal2 } from './utils/CustomModal'
import { MiniLoader } from './utils/MiniLoader'
import { capitalize, getMeta } from 'utils/functions'
import { useQuery } from '@apollo/client'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'
import Lottie from 'lottie-react'
import outlineSkeleton from 'common/json/lottie/OutlineSkeleton.json'
import magicCreditsAnimation from 'common/json/lottie/MagicCreditsAnimation.json'
import { updateAppCache } from 'app'

type FileData = {
  name: string,
  displayName: string,
  mimeType: string,
  sizeBytes: string,
  createTime: string,
  updateTime: string,
  expirationTime: string,
  sha256Hash: string,
  uri: string,
  state: string,
  filePrecision?: string,
}

type MagicType = {
  title: string,
  type: string,
  description: string,
  courseDescription?: string,
  useCase: string,
  language: string,
  programLength: string,
  fileMatchingType: string,
  fileUrls: string[],
  uploadedFilesData: FileData[],
  audience: string,
  voice: 'professional' | 'casual' | 'inspirational' | 'playful' | 'educational' | 'story_driven',
  generateAiImages: boolean,
}
type CustomFile = File & {
  urlS3?: string|null
  urlGemini?: string|null
  geminiDisplayName?: string|null
  geminiName?: string|null
  filePrecision?: string|null
  uploadProgress?: number|null
  uploadStatus?: 'pending' | 'uploading' | 'uploaded' | 'failed'
}

type TypeOfCreator = 'action' | 'chapter' | 'course'

type MagicCheckboxProps = {
  name: string,
  setMagicData: any,
  fieldKey: string
}

type MagicUploaderProps = {
  onChange: (e) => void,
  magicData: MagicType,
  setMagicData: any,
  showUploadIndicator: boolean,
  setShowUploadIndicator: any,
  selectedFiles: CustomFile[],
  setSelectedFiles: any,
  typeOfCreator: TypeOfCreator,
  courseId?: string,
  organizationId: string,
}

type MagicCreditsProps = {
  magicData: MagicType,
  creditsTokens
  setCredits: any,
  setShowCreditsIndicator: any,
  prompt: string,
  courseId?: string,
  organizationId: string,
  type: TypeOfCreator,
  programLength?: string,
}

type MagicCreatorProps = {
  typeOfCreator: TypeOfCreator,
  courseId?: string,
  chapterId?: string,
  organizationId?: string,
  closeModal: () => void,
  onPrevious?: () => void,
  whiteLabelData?: {
    bgColor: string | null;
    primaryColor: string | null;
    accentColor: string | null;
    largeLogoUrl: string | null;
  }
  showZendeskWidget?: () => void,
}

type CreateSessionOrActionProps = {
  courseId: string,
  chapterId?: string,
  organizationId: string,
  companyId: string,
  inputData: any,
  type: TypeOfCreator,
  uploadedFiles: FileData[],
  token: string,
  dummy: boolean,
  generateAiImages: boolean,
  totalCreditsCount: number, // estimated credits, sending them to creation endpoint to track the usage
  totalTokensCount: number, // estimated tokens, sending them to creation endpoint to track the usage
}

type CreateProgramProps = {
  organizationId: string,
  inputData: {
    title: string,
    description: string,
    useCase: string,
    language: string,
    courseLength: string,
    audience?: string,
    voice: string,
    outline?: string,
  },
  token: string,
  dummy?: boolean, //  used for E2E testing
  generateAiImages: boolean,
  uploadedFiles: FileData[],
  totalCreditsCount: number,
  totalTokensCount: number,
}

type CreateProgramOutline = {
  organizationId: string,
  inputData: any,
  uploadedFiles: FileData[],
  token: string,
  dummy?: boolean,
  previousOutline?: string,
}

type MagicCreditsResponse = {
  credits: {
    totalTokens: number,
    totalCredits: number,
    availableCredits: number,
  },
  prompt: string,
  generateAiImages: boolean,
}

const inputAreaCn = 'rounded-md py-2 shadow-sm border focus:ring-magic focus:border-magic border-magic'
const inputAreaPrecisionCn = 'rounded-xl py-0 shadow-sm border focus:ring-magic focus:border-magic border-magic'
const wrapperInputCn = 'flex flex-col space-y-2 w-full'
const labelCn = 'text-sm sm:text-base'
const recreateOutlineBtns = 'text-sm bg-[#d2d7fd] text-[#525254]'

const matchTypes = {
  high_precision: "High Precision",
  low_precision: "Low Precision",
}

const createSessionOrAction = async ({courseId, chapterId, organizationId, companyId, inputData, type, uploadedFiles, token, dummy, generateAiImages, totalCreditsCount} : CreateSessionOrActionProps) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createChapterOrAction`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      courseId,
      chapterId,
      organizationId,
      type,
      inputData,
      uploadedFiles,
      dummy,
      generateAiImages,
      totalCreditsCount: totalCreditsCount || 0,
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

const createProgram = async ({organizationId, inputData, uploadedFiles, token, dummy, generateAiImages, totalCreditsCount} : CreateProgramProps) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createCourse`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      organizationId,
      inputData,
      uploadedFiles,
      dummy,
      generateAiImages,
      totalCreditsCount: totalCreditsCount || 0,
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

const createProgramOutline = async ({ organizationId, inputData, uploadedFiles, dummy, token, previousOutline} : CreateProgramOutline) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createCourseOutline`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      organizationId,
      inputData,
      uploadedFiles,
      dummy,
      previousOutline
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

const OutlineContent = ({ rawData, expanded, setMagicData }: { rawData: string, expanded: boolean, setMagicData: any }) => {
  // Parse the string into structured data
  const parseData = (data) => {
    const lines = data.split('\n') // Split the string into lines
    const titleLine = lines.find((line) => line.startsWith('Title of Program:')) // Extract the title
    const title = titleLine.replace('Title of Program: ', '').trim() // Remove prefix and trim whitespace
    const descriptionLine = lines.find((line) => line.startsWith('Description of Program:')) // Extract the description
    const description = descriptionLine.replace('Description of Program: ', '').trim() // Remove prefix and trim whitespace

    // Extract session data
    const sessions: { sessionNumber: string; title: string; description: string }[] = []
    let currentSession = { sessionNumber: '', title:'', description: '', }

    lines.forEach((line) => {
      line = line.trim() // Remove extra spaces
      if (line.startsWith('Session')) {
        if (currentSession) {
          sessions.push(currentSession); // Add the previous session to the list
        }
        currentSession = { sessionNumber: line, title: '', description: '' }
      } else if (line.startsWith('Title:')) {
        currentSession.title = line.replace('Title: ', '')
      } else if (line.startsWith('Description:')) {
        currentSession.description = line.replace('Description: ', '');
      }
    })

    // Push the last session
    if (currentSession) {
      sessions.push(currentSession)
    }

    return { title, description, sessions }
  }

  // Call the parsing function
  const parsedData = parseData(rawData)

  // useEffect(() => {
  //   if (parsedData.description) setMagicData((prevData) => ({ ...prevData, courseDescription: parsedData.description}))
  // }, [parsedData.description, setMagicData])

  return (
    <div className={`text-[#fcffff] text-sm pr-4 ${expanded ? 'h-screen': 'h-96'} scrollable-div`}>
      <div><strong>Title of Program:</strong> {parsedData.title}</div>
      <br />
      {parsedData.sessions.map((session, index) => {
        if (index === 0) return null
        return <div key={index}>
          <div><strong>{session.sessionNumber}</strong></div>
          <div><strong>Title:</strong> {session.title}</div>
          <div><strong>Description:</strong> {session.description}</div>
          <br />
        </div>
      })}
    </div>
  )
}

const MagicSelect = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string}) => {
  return (
    <div className={`${wrapperInputCn} sm:w-1/3`}>
      <div className={`${hasInfo && 'flex flex-row space-x-2'}`}>
        <label htmlFor="magicActionTitle" className={labelCn}>{labelText}</label>
        {hasInfo && <AdditionalInfo tooltipContent={tooltipContent || ''} />}
      </div>
      <select
        name={name}
        id="magicActionTitle"
        className={inputAreaCn}
        value={selectedValue}
        onChange={onChange}>
        <option value="automatic">Automatic</option>
        {content.map(([key, value], index) => {
          return <option value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

const MagicSelectPrecision = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string}) => {
  return (
    <div className={`${wrapperInputCn} sm:w-1/4`}>
      <select
        name={name}
        id="magicActionTitle2"
        className={inputAreaPrecisionCn}
        value={selectedValue}
        onChange={onChange}>
        <option value="automatic">Automatic</option>
        {content.map(([key, value], index) => {
          return <option value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

const MagicCheckbox = ({name, setMagicData, fieldKey } : MagicCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(true)

  useEffect(() => {
    setMagicData((prevData) => ({
      ...prevData,
      [fieldKey]: isChecked,
    }))
  }, [isChecked])

  const handleChange = (event) => {
    setIsChecked(event.target.checked)
  }

  return (
    <div>
      <label>
        <input
          type="checkbox"
          className="focus:ring-magic h-4 w-4 text-magic border-magic rounded mr-2"
          checked={isChecked}
          onChange={handleChange}
        />
        {name}
      </label>
    </div>
  )
}

const MagicUploader = ({ onChange, magicData, setMagicData, showUploadIndicator, setShowUploadIndicator, selectedFiles, setSelectedFiles, typeOfCreator, courseId, organizationId }: MagicUploaderProps) => {
  const appState = useAppState()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const previousFilesLength = useRef(selectedFiles.length)

  const [fileQueue, setFileQueue] = useState<CustomFile[]>([])
  const [isProcessing, setIsProcessing] = useState(false)

  // after all files have been removed, trigger the upload process to recalculate credits and clear the uploaded files data
  useEffect(() => {
    if (!appState) return
    const recalculateCredits = async () => {
      if (previousFilesLength.current > 0 && selectedFiles.length === 0) {
        setMagicData((prevData) => ({
          ...prevData,
          uploadedFilesData: [],
        }))
      }

      previousFilesLength.current = selectedFiles.length
    }
    recalculateCredits()
  }, [selectedFiles.length, magicData.uploadedFilesData])

  if (!appState) return null

  const handleInputButtonClick = () => {
    fileInputRef?.current?.click()
  }

  const isAudioOrVideoFile = (file: CustomFile) => {
    const audioTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3', 'audio/mp4', 'audio/aac']
    const videoTypes = ['video/mp4', 'video/x-m4v', 'video/ogg', 'video/webm', 'video/quicktime']

    return audioTypes.includes(file.type) || videoTypes.includes(file.type)
  }

  const hasAudioOrVideoFiles = selectedFiles.some(isAudioOrVideoFile)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      alert('No file selected')
      return
    }

    const newFile: CustomFile[] = Array.from(event.target.files).map((file) =>
      Object.assign(file, {
        urlS3: null,
        urlGemini: null,
        geminiDisplayName: null,
        geminiName: null,
        filePrecision: 'automatic',
        uploadProgress: 0,
        uploadStatus: 'pending' as const,
      })
    )

    setSelectedFiles((prevFiles: CustomFile[]) => {
      // Prevent adding more than 10 files
      if (prevFiles.length + newFile.length > 10) {
        showModal2({
          title: intl.get('magic_file_upload_limit_reached'),
          content: intl.get('magic_upload_limit_reached_text'),
          destructive: true,
          primaryText: intl.get('global_close'),
          secondaryButton: false,
        })
        return prevFiles
      }

      return [...prevFiles, ...newFile]
    })

    if (newFile) {
      setFileQueue((prevQueue) => [...prevQueue, ...newFile])
    }
  }

  useEffect(() => {
    const processQueue = async () => {

      if (isProcessing || fileQueue.length === 0) {
        return
      }
      setIsProcessing(true)
      setShowUploadIndicator(true)

      const file  = fileQueue[0]

      try {
        const url = await uploadFileToS3(file)
        const geminiResponse: { data:FileData[] } = await uploadFileToGemini(appState, [url])
        const geminiData = geminiResponse.data[0]

        setSelectedFiles((prevFiles: CustomFile[]) => {
          const fileIndex = prevFiles.findIndex((f) => f.name === file.name)
          if (fileIndex === -1) return prevFiles

          const updatedFile = Object.assign(prevFiles[fileIndex], {
            urlS3: url,
            urlGemini: geminiData.uri,
            geminiDisplayName: geminiData.displayName,
            geminiName: geminiData.name,
            filePrecision: 'automatic',
            uploadProgress: 100,
            uploadStatus: 'uploaded' as const,
          })

          const updatedFiles = [
            ...prevFiles.slice(0, fileIndex),
            updatedFile,
            ...prevFiles.slice(fileIndex + 1),
          ];

          return updatedFiles;

        })

        setMagicData((prevData: MagicType) => ({
          ...prevData,
          uploadedFilesData: [
            ...prevData.uploadedFilesData,
            geminiData,
          ],
        }))

        trackAiFileUpload({
          type: typeOfCreator,
          fileUrl: geminiData.uri,
          userId: appState.loggedInAs.uid,
          courseId,
          organizationId,
        })

        setFileQueue((prevQueue) => prevQueue.slice(1))

      } catch (error) {
        console.error('Error processing file:', error)
      } finally {
        setIsProcessing(false)

        if (fileQueue.length <= 1) {
          setShowUploadIndicator(false)
        }
      }
    }
    processQueue()
  }, [fileQueue, isProcessing])


  const handleRemoveFile = async (indexToRemove: number) => {
    setSelectedFiles((prevFiles: CustomFile[]) => {
      const fileToRemove = prevFiles.find((_, index) => index === indexToRemove)
      const updatedSelectedFiles = prevFiles.filter((_, index) => index !== indexToRemove)

      setMagicData((prevData) => ({
        ...prevData,
        uploadedFilesData: prevData.uploadedFilesData.filter(
          (fileData) => fileData.displayName !== fileToRemove?.geminiDisplayName
        ),
      }))

      return updatedSelectedFiles
    })
  }

  function areAllFilesUploaded(selectedFiles: CustomFile[]) {
    return selectedFiles.every((file) => file.urlS3 && file.urlS3.trim() !== '')
  }

  function shortenFileName(filename) {
    const maxLength = 45
    const lastChars = 4

    const extensionIndex = filename?.lastIndexOf('.')

    if (extensionIndex === -1) return filename

    const extension = filename.slice(extensionIndex)
    const baseName = filename.slice(0, extensionIndex)

    if (baseName.length <= maxLength + lastChars) {
      return filename
    }

    const start = baseName.slice(0, maxLength)
    const end = baseName.slice(-lastChars)

    return `${start}...${end}${extension}`
  }

  const uploadFileToS3 = async (file: CustomFile) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      const { data, error }: PresignedUrl = await getPresignedUrl(appState.loggedInAs.token, file.name, file.type, file.size, `user:${appState.loggedInAs.uid}:public`)

      if (error) {
        return rej(error.message)
      }

      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            res(data.url)
          }
          else {
            rej('error - xhr !== 200')
          }
        }
      }
      xhr.upload.addEventListener('progress', (e) => {
        const progress = (e.loaded / e.total) * 100
      })

      xhr.addEventListener('error', () => rej('errors.response_not_ok'))
      xhr.addEventListener('abort', () => rej('errors.upload_aborted'))
      xhr.addEventListener('load', () => res(data.url))
      xhr.open('PUT', data.presignedUrl)
      Object.entries(data.headers).map((h) => {
        const [header, value] = h
        if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
        xhr.setRequestHeader(header, value)
      })
      xhr.send(file)
    })
  }

  async function uploadFileToGemini(appState, urls) {
    const baseUrl = `${BASE_API_URL}/api/v1/integrations/ai/magicCreator/uploadFiles`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({ urls }),
    }

    try {
      const response = await fetch(baseUrl, options)
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)

      const text = await response.text()

      return text ? JSON.parse(text) : {}

    } catch (error) {
      throw new Error('Error uploading file to Gemini')
    }
  }

  const handleFilePrecisionChange = (e, file: CustomFile) => {
    const updatedPrecision = e.target.value
    file.filePrecision = updatedPrecision
    setSelectedFiles(prevFiles => [...prevFiles])
  }

  return ( <div className="relative mb-6">
    <div className="flex flex-row items-center justify-start space-x-4 h-full mb-6">
      <div className="flex space-x-2">
        <label htmlFor="magicActionTitle" className={labelCn}>Upload Files</label>
        <AdditionalInfo tooltipContent={intl.get('magic_upload_file_tooltip')} />
      </div>
      <div data-test="attach-and-upload" className="flex space-x-2">
        <input id="magic-ai-upload-input" ref={fileInputRef} type="file" accept="image/*,video/*,audio/*,.pdf" className="hidden" onChange={handleFileChange}/>
        <Button
          type="magic"
          className="bg-magic-light text-deepgray px-4 sm:px-6 py-2.5"
          text={intl.get('magic_attach')}
          onClick={(e) => {
            e.preventDefault()
            handleInputButtonClick()
            trackButtonEnhanced({
              button: 'Attach',
              onScreen: 'Magic Action Creator',
            })
          }}
        />
         {(hasAudioOrVideoFiles && showUploadIndicator && !areAllFilesUploaded(selectedFiles)) && <div className="flex items-center">
          <p className="text-xs text-gray-500 mt-2">
            {intl.get('magic_upload_audio_video_note')}
          </p>
        </div>}
      </div>
    </div>
    {selectedFiles.map((file, index) => {
      return <div key={`${file}-${index}`} className="flex flex-row mt-3 space-x-2 sm:space-x-4 items-center justify-start">
        <div className="flex items-center w-full text-sm sm:text-base">
          <MagicSelectPrecision
            name="fileMatchingType"
            labelText="Upload Files"
            content={Object.entries(matchTypes)}
            hasInfo={true}
            tooltipContent={intl.get('magic_upload_file_tooltip')}
            onChange={(e) => handleFilePrecisionChange(e, file)}
            selectedValue={file.filePrecision || 'automatic'}
          />
          <div onClick={() => {
            if (showUploadIndicator) return
            trackButtonEnhanced({
              button: 'Remove Uploaded File (X)',
              onScreen: 'Magic Action Creator',
            })
            handleRemoveFile(index)
            }}>
            <XMarkIcon className="w-4 h-4 fill-[#c0c0c0] text-[#c0c0c0] cursor-pointer ml-4 mr-1" />
          </div>

          <div className="flex items-center space-x-2">
            <a href={file.urlS3 || '#'} target="_blank" rel="noopener noreferrer" className={`${file.urlS3 ? 'underline' : ''} text-magic`}>
              {shortenFileName(file.name)}
            </a>
            {showUploadIndicator && <MiniLoader />}
          </div>
        </div>
      </div>
    })}
  </div>
  )
}

const AdditionalInfo = ({ tooltipContent, interactive, className }: { tooltipContent: string | React.ReactNode, interactive?: boolean, className?: string }) => {
 return (
  <Tippy content={tooltipContent} interactive={interactive}>
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "24px", height: "24px", overflow: "visible", fill: "#808084" }} viewBox="0 0 24 24" className={`${className} h-5 w-5`}>
      <path d="M0 0h24v24H0z" fill="none">
      </path>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
      </path>
    </svg>
  </Tippy>
 )
}

const CreationInProgress = ({ title, description }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h2>
      <p className="text-lg mb-4 text-gray-600">{description}</p>
      <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden relative">
        <div
          className="h-full rounded-full transition-all duration-75"
          style={{
            width: `${progress}%`,
            backgroundColor: '#915fee',
          }}
        ></div>
      </div>
    </div>
  )
}

const CreationDone = ({ title, description }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h2>
      <p className="text-lg mb-4 text-gray-600">{description}</p>
      <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden relative">
        <div
          className="h-full rounded-full transition-all duration-75"
          style={{
            width: `${progress}%`,
            backgroundColor: '#915fee',
          }}
        ></div>
      </div>
    </div>
  )
}

const MagicCredits = ({ magicData, creditsTokens, setCredits, setShowCreditsIndicator, prompt, courseId, organizationId, type, programLength }: MagicCreditsProps) => {
  const appState = useAppState()
  const DEBOUNCE_DELAY = 500
  const [creditCount, setCreditCount] = useState(0)
  const [availableCredits, setAvailableCredits] = useState(0)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (!appState) return

    const debounceTimer = setTimeout(() => {
      credits()
    }, DEBOUNCE_DELAY)

    return () => clearTimeout(debounceTimer)
  }, [magicData.uploadedFilesData, magicData.programLength, magicData.generateAiImages, appState])

  if (!appState) return

  const credits = async () => {
    setLoading(true)
    setShowCreditsIndicator(true)
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/estimateTokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({
          uploadedFilesData: magicData.uploadedFilesData,
          prompt: '',
          generateAiImages: magicData.generateAiImages,
          courseId: courseId || '',
          organizationId: organizationId || '',
          type,
          programLength,
        }),
      })

      const result: MagicCreditsResponse = await response.json()

      setCreditCount(result.credits.totalCredits)
      setAvailableCredits(result.credits.availableCredits)
      setCredits(result.credits)
    } catch (err) {
      showModal2({
        title: `Error`,
        content: (err as Error)?.message,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
    } finally {
      setLoading(false)
      setShowCreditsIndicator(false)

      // limits
      let limitReachTitle: string | undefined
      let limitReachDescription: string | undefined
      if (creditsTokens.creditCount > creditsTokens.availableCredits) {
        limitReachTitle = intl.get('magic_credits_limit_reached')
        limitReachDescription = intl.get('magic_credits_limit_reached_text')
      } else if (creditsTokens.totalTokens > 1_900_000) {
        limitReachTitle = intl.get('magic_credits_limit_reached_tokens')
        limitReachDescription = intl.get('magic_credits_limit_reached_text_tokens')
      }

      if (limitReachTitle && limitReachDescription) {
        setShowCreditsIndicator(true)
        showModal2({
          title: limitReachTitle,
          content: limitReachDescription,
          destructive: true,
          primaryText: intl.get('global_close'),
          secondaryButton: false,
        })
      }
    }
  }
  return (
    <div data-test="ai-credits" className="flex flex-row items-center justify-center space-x-1 sm:space-x-4">
      {loading ? <Lottie className="w-10 h-10" animationData={magicCreditsAnimation}/> : <MagicCreditsIcon className="w-10 h-10 fill-magic" />}
      <div className={loading ? 'blur-sm animate-pulse': ''}>
        <div data-test="credit-count" className="font-bold text-xs">{creditCount} {intl.get('settings_credits')}</div>
        <div data-test="available-credits" className="flex items-center justify-center space-x-2 text-xs">
          <div>
            {availableCredits} {intl.get('global_available')}
          </div>
          <AdditionalInfo
            tooltipContent={<div>{intl.getHTML('magic_credits_tooltip')}</div>}
            interactive={true} />
        </div>
      </div>
    </div>
  )
}

const MagicCreator = ({ typeOfCreator, courseId, chapterId, closeModal, onPrevious, whiteLabelData, showZendeskWidget }: MagicCreatorProps) => {
  const showProgramLength = typeOfCreator === 'course'
  const showSessionLength = typeOfCreator === 'chapter'
  const showAudience = typeOfCreator === 'course'
  const showVoice = typeOfCreator === 'course'
  const appState = useAppState()
  const [creationLoader, setCreationLoader] = useState(false)
  const [showDetails, setShowDetails] = useState(typeOfCreator === 'course' ? true : false)
  const [showOptions, setShowOptions] = useState(false)
  const [prompt, setPrompt] = useState<string>('')
  const [selectedFiles, setSelectedFiles] = useState<CustomFile[]>([])
  const [showUploadIndicator, setShowUploadIndicator] = useState(false)
  const [showCreditsIndicator, setShowCreditsIndicator] = useState(false)
  const [disableCreateButton, setDisableCreateButton] = useState(false)
  const [programStep, setProgramStep] = useState(1)
  const [expanded, setExpanded] = useState(false)
  const [describeChanges, setDescribeChanges] = useState('')
  const [outlineLoading, setOutlineLoading] = useState(false)
  const [outlineData, setOutlineData] = useState('')

  const [credits, setCredits] = useState<MagicCreditsResponse['credits']>({
    totalTokens: 0,
    totalCredits: 0,
    availableCredits: 0,
  })
  const [magicData, setMagicData] = useState({
    title: '',
    type: typeOfCreator === 'action' ? 'automatic' : undefined,
    description: '',
    courseDescription: '',
    useCase: 'automatic',
    language: 'automatic',
    programLength: typeOfCreator !== 'action' ? 'automatic' : undefined,
    audience: 'general',
    voice: 'casual',
    fileMatchingType: '',
    fileUrls: [],
    uploadedFilesData: [] as FileData[],
    generateAiImages: true,
  } as MagicType)

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'hide')
    }
  }, [])

  const contentTypes: Record<ContentType, string> = {
    multiple_choice: "Multiple Choice",
    qa: "QA",
    photo: "Photo",
    image: "Image",
    text: "Text",
    quote: "Quote",
    certificate: "Certificate",
    assessment: "Assessment",
    upload: "Upload",
    rating: "Rating",
    word_puzzle: "Word Puzzle"
  }

  const useCases = {
    training: "Training",
    onboarding: "Onboarding",
    sales_enablement: "Sales Enablement",
    leadership_development: "Leadership Development",
    operations: "Operations",
    safety_and_compliance: "Safety and Compliance",
    partner_engagement: "Partner Engagement",
    customer_engagement: "Customer Engagement"
  }

  const languages = {
    ar: "Arabic",
    bn: "Bengali",
    bg: "Bulgarian",
    zh: "Chinese",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    et: "Estonian",
    fi: "Finnish",
    fr: "French",
    de: "German",
    el: "Greek",
    iw: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    lv: "Latvian",
    lt: "Lithuanian",
    no: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    sw: "Swahili",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese"
  }

  const programLength = {
    short: 'Short',
    medium: 'Medium',
    long: 'Long'
  }

  const voice = {
    professional: 'Professional',
    casual: 'Casual',
    inspirational: 'Inspirational',
    playful: 'Playful',
    educational: 'Educational',
    story_driven: 'Story-Driven'
  }


  const { data: subData, loading: subLoading } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  useEffect(() => {
    setDisableCreateButton(showUploadIndicator || showCreditsIndicator)
  }, [showUploadIndicator, showCreditsIndicator])

  const organizationId: string = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  if (!appState) return null

  const [companyId] = appState.currentCompanyIdCourseId.split('-') || ['', '']

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setMagicData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleMagicActionCreate = async () => {
    const isE2eTest = localStorage.getItem("e2eTest") === "true"
    if (!courseId) return
    setCreationLoader(true)

    const title = typeOfCreator === 'action' ? intl.get('magic_action_creation_in_progress_title') : intl.get('magic_session_creation_in_progress_title')
    const description = typeOfCreator === 'action' ? intl.get('magic_action_creation_in_progress_description') : intl.get('magic_session_creation_in_progress_description')

    const modalCreationInProcess = showModal2({
      component: <CreationInProgress title={title} description={description}/>,
      disableClickOutside: true,
      onlyContent: true,
      secondaryButton: false,
    })

    const enrichedFilesData = magicData.uploadedFilesData.map(file => {
      const matchingSelectedFile = selectedFiles.find(
        selected => selected.geminiName === file.name
      )

      return {
        ...file,
        filePrecision: matchingSelectedFile?.filePrecision || 'automatic',
      }
    })

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'automatic',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const payload = {
      title: magicData.title,
      description: magicData.description,
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      generateAiImages: magicData.generateAiImages ?? true,
    }

    const response = await createSessionOrAction({
      courseId,
      chapterId,
      organizationId,
      companyId,
      inputData: {
        title: payload.title,
        description: payload.description,
        useCase: payload.useCase,
        language: payload.language,
        type: typeOfCreator === 'action' ? payload.type : undefined,
        sessionLength: typeOfCreator === 'chapter' ? payload.programLength : undefined,
      },
      type: typeOfCreator,
      uploadedFiles: enrichedFilesData,
      token: appState.loggedInAs.token,
      dummy: isE2eTest || false,
      generateAiImages: payload.generateAiImages,
      totalCreditsCount: credits.totalCredits,
      totalTokensCount: credits.totalTokens,
    })

    modalCreationInProcess.close()
    if (response.error) {
      setCreationLoader(false)
      showModal2({
        title: `Error`,
        content: response.error.message,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      return
    }

    closeModal()

    trackAiCreation({
      type: typeOfCreator,
      fileCount: selectedFiles.length,
      creditAvailable: credits.availableCredits,
      credits: credits.totalCredits,
      courseId,
      organizationId,
    })
  }
  const createMagicProgram = async () => {
    const isE2eTest = localStorage.getItem("e2eTest") === "true"
    setCreationLoader(true)

    const title = 'Great! We are creating your program now!'
    const description = 'If you choose to close this window, you can find your program in your sandbox once it is ready.'

    const modalCreationInProcess = showModal2({
      component: <CreationInProgress title={title} description={description}/>,
      disableClickOutside: true,
      onlyContent: true,
      secondaryButton: false,
    })

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'automatic',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const enrichedFilesData = magicData.uploadedFilesData.map(file => {
      const matchingSelectedFile = selectedFiles.find(
        selected => selected.geminiName === file.name
      )

      return {
        ...file,
        filePrecision: matchingSelectedFile?.filePrecision || 'automatic',
      }
    })

    const payload = {
      title: magicData.title,
      description: magicData.description || '-', // magicData.courseDescription
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      audience: magicData.audience || 'general',
      voice: magicData.voice || 'casual',
      generateAiImages: magicData.generateAiImages ?? true
    }

    const response = await createProgram({
      organizationId,
      inputData: {
        title: payload.title,
        description: payload.description,
        useCase: payload.useCase,
        language: payload.language,
        audience: payload.audience,
        voice: payload.voice,
        outline: outlineData,
        courseLength: payload.programLength

      },
      uploadedFiles: enrichedFilesData,
      token: appState.loggedInAs.token,
      dummy: isE2eTest || false,
      generateAiImages: payload.generateAiImages,
      totalCreditsCount: credits.totalCredits,
      totalTokensCount: credits.totalTokens,
    })
    setTimeout(() => {
      modalCreationInProcess.close()
    }, 10000)
    if (response.error) {
      setCreationLoader(false)
      showModal2({
        title: `Error`,
        content: response.error.message,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      return
    }

    closeModal()
    if (showZendeskWidget) {
      showZendeskWidget()
    }
    updateAppCache('isCreatingCourse', true)

    trackAiCreation({
      type: typeOfCreator,
      fileCount: selectedFiles.length,
      creditAvailable: credits.availableCredits,
      credits: credits.totalCredits,
      organizationId,
    })
  }

  const createOutline = async (additionalInfo?: string) => {
    setOutlineLoading(true)

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'automatic',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const payload = {
      title: magicData.title,
      description: magicData.description,
      describeChanges: describeChanges,
      additionalInfo: additionalInfo,
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      audience: magicData.audience,
      voice: magicData.voice,
      previousOutline: outlineData || null,
    }

    const response = await createProgramOutline({
      organizationId,
      inputData: {
        title: payload.title,
        description: payload.description,
        describeChanges: payload.describeChanges,
        additionalInfo: payload.additionalInfo,
        useCase: payload.useCase,
        language: payload.language,
        courseLength: payload.programLength,
        audience: payload.audience,
        voice: payload.voice,
        previousOutline: payload.previousOutline,
      },
      uploadedFiles: magicData.uploadedFilesData,
      token: appState.loggedInAs.token,
    })

    setOutlineData(response.data)

    setOutlineLoading(false)
    track({
      event: typeOfCreator,
      variables: {
        organizationId,

      }
    })
  }

  return ( <div className={`${!expanded && 'p-8'} sm:pr-20 relative h-[99.9%] overflow-auto`} style={whiteLabelData ? { backgroundColor: whiteLabelData.bgColor || '#f9f7f2' } : undefined}>
    {typeOfCreator === 'course' && onPrevious && programStep === 1 ? <button className="flex gap-2 text-actions-multiple_choice font-bold mb-8" onClick={() => {
      onPrevious()
      if (showZendeskWidget) {
        showZendeskWidget()
      }
      }}>
      <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
    </button> : typeOfCreator === 'course' && <button className="flex gap-2 text-actions-multiple_choice font-bold mb-8" onClick={() => setProgramStep(1)}>
      <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
    </button>
    }
    <div className="flex items-center justify-start w-full space-x-4 mb-2">
      <img className="w-5 h-5 sm:w-7 sm:h-7" src="/images/wand-magic-sparkles.svg" alt="magic wand" />
      <div>
        <div className={`${typeOfCreator === 'course' ? 'text-3xl': 'text-lg sm:text-2xl'}`}>{typeOfCreator === 'action' ? intl.get('magic_action_creator') : typeOfCreator === 'chapter' ? intl.get('magic_session_creator') : 'Magic Program Creator'}</div>
      </div>
    </div>
    <div className={`${typeOfCreator === 'course' ? 'text-sm': 'text-xs sm:text-sm'}`}>{typeOfCreator === 'action' ? intl.get('magic_action_creator_description') : typeOfCreator === 'chapter' ? intl.get('magic_session_creator_description') : 'Automatically create an editable, interactice program by using prompts or uploading a file, video or link.'}</div>
    {typeOfCreator !== 'course' &&
    <div className="my-5">
      <button data-test="provide-more-details" className="flex text-sm sm:text-base items-center justify-center" onClick={() => {
        trackButtonEnhanced({
          button: 'Provide More Details',
          onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
        })
        setShowDetails(!showDetails)
        }}>
          {intl.get('magic_provide_more_details')}
        <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showDetails ? 'rotate-180' : ''}`} />
      </button>
    </div>}
    {showDetails && programStep === 1 && (
      <div className="mt-6 mb-8">
        <form className="space-y-6">
          <div className={wrapperInputCn}>
            <div className="flex flex-row space-x-2">
              <label htmlFor="magicActionTitle" className={labelCn}>{typeOfCreator === 'action' ? intl.get('global_action_title') : typeOfCreator === 'chapter' ? intl.get('chapter_placeholder_title') : 'Program Title'}</label>
              <AdditionalInfo tooltipContent={intl.get('magic_action_title_description')} />
            </div>
            <input
              name="title"
              id="magicActionTitle"
              placeholder={typeOfCreator === 'action' ? intl.get('global_action_title') : typeOfCreator === 'chapter' ? intl.get('chapter_placeholder_title') : 'Program Title'}
              className={inputAreaCn}
              type="text"
              maxLength={200}
              value={magicData.title || ''}
              onChange={(e) => handleInputChange(e)}/>
              <div className='text-xs text-gray-500 text-right'>{magicData.title.length || 0}/200</div>
          </div>
          {typeOfCreator === 'course' && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator="course" courseId={courseId} organizationId={organizationId} />}
          {typeOfCreator === 'action' && <MagicSelect
            name="type"
            labelText={intl.get('magic_choose_action_type')}
            content={Object.entries(contentTypes)}
            onChange={(e) => handleInputChange(e)}
            selectedValue={magicData.type}
          />}
          <div className={wrapperInputCn}>
            <label htmlFor="magicDescription" className={labelCn}>{typeOfCreator === 'course' ? 'Describe what you want your program to look like' : intl.get('global_description')}</label>
            <textarea
              name="description"
              id="magicDescription"
              placeholder={typeOfCreator === 'action' ? intl.get('action_description') : typeOfCreator === 'chapter' ? intl.get('session_description') : 'Add program goals, learning outcomes, ideas of topics to cover and more!'}
              className={`${inputAreaCn} min-h-[130px]`}
              value={magicData.description || ''}
              onChange={(e) => handleInputChange(e)}
              maxLength={1000}
            />
            <div className='text-xs text-gray-500 text-right'>{magicData.description.length}/1000</div>
          </div>
          <div>
            {typeOfCreator === 'chapter' && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
          </div>
          <button data-test="more-options" className="flex text-sm sm:text-base items-center justify-center mt-2" onClick={(e) => {
            e.preventDefault()
            trackButtonEnhanced({
              button: 'More Options',
              onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
            })
            setShowOptions(!showOptions)
          }}>
            {intl.get('magic_more_options')}
            <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showOptions ? 'rotate-180' : ''}`} />
          </button>
          {showOptions && (
            <div>
              <div className='mb-4'>
                {typeOfCreator === 'action' && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 mb-8">
                <MagicSelect
                  name="useCase"
                  labelText={intl.get('magic_use_case')}
                  content={Object.entries(useCases)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.useCase}
                />
                <MagicSelect
                  name="language"
                  labelText={intl.get('magic_language')}
                  content={Object.entries(languages)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.language}
                />
                {
                  (showProgramLength || showSessionLength) && (
                    <MagicSelect
                    name="programLength"
                    labelText={showProgramLength ? intl.get('magic_program_length') : intl.get('magic_session_length')}
                    content={Object.entries(programLength)}
                    onChange={(e) => handleInputChange(e)}
                    selectedValue={magicData.programLength}
                  />
                  )
                }
              </div>
              {typeOfCreator === 'course' && <div className="flex flex-col sm:flex-row space-y-2 items-center justify-between">
                {showAudience && <div className={wrapperInputCn}>
                  <div className="flex flex-col sm:flex-row space-x-2">
                      <label htmlFor="magicAudience" className={labelCn}>Audience</label>
                    </div>
                    <input
                      name="audience"
                      id="magicAudience"
                      placeholder="Describe your target audience here"
                      className={`${inputAreaCn} w-72`}
                      type="text"
                      maxLength={200}
                      value={magicData.audience || ''}
                      onChange={(e) => handleInputChange(e)}/>
                      {/* <div className='text-xs text-gray-500 text-right'>{magicData.audience.length || 0}/20</div> */}
                  </div>}
                {showVoice && <MagicSelect
                  name="voice"
                  labelText="Ton of Voice"
                  content={Object.entries(voice)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.voice}
                />}
              </div>}
              <div className="mt-4">
                <MagicCheckbox name='Generate Images' setMagicData={setMagicData} fieldKey='generateAiImages' />
              </div>
            </div>
          )}
        </form>
      </div>
    )}

    {programStep === 2 && <div className="mt-6 mb-8">
      <form>
        <div className="flex flex-col">
          <div className="flex flex-col space-y-2 mb-2">
            <div className={`${labelCn} mt-2.5`}>Program Outline Preview</div>
            <div className="text-sm">Here is the program description we created for you! Please edit anything you would like to change by using the suggested prompts or the text box below, and once you are ready, click "create now"!</div>
          </div>
          <div className={`bg-[#808084] rounded-lg pl-8 pr-4 py-10 overflow-hidden ${expanded && 'absolute top-0 h-full sm:h-screen w-full rounded-none'}`}>
            {!outlineLoading && <div className="relative">
              {!expanded
                ? <img className="w-5 h-5 absolute -top-7 right-0 cursor-pointer" src="/images/open_in_full.svg" alt="open full image" onClick={() => setExpanded(true)} />
                : <img className="w-5 h-5 absolute -top-7 right-0 cursor-pointer" src="/images/minus.svg" alt="close full image" onClick={() => setExpanded(false)} />
                }
            </div>}
            {outlineLoading && <div className="h-96 -ml-5 -mt-5 mb-5">
              <Lottie animationData={outlineSkeleton} />
            </div>}
            {outlineData && <OutlineContent rawData={outlineData} expanded={expanded} setMagicData={setMagicData} />}
          </div>
        </div>
      </form>
      <div className="flex flex-col sm:flex-row w-full items-center gap-y-3 sm:gap-x-3 justify-between mt-2">
        <div className="flex gap-x-3">
          <Button text="Regenerate" className={recreateOutlineBtns} onClick={() => {
            setOutlineData('')
            createOutline('Regenerate')
            trackButtonEnhanced({
              button: 'Regenerate',
              onScreen: 'Magic Program Creator',
            })
            }} />
          <Button text="Make it more fun" className={recreateOutlineBtns} onClick={() => {
            setOutlineData('')
            createOutline('Make it more fun')
            trackButtonEnhanced({
              button: 'Make It More Fun',
              onScreen: 'Magic Program Creator',
            })
            }} />
        </div>
        <div className="flex gap-x-3">
          <Button text="Make it more professional" className={recreateOutlineBtns} onClick={() => {
            setOutlineData('')
            createOutline('Make it more professional')
            trackButtonEnhanced({
              button: 'Make It More Professional',
              onScreen: 'Magic Program Creator',
            })
            }} />
          <Button text="Add Another Session" className={recreateOutlineBtns} onClick={() => {
            setOutlineData('')
            createOutline('Add One More Session')
            trackButtonEnhanced({
              button: 'Add Another Session',
              onScreen: 'Magic Program Creator',
            })
            }} />
        </div>
      </div>
      <div className={`${wrapperInputCn} mt-6 ${!expanded && 'relative'}`}>
        <div className={`${!expanded && 'absolute'} bottom-10 right-3 ${describeChanges ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={() => {
          if (!describeChanges) return
          setOutlineData('')
          createOutline(describeChanges)
          setDescribeChanges('')
          trackButtonEnhanced({
            button: 'Send Outline Changes Icon',
            onScreen: 'Magic Program Creator',
          })
          }}>
            {describeChanges ? <img src="/images/send-magic-button.svg" alt="send button" /> : <img src="/images/send-button.svg" alt="send button" />}
        </div>
        <label htmlFor="magicDescribeChanges" className={labelCn}>Describe Anything You Would Like To Change Above</label>
        <textarea
          name="describeChanges"
          id="magicDescribeChanges"
          placeholder="Describe any changes here"
          className={`${inputAreaCn} min-h-[144px]`}
          value={describeChanges || ''}
          onChange={(e) => setDescribeChanges(e.target.value)}
          maxLength={1000}
        />
        <div className='text-xs text-gray-500 text-right'>{describeChanges.length}/1000</div>
      </div>
    </div>}

    <div className="flex items-center justify-between mt-10 space-x-4">
      <MagicCredits
        magicData={magicData}
        creditsTokens={credits}
        setCredits={setCredits}
        setShowCreditsIndicator={setShowCreditsIndicator}
        prompt={''}
        courseId={courseId}
        organizationId={organizationId}
        type={typeOfCreator}
        programLength={magicData.programLength}
      />
      {typeOfCreator === 'course' && programStep === 1 ? <Button
        text="Next"
        type="magic"
        className="px-3 sm:px-6"
        disabled={!magicData.title}
        onClick={() => {
          setOutlineData('')
          createOutline()
          setProgramStep(2)
          trackButtonEnhanced({
            button: 'Next',
            onScreen: 'Magic Program Creator',
          })
        }}
      /> : typeOfCreator === 'course' && programStep === 2 && <Button
        text={intl.get('magic_create_now')}
        type="magic"
        className="px-3 sm:px-6"
        disabled={disableCreateButton || outlineLoading}
        onClick={() => {
          createMagicProgram()
          trackButtonEnhanced({
            button: 'Create Now',
            onScreen: 'Magic Program Creator',
          })
        }}
        svgIcon={<MagicWandSparklesIcon className="fill-white mr-4 h-" />}
        />}
      {typeOfCreator !== 'course' && <Button
        text={intl.get('magic_create_now')}
        type="magic"
        className="px-3 sm:px-6"
        disabled={disableCreateButton}
        onClick={() => {
          handleMagicActionCreate()
          trackButtonEnhanced({
            button: 'Create Now',
            onScreen: `'Magic ${typeOfCreator} Creator'`,
          })
        }}
        svgIcon={<MagicWandSparklesIcon className="fill-white mr-4" />}
        />}
    </div>
  </div>
  )
}

export default MagicCreator