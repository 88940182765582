import React from 'react'
import { IconProps } from './CheckGnowbeIcon'

const MagicStarsIcon = ({className}: IconProps) => {
  return ( <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" width="29" height="29" fill="none" className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" height="29" width="29" viewBox="0 0 24 24" fill="#fff" x="0" y="0" opacity="100%">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm0 6-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25zm-7.5-5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zm-1.51 3.49L9 15.17l-.99-2.18L5.83 12l2.18-.99L9 8.83l.99 2.18 2.18.99-2.18.99z"></path>
    </svg>
    <defs>
      <filter id="filter_dshadow_10_0_2_0000001a" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="bg-fix"></feFlood>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="alpha"></feColorMatrix>
        <feOffset dx="0" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="5"></feGaussianBlur>
        <feComposite in2="alpha" operator="out"></feComposite>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
        <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_10_0_2_0000001a"></feBlend>
        <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_10_0_2_0000001a" result="shape"></feBlend>
      </filter>
    </defs>
  </svg> )
}

export default MagicStarsIcon