import { gql } from '@apollo/client'
import { User } from 'graphql/schemas/user/User'
import { Course } from 'graphql/schemas/course/Course'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { progressReviewFragment } from 'graphql/fragments/Reviews'
import { userBasicFragment } from 'graphql/fragments/User'
import { subsDistributionParamsFragment } from 'graphql/fragments/Company'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { appStateFragment } from 'graphql/fragments/AppState'
import { progressFragment } from 'graphql/fragments/Progress'

export interface GQLUserResponse {
  user: User;
}

export interface GQLUserVariables {
  userId: string;
}

enum DisabledNotifications {
  PUSH_PRIVATE_MSGS,
  PUSH_CURATOR_MSGS,
  PUSH_COMMENTS_LIKES,
  PUSH_MENTIONS,
  PUSH_DAILY_COURSE_MSGS,
  PUSH_INACTIVITY,
  PUSH_GROUP_AGENT_MSGS,
  EMAIL_DAILY_COURSE_MSGS,
  EMAIL_COURSE_COMPLETION_MSGS,
  EMAIL_WEEKLY_JOURNEY,
  EMAIL_ACTIVITY_NOTIFICATIONS,
  EMAIL_GROUP_AGENT_MSGS,
  EMAIL_MONTLY_AGENT_REPORT,
}

export const getUserQuery = gql`
  query GetUser ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      email
      phoneNumber
      language
      roles
      editorCourses
      mEditorCourses
      agentCompanies
      editorOrganizations
      editorCompanies
      billingManagerOrganizations
      editorCourseNotifications {
        userId
        courseId
        distributionStatusUpdates
      }
      allowCourseCreation
      subscriptions
      metadata {
        general
      }
      profile {
        id
        firstName
        lastName
        fullName
        imageUrl
        timezone
        currentSubscription
      }
    }
  }
`

export type GetCurrentSubscriptionVars = {
  userId: string;
}

export type GetCurrentSubscriptionRes = {
  user: User;
}

export const purchaseSubscriptionFragment = gql`
  fragment PurchaseSubscriptionFragment on PurchaseSubscription {
    billingCycle
    bundleId
    expiresAt
    nextPaymentAt
    paymentType
    status
    subscriptionId
    trialExpiresAt
    price
    priceCurrency
    inappProductId
    name
  }
`

export const getCurrentSubscriptionQuery = gql`
  ${purchaseSubscriptionFragment}

  query GetCurrentSubscription ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      email
      phoneNumber
      language
      roles
      editorCourses
      editorOrganizations
      editorCompanies
      mEditorCourses
      billingManagerOrganizations
      agentCompanies
      managerOrganizations
      archivedSubscriptions
      subscriptions
      favoritedSubscriptions
      pinnedCompanyIds
      features
      allowCourseCreation
      secondsSpent
      completedChaptersCount
      completedActionsCount
      onboardingCourse {
        title
        joinLink
      }
      purchases {
        subscriptions {
          ...PurchaseSubscriptionFragment
        }
      }
      policies {
        id
        title
        documentUrl
      }
      authProviders {
        id
        name
        profileUrl
        forbidEmailChange
        forbidNameChange
      }
      metadata {
        appInstalled
        general
      }
      profile {
        id
        firstName
        lastName
        fullName
        imageUrl
        timezone
        currentSubscription
      }
      anonymitySettings {
        companyId
        anonymitySettings
      }
      favoritedSubscriptions
      pinnedCompanyIds
      creatorInOrganizations
      memberOfOrganizations
    }
  }
`

export type GetUserAndActiveCourseVariables = {
  companyId: string;
  courseId: string;
  userId: string;
}

export type GetUserAndActiveCourseResponse = {
  course: Course;
  subscription: UserSubscription;
  app: {
    activeChapterId: string;
    currentCompanyIdCourseId: string;
    isEditing: boolean;
    isHelpEnabled: boolean;
  };
}

// main query
export const getUserAndActiveCourseQuery = gql`
  ${progressReviewFragment}
  ${subsDistributionParamsFragment}
  ${timestampsFragment}
  ${appStateFragment}
  ${progressFragment}

  query GetUserAndActiveCourse ($companyId: String!, $courseId: String!, $userId: String!) {
    course: getCourse (courseId: $courseId) {
      id
      title
      chaptersUnlocked
      curatorChatEnabled
      workbookDisabled
      imageUrl
      hasAssessments
      testCompany {
        id
        accessCodes
      }
      canRetakeActionAssessments
      maxActionAssessmentsRetakes
      passingGrade
      graduationGrade
      curatorName
      curatorTitle
      curatorCompany
      chaptersCount
      description
      curatorBio
      curatorPhotoUrl
      denyContentCopy
      allowMediaDownload
      showActionAssessmentsResultsPerAction
      manualReviews
      type
      hasAssessments
      timestamps {
        ...TimestampsFragment
      }
      translatedToLanguage
      creatingAIContent
      creatingAIChapterContent
    }
    subscription: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      courseId
      companyId
      company {
        id
        organizationId
        organizationName
        accessCodes
        tags
        name
        groupName
        membersListEnabled
        certificateSigner
        companyType
        greetingsDisabled
        wallDisabled
        peerInvitationMode
        usersCount
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
      }
      progress {
        ...ProgressFragment
      }
    }
    app: appState @client {
      ...AppStateFragment
    }
  }

`

export const getUserProfile = gql`
  query GetUserProfile ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      profile {
        id
        fullName
        imageUrl
      }
      secondsSpent
      completedChaptersCount
      completedActionsCount
      linkedInUrl
      completedCourses {
        companyId
        courseId
        completedAt
        invisible
        company {
          id
          name
        }
        course {
          id
          title
          imageUrl
          curatorName
          chaptersCount
        }
      }
    }
  }
`

export type SubMinimal = {
  company: {
    id: string;
  };
  course: {
    id: string;
  };
}

export type GetUserSubs = {
  user: {
    id: string;
    roles: string[];
    profile: {
      currentSubscription: string;
    };
  };
  subs: SubMinimal[];
}

export const getUserSubs = gql`
  ${subsDistributionParamsFragment}
  ${progressFragment}

  query GetUserSubs ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      roles
      profile {
        id
        currentSubscription
      }
    }
    subs: getUserSubscriptions (userId: $userId, archived: false) {
      id
      company {
        id
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
      }
      course {
        id
      }
      progress {
        ...ProgressFragment
      }
    }
    app: appState @client {
      currentCompanyIdCourseId
      isEditing
      isLoading
      isHelpEnabled
      isCreatingCourse
      switchToAddedCourse
      isDuplicatingCourse
      isImportingPpt
    }
  }
`

export const getUserAgentCompanies = gql`
  query GetUserAgentCompanies ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      agentCompanies
    }
  }
`

export const getUserBasicQuery = gql`
  ${userBasicFragment}

  query GetUserBasic ($userId: String!) {
    user: getUser (userId: $userId) {
      ...UserBasicFragment
    }
  }
`

export const getUserAnonymitySettingsQuery = gql`
  query GetUserAnonymitySettings ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      anonymitySettings {
        companyId
        anonymitySettings
      }
    }
  }
`

export const getUserDisabledNotificationsQuery = gql`
  query GetUserDisabledNotifications ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      disabledNotifications
      disabledSubsNotifications
    }
    subs: getUserSubscriptions (userId: $userId, archived: false) {
      id
      company {
        id
        name
      }
      course {
        id
        title
        imageUrl
      }
    }
  }
`

export const getUserEditorCourses = gql`
  query GetUserEditorCourses ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      editorCourses
      mEditorCourses
      agentCompanies
      editorOrganizations
      editorCompanies
      managerOrganizations
    }
  }
`

export type GetUserMetadataRes = {
  user?: {
    id: string;
    metadata?: {
      general?: any;
    };
  };
}

export const getUserMetadata = gql`
  query GetUserMetadata ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      metadata {
        general
      }
    }
  }
`

export const getActiveCourseQuery = gql`
  query GetUserAndActiveCourse ($courseId: String!) {
    course: getCourse (courseId: $courseId) {
      id
      title
    }
  }
`

export const getUserLanguageQuery = gql`
  query GetUser ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      language
    }
  }
`

export type GetUserPurchasesRes = {
  user: Pick<User, 'id'|'purchases'>;
}

export const getUserPurchasesQuery = gql`
  ${purchaseSubscriptionFragment}

  query GetCurrentSubscription ($userId: String!) {
    user: getUser (userId: $userId) {
      id
      purchases {
        subscriptions {
          ...PurchaseSubscriptionFragment
        }
      }
    }
  }
`
