import intl from 'react-intl-universal'

export const manageProgramTourSteps = [
  {
    selector: '#first-distrib-checkbox',
    content: 'Select the group(s) that you want to share this program to.',
  },
  {
    selector: '#distribute-btn',
    content: 'Click the "Save changes" button to save your changes.',
  },
  {
    selector: '#first-distrib-actions',
    content: 'Click the Actions button to invite users to your group.',
  },
]
