import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps';
import { UserEngagement } from 'graphql/schemas/user/UserEngagement';

// export const engagementProctorResultsFragment = gql`
//   fragment EngagementProctorResultsFragment on EngagementProctorResults {
//     status
//     durationInMinutes
//     startedAt
//     endedAt
//     score
//     comment
//   }
// `

export type GetEngagementsForSubscriptionVars = {
  companyId: string;
  courseId: string;
  userId: string;
}


export type GetEngagementsForSubscriptionRes = {
  engagement: UserEngagement[];
}

// ${engagementProctorResultsFragment}

export const getEngagementsForSubscriptionQuery = gql`
  ${timestampsFragment}

  query GetEngagementsForSubscription ($companyId: String!, $courseId: String!, $userId: String!) {
    engagement: getEngagementsForSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      courseId
      chapterId
      engagement
      assessmentsStartedAt
      assessmentsEnded
      # assessmentsHasProctoring
      # assessmentsProctorResults {
      #  ...EngagementProctorResultsFragment
      #}
      timestamps {
        ...TimestampsFragment
      }
      reviewTimestamps {
        ...TimestampsFragment
      }
    }
  }
`
