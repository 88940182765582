import React, { useEffect } from 'react'
import intl from 'react-intl-universal'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getPostsLikesForSubscriptionQuery } from 'graphql/queries/group/getPostsForSubscription'
import { AppState } from 'graphql/schemas/app/State'
import { PostLike } from 'graphql/schemas/group/PostLike'
import { localeFormatDistanceStrict } from 'utils/dateFunctions'
import { ShareKeyData, shareLikeSeen } from 'actions/likes_actions'
import ProfilePicture from 'components/utils/ProfilePicture'
import { MiniLoader } from 'components/utils/MiniLoader'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { history } from 'utils/history'


type MiniPostLikesProps = {
  shareKey: ShareKeyData;
}

const PostLikes = () => {
  const navigate = useNavigate()
  const params = useParams() as {actionId: string, chapterId: string, userId: string};
  const { data: appData, loading: appLoading } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const [getLikes, { data: likesData, loading: likesLoading }] = useLazyQuery<{ likes: PostLike[] }>(getPostsLikesForSubscriptionQuery, {
    variables: {
      companyId,
      courseId,
      chapterId: params.chapterId,
      actionId: params.actionId,
      userId: params.userId,
      limit: 100,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getLikes()

    const share = { companyId, courseId, chapterId: params.chapterId, actionId: params.actionId }
    shareLikeSeen(share)
  }, [appData])

  if (!appData || likesLoading || !likesData) return <MiniLoader />

  const { likes } = likesData
  const str = `${companyId}-${courseId}-${params.chapterId}-${params.actionId}-${params.userId}`

  return (
    <div className="container">
      <div className="w-full">
        <div className="page activity">
          <div className="flex items-center mt-4 mb-8">
            <span className="inline-block p-4" onClick={() => navigate(-1)}>
              <img src="/images/icon-back-red.png" className="cursor-pointer" />
            </span>

            <h2 className="flex-1 text-center m-0">
              {intl.get('activity_likes')}
            </h2>

            <button className="text-coral" onClick={() => navigate(`/learn/share/${str}`)}>
              {intl.get('activity_view_share')}
            </button>
          </div>

          {likes && likes.length > 0
            ? <div>
                <ul className="divide-y divide-gray-200">
                  {likes.map((like) => {
                    const userLiked = like.userLiked
                    if (!userLiked) return null

                    return (
                      <div className="flex items-center py-3" key={like.userIdLiked}>
                        <span className="inline-block mr-4">
                          <ProfilePicture
                            userId={userLiked.id}
                            inUser={userLiked}
                            additionalClass="w-8 h-8"
                          />
                        </span>

                        <div className="flex-1">
                          <strong className="cursor-pointer" onClick={() => navigate(`/profile/${like.userIdLiked}`)}>
                            {userLiked.profile.fullName}
                          </strong>
                        </div>

                        <span className="text-medgray">{localeFormatDistanceStrict(like.likedAt, Date.now())}</span>
                      </div>
                    )
                  })}
                </ul>
              </div>
            : <div className="text-center">
                No likes.
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PostLikes

export const MiniPostLikes = (props: MiniPostLikesProps) => {
  const navigate = history.navigate || useNavigate()
  const { data: appData, loading: appLoading } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const [getLikes, { data: likesData, loading: likesLoading }] = useLazyQuery<{ likes: PostLike[] }>(getPostsLikesForSubscriptionQuery, {
    variables: {
      companyId,
      courseId,
      chapterId: props.shareKey.chapterId,
      actionId: props.shareKey.actionId,
      userId: props.shareKey.userId,
      limit: 100,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getLikes()

    const share = { companyId, courseId, chapterId: props.shareKey.chapterId, actionId: props.shareKey.actionId }
    shareLikeSeen(share)
  }, [appData])

  if (!appData || likesLoading || !likesData) return <MiniLoader />

  const { likes } = likesData
  const str = `${companyId}-${courseId}-${props.shareKey.chapterId}-${props.shareKey.actionId}-${props.shareKey.userId}`

  return (
    <div>
      <ul className="divide-y divide-gray-200 ml-0">
        {likes.map((like) => {
          const userLiked = like.userLiked
          if (!userLiked) return null

          return (
            <div className="flex items-center py-3" key={like.userIdLiked}>
              <span className="inline-block mr-4">
                <ProfilePicture
                  userId={userLiked.id}
                  inUser={userLiked}
                  additionalClass="w-8 h-8"
                />
              </span>

              <div className="flex-1">
                <strong className="cursor-pointer" onClick={() => navigate(`/profile/${like.userIdLiked}`)}>
                  {userLiked.profile.fullName}
                </strong>
              </div>

              <span className="text-medgray">{localeFormatDistanceStrict(like.likedAt, Date.now())}</span>
            </div>
          )
        })}
      </ul>
    </div>
  )
}
