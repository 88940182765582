import gql from 'graphql-tag'

export type InviteCompanyUserVars = {
  noEmail: boolean;
  userIdOrEmail: string;
  companyId: string;
}

export type InviteCompanyUserRes = {
  success: boolean;
}

export const inviteCompanyUserMutation = gql`
  mutation InviteCompanyUser ($companyId: String!, $userIdOrEmail: String!, $noEmail: Boolean!) {
    success: inviteCompanyUser (companyId: $companyId, userIdOrEmail: $userIdOrEmail, noEmail: $noEmail)
  }
`
