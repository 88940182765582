import React, { Fragment, useEffect, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { Popover } from 'react-tiny-popover'
import { Transition } from '@headlessui/react'
import { showModal } from 'components/utils/CustomModal'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import { UpsellModal } from 'components/modals/UpsellModal'
import Lock from 'components/icons/Lock'
import { changeCurrentCourse } from 'actions/users_actions'
import { Company } from 'graphql/schemas/company/Company'
import { getCourseCompanies } from 'graphql/schemas/user/UserSubscription'
import { Invite } from 'components/navbar/course-list/Header'
import { useNavigate } from 'react-router-dom'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'
import { useQuery } from '@apollo/client'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { useWhiteLabel } from 'context/whiteLabel'

type MoreButtonProps = {
  sidebar?: boolean;
  companyId: string;
  courseId: string;
  courseTitle: string;
  canInvite: boolean;
  isEditor: boolean;
  groupName: string;
  isAgent: boolean;
  isAdmin?: boolean;
  isIndependentEditor?: boolean;
  isSandbox?: boolean;
  menuClassName?: string;
}

export const MoreButton = ({ sidebar, companyId, courseId, canInvite, isEditor, isAgent, isIndependentEditor, isSandbox, menuClassName }: MoreButtonProps) => {
  const navigate = useNavigate()
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const [isOpen, setIsOpen] = useState(false)
  const selectedCompany = useRef<Company|undefined>(undefined)

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const userId = appData?.appState.loggedInAs.uid || ''
  const { data: companiesData, loading } = useQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>(getUserSubscriptions2Query, { variables: {
    userId,
    archived: false,
  }})


  useEffect(() => {
    const companies = companiesData ? getCourseCompanies(companiesData, courseId) : [];

    if (companies.length > 0) {
      selectedCompany.current = companies[0];
    }
  }, [companiesData, courseId]);

  if (loading || !companiesData) {
    return null
  }

  const menuCn = classNames({
    'w-52 custom-dropdown z-10': true,
    [menuClassName || '']: true,
  })

  // const changeCourse = () => {
  //   if (`${companyId}-${courseId}` !== appData?.appState.currentCompanyIdCourseId) {
  //     return showModal({
  //       title: intl.get('ask_change_course'),
  //       component: (
  //         <p>{intl.getHTML('dialog_change_subscription_description', { 0: courseTitle || 'Unknown' })}</p>
  //       ),
  //       backgroundAction: () => changeCurrentCourse({ courseKey: { companyId, courseId }, landing: `/details/${companyId}-${courseId}`, enableEditing: true }),
  //       backgroundText: intl.get('yes'),
  //       secondaryText: intl.get('no'),
  //     })
  //   }

  //   history.push(`/details/${companyId}-${courseId}`)
  //   updateAppCache('isEditing', 'true')
  // }

  return (
    <Popover
      isOpen={isOpen}
      positions={['left', 'right']}
      padding={10}
      align="start"
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <Transition
          show={true}
          appear={true}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className={menuCn}>
            {/* {isEditor && (
              <button
                onClick={changeCourse}
                className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 focus:text-coral hover:bg-gray-100 hover:text-gray-900">
                <i className="icon icon-pencil-light -mt-0.5 text-lg w-8" /> Edit program
              </button>
            )} */}

            {!isEditor && (
              <button
                onClick={() => {
                  setIsOpen(false)
                  trackButtonEnhanced({
                    button: 'View Program Details',
                    onScreen: 'Program Card',
                  })
                  navigate(`/details/${courseId}`)
                }}
                className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 focus:text-coral hover:bg-gray-100 hover:text-gray-900">
                <i className="icon icon-info -mt-0.5 text-lg w-8" /> {intl.get('program_menu_details')}
              </button>
            )}

            {isEditor && (
              <div>
                <button
                  onClick={() => {
                    setIsOpen(false)

                    if (isIndependentEditor) {
                      const modal = showModal({
                        title: intl.get('home_group_invite'),
                        component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                        onlyContent: true,
                        className: 'add-action-modal',
                      })
                      return
                    }

                    trackButtonEnhanced({
                      button: 'Manage Program',
                      onScreen: 'Program Card',
                    })

                    // window.location.href = `${BASE_DASHBOARD_URL}/program/${courseId}/status?referrer=${encodeURIComponent(window.location.href)}`
                    changeCurrentCourse({ courseKey: { companyId, courseId }, landing: '/home/publish' })
                  }}
                  className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                  <i className="icon icon-cog-light -mt-0.5 text-xl w-8" /> {intl.get('global_manage_program')}
                  {isIndependentEditor && (
                    <Lock className="ml-2 w-3 h-3 text-gray-600" />
                  )}
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false)

                    if (isIndependentEditor) {
                      const modal = showModal({
                        title: intl.get('home_group_invite'),
                        component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                        onlyContent: true,
                        className: 'add-action-modal',
                      })
                      return
                    }

                    trackButtonEnhanced({
                      button: 'Share Program',
                      onScreen: 'Program Card',
                    })

                    changeCurrentCourse({ courseKey: { companyId, courseId }, landing: '/home/publish#share' })
                  }}
                  className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                  <i className="icon icon-share-light -mt-2 text-xl w-8" /> {intl.get('program_share_sandbox')}
                  {isIndependentEditor && (
                    <Lock className="ml-2 w-3 h-3 text-gray-600" />
                  )}
                </button>
              </div>
            )}

            {/* {canInvite && !isSandbox && (
              <button
                onClick={() => {
                  setIsOpen(false)

                  if (isIndependentEditor && !sessionStorage.getItem('invite_upsell_shown')) {
                    sessionStorage.setItem('invite_upsell_shown', 'true')
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                    })
                    return
                  }

                  showModal({
                    title: intl.get('home_group_invite'),
                    component: <ShareCourseModal companies={companies} courseId={courseId} isAgent={isAgent} />,
                    secondaryButton: false,
                    backgroundText: intl.get('global_close'),
                    hideFooter: true,
                    className: 'add-action-modal',
                  })
                }}
                className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                <i className="icon icon-add-participant -mt-0.5 text-xl w-8" /> {intl.get('program_share_sandbox')}
              </button>
            )} */}

            {/* {isEditor && isSandbox && (
              <button
                onClick={() => {
                  setIsOpen(false)

                  if (isIndependentEditor && !sessionStorage.getItem('share_upsell_shown')) {
                    sessionStorage.setItem('share_upsell_shown', 'true')
                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                      onlyContent: true,
                      className: 'add-action-modal',
                    })
                    return
                  }

                  trackButton({
                    button: 'invite_co_curator',
                    on_screen: 'three_dots',
                  })

                  showModal({
                    title: intl.get('invite_co_curator_title'),
                    component: (
                      <ShareCourse
                        courseId={courseId}
                        onClose={() => null}
                      />
                    ),
                    secondaryButton: false,
                    backgroundText: intl.get('global_close'),
                    hideFooter: true,
                  })
                }}
                className="flex items-center h-10 w-full px-4 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                <i className="icon icon-share-light -mt-0.5 w-8" /> {intl.get('invite_co_curator_title')}
              </button>
            )} */}
          </div>
        </Transition>
      )}>

      {sidebar
        ? <button
            className="w-12 h-12 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset focus:ring-actions-multiple_choice"
            aria-label="Program options"
            onClick={() => {
              trackButton({
                button: 'course_menu',
                on_screen: 'edit_course',
              })

              trackButtonEnhanced({
                button: 'Program Menu',
                onScreen: 'Edit Program',
              })

              setIsOpen(!isOpen)
            }}>
            <Tippy placement="right" content={intl.get('more')}>
              <i className="icon icon-more-button mx-auto font-bold w-12 h-12 flex justify-center items-center m-auto hover:text-actions-multiple_choice" />
            </Tippy>
          </button>
        : <button
            style={whiteLabelData.primaryColor ? { '--primary-color': whiteLabelData.primaryColor } as React.CSSProperties : {}}
            className={`w-4 h-4 inline-flex items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-4 group-hover:bg-deadyellow ${whiteLabelData.primaryColor ? 'focus:ring-[var(--primary-color)]': 'focus:ring-actions-multiple_choice'}`}
            aria-label="Program options"
            onClick={() => {
              trackButton({
                button: 'course_menu',
                on_screen: 'home',
              })

              trackButtonEnhanced({
                button: 'Program Menu',
                onScreen: 'Home',
              })

              setIsOpen(!isOpen)
            }}>
            <i className={`icon icon-more-button mx-auto font-bold ${whiteLabelData.accentColor ? 'hover:text-[var(--accent-color)]': 'hover:text-actions-multiple_choice'}`} style={whiteLabelData.primaryColor ? { '--primary-color': whiteLabelData.primaryColor } as React.CSSProperties : {}}/>
          </button>
        }
      </Popover>
  )
}

const ShareCourseModal = ({ companies, courseId, isAgent }) => {
  const [selectedCompany, setSelectedCompany] = useState(companies[0])

  return (
    <>
      <div className="m-4">
        {companies && companies.map((c, i) => {
          return (
            <div key={c.id} className="flex items-center">
              <input id={c.id} type="radio" name="company_picker" value={c.id} className="focus:ring-lake h-4 w-4 text-lake border-gray-300" defaultChecked={c.id === selectedCompany.id} onChange={() => setSelectedCompany(c)} />
              <label htmlFor={c.id} className="ml-3 block font-medium text-gray-700">{c.groupName || c.name}</label>
            </div>
          )
        })}
      </div>

      <Invite key={selectedCompany.id} companyId={selectedCompany.id} courseId={courseId} home={true} groupName={selectedCompany.groupName || selectedCompany.name || 'Unknown'} isAgent={isAgent} />
    </>
  )
}