import { gql } from '@apollo/client'

export type GetScormLaunchLinkVars = {
  companyId: string;
  courseId: string;
  redirectUrl: string;
}

export type GetScormLaunchLinkRes = {
  getScormLaunchLink: string;
}

export const getScormLaunchLinkMutation = gql`
  mutation GetScormLaunchLink ($companyId: String!, $courseId: String!, $redirectUrl: String!) {
    getScormLaunchLink (companyId: $companyId, courseId: $courseId, redirectUrl: $redirectUrl)
  }
`
