import React from 'react'
import intl from 'react-intl-universal'
import { SimpleEditable } from 'components/utils/SimpleEditable'

type ChapterTitleProps = {
  title: string;
  chapterId: string;
  isEditing: boolean;
  update: (v: string) => void;
}

export const ChapterTitle = ({ title, chapterId, isEditing, update }: ChapterTitleProps) => {
  return (
    <h1>
      <SimpleEditable
        key={`${chapterId}-title`}
        defaultValue={title}
        placeholder={intl.get('chapter_placeholder_type_title')}
        fullWidth={true}
        mandatory={true}
        isEditing={isEditing}
        update={update}
        classname="text-center text-2xl sm:text-3xl font-noto text-deepgray"
        help="firehelp_chapter_title"
      />
    </h1>
  )
}
