import React, { ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { client } from 'app'

const FullscreenLoader: () => ReactNode = () =>{
  const intervalRef: any = useRef()

  const app = document.getElementById('app')
  if (!app) return null

  let _counter = 0
  useEffect(() => {
    const id = setInterval(() => {
      _counter += 1
      if (_counter === 15) {
        client.reFetchObservableQueries()
      }
    }, 1000)
    intervalRef.current = id
    return () => {
      clearInterval(intervalRef.current)
    }
  })

  return <>
    {createPortal(
      <div className="loader-overlay">
        <div className="loader" />
      </div>,
      app,
    )}
  </>
}

export default FullscreenLoader