import { gql } from '@apollo/client'
import { Course } from 'graphql/schemas/course/Course'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { User } from 'graphql/schemas/user/User'
import { courseBasicFragment, courseFragment, editorCourseFragment } from 'graphql/fragments/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { subsDistributionParamsFragment } from 'graphql/fragments/Company'
import { Company } from 'graphql/schemas/company/Company'
import { progressFragment } from 'graphql/fragments/Progress'

export type GetCourseWithDataRes = {
  course: Course;
  chapters: Chapter[];
}

export type GetCourseWithDataVars = {
  companyId: string;
  courseId: string;
  returnAllAssessments: boolean;
}

export const getCourseWithDataQuery = gql`
  ${courseFragment}
  ${editorCourseFragment}

  query GetCourseWithData ($companyId: String!, $courseId: String!, $returnAllAssessments: Boolean) {
    course: getCourse (courseId: $courseId) {
      ...CourseFragment
      ...EditorCourseFragment
    }
    chapters: getChaptersForCourse (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments) {
      id
      order
      title
      description
      imageUrl
      imageUrlAlt
      videoUrl
      videoUrlAlt
      disabledUntilDay
      actions {
        id
        contentType
      }
    }
  }
`

export type GetCourseSubDataRes = {
  subscription: {
    isArchived: boolean;
    isLocked: boolean;
    company: Pick<Company, 'id'|'companyType'|'organizationId'|'peerInvitationMode'|'subsDistributionParams'|'groupName'|'name'>;
    progress: Pick<UserProgress, 'currentDay'|'completion'|'assessmentsCompleted'|'assessmentsGrade'|'assessmentsRetakeNum'|'startedAt'>;
  };
}

export type GetCourseSubDataVars = {
  companyId: string;
  courseId: string;
  userId: string;
}

export const getCourseSubDataQuery = gql`
  ${subsDistributionParamsFragment}
  ${progressFragment}

  query GetCourseSubData ($companyId: String!, $courseId: String!, $userId: String!) {
    subscription: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      isArchived
      isGnowbeLearn
      gnowbeLearnBundleIds
      isLocked
      company {
        id
        companyType
        organizationId
        peerInvitationMode
        groupName
        name
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
      }
      progress {
        ...ProgressFragment
      }
    }
  }
`

export type GetCourseAvailabilityRes = {
  course: {
    id: string;
    title: string;
    availableToCompanies: string[];
    availableToOrganizations: string[];
    testCompany: {
      id: string;
      accessCodes: string[]|null;
    }|null;
  };
}

export const getCourseAvailabilityQuery = gql`
  query GetCourseAvailability ($courseId: String!) {
    course: getCourse (courseId: $courseId) {
      id
      title
      availableToCompanies
      availableToOrganizations
      testCompany {
        id
        accessCodes
      }
    }
  }
`

export type GetCourseDetailsRes = {
  course: Course;
}

export type GetCourseDetailsVars = {
  courseId: string;
}

export const getCourseDetailsQuery = gql`
${courseFragment}
${editorCourseFragment}

query GetCourseWithData ($courseId: String!) {
  course: getCourse (courseId: $courseId) {
    ...CourseFragment
    ...EditorCourseFragment
  }
}
`

export type GetCourseBasicDetailsRes = {
  course: Pick<Course, 'id'|'title'|'description'|'tagline'|'chaptersCount'|'chaptersUnlocked'|'curatorName'|'curatorTitle'|'curatorBio'|'curatorPhotoUrl'|'curatorChatEnabled'|'curatorSignatureDisabled'|'curatorCompany'|'imageUrl'|'actionsCount'|'interactionTime'|'allowMediaDownload'|'type'|'showActionAssessmentsResultsPerAction'|'passingGrade'>;
}

export const getCourseBasicDetailsQuery = gql`
  ${courseBasicFragment}

  query GetCourseWithData ($courseId: String!) {
    course: getCourse (courseId: $courseId) {
      ...CourseFragmentBasic
    }
  }
`
