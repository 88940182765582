import React from 'react'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import { useParams } from 'react-router-dom'
import { createMarkup, escapeHtml, escapeRegExp } from 'utils/functions'
import { augmentExplain, augmentGreeting } from 'utils/utils'
import { getUserBasicQuery } from 'graphql/queries/user/getUser'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { UserBasic } from 'graphql/schemas/user/User'
import Editor from './Editor'
import { Help } from './Help'
import { showSave } from './SavingIndicator'
import { act } from 'react-dom/test-utils'

interface AdvancedEditableProps {
  id: string;
  defaultValue: string;
  placeholder: string;
  isEditing: boolean;
  divClassName?: string;
  additionalFormats?: string[];
  showReadingTime?: boolean;
  user: UserBasic;
  isConnected: boolean;
  maxLength?: number;
  help?: string;
  notes?: string;
  readonly?: boolean;
  showAiGenerateText?: boolean;
  update: (v: string) => void;
  courseId: string;
  chapterId: string;
  actionId: string;
  organizationId: string;
  workspaceCanImproveText?: boolean
  divDirection?: React.CSSProperties | {} | undefined;
}

class Editable extends React.PureComponent<AdvancedEditableProps, { value: string, focused: boolean }> {
  private _dirty = false
  private _timeout
  private _isMounted

  constructor(props) {
    super(props)

    this.state = { value: props.defaultValue || '', focused: false }

    this.save = this.save.bind(this)
    this.inputChange = this.inputChange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentDidUpdate(pp, ps) {
    if (!this._dirty && ps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue || '' })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    clearTimeout(this._timeout)
  }

  inputChange(field, value) {
    this._dirty = true
    this.setState({ value })

    this._timeout = clearTimeout(this._timeout)
    this._timeout = setTimeout(() => {
      this.save(false)
    }, 750)
  }

  save(blur = true) {
    blur && this.setState({ focused: false })
    if (!this._dirty) return

    // const cleanText = sanitize(this.state.value.trim(), [])
    showSave()
    this.props.update(this.state.value)
    this._dirty = false
  }

  render() {
    const { id, placeholder, isConnected, isEditing, divClassName, maxLength, additionalFormats, user, showReadingTime, readonly, help, notes, showAiGenerateText, organizationId, workspaceCanImproveText, divDirection } = this.props
    const { value } = this.state

    if (!isEditing) {
      let augmentedText = augmentExplain(value)
      augmentedText = augmentGreeting(augmentedText)
      if (value) {
        augmentedText = augmentedText
          .replace(/{{firstName}}|{{name}}/g, escapeHtml(user?.profile?.firstName || 'First'))
          .replace(/{{lastName}}/g, escapeHtml(user?.profile?.lastName || 'Last'))
          .replace(/{{fullName}}/g, escapeHtml(user?.profile?.fullName || 'First Last'))
      }
      return (
        <AugmentedWithNotes
          className={divClassName}
          defaultText={augmentedText}
          notes={notes}
          divDirection={divDirection}
        />
      )
    }

    return (
      <>
        <Editor
          key={id}
          id={id}
          field={id}
          defaultText={value}
          placeholder={placeholder}
          maxLength={maxLength}
          additionalFormats={additionalFormats}
          isConnected={isConnected}
          showReadingTime={showReadingTime}
          readonly={readonly}
          onFocus={() => this.setState({ focused: true })}
          onChange={this.inputChange}
          onBlur={() => this.save()}
          showAiGenerateText={showAiGenerateText}
          courseId={this.props.courseId}
          chapterId={this.props.chapterId}
          actionId={this.props.actionId}
          userId={user.id}
          organizationId={organizationId}
          workspaceCanImproveText={workspaceCanImproveText}
        />

        {help &&
          <Help
            path={help}
            hide={!this.state.focused}
          />
        }
      </>
    )
  }
}

export const AdvancedEditable: React.ComponentClass<any> = compose(
  graphql(getAppStateQuery, {
    name: 'app',
  }),
  graphql(getUserBasicQuery, {
    skip: (props: any) => !props.app.appState.loggedInAs.uid,
    options: (props: any) => {
      return ({
        variables: {
          userId: props.app.appState.loggedInAs.uid,
        },
        errorPolicy: 'all',
      })
    },
  }),
)((p) => {
  if (!p.data || !p.data.user) {
    return <span>Loading</span>
  }

  const user: UserBasic = p.data.user
  const onlineStatus = true

  return (
    <Editable
      id={p.id}
      readonly={p.readonly}
      defaultValue={p.defaultValue}
      placeholder={p.placeholder}
      isEditing={p.isEditing}
      divClassName={p.divClassName}
      additionalFormats={p.additionalFormats}
      showReadingTime={p.showReadingTime}
      maxLength={p.maxLength}
      isConnected={onlineStatus}
      notes={p.notes}
      update={p.update}
      user={user}
      help={p.help}
      showAiGenerateText={p.showAiGenerateText}
      courseId={p.courseId}
      chapterId={p.chapterId}
      actionId={p.actionId}
      organizationId={p.organizationId}
      workspaceCanImproveText={p.workspaceCanImproveText}
      divDirection={p.divDirection}
    />
  )
})

const AugmentedWithNotes = ({ className, defaultText, notes, divDirection }) => {
  const params = useParams()
  let text = defaultText
  if (notes) {
    const notesArray = notes.split('\n')
    notesArray.map((n) => {
      if (!n) return text

      const [source, type, chapterId, a, b] = n.replace(/[{}]/g, '').split(':')
      if (!params.chapterId || params.chapterId && params.chapterId === chapterId) {
        const decodedNote = decodeURIComponent(a)

        // todo: bug if line starts with &nbsp;
        let lines: any = decodedNote.split('\n').filter(l => l !== '')
        let newLines
        if (lines.length > 0) {
          newLines = lines.map(l => l.replace(/(.*)/, '<mark>$1</mark>'))
          lines.map((l, i) => {
            const e = escapeRegExp(l, false, true)
            const r = new RegExp(`${e}(?!")`, 'g')
            text = text.split('').reverse().join('').replace(r, newLines[i].split('').reverse().join('')).split('').reverse().join('')
          })
        }
        // else {
        //   text = text
        //    .split('')
        //    reverse()
        //    .join('')
        //    .replace(new RegExp(`${decodedNote.split('').reverse().join('')}(?!")`, 'g'), `<mark>${decodedNote}</mark>`.split('').reverse().join('')).split('').reverse().join('')
        // }
      }

      if (params.actionId && params.actionId === a) {
        const decodedNote = decodeURIComponent(b)

        let lines: any = decodedNote.split('\n').filter(l => l !== '')
        let newLines
        if (lines.length > 0) {
          newLines = lines.map(l => l.replace(/(.*)/, '<mark>$1</mark>'))
          lines.map((l, i) => {
            // doesn't work in safari because it doesn't support negative lookbehind :facepalm:
            // const e = escapeRegExp(l)
            // const r = new RegExp(`(?<!")${e}`, 'g')
            const e = escapeRegExp(l, false, true)
            const r = new RegExp(`${e}(?!")`, 'g')
            text = text.split('').reverse().join('').replace(r, newLines[i].split('').reverse().join('')).split('').reverse().join('')
          })
        }
        // else {
        //   text = text.replace(new RegExp(`(?<!")${decodedNote}`, 'g'), `<mark>${decodedNote}</mark>`)
        // }
      }
    })
  }

  text = text.replace('href="www', 'href="https://www')

  const customStyle = {
    ...divDirection
  };

  return (
    <div
      style={customStyle}
      className={`break-words ${className} text-gray-800`}
      dangerouslySetInnerHTML={createMarkup(text, true)}
    />
  )
}
