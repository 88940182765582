import { gql } from '@apollo/client'
import { companyFragment } from 'graphql/fragments/Company'
import { Company } from 'graphql/schemas/company/Company'

export type GetCompaniesAllowedToDistributeCourseToRes = {
  res: {
    companies: Company[];
    companiesCount: number;
  };
}

export type GetCompaniesAllowedToDistributeCourseToVars = {
  userId: string;
  courseId: string;
  organizationId?: string;
  searchText: string;
  skip: number;
  limit: number;
}

export const getCompaniesAllowedToDistributeCourseToQuery = gql`
  ${companyFragment}

  query GetCompaniesAllowedToDistributeCourseTo ($userId: String!, $courseId: String!, $organizationId: String, $searchText: String!, $skip: Int!, $limit: Int!) {
    res: getCompaniesAllowedToDistributeCourseTo (userId: $userId, courseId: $courseId, organizationId: $organizationId, searchText: $searchText, skip: $skip, limit: $limit) {
      companies {
        ...CompanyFragment
      }
      companiesCount
    }
  }
`

export type GetCompanyIdsCourseAlreadyDistributedToRes = { res: string[] }
export type GetCompanyIdsCourseAlreadyDistributedToVars = {
  organizationId?: string;
  courseId: string;
}

export const getCompanyIdsCourseAlreadyDistributedToQuery = gql`
  query GetCompanyIdsCourseAlreadyDistributedTo ($organizationId: String, $courseId: String!) {
    res: getCompanyIdsCourseAlreadyDistributedTo (organizationId: $organizationId, courseId: $courseId)
  }
`
