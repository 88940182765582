import React, { useEffect } from 'react'
import intl from 'react-intl-universal'
import { getLoginUrl, LogoutReason } from 'actions/auth_actions'
import { Button } from 'components/utils/Button'
import { APP_TITLE } from 'constants/General'
import { nl2br, parse } from 'utils/functions'

const Login = () => {
  const searchParams = parse(decodeURIComponent(window.location.href), true)
  const reason: LogoutReason|null = searchParams['reasonCode']?.split('#')[0] as LogoutReason || null

  useEffect(() => {
    if (reason) return

    // setTimeout(() => {
    // login()
    // }, 3000)
  }, [])

  const login = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault()
    const loginUrl = getLoginUrl(window.location.href)
    return window.location.assign(loginUrl)
  }

  return (
    <div className="main-container absolute inset-0 bg-deadyellow pt-16">
      <div className="w-full lg:w-1/2 xl:w-1/3 ml-auto mr-auto col-centered login-box bg-deadyellow shadow-none">
        <div className="text-center">
          <img src="/images/logo.png" />
        </div>

        {reason && reason === 'sessionExpired' && (
          <>
            <h3 className="logo-title py-6 text-eggplant text-2xl">{intl.get('pls_log_in')}</h3>
            <div className="px-6 py-5 bg-red-100 rounded-md mb-8">
              <div dangerouslySetInnerHTML={{ __html: nl2br(intl.get('user_logout_reason_inactivity', { 0: APP_TITLE[process.env.BUILD || 'production'], 1: APP_TITLE[process.env.BUILD || 'production'] })) }} />
            </div>
          </>
        )}

        {!reason && (
          <>
            <h3 className="logo-title py-6 text-eggplant text-2xl">
              Hi there!
            </h3>

            <h4 className="mb-8 font-noto-sans text-base">
              {intl.get('login_redirect_text')}
            </h4>
          </>
        )}

        <div className="text-center">
          <Button
            type="primary"
            text={intl.get('login_text')}
            icon="ml-12"
            rightIcon="mr-12"
            onClick={login}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
