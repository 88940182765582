import { gql } from '@apollo/client'

export type CreateFreeTierOrganizationVars = {
  organizationName?: string;
  userId: string;
}

export const createFreeTierOrganizationMutation = gql`
  mutation CreateFreeTierOrganization(
    $organizationName: String
    $userId: String!
  ) {
    res: createFreeTierOrganization(
      organizationName: $organizationName,
      userId: $userId,
    )
  }
`
