import { gql } from '@apollo/client'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { subsDistributionParamsFragment } from 'graphql/fragments/Company'
import { progressFragment } from 'graphql/fragments/Progress'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { billingServiceFragment } from 'graphql/schemas/organization/Organization'

export type UserSubscriptionsChangesResp = {
  listenUserSubscriptionsChanges: {
    addedSubs: UserSubscription[];
    updatedSubs: UserSubscription[];
    removedSubs: string[];
    queryTimestamp: number;
    companies: Company[];
    courses: Course[];
  };
}

export type UserSubscriptionsChanges = {
  listenUserSubscriptionsChanges: {
    addedSubs: UserSubscription[];
    updatedSubs: UserSubscription[];
    removedSubs: UserSubscription[];
    companies: Company[];
    courses: Course[];
  };
}

export const listenUserSubscriptionsChangesSubscription = gql`
  ${subsDistributionParamsFragment}
  ${progressFragment}
  ${billingServiceFragment}

  subscription ListenUserSubscriptionsChanges ($userId: String!, $archived: Boolean!) {
    listenUserSubscriptionsChanges (userId: $userId, archived: $archived) {
      addedSubs {
        id
        companyId
        courseId
        isArchived
        isGnowbeLearn
        gnowbeLearnBundleIds
        progress {
          ...ProgressFragment
        }
      }
      updatedSubs {
        id
        companyId
        courseId
        isArchived
        isGnowbeLearn
        gnowbeLearnBundleIds
        progress {
          ...ProgressFragment
        }
      }
      removedSubs {
        id
        companyId
        courseId
      }
      companies {
        id
        name
        groupName
        organizationId
        organizationName
        companyType
        usersCount
        tags
        greetingsDisabled
        accessCodes
        peerInvitationMode
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
        organization {
          id
          allowUserAnonymity
          featureFlags
          billingService {
            ...BillingServiceFragment
          }
        }
      }
      courses {
        id
        title
        chaptersCount
        chaptersUnlocked
        curatorName
        curatorPhotoUrl
        curatorChatEnabled
        imageUrl
        isTemplate
        tags
        testCompany {
          id
          accessCodes
        }
        timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
        likesCount
        creatingAIContent
        creatingAIChapterContent
        actionsCount
        interactionTime
      }
    }
  }
`
