import { ShareCourse } from 'components/editor/course/Sidebar';
import { Invite } from 'components/navbar/course-list/Header';
import { Company } from 'graphql/schemas/company/Company';
import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'

const ShareProgramTabs = ({canInvite, company, course, groupName, isAgent, isEditorOfOpenCourse, isPublisher, isIndependentEditor, isGnowbefyButton, location, isEditing, close}: {canInvite: boolean | null, company?: Pick<Company, 'id'|'companyType'|'organizationId'|'peerInvitationMode'|'subsDistributionParams'|'groupName'|'name'> | undefined | null, course: any, groupName?: string, isAgent: boolean, isEditorOfOpenCourse: boolean | undefined, isPublisher: boolean | undefined, isIndependentEditor: boolean | undefined, isGnowbefyButton?: boolean, location?: string, isEditing?: boolean, close?: any}) => {
  const [tabState, setTabState] = useState(true)

  useEffect(() => {
    if ((canInvite && !(company?.companyType === 'test' || false))) {
      setTabState(true)
    }
    if ((isEditorOfOpenCourse && (isPublisher || isIndependentEditor)) && !(canInvite && !(company?.companyType === 'test' || false))) {
      setTabState(false)
    }
  }, [])

  useEffect(() => {
    if (isGnowbefyButton) {
      setTabState(false)
    }
  }, [])

  return (<>
    {(location?.includes('/details') && !isEditing && !location?.includes(course.id)) && <div className="bg-yellow-200 rounded-md p-4 mb-4">{intl.get('dynamic_inviting_to_active_program', {0: course.title, 1: groupName})}</div>}
    <div className="flex gap-3 mt-2">
      <button className={`w-3/12 cursor-pointer py-2.5 ${!tabState && 'text-lightgray'}`} onClick={() => setTabState(true)}>
        {intl.get('share_with_group_invite_learners')}
      </button>
      <button className={`w-3/12 cursor-pointer ${tabState && 'text-lightgray'}`} onClick={() => setTabState(false)}>
        {intl.get('invite_co_curator_title')}
      </button>
      <div className="w-1/2">
      </div>
    </div>
    <div className="w-full flex mb-6">
      <div className={`${tabState ? 'bg-lake h-[2px]' : 'bg-[#E7E8EC] h-[1px]'}  w-3/12`}></div>
      <div className={`${!tabState ? 'bg-lake h-[2px]' : 'bg-[#E7E8EC] h-[1px]'}  w-3/12`}></div>
      <div className="bg-[#E7E8EC] h-[1px] w-1/2"></div>
    </div>
    {tabState && <div className="mt-4 mb-8">
      {(canInvite && !(company?.companyType === 'test' || false))
        ? <Invite companyId={course && course.companyId || company && company.id} close={close} home={true} groupName={groupName} isAgent={isAgent} courseName={course.title} courseId={course.id} />
        : <div className="text-center">
            <div className="mb-9">
              <img className="w-auto inline-block h-12" src="/images/share-icon.svg" />
            </div>
            <div className="font-bold text-2xl px-3 mb-2">{intl.get('invite_learner_permission_1')}</div>
            <p className="px-3">{intl.get('invite_learner_permission_2')}</p>
        </div>}
      </div>
    }
    {!tabState && <div className="mt-4 mb-8">
      {(isEditorOfOpenCourse && (isPublisher || isIndependentEditor))
    ? <ShareCourse
        courseId={course.id}
        onClose={() => null}
        courseName={course.title}
      />
    : <div className="text-center">
      <div className="mb-9">
        <img className="w-auto inline-block h-12" src="/images/share-icon.svg" />
      </div>
      <div className="font-bold text-2xl px-3 mb-2">{intl.get('share_program_permissions_1')}</div>
      <p className="px-3">{intl.get('share_program_permissions_2')}</p>
    </div>}
    </div>
    }
  </>  );
}

export default ShareProgramTabs;