import { ContentType } from 'graphql/schemas/action/Action'
import { UserStudy } from 'graphql/schemas/user/UserStudy'

export function isActionCompleted(contentType: ContentType, placeholderAnswer: string, study: UserStudy|null): boolean {
  if (contentType === 'quote' || contentType === 'certificate') {
    return true
  }

  if (contentType === 'qa') {
    if (study?.answer === placeholderAnswer) return false
    if (study?.answer) return true
    if (study?.reminder?.at && study.reminder.at >= Date.now()) return true
    return false
  }

  if (contentType === 'rating' || contentType === 'photo' || contentType === 'upload') {
    return Boolean(study && (!!study.answer || study?.reminder?.at && study.reminder.at >= Date.now()))
  }

  if (contentType === 'multiple_choice' || contentType === 'assessment' || contentType === 'word_puzzle') {
    if (placeholderAnswer === '') {
      return Boolean(study && study.viewed)
    }

    return Boolean(study && !!study.answer)
  }

  return Boolean(study && study.viewed)
}
