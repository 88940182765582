import { gql } from '@apollo/client'
import { Banner } from 'graphql/schemas/banner/Banner'

export type GetUserNotificationBannersRes = {
  banners: Banner[];
}

export const getUserNotificationBannersQuery = gql`
  query GetUserNotificationBanners {
    banners: getUserNotificationBanners {
      bannerId
      type
      bannerText
      bannerColor
      ctaButton {
        url
        label
      }
      showToPlatform
      showToProduct
      expirationTime
      minRangeCopy
      maxRangeCopy
      dismissForDays
    }
  }
`

