import { gql } from '@apollo/client'
import { companyFragment } from 'graphql/fragments/Company'
import { courseFragment } from 'graphql/fragments/Course'
import { progressFragment } from 'graphql/fragments/Progress'

export const userSubscriptionFragment = gql`
  ${companyFragment}
  ${courseFragment}
  ${progressFragment}

  fragment UserSubscriptionFragment on UserSubscription {
    id
    userId
    companyId
    courseId
    isLocked
    isArchived
    isGnowbeLearn
    gnowbeLearnBundleIds
    gnowbeLearnAvailableAt
    company {
      ...CompanyFragment
    }
    course {
      ...CourseFragment
    }
    progress {
      ...ProgressFragment
    }
  }
`
