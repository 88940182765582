import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { chapterFragment } from 'graphql/fragments/Chapter'
import { profileFragment } from 'graphql/fragments/User'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { User } from 'graphql/schemas/user/User'
import { Chapter, ChapterBasic } from 'graphql/schemas/chapter/Chapter'
import { progressFragment } from 'graphql/fragments/Progress'
import { UserProgress } from 'graphql/schemas/user/UserProgress'

export interface GetChaptersForCurrentCourseResponse {
  company: Pick<Company, 'id'|'name'|'certificateSigner'|'companyType'>;
  course: Pick<Course, 'id'|'title'|'chaptersUnlocked'|'canRetakeActionAssessments'|'maxActionAssessmentsRetakes'|'passingGrade'|'graduationGrade'|'curatorName'|'curatorTitle'|'curatorCompany'|'testCompany'|'timestamps'>;
  chapters: Chapter[];
  progress: {
    userId: string;
    companyId: string;
    progress: Pick<UserProgress, 'currentDay'|'startedAt'|'assessmentsCompleted'|'assessmentsGrade'|'assessmentsRetakeNum'|'teamCurrentDay'|'notes'|'completion'>;
  }
  study: Pick<UserStudy, 'chapterId'|'actionId'|'rewardsSeen'|'contentType'|'viewed'|'answer'|'thumbnail'|'sharedAt'|'commentsCount'|'likesCount'|'commentsLastSeenAt'|'lastCommentAt'|'reminder'|'timestamps'|'isAssessmentCorrect'>;
  user: Pick<User, 'id'|'profile'|'editorCourses'|'metadata'>;
  app: {
    isEditing: boolean;
    isHelpEnabled: boolean;
    activeChapterId: string;
  }
}

export type GetChaptersForCourseRes = {
  chapters: Chapter[];
}

export type GetChaptersForCourseVars = {
  courseId: string;
  companyId?: string;
  returnAllAssessments?: boolean;
}

// Gets only chapters for course (to display on course details screen).
export const getChaptersForCourseQuery = gql`
  ${chapterFragment}

  query GetChaptersForCourse ($companyId: String, $courseId: String!, $returnAllAssessments: Boolean) {
    chapters: getChaptersForCourse (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments) {
      ...ChapterFragment
    }
  }
`

// Gets chapters with actions (to display on chapter screen).
export const getChaptersForCurrentCourseQuery = gql`
  ${timestampsFragment}
  ${chapterFragment}
  ${profileFragment}
  ${progressFragment}

  query GetChaptersForCourse ($companyId: String!, $courseId: String!, $userId: String!, $returnAllAssessments: Boolean) {
    company: getCompany (companyId: $companyId) {
      id
      name
      certificateSigner
      companyType
    }
    course: getCourse (courseId: $courseId) {
      id
      title
      chaptersUnlocked
      canRetakeActionAssessments
      maxActionAssessmentsRetakes
      passingGrade
      graduationGrade
      curatorName
      curatorTitle
      curatorCompany
      showActionAssessmentsResultsPerAction
      hasAssessments
      testCompany {
        id
        accessCodes
      }
      timestamps {
        ...TimestampsFragment
      }
    }
    chapters: getChaptersForCourse (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments) {
      ...ChapterFragment
    }
    progress: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      progress {
        ...ProgressFragment
      }
    }
    study: getStudyForSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      companyId
      courseId
      chapterId
      actionId
      rewardsSeen
      viewed
      answer
      # isCorrect
      thumbnail
      sharedAt
      commentsCount
      contentType
      likesCount
      commentsLastSeenAt
      lastCommentAt
      isAssessmentCorrect
      retriesBlocked
      reminder {
        id
        at
      }
      timestamps {
        ...TimestampsFragment
      }
    }
    user: getUser (userId: $userId) {
      id
      profile {
        ...ProfileFragment
      }
      editorCourses
      metadata {
        appInstalled
        general
      }
    }
    app: appState @client {
      isEditing
      isHelpEnabled
      activeChapterId
    }
  }
`

export const getChaptersForCourseImportQuery = gql`
  query GetChaptersForCourse ($companyId: String!, $courseId: String!, $returnAllAssessments: Boolean) {
    course: getCourse (courseId: $courseId) {
      id
      title
      tagline
      description
      curatorName
      curatorTitle
      curatorBio
      hasAssessments
    }
    chapters: getChaptersForCourse (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments) {
      id
      title
      description
      morningEmail
      morningPush
      actions {
        id
        chapterId
        title
        description
        placeholderAnswer
        contentType
        rewards {
          id
          title
          text
        }
      }
    }
  }
`

export type GetChaptersForCourseInBundleVars = {
  companyId: string;
  courseId: string;
  bundleId?: string;
}

export type GetChaptersForCourseInBundleRes = {
  chapters: ChapterBasic[];
}

export const getChaptersForCourseInBundleQuery = gql`
  query GetChaptersForCourseInBundle ($companyId: String!, $courseId: String!, $bundleId: String) {
    chapters: getChaptersForCourseInBundle (companyId: $companyId, courseId: $courseId, bundleId: $bundleId) {
      id
      order
      title
      imageUrl
      imageUrlAlt
      actionsCount
      disabledUntilDay
    }
  }
`
