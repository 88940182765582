import { pickBy } from 'ramda'
import { Review } from '../review/Review'
import { StudyReview } from 'graphql/fragments/Reviews'
import { Timestamps } from './UserProgress'

export type UserStudy = {
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  chapterId: string;
  actionId: string;
  timestamps: Timestamps;
  reviewTimestamps: Timestamps;
  rewardsSeen: string[];
  commentsCount: number;
  likedByMe: boolean;
  likesCount: number;
  contentType: string;
  tries: number;
  secondsUsed: number;
  retriesBlocked: boolean;

  review: StudyReview|null;
  viewed: number|null;
  answer: string|null;
  answeredAt: number|null;
  completedAt: number|null;
  sharedAt: number|null;
  isCorrect: Boolean|null
  thumbnail: string|null;
  comment: string|null;
  secondsSpent: number|null;
  reminder: {
    id: string;
    at: number;
  }|null;
  lastCommentAt: number|null;
  commentsLastSeenAt: number|null;
  isAssessmentCorrect: boolean|null;
}

type StudyKey = {
  id: string,
  userId: string,
  companyId: string,
  courseId: string,
  chapterId: string,
  actionId: string,
}

export type StudyViewedUpdated = StudyKey & {
  viewed: number,
}

export type StudyAnswerUpdated = StudyKey & {
  answer: string,
  answeredAt: number,
  // isCorrect: boolean,
  thumbnail: string,
  comment: string,
  isAssessmentCorrect: boolean|null,
}

export type StudyCompletionUpdated = StudyKey & {
  completedAt: number,
}

export type StudyTimestampsUpdated = StudyKey & {
  timestamps: {
    createdAt: number|null,
    createdAtTree: number|null,
    updatedAt: number|null,
    updatedAtTree: number|null,
    __typename: string,
  },
}

export type StudyRemoved = StudyKey

export type StudyShared = StudyKey & {
  sharedAt: number,
}

export type StudyUnshared = StudyKey & {
  unsharedAt: number,
}

export type StudyShareLiked = StudyKey & {
  userIdLiked: string,
  likedAt: number,
}

export type StudyShareUnliked = StudyKey & {
  userIdLiked: string,
}

export type StudyShareCommented = StudyKey & {
  messageId: string,
  message: string,
  messageAt: number,
  userIdCommented: string,
}

export type StudyShareCommentRemoved = StudyKey & {
  messageId: string,
  newLastMessage: string|null,
  userIdCommented: string,
}

export type StudyChange =
  StudyViewedUpdated|StudyAnswerUpdated|StudyCompletionUpdated|StudyTimestampsUpdated|
  StudyRemoved|StudyShareLiked|StudyShareUnliked|StudyShareCommented|StudyShareCommentRemoved

export function userStudyFactory(
  data: Partial<UserStudy> & Pick<UserStudy, 'companyId'|'courseId'|'chapterId'|'actionId'>,
): UserStudy&{__typename: string} {
  return {
    ...data as any,
    __typename: 'Study',
    timestamps: data.timestamps
      ? { __typename: 'Timestamps', ...pickBy(v => v != null, data.timestamps) }
      : { createdAt: 0, createdAtTree: 0, updatedAt: 0, updatedAtTree: 0, __typename: 'Timestamps' },
    rewardsSeen: data.rewardsSeen || [],
    commentsCount: data.commentsCount || 0,
    likedByMe: data.likedByMe || false,
    likesCount: data.likesCount || 0,
    viewed: data.viewed || null,
    answer: data.answer || null,
    answeredAt: data.answeredAt || null,
    completedAt: data.completedAt || null,
    sharedAt: data.sharedAt || null,
    contentType: data.contentType || '',
    tries: data.tries || 0,
    secondsUsed: data.secondsUsed || 0,
    retriesBlocked: data.retriesBlocked || false,

    // isCorrect: data.isCorrect || null,
    thumbnail: data.thumbnail || null,
    comment: data.comment || null,
    secondsSpent: data.secondsSpent || null,
    reminder: data.reminder || null,
    lastCommentAt: data.lastCommentAt || null,
    commentsLastSeenAt: data.commentsLastSeenAt || null,
    isAssessmentCorrect: data.isAssessmentCorrect || false,
    review: data.review || null,
    reviewTimestamps: data.reviewTimestamps
      ? { __typename: 'Timestamps', ...pickBy(v => v != null, data.reviewTimestamps) }
      : { createdAt: 0, createdAtTree: 0, updatedAt: 0, updatedAtTree: 0, __typename: 'Timestamps' },
  }
}

export type StudyReviewUpdated = StudyKey & {
  addedReviews: Review[]|null;
  updatedReviews: Review[]|null;
  removedReviews: string[]|null;
}

export type StudyReviewTimestampsUpdated = StudyKey & {
  timestamps: Timestamps;
}
