import { gql } from '@apollo/client'
import { Organization } from 'graphql/schemas/organization/Organization'

export type GetUsersLinkedToOrganizationsVars = {
  userId: string;
  onlyClientOrgs?: boolean;
  includeEditorOrgs?: boolean;
  includeAgentOrgs?: boolean;
  includeManagerOrgs?: boolean;
  includeLearnerOrgs?: boolean;
}

export type GetUsersLinkedToOrganizationsRes = {
  organizations: Pick<Organization, 'id'|'name'>[];
}

export const getUsersLinkedToOrganizationsQuery = gql`
  query GetUsersLinkedToOrganizations ($userId: String!, $includeLearnerOrgs: Boolean, $includeManagerOrgs: Boolean, $includeAgentOrgs: Boolean, $includeEditorOrgs: Boolean, $onlyClientOrgs: Boolean) {
    organizations: getUsersLinkedToOrganizations (userId: $userId, includeLearnerOrgs: $includeLearnerOrgs, includeManagerOrgs: $includeManagerOrgs, includeAgentOrgs: $includeAgentOrgs, includeEditorOrgs: $includeEditorOrgs, onlyClientOrgs: $onlyClientOrgs) {
      id
      name
    }
  }
`
