import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { actionFragment } from 'graphql/fragments/Action'
import { assessmentModeFragment } from 'graphql/mutations/chapter/updateChapter'

export const chapterFragment = gql`
  ${timestampsFragment}
  ${actionFragment}
  ${assessmentModeFragment}

  fragment ChapterFragment on Chapter {
    id
    courseId
    order
    title
    description
    imageUrl
    imageUrlAlt
    videoUrl
    videoUrl_720p
    videoUrlThumbnail
    videoUrlAlt
    isSequential
    disabledUntilDay
    timestamps {
      ...TimestampsFragment
    }
    actions {
      ...ActionFragment
    }
    # todo(rudik) only fetch for curators
    assessmentMode {
      ...AssessmentModeFragment
    }
    morningPush
    morningEmail
  }
`

export const editorChapterFragment = gql`
  ${assessmentModeFragment}

  fragment EditorChapterFragment on Chapter {
    assessmentMode {
      ...AssessmentModeFragment
    }
    morningPush
    morningEmail
  }
`
