import React, { useEffect, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import { showModal } from 'components/utils/CustomModal'
import { inviteByEmail } from 'actions/companies_actions'
import { showAlert } from 'components/utils/Alert'
import { copyToClipboard, isEmail, stringSlicer } from 'utils/functions'
import { track } from 'utils/track'
import { getCompanyInvitationUrl } from 'graphql/schemas/company/Company'
import { Button } from 'components/utils/Button'
import { BASE_DASHBOARD_URL } from 'constants/General'
import { getCourseCompanies } from 'graphql/schemas/user/UserSubscription'
import { client } from 'app'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { useQuery } from '@apollo/client'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'

type CourseListHeaderProps = {
  companyId: string;
  groupName: string;
  name: string;
  organizationName: string;
  accessCode: string|null;
  isAgent: boolean;
  onClick: (company: string) => void;
}

const CourseListHeader = ({ companyId, groupName, name, organizationName, accessCode, isAgent, onClick }: CourseListHeaderProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
    const cId = groupName && companyId !== 'test' && companyId !== 'public'
      ? groupName
      : companyId
    onClick(cId)
  }

  const inviteUser = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
      const modal = showModal({
      title: intl.get('invite_participants'),
      component: (
        <Invite
          isAgent={isAgent}
          companyId={companyId}
          groupName={groupName}
          close={() => modal.close()}
        />
      ),
      primaryText: intl.get('done_text'),
      secondaryButton: false,
    })
  }

  return (
    <h3 className="relative cursor-pointer space-y-2" onClick={handleClick}>
      <div className="relative inline-block leading-normal">
        {name}
      </div>

      <small className="block">{organizationName}</small>

      {isAgent &&
        <a href="#" className="flex items-center text-sm text-lake hover:text-teal-500" onClick={inviteUser}>
          <img src="/images/icon-add-participant.svg" className="mr-2 -mt-1 h-4" alt={intl.get('home_group_invite')} />
          {intl.get('home_group_invite')}
        </a>
      }
    </h3>
  )
}

export default CourseListHeader


export const Invite = ({ companyId, courseId, home, groupName, isAgent, createGroup, close, courseName, orgId, fromHomeGroups, isPublish}: { companyId?: string, courseId?: string, home?: boolean, groupName?: string, isAgent: boolean, createGroup?: boolean, close?: any, courseName?: string, orgId?: string, fromHomeGroups?: boolean, isPublish?: boolean }) => {

  const appData = client.readQuery<AppState>({ query: getAppStateQuery })
  const { data: companiesData, loading: companiesLoading } = useQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>(getUserSubscriptions2Query, { variables: {
    userId: appData?.appState.loggedInAs.uid || '',
    archived: false,
  }})

  if (companiesLoading || !companiesData) {
    return null
  }
  const companies = getCourseCompanies(companiesData, courseId || '')

  const [email, setEmail] = useState('')
  const [shareUrlWithCourseId, setShareUrlWithCourseId] = useState('')
  const [shareUrl, setShareUrl] = useState('')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [showInvite, setShowInvite] = useState(false)
  const shareFieldwithCourseId = useRef<any>(null)
  const shareField = useRef<any>(null)

  useEffect(() => {
    if (isAgent) {
      fetchUrl()
    }
  }, [])

  useEffect(() => {
    fetchUrl()
    }, [selectedCompany])

  useEffect(() => {
    const path = window.location.pathname
    const hash = window.location.hash
    const isHomeGroups = path.includes("/home/groups")
    const isPublishShare = path.includes("/home/publish") && hash === '#share'
    if (isHomeGroups || isPublishShare){
      setSelectedCompany(companyId || selectedCompany)
      setShowInvite(true)
    }
  }, [])

  const fetchUrl = async () => {
    try {
      const urlWithCourseId = await getCompanyInvitationUrl(selectedCompany || companyId || '', courseId)
      const url = await getCompanyInvitationUrl(selectedCompany || companyId || '', undefined)
      setShareUrlWithCourseId(urlWithCourseId)
      setShareUrl(url)
    } catch (err) {
      const error = Error(err as any)
      console.log(error.message, err)
    }
  }

  const selectField = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
    shareField.current && shareField.current.select()
  }
  const selectFieldwithCourseId = (e: React.MouseEvent) => {
    e.nativeEvent.preventDefault()
    shareFieldwithCourseId.current && shareFieldwithCourseId.current.select()
  }

  const sendInvite = async () => {
    if (!isEmail(email)) {
      return showAlert(intl.get('invalid_email_check'), 'danger')
    }
    const res = await inviteByEmail(companyId || selectedCompany, email)
    if (res.error && res.reason === 'close modal'){
      return close()
    }
    if (!res.error) {
      setEmail('')
      track({ event: '"Invite" Button Clicked In "Share Your Program" Screen' })
      track({
        event: 'Button Invite Learner Clicked',
        variables: {
          company_id: companyId || selectedCompany,
          invitedUserEmail: email,
          programId: courseId,
          invitedById: appData?.appState?.loggedInAs?.uid || '' ,
          programName: courseName,
        },
      })
    }
  }

  // const shareUrl = `${BASE_URL}/d/joinGroup/${accessCode || ''}`

  const cls = classNames({
    'btn-v2 flex items-center capitalize': true,
    team: !home,
    primary: home,
  })

  const selectedCompanyName = companies.find(c => c.id === selectedCompany)?.name

  return (
    <div>
      <section className="mt-2">
        {(!showInvite && !createGroup) ? <div>
          {intl.get('header_to_invite_learners')} <span>{intl.get('head_to_dashboard_1')}&nbsp;
            <button className="text-coral underline hover:cursor-pointer"
            onClick={() => {
              if (orgId) {
                return window.location.href = `${BASE_DASHBOARD_URL}/organization/${orgId}`
              }
              return window.location.href = BASE_DASHBOARD_URL
            }}> {intl.get('gnowbe_dashboard')}</button> {intl.get('head_to_dashboard_3')}</span>
          </div>
          : <div>
            {!fromHomeGroups ? intl.getHTML('invited_users_access_to_group', { 0: courseName, 1: groupName || selectedCompanyName, }) : intl.getHTML('invited_users_access_to_group_2', { 0: groupName || selectedCompanyName })}
              {isAgent && <span>{intl.get('head_to_dashboard_1')}&nbsp;
                <button className="text-coral underline hover:cursor-pointer"
                  onClick={() => {
                    if (orgId) {
                      return window.open(`${BASE_DASHBOARD_URL}/organization/${orgId}`, '_blank')
                    }
                    return window.open(BASE_DASHBOARD_URL, '_blank')
                  }}> {intl.get('gnowbe_dashboard')}
                </button> {intl.get('head_to_dashboard_3')}
              </span>}

          {createGroup && <div className="rounded-lg border-2 border-team bg-[#FFF8EB] text-[#9A6500] my-4 py-3 px-4">
            {intl.get('distributed_program', { 0: courseName, 1: groupName })}
          </div>}
        </div>}
        {!createGroup && <div className={`mt-4 ${showInvite ? 'border-b border-gray-300 pb-2' : ''}`}>
          <CompanyPicker companies={companies} setSelectedCompany={setSelectedCompany} setShowContent={setShowInvite} selectedCompany={selectedCompany} notShowPicker={isPublish || fromHomeGroups || location.pathname.includes('/home/groups')} />
        </div>}


        {(showInvite || createGroup) && <>
          <div className="text-xl font-noto-sans mt-6 font-medium font-sm">{intl.get('invite_by_email')}</div>
          <div className="mt-2">{intl.get('learners_register_invited_email')}</div>
          <div id="invite-by-email-div" className="flex mt-3">
            <input id="invite-by-email-input" type="text" className="flex-1 mr-4 form-input" placeholder={intl.get('type_an_email_address_here')} value={email} onChange={e => setEmail(e.target.value)} />
            <button className={cls} onClick={sendInvite}>
              <i className="icon icon-users-solid mr-2" />
              {intl.get('invite')}
            </button>
          </div>
        </>}
      </section>

      {((isAgent && showInvite) || (isAgent && createGroup)) && (
        <>
          {courseId && <section className="mt-6 pt-6 border-t border-gray-300">
            <div className="text-xl font-noto-sans font-medium font-sm">{intl.get('share_program_link')}</div>
            <div className="mt-2">{intl.get('share_program_link_text')}</div>
            <div className="flex mt-3">
              <input ref={shareField} type="text" className="text-pretty flex-1 mr-4 form-input" value={shareUrlWithCourseId} onClick={selectFieldwithCourseId} readOnly />

              <button className={`${cls} default`} onClick={() => {
                copyToClipboard(shareUrlWithCourseId)
                track({ event: '"Share" Button Clicked In "Share Your Program" Screen' })
              }}>
                <i className="icon icon-paste-light mr-2" />
                {intl.get('global_copy_link')}
              </button>
            </div>
          </section>}
          <section className="mt-6 pt-6 border-t border-gray-300">
            <div className="text-xl font-noto-sans font-medium font-sm">{intl.get('share_group_link')}</div>
            <div className="mt-2">{intl.get('share_group_link_text')}</div>
            <div className="flex mt-3">
              <input ref={shareField} type="text" className="text-pretty flex-1 mr-4 form-input" value={shareUrl} onClick={selectField} readOnly />

              <button className={`${cls} default`} onClick={() => {
                copyToClipboard(shareUrl)
                track({ event: '"Share" Button Clicked In "Share Your Program" Screen' })
              }}>
                <i className="icon icon-paste-light mr-2" />
                {intl.get('global_copy_link')}
              </button>
            </div>
          </section>
        </>
      )}
      {(createGroup || fromHomeGroups) && <section className="flex mt-8">
        <div className="w-1/2 m-auto justify-start">
          <div className="inline-block px-3 py-1 m-auto bg-[#E3E4E5] rounded-2xl text-xs">
            {intl.get('steps_dynamic', { 0: 2, 1: 2 })}
          </div>
        </div>
        <div className="justify-end mt-4 flex gap-4 w-1/2">
          <Button id="finish-the-invite-button" type="team" text={intl.get('global_finish')} onClick={close} />
        </div>
      </section>}
    </div>
  )
}

type CompanyPickerProps = {
  setShowContent: (showInvite: boolean) => void,
  setSelectedCompany: (e) => void,
  selectedCompany: string,
  companies: any,
  notShowPicker?: boolean,
}

export const CompanyPicker = (props: CompanyPickerProps) => {
  if (props.notShowPicker) return null
  const liveCompanies = props.companies.filter(c => !['test'].includes(String(c.companyType)))

  const onChange = (e) => {
    props.setSelectedCompany(e.target.value)
    props.setShowContent(true)
  }

  useEffect(() => {
    if (liveCompanies.length >= 1) {
      props.setSelectedCompany(liveCompanies[0].id)
      props.setShowContent(true)
    }
  }, [])

  if (liveCompanies.length === 0) {
    return (
      <div className="mt-4">
        {intl.get('program_not_distributed_to_any_group')}
      </div>
    )
  }

  return (
    <div className="form-group">
      <select
        id="group_selector"
        className={`my-2 block w-full border ${!props.selectedCompany && 'text-lightgray'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-300 focus:border-blue-300`}
        value={props.selectedCompany}
        onChange={(e) => {
          onChange(e)
        }}>
          <option value="" className='hidden'>{intl.get('group_select_program_modal_title')}</option>
          {liveCompanies.map((company) => (
            <option className="text-black mt-1" key={company.id} value={company.id}>
              {stringSlicer(company.name, 40)}
            </option>
          ))}
      </select>
    </div>
  )
}
