import { gql } from '@apollo/client'
import { companyFragment } from 'graphql/fragments/Company'

export const CREATE_COMPANY = gql`
  ${companyFragment}

  mutation CreateCompany($organizationId: String!, $companyCreate: CompanyCreate!) {
    company: createCompany(organizationId: $organizationId, companyCreate: $companyCreate) {
      ...CompanyFragment
    }
  }
`

export const UPDATE_COMPANY = gql`
  ${companyFragment}

  mutation UpdateCompany($companyId: String!, $companyUpdate: CompanyUpdate!) {
    company: updateCompany(companyId: $companyId, companyUpdate: $companyUpdate) {
      ...CompanyFragment
    }
  }
`

export const DELETE_COMPANY = gql`
  ${companyFragment}

  mutation DeleteCompany($companyId: String!) {
    company: deleteCompany(companyId: $companyId) {
      ...CompanyFragment
    }
  }
`
