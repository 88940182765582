import React, { memo, useContext, useEffect, useRef } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import intl from 'react-intl-universal'
import { secondsToHms } from 'utils/functions'
import { AssessmentsSupervisorCtx } from '../course/AssessmentsSupervisor'

export const AssessmentCounter2 = memo(({ chapterId, isAction }: { chapterId: string, isAction: boolean }) => {
  const ctx = useContext(AssessmentsSupervisorCtx)
  const status = ctx?.chapters.get(chapterId)

  const [timeLeft, setTimeLeft] = React.useState(0)
  const timer = useRef<any>(null)
  const clearTimer = () => {
    clearInterval(timer.current)
    timer.current = null
  }

  const checkTimer = async (startedAt: number, timeLimit: number) => {
    const timeLeftInitial = Math.round((startedAt + timeLimit - Date.now()) / 1000)
    if (timeLeftInitial <= 0) {
      setTimeLeft(0)
      clearTimer()
      return
    }
    if (timer.current) {
      // Timer already running so just check if ok
      if (Math.abs(timeLeftInitial - timeLeft) < 1000) {
        return
      }
    }
    setTimeLeft(timeLeftInitial)
    clearTimer()
    timer.current = setInterval(() => {
      setTimeLeft((current) => {
        if (current <= 0) {
          clearTimer()
          return 0
        }

        return current - 1
      })
    }, 1000)
  }

  useEffect(() => {
    if (status?.status === 'started') {
      checkTimer(status.startedAt, status.timeLimit)
    } else {
      clearTimer()
      setTimeLeft(0)
    }
    return clearTimer
  }, [ctx, chapterId])

  if (status?.status !== 'started' && status?.status !== 'ended') {
    return null
  }

  const percentageLeft = status.status === 'ended'
    ? 0
    : Math.round(1000 * timeLeft / status.timeLimit * 100)

  if (isAction) {
    return (
      <div className="text-lg text-center">
        { status.status === 'started'
          ? <div className={`${percentageLeft >= 50 ? 'text-green-600' : percentageLeft >= 10 ? 'text-orange-600' : 'text-red-600'}`}>
              {secondsToHms(Math.max(timeLeft, 0) + 1).m.toString().padStart(2, '0')}:{secondsToHms(Math.max(timeLeft, 0) + 1).s.toString().padStart(2, '0')}
            </div>
          : <div className="text-coral text-sm">{intl.get('timed_assessments_time_up')}</div>
        }
      </div>
    )
  }

  return (
    status.status === 'started'
      ? <div className="absolute w-16 h-16 right-0 top-0 mr-4 mt-4">
          <CircularProgressbar
            value={percentageLeft}
            strokeWidth={3}
            background={true}
            counterClockwise={true}
            text={`${secondsToHms(Math.max(timeLeft, 0) + 1).h && `${secondsToHms(Math.max(timeLeft, 0) + 1).h.toString().padStart(2, '0')}:` || ''}${secondsToHms(Math.max(timeLeft, 0) + 1).m.toString().padStart(2, '0')}:${secondsToHms(Math.max(timeLeft, 0) + 1).s.toString().padStart(2, '0')}`}
            styles={buildStyles({
              // backgroundColor: '#EEE8D9',
              trailColor: '#fff',
              pathColor: percentageLeft >= 50 ? 'green' : percentageLeft >= 10 ? 'orange' : 'red',
              textColor: percentageLeft >= 50 ? 'green' : percentageLeft >= 10 ? 'orange' : 'red',
            })}
          />
        </div>
      : <div className="absolute flex items-center justify-center p-1 pt-2 bg-coral text-white text-sm text-center rounded-full w-16 h-16 right-0 top-0 mr-4 mt-4">
          {intl.get('timed_assessments_time_up')}
        </div>
  )
})
