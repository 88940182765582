import { gql } from '@apollo/client'
import { courseFragment, editorCourseFragment } from 'graphql/fragments/Course'

export const updateCourseMutation = gql`
  ${courseFragment}
  ${editorCourseFragment}

  mutation UpdateCourse ($courseId: String!, $courseUpdate: CourseUpdate!) {
    updateCourse (courseId: $courseId, courseUpdate: $courseUpdate) {
      ...CourseFragment
      ...EditorCourseFragment
    }
  }
`
