
export interface CourseKeyData {companyId: string; courseId: string}
export function getCourseKey(data: CourseKeyData) {
  return `${data.companyId}-${data.courseId}`
}
export function parseCourseKey(courseKey: string): CourseKeyData {
  const match = /([a-z0-9]+)-([a-z0-9]+)/i.exec(courseKey)
  if (!match) return {companyId: '', courseId: ''}
  return {companyId: match[1], courseId: match[2]}
}
export function courseKeysEqual(data1: CourseKeyData, data2: CourseKeyData) {
  return data1.companyId === data2.companyId && data1.courseId === data2.courseId
}



export interface ChapterKeyData extends CourseKeyData {chapterId: string}
export function chapterKeysEqual(data1: ChapterKeyData, data2: ChapterKeyData) {
  return courseKeysEqual(data1, data2) && data1.chapterId === data2.chapterId
}



export interface ActionKeyData extends ChapterKeyData {actionId: string}
export function actionKeysEqual(data1: ActionKeyData, data2: ActionKeyData) {
  return chapterKeysEqual(data1, data2) && data1.actionId === data2.actionId
}
