import { useQuery } from '@apollo/client'
import { getAuthTokens } from 'actions/utils'
import BoAnimation from 'common/json/lottie/BoFace.json'
import LoadingSkeleton from 'common/json/lottie/LoadingSkeleton.json'
import colors from 'constants/Colors'
import icons from 'constants/Icons'
import {
  ConversationStep,
  IntentSelect,
  LottieAnimation,
  TopBar,
  useBotActions,
  LottieLoadingAnimation,
} from 'gnowbe-bot'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { AppState } from 'graphql/schemas/app/State'
import React, { useEffect } from 'react'
import { Pressable, Text } from 'react-native'
import { history } from 'utils/history'
import useBotCustomStyles from './useBotCustomStyles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GNOWBE_BOT_URL } from 'constants/General'
import { getMeta } from 'utils/functions'
import { client } from 'app'
import { useWindowDimensions } from 'react-native'
import { GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { track } from 'utils/track'

const {
  OnboardingIcon,
  SupportIcon,
  ProgramIcon,
  GearIcon,
  ArrowUpFilledIcon,
  ArrowLeftIcon,
  MessageIcon,
  CustomIcon,
} = icons

const emptyConversation = {
  messages: [
    'We can talk about many things, but let’s get you started with a clear goal',
  ],
  buttons: [],
  state: 'ENDED',
  canUndo: false,
}

const options = [
  {
    label: 'Create a Program',
    value: 'gnowbe-create-program',
    icon: <ProgramIcon />,
  },
  {
    label: 'Support',
    value: 'support',
    icon: <SupportIcon />,
  },
  {
    label: 'Get Expert Advice',
    value: 'open-chat',
    icon: <MessageIcon />
  }
]

const Bot = () => {
  const { width } = useWindowDimensions()
  const navigate = history.navigate || useNavigate()
  const [currentQueryParameters, setSearchParams] = useSearchParams();
	const newQueryParameters : URLSearchParams = new URLSearchParams();

  const intentUrl = currentQueryParameters.get('intent')

  const token = getAuthTokens()
  const { data } = useQuery<AppState>(getAppStateQuery)

  const subData = client.readQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>({
    query: getCurrentSubscriptionQuery,
    variables: { userId: data?.appState?.loggedInAs?.uid || '' },
  })

  const userId = data?.appState.loggedInAs.uid || ''
  const activeWorkspaceId: string = getMeta(subData?.user.metadata, 'activeWorkspaceId') || ''

  const {
    intent,
    conversation,
    animationsEnabled,
    loading,
    toggleAnimation,
    setIntent,
    selectConversationStep,
    resetConversation,
    undoConversation,
    endConversation,
  } = useBotActions({
    apiUri: GNOWBE_BOT_URL,
    token,
    userId,
    organizationId: activeWorkspaceId,
  })

  const hideAdditionalButtons = () => {
    const element: HTMLButtonElement | null =
      document.querySelector(`[id^="headlessui"]`)

    if (element) {
      element.style.display = 'none'
    }
  }

  const updateIntent = (newIntent) => {
    setIntent(newIntent);

    // Optionally, update the URL immediately
    if (newIntent) {
      newQueryParameters.set('intent', newIntent);
    } else {
      newQueryParameters.delete('intent');
    }

    setSearchParams(newQueryParameters);
  };

  useEffect(() => {
    hideAdditionalButtons()
  }, [])

  useEffect(() => {
    if (intentUrl && intentUrl !== intent) {
      setIntent(intentUrl);
    } else if (!intentUrl && intent) {
      setIntent(null);
    }
  }, [intentUrl]);

  const {
    topBarExternalStyles,
    intentSelectExternalStyles,
    conversationStepExternalStyles,
  } = useBotCustomStyles({ intent, conversation })

  return (
    <div className="flex flex-1 justify-start items-center flex-col min-h-screen bg-gray-50">
      <style>
        {`
        p, span, div {
          font-family: "PoppinsMedium"!important;
        }
        `}
      </style>
      <TopBar
        title={''}
        restartButtonAction={() => {
          track({
            event: 'Restart Goal Chat Bot from Top Bar',
            variables: {
              userId,
              intent,
            }
          })
          resetConversation()
        }}
        undoButtonAction={() => {
          track({
            event: 'Undo Conversation Chat Bot',
            variables: {
              userId,
              intent,
            }
          })
          undoConversation()
        }}
        isVisibleRouteButton={!!intent}
        isVisibleBackButton
        animationsEnabled={animationsEnabled}
        toggleAnimation={() => {
          track({
            event: 'Toggle Animation Chat Bot',
            variables: {
              userId,
              intent,
              animationsEnabled: !animationsEnabled,
            }
          })
          toggleAnimation()
        }}
        backButton={() => {
          track({
            event: 'Back Button Clicked Chat Bot',
            variables: {
              userId,
              intent,
            }
          })
          if (!intent) {
            navigate('/')
            return
          }
          endConversation()
          updateIntent(null)
        }}
        leftIcon={<ArrowLeftIcon />}
        rightIcon={<GearIcon />}
        rightActiveIcon={<GearIcon />}
        externalStyles={topBarExternalStyles}
      />
      <LottieAnimation
        animationData={BoAnimation}
        loop
        lottieStyle={{ width: 290, height: 290 }}
        color={colors.red400}
      />

     {loading
      ? <LottieLoadingAnimation
          animationData={LoadingSkeleton}
          lottieStyle={{
            maxWidth: '100%',
            width: Math.min(800, width - 40),
            marginTop: 40,
            paddingHorizontal: 20,
          }}
        /> 
      : <ConversationStep
          conversation={intent ? conversation : emptyConversation}
          handleSelectConversationStep={(message: string) => {
            track({
              event: 'Select Conversation Step Chat Bot',
              variables: {
                userId,
                intent,
                message,
              }
            })
            selectConversationStep(message)
          }}
          handleResetConversation={() =>{
            track({
              event: 'Reset Goal Chat Bot',
              variables: {
                userId,
                intent,
              }
            })
            resetConversation()
          }}
          animationsEnabled={animationsEnabled}
          externalStyles={conversationStepExternalStyles}
        />}

      {!intent && <div className="fixed bottom-0 m-4">
        <IntentSelect
          selected={intent}
          options={options}
          onSelect={(value) => {
            track({
              event: 'Select Goal Chat Bot',
              variables: {
                userId,
                intentSelected: value.value,
              }
            })
            updateIntent(value.value)
          }}
          placeholder="Select Goal"
          menuTitle="Have GnowBot assist you with a specific goal"
          menuDescription="In this mode your answers you’ll have a limited range of answers to help you reach your goals faster"
          externalStyles={intentSelectExternalStyles}
          endSelectComponent={
            <ArrowUpFilledIcon color={colors.red500} width={20} height={20} />
          }
        />
      </div>}
    </div>
  )
}

export default Bot
