import { gql } from '@apollo/client'

export type GetCourseWordCountRes = {
  res: {
    wordCount: number;
  }
}
export type GetCourseWordCountVars = {
  courseId: string;
}

export const getCourseWordCountQuery = gql`
  query GetCourseWordCount ($courseId: String!) {
    res: getCourseWordCount (courseId: $courseId) {
      wordCount
    }
  }
`

