import React from 'react'
import * as logger from 'logger'

export default class ErrorBoundary extends React.Component<{ children: any, errorElement: JSX.Element }, { hasError: boolean, reloadCount: number }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false, reloadCount: 0 }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    logger.error('<ErrorBoundary> ' + error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <span className="error-box">
          {this.props.errorElement}
          {this.state.reloadCount <= 2
            ? <input type="button" className="btn btn-link" value="Reload Component" onClick={() => {
                this.forceUpdate()
                this.setState({ hasError: false, reloadCount: this.state.reloadCount + 1 })
              }} />
            : <input type="button" className="btn btn-link" value="Reload Page" onClick={() => {
                window.location.reload()
              }} />
          }
        </span>
      )
    }

    return this.props.children;
  }
}