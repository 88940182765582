import { gql } from '@apollo/client'

export const listenToAuthChangesChangesSubscription = gql`
  subscription ListenToAuthChanges {
    status: listenToAuthChanges {
      ... on UserLoggedOut {
        userId
        tokenId
        reasonMsg
        reasonCode
      }

      ... on UserAuthNoChange {
        userId
      }
    }
  }
`
