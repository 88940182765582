import * as logger from '../logger'

class ResponseError extends Error {
  constructor(public statusCode, message: string, public error: any) {
    super(message)
  }
}

export function checkHttpStatus(response, allow4xx = false, skipUnauthorizedCheck = false) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if (!skipUnauthorizedCheck && response.status === 401) {
    removeAuthTokens()
    return window.location.reload()
  }

  if (allow4xx && response.status >= 400 && response.status < 500) {
    return response
  }

  return new Promise((res, rej) => {
    response.json().then((json) => {
      rej(new ResponseError(response.status, response.statusText, json.error))
    })
  })
}

export function getAuthTokens() {
  const token = localStorage.getItem('token') || ''
  return token
}
export function saveAuthTokens(token: string) {
  localStorage.setItem('token', token)
}
export function removeAuthTokens() {
  localStorage.removeItem('token')
}

interface SubscriptionData {
  data: any;
  fireRef: any;
  valueListener?;
  childAddedListener?;
  childChangedListener?;
  childRemovedListener?;
  counter: number;
}

// Ideally this would be in Redux store but it is here now :)
let subscriptions: {[key: string]: SubscriptionData} = {}

export function checkAndIncSubscription(subKey: string): boolean {
  if (subscriptions[subKey]) {
    subscriptions[subKey].counter = (subscriptions[subKey].counter || 1) + 1
    return true
  }
  return false
}

export function addSubscription(subKey: string, data: SubscriptionData) {
  subscriptions[subKey] = data
}

export function endSubscription(subKey: string, force?: boolean): SubscriptionData|null {
  const data = subscriptions[subKey]
  if (!data) return null
  if (data.counter > 1 && !force) {
    data.counter = data.counter - 1
    return null
  }
  data.valueListener && data.fireRef.off('value', data.valueListener)
  data.childAddedListener && data.fireRef.off('child_added', data.childAddedListener)
  data.childChangedListener && data.fireRef.off('child_changed', data.childChangedListener)
  data.childRemovedListener && data.fireRef.off('child_removed', data.childRemovedListener)
  delete subscriptions[subKey]
  return data
}

export function clearSubscriptionsData() {
  subscriptions = {}
}
