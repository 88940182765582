import React from 'react'
import autosize from 'autosize'
import intl from 'react-intl-universal'
import { graphql } from '@apollo/client/react/hoc'
import { useLazyQuery } from '@apollo/client/react'
import { newChatMsg } from '../../actions/chats_actions'
import { getChatChannelQuery } from 'graphql/queries/chat/getChat'
import { ChatChannel } from 'graphql/schemas/chat/Chat'
import { ReplyToBox } from 'components/chat/ReplyToBox'
import { searchChatMembersQuery, SearchChatMembersRequest, SearchChatMembersResponse } from 'graphql/queries/chat/searchChatMembers'
import { getSignedValue } from 'actions/files'
import OutsideAlerter from 'components/utils/OutsideAlerter'


const getWordAtCursor = (field: HTMLTextAreaElement) => {
  const startPosition = field.selectionStart
  const textUntilCursor = field.value.substring(0, startPosition) || ''
  const textAfterCursor = field.value.substring(startPosition || 0) || ''

  let startOfSelectedWord = ''
  let endOfSelectedWord = ''
  let wordLength = 0
  let startOfSelectedWordLength = 0

  if (textAfterCursor.includes(' ')) {
    const words = textAfterCursor.split(' ')
    const endOfFirstWord = words[0]
    endOfSelectedWord = endOfFirstWord
  }
  else {
    endOfSelectedWord = textAfterCursor
  }

  if (textUntilCursor.includes(' ')) {
    const words = textUntilCursor.split(' ')
    const startOfLastWord = words[words.length - 1]
    if (startOfLastWord.startsWith('@')) {
      startOfSelectedWord = startOfLastWord
      wordLength = (startOfSelectedWord + endOfSelectedWord).length
      startOfSelectedWordLength = startOfSelectedWord.length
      return {
        wordLength,
        startOfSelectedWordLength,
        word: startOfSelectedWord + endOfSelectedWord,
      }
    }
    return null
  }

  if (textUntilCursor.startsWith('@')) {
    wordLength = (textUntilCursor + endOfSelectedWord).length
    startOfSelectedWordLength = textUntilCursor.length
    return {
      wordLength,
      startOfSelectedWordLength,
      word: textUntilCursor + endOfSelectedWord,
    }
  }

  return null
}

const selectWordAtCursor = (field: HTMLTextAreaElement) => {
  const w = getWordAtCursor(field)
  if (field && w) {
    const caretPosition = field.selectionStart
    const startOfTheWordPosition = caretPosition - w.startOfSelectedWordLength
    const endPosition = startOfTheWordPosition + w.wordLength
    field.setSelectionRange(startOfTheWordPosition, endPosition)
  }
}

const insertWordAtCursor = (field: HTMLTextAreaElement, value: string, fn?: () => void) => {
  if (field.selectionStart || field.selectionStart === 0) {
    const startPos = field.selectionStart
    const endPos = field.selectionEnd
    field.value = field.value.substring(0, startPos)
      + value
      + field.value.substring(endPos, field.value.length)
  }
  else {
    field.value += value
  }

  if (fn) {
    return fn()
  }
}

type Suggestion = { id: string, display: string, imageUrl: string|null }

const amendMentionedUsers = (suggestions: Suggestion[], message: string) => {
  if (!suggestions) {
    return message
  }

  const regex = /@([^@\.\,\;\s\n\r\t]+)(\s[^@\.\,\;\s\n\r\t]+|[\.\,\;\s\n\r\t$]){0,4}/g

  const findSuggestion = (parts: string[]) => {
    if (!parts.length) {
      return null
    }
    const name = parts.join(' ')

    const suggestion = suggestions.find(s => s.display === name)
    if (!suggestion) {
      return findSuggestion(parts.slice(0, parts.length - 1))
    }
    return {suggestion, usedParts: parts.length}
  }

  return message.replace(regex, (match) => {
    const parts = match.split(' ')
    const result: {suggestion, usedParts}|undefined = findSuggestion(parts.filter(p => !/^[\,\;\s\n\r\t$]/.test(p)))

    if (!result) return match

    if (result.suggestion) {
      return result.usedParts < parts.length
        ? `{{user:${result.suggestion.id}:${encodeURIComponent(result.suggestion.display.substring(1))}}} ${parts.slice(result.usedParts).join(' ')}`
        : `{{user:${result.suggestion.id}:${encodeURIComponent(result.suggestion.display.substring(1))}}}`
    }

    return match
  })
}

const F = ({ disabled, activeUrn, members, onSubmit, classname, showSubmit, disableAutoFocus }) => {
  const [value, setValue] = React.useState('')
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>(members)
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const _message = React.useRef<HTMLTextAreaElement|null>(null)
  const _timeout: any = React.useRef()
  const _suggestions = React.useRef<HTMLUListElement|null>()
  const _usedSuggestions = React.useRef<Suggestion[]>([])

  React.useEffect(() => {
    document.addEventListener('keydown', moveSuggestions)

    if (!disableAutoFocus && _message.current) {
      _message.current.focus()
    }

    return () => {
      document.removeEventListener('keydown', moveSuggestions)
    }
  }, [])

  const [loadMembers, { data, loading, error, called }] = useLazyQuery<SearchChatMembersResponse, SearchChatMembersRequest>(searchChatMembersQuery, { variables: {
    urn: activeUrn,
    limit: 20,
    skip: 0,
    searchText: '',
  }})

  React.useEffect(() => {
    if (!data) {
      if (called) {
        setSuggestions([])
      }
    }
    else {
      const suggestions = data.members.map((m) => {
        return {
          id: m.id,
          // display: `{{user:${m.id}:${encodeURIComponent(m.profile.fullName || '')}}}`,
          display: `@${m.profile.fullName}`,
          imageUrl: m.profile.imageUrl,
        }
      })
      setSuggestions(suggestions)
    }

    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [data])

  const onChange = (e) => {
    const value: string = e.target.value
    setValue(value)

    if (!value) {
      setShowSuggestions(false)
      return
    }
    const myElement = _message.current
    if (!myElement) {
      return
    }

    const w = getWordAtCursor(myElement)
    if (w) {
      setLoading(true)
      setShowSuggestions(true)
    } else {
      setLoading(false)
      setShowSuggestions(false)
    }

    _timeout.current = clearTimeout(_timeout.current)
    _timeout.current = setTimeout(() => {
      if (w && w.word.length > 2) {
        loadMembers({ variables: {
          urn: activeUrn,
          limit: 20,
          skip: 0,
          searchText: w.word.substring(1),
        }})
      }
      else {
        setLoading(false)
      }
    }, 1000)
  }

  const replaceWithSuggestion = (suggestion: Suggestion) => {
    const myElement = _message.current
    if (!myElement) return
    selectWordAtCursor(myElement)
    insertWordAtCursor(myElement, suggestion.display, () => {
      myElement && setValue(myElement.value)
      autosize.update(_message.current)
      setShowSuggestions(false)
    })
    _usedSuggestions.current = _usedSuggestions.current.concat(suggestion)
  }

  let liSelected: HTMLLIElement|null
  let index = -1
  let next
  const moveSuggestions = (event) => {
    const ul = _suggestions.current
    if (!ul) return

    const items = ul.getElementsByTagName('li')
    const itemsLength = items.length - 1

    if (event.which === 27) {
      return setShowSuggestions(false)
    }

    // down
    if (event.which === 40) {
      event.preventDefault()
      index += 1

      if (liSelected) {
        liSelected.classList.remove('bg-gray-300')
        next = items[index]

        if (typeof next !== undefined && index <= itemsLength) {
          liSelected = next
        }
        else {
          index = 0
          liSelected = items[0]
        }
        liSelected && liSelected.classList.add('bg-gray-300')
      }
      else {
        index = 0
        liSelected = items[0]
        liSelected.classList.add('bg-gray-300')
      }
    }
    // up
    else if (event.which === 38) {
      event.preventDefault()

      if (liSelected) {
        liSelected.classList.remove('bg-gray-300')
        index -= 1

        next = items[index]
        if (typeof next !== undefined && index >= 0) {
          liSelected = next
        }
        else {
          index = itemsLength
          liSelected = items[itemsLength]
        }
        liSelected && liSelected.classList.add('bg-gray-300')
      }
      else {
        index = 0
        liSelected = items[itemsLength]
        liSelected.classList.add('bg-gray-300')
      }
    }
  }

  if (error) {
    console.log(error)
  }

  const sendMessage = (e, msg: string) => {
    const msgAmended = amendMentionedUsers(_usedSuggestions.current, msg)
    onSubmit(e, msgAmended)
    _usedSuggestions.current = []
    setValue('')
    setTimeout(() => {
      autosize.update(_message.current)
    }, 100)
  }

  return (
    <OutsideAlerter
      alert={showSuggestions}
      onAlert={() => setShowSuggestions(false)}>
      {showSuggestions &&
        <div id="suggestions" className="peer-suggestions absolute inset-x-0 z-10 bg-white mx-2 max-h-52 overflow-auto shadow-lg border border-gray-200" style={{ bottom: 44 }}>
          {isLoading
            ? <div className="px-3 py-2">
                {intl.get('loading')}
              </div>
            : suggestions.length > 0
              ? <ul ref={ref => _suggestions.current = ref} className="list-none m-0">
                  {suggestions.map((s, i) => {
                    return (
                      <li
                        key={s.id}
                        className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          replaceWithSuggestion(s)
                        }}
                        data-id={s.id}
                        data-display={s.display}
                        data-imageurl={s.imageUrl}
                      >
                        <div className="mr-2 w-6 h-6 flex-none bg-lightbrown rounded-full">
                          {s.imageUrl && <img src={s.imageUrl} className="rounded-full w-full h-full object-cover" />}
                        </div>

                        <div>
                          {decodeURIComponent(s.display)}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              : <div className="px-3 py-2">No suggestions.</div>
          }
        </div>
      }

      <textarea
        ref={(ref) => {
          _message.current = ref
          autosize(ref)
        }}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()

            if (!_suggestions.current) {
              sendMessage(e, value)
            } else {
              const li: any = document.getElementsByClassName('bg-gray-300')[0]
              if (li) {
                replaceWithSuggestion({
                  id: li.dataset.id,
                  display: li.dataset.display,
                  imageUrl: li.dataset.imageurl,
                })
              }
            }
          }
        }}
        data-test="message-input" className={`messageInput form-input rounded-none ${classname} ${activeUrn ? 'block' : 'hidden'}`}
        rows={1}
        placeholder={intl.get('type_a_message')}
      />

      <span className={`absolute right-0 bottom-0 mb-2.5 mr-4 ${!value ? 'cursor-default' : 'cursor-pointer'}`}>
        <img src={`/images/${!value ? 'paper-plane-light-disabled.svg' : 'paper-plane-light-enabled.svg'}`} onClick={e => value && sendMessage(e, value)} />
      </span>
    </OutsideAlerter>
  )
}


interface FieldProps {
  disableAutoFocus?: boolean;
  activeUrn: string;
  chatChannel: ChatChannel;
  updateCounter?: boolean;
  simple?: boolean;
  singleShare?: boolean;
  disabled: boolean;
  classname?: string;
  showSubmit?: boolean;
  onSubmit?: () => void;
}

class Field extends React.PureComponent<FieldProps, { value: string }> {
  private _message
  private _replyToBox

  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.updateValue = this.updateValue.bind(this)
  }

  componentDidMount() {
    this._message && this._message.addEventListener('keydown', this.checkKeyDown)
    if (!this.props.disableAutoFocus) {
      this._message && this._message.focus()
    }
  }

  componentDidUpdate() {
    if (this._message) {
      this._message.value = ''
      if (!this.props.disableAutoFocus) {
        this._message.focus()
      }
    }
  }

  componentWillUnmount() {
    this._message && this._message.removeEventListener('keydown', this.checkKeyDown)
  }

  checkKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      this.sendMessage()
    }
  }

  sendMessage = (e?: React.SyntheticEvent<any>, v?: string) => {
    e && e.preventDefault()
    let msg = v || this.state.value
    if (!msg) {
      return
    }
    msg = msg.replace(/@\[(.*?)\]/g, '$1')
    const replyTo = sessionStorage.getItem('replyTo')
    newChatMsg(this.props.activeUrn, replyTo && replyTo + msg || msg, this.props.updateCounter || false, this.props.singleShare ? 'single' : 'default')
    replyTo && sessionStorage.removeItem('replyTo')
    this._replyToBox.forceUpdate()
    this.props.onSubmit && this.props.onSubmit()
  }

  updateValue(e: React.ChangeEvent<any>) {
    this.setState({ value: e.target.value })
  }

  render() {
    const { activeUrn, chatChannel, disabled, disableAutoFocus } = this.props

    const autosuggest = chatChannel && chatChannel.members.reduce((arr, member) => {
      const signedImage = member.user?.profile.imageUrl && getSignedValue(member.user?.profile.imageUrl)
      return arr = arr.concat([{
        id: member.userId,
        display: `@${(member.user?.profile.fullName || '')}`,
        imageUrl: signedImage,
      }])
    }, [] as any) || []

    return (
      <>
        <ReplyToBox ref={ref => this._replyToBox = ref} />

        <form onSubmit={this.sendMessage} className="messageInputForm relative">
          <F
            classname={this.props.classname}
            disabled={disabled}
            disableAutoFocus={disableAutoFocus}
            activeUrn={activeUrn}
            members={autosuggest || null}
            onSubmit={this.sendMessage}
            showSubmit={!!this.props.showSubmit}
          />

          <input disabled={true} value={intl.get('no_active_chat')} className={`${!activeUrn ? 'block' : 'hidden'}`} />
        </form>
      </>
    )
  }
}

const InputField = graphql(getChatChannelQuery, {
  skip: (p: any) => !p.activeUrn,
  options: (p: any) => {
    return ({
      variables: {
        urn: p.activeUrn,
        messagesLimit: 1,
      },
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    })
  },
})((p) => {
  if (!p.data || p.data.loading) {
    return <div/>
  }

  // const isOnline = useOnlineStatus()
  const isOnline = true
  const channel: ChatChannel = p.data.chatChannel

  return (
    <Field
      classname={p.classname}
      disableAutoFocus={p.disableAutoFocus}
      activeUrn={p.activeUrn}
      simple={p.simple}
      singleShare={p.singleShare}
      chatChannel={channel}
      disabled={!isOnline}
      showSubmit={p.showSubmit}
      updateCounter={p.updateCounter}
      onSubmit={p.onSubmit}
    />
  )
})

export default InputField
