import React from 'react'
import intl from 'react-intl-universal'
import { detect } from 'detect-browser'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import { BASE_URL, APP_STORE_URL } from 'constants/General'
import { updateMeta } from 'actions/users_actions'

import 'common/stylesheets/components/open-inapp.less'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { checkMeta } from 'utils/functions'
import FullscreenLoader from './utils/FullscreenLoader'
import { User } from 'graphql/schemas/user/User'
import { AppState } from 'graphql/schemas/app/State'
import { withRouter } from './utils/withRouterWrapper'
import { NavigateFunction, Location } from 'react-router-dom'

type OpenInAppProps = {
  navigate?: NavigateFunction;
  params?: any;
  location?: Location<any>;
  app: AppState;
  user: User;
}

class OpenInApp extends React.Component<OpenInAppProps, {}> {
  constructor(props) {
    super(props)

    this.generateDeeplink = this.generateDeeplink.bind(this)
    this.dismiss = this.dismiss.bind(this)
  }

  generateDeeplink() {
    const [companyId, courseId] = this.props.app.appState.currentCompanyIdCourseId.split('-')
    if (!companyId || !courseId) {
      return ''
    }

    const browser = detect()
    const appStoreUrl = `${APP_STORE_URL}?$canonical_url=`
    const path = this.props.location?.pathname || ''
    const params = this.props.params
    let returnUrl = `${BASE_URL}/d`
    if (/:actionId/.test(path)) {
      returnUrl += `/openAction/${companyId}/${courseId}/${params.chapterId}/${params.actionId}`
    }
    else if (/:chapterId/.test(path)) {
      // /session/current might cause troubles
      returnUrl += `/openChapter/${companyId}/${courseId}/${params.chapterId}`
    }
    else if (/:courseId/.test(path)) {
      const [companyId, courseId] = params.courseId.split('-')
      returnUrl += `/openCourse/${companyId}/${courseId}`
    }
    else if (/:urn/.test(path)) {
      returnUrl += `/openChat/${params.urn}`
    }
    else if (browser && (browser.os === 'iOS' || browser.os === 'Android OS')) {
      returnUrl = appStoreUrl + encodeURIComponent(returnUrl)
    }
    return returnUrl
  }

  dismiss() {
    updateMeta('dismissedOpenInApp', 'true')
  }

  render() {
    if (checkMeta(this.props.user.metadata, '/general/dismissedOpenInApp')) {
      return null
    }

    const browser = detect()

    if (browser && (browser.os === 'Android OS' || browser.os === 'iOS')) {
      return (
        <div className="open-inapp">
          <span className="absolute" style={{ left: 5 }} onClick={this.dismiss}>
            <img src="/images/icon-close.png" className="h-3" />
          </span>
          <img className="app-icon" src="/images/app-icon.png" />
          <a href={this.generateDeeplink()} className="text-link">{intl.get('download_app_text')}</a>
          <a href={this.generateDeeplink()} className="view-link">{intl.get('view_group')}</a>
        </div>
      )
    }

    return null
  }
}

const withUser = compose(
  graphql(getAppStateQuery, { name: 'app' }),
  graphql(getCurrentSubscriptionQuery, {
    skip: ({ app }: any) => !app.appState.currentCompanyIdCourseId,
    options: ({ app }: any) => {
      return ({
        variables: { userId: app.appState.loggedInAs.uid },
        errorPolicy: 'all',
      })
    },
  }),
)((p) => {
  if (!p.app || !p.data || !p.data.user) {
    return <FullscreenLoader />
  }

  const app: AppState = p.app
  const user: User = p.data.user

  return (
    <OpenInApp
      navigate={p.navigate}
      params={p.params}
      location={p.location}
      app={app}
      user={user}
    />
  )
})

export default withRouter(withUser)
