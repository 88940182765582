import { gql } from '@apollo/client'
import { userBasicExtraFragment } from 'graphql/fragments/User'
import { UserBasicExtra } from 'graphql/schemas/user/User'

export type GetUpsellModeRes = {
  mode: {
    __typename: 'UpsellModeManagerUnknown'|'UpsellModeManagerKnown';
    billingManager: UserBasicExtra;
    isMe: boolean;
    organizationId?: string;
  };
}

export const getUpsellModeQuery = gql`
  ${userBasicExtraFragment}

  query GetUpsellMode ($userId: String!) {
    mode: getUpsellMode (userId: $userId) {
      __typename
      ... on UpsellModeManagerKnown {
        billingManager {
          ...UserBasicExtraFragment
        }
        isMe
        organizationId
      }
    }
  }
`
