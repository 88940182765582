import gql from 'graphql-tag'

export type JoinCompanyMutationVars = {
  accessCode: string;
  employeeId?: string;
}

export type JoinCompanyMutationRes = {
  company: {
    id: string;
    name: string;
    organizationId: string;
    organizationName: string|null;
    groupName: string|null;
    courses: {
      id: string;
      title: string;
      titleEnglish: string;
      imageUrl: string|null;
      chaptersCount: number|null;
      actionsCount: number|null;
      interactionTime: number|null;
    }[];
  };
}

export const joinCompanyMutation = gql`
  mutation JoinCompany ($accessCode: String!, $employeeId: String) {
    company: joinCompany (accessCode: $accessCode, employeeId: $employeeId) {
      id
      name
      organizationId
      organizationName
      groupName
      courses {
        id
        title
        titleEnglish
        imageUrl
        chaptersCount
        actionsCount
        interactionTime
      }
    }
  }
`

export type JoinCompanyEmployeeIdRequiredVars = {
  accessCode: string;
}

export type JoinCompanyEmployeeIdRequiredRes = {
  required: boolean;
}

export const joinCompanyEmployeeIdRequiredQuery = gql`
  query JoinCompanyEmployeeIdRequired ($accessCode: String!) {
    required: joinCompanyEmployeeIdRequired (accessCode: $accessCode)
  }
`

export type EmployeeIdRequiredRes = {
  settings: {
    required: boolean;
    employeeIdName: string;
  };
}

export const joinCompanyEmployeeIdRequired2Query = gql`
  query JoinCompanyEmployeeIdRequired2 ($accessCode: String!) {
    settings: joinCompanyEmployeeIdRequired2 (accessCode: $accessCode) {
      required
      employeeIdName
    }
  }
`
