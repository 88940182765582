import Tippy from '@tippyjs/react'
import React from 'react'

const CheckIcon = ({ className, tooltip }: { className: string, tooltip?: string }) => {
  return (
    <div>
      <Tippy placement="bottom" content={tooltip} disabled={!tooltip}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={className} >
          <path d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z"/>
        </svg>
      </Tippy>
    </div>
  )
}

export default CheckIcon
