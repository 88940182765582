import React from 'react'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export function showAlert(message: string, type?: 'success'|'error'|'danger'|'info'|'warning', duration?: number) {
  if (!type || type === 'success') return toast.success(message)
  if (type === 'danger' || type === 'error') return toast.error(message)
  if (type === 'info') return toast(message)
  if (type === 'warning') return toast(message, { style: {
    backgroundColor: '#fef3c7',
  }})
}

export function showNotification(message: string) {
  toast.success(message)
}

type AlertProps = {
  variant: 'info'|'warning'|'success'|'danger';
  className?: string, dismissible?: boolean;
  showIcon?: boolean;
  children: JSX.Element;
  onClose: (e) => void;
}

export const Alert = ({ variant, className, dismissible, showIcon, children, onClose }: AlertProps) => {
  const divCn = classNames({
    'rounded-md p-4 mb-4': true,
    'bg-green-100 border border-green-200': variant === 'success',
    'bg-yellow-100 border border-yellow-200': variant === 'warning',
    'bg-red-100 border border-red-200': variant === 'danger',
    'bg-blue-100 border border-blue-200': variant === 'info',
    [className || '']: !!className,
  })

  const dismissCn = classNames({
    'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2': true,
    'text-green-500 bg-green-100 hover:bg-green-200 focus:ring-offset-green-100 focus:ring-green-600': variant === 'success',
    'text-yellow-500 bg-yellow-100 hover:bg-yellow-200 focus:ring-offset-yellow-100 focus:ring-yellow-600': variant === 'warning',
    'text-red-500 bg-red-100 hover:bg-red-200 focus:ring-offset-red-100 focus:ring-red-600': variant === 'danger',
    'text-blue-500 bg-blue-100 hover:bg-blue-200 focus:ring-offset-blue-100 focus:ring-blue-600': variant === 'info',
  })

  let icon = <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
  if (variant === 'warning') icon = <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
  if (variant === 'danger') icon = <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
  if (variant === 'info') icon = <InformationCircleIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />

  return (
    <div className={divCn}>
      <div className="flex">
        {showIcon
          ? <>
              <div className="shrink-0">
                {icon}
              </div>

              <div className="ml-3">
                {children}
              </div>
            </>
          : children
        }

        <div className="absolute top 0 right-0 mr-4">
          <div className="-mx-1.5 -my-1">
            {dismissible && (
              <button
                type="button"
                className={dismissCn}
                onClick={onClose}>
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
