import { gql } from '@apollo/client'
import { courseFragment } from 'graphql/fragments/Course'

export type UploadScormCourseContentVars = {
  courseId: string;
  fileUrl: string;
}

export type UploadScormCourseContentRes = {
  getScormLaunchLink: string;
}

export const uploadScormCourseContentMutation = gql`
  ${courseFragment}

  mutation UploadScormCourseContent ($courseId: String!, $fileUrl: String!) {
    uploadScormCourseContent (courseId: $courseId, fileUrl: $fileUrl) {
      ...CourseFragment
    }
  }
`


