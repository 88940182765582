import GearIcon from '../common/images/svg/gear.svg'
import OnboardingIcon from '../common/images/svg/onboarding.svg'
import ProgramIcon from '../common/images/svg/program.svg'
import RestartIcon from '../common/images/svg/restart.svg'
import SendIcon from '../common/images/svg/send.svg'
import SupportIcon from '../common/images/svg/support.svg'
import UndoIcon from '../common/images/svg/undo.svg'
import ArrowUpFilledIcon from '../common/images/svg/arrow-up-filled.svg'
import ArrowLeftIcon from '../common/images/svg/arrow-left.svg'
import MessageIcon from '../common/images/svg/message.svg'
import CustomIcon from '../common/images/svg/custom.svg'

export default {
  ArrowLeftIcon,
  OnboardingIcon,
  ProgramIcon,
  SupportIcon,
  SendIcon,
  UndoIcon,
  GearIcon,
  RestartIcon,
  MessageIcon,
  CustomIcon,
  ArrowUpFilledIcon,
}
