import { produce } from 'immer'
import React from 'react'
import { useMutation } from '@apollo/client'
import { updateUserPinnedCompanyIdsMutation } from 'graphql/mutations/home/updateUserPinnedCompanyIds'
import { GetCurrentSubscriptionRes, getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { useWhiteLabel } from 'context/whiteLabel'

export const PinCompany = ({ userId, companyId, pinnedCompanyIds }) => {
  const [togglePin] = useMutation(updateUserPinnedCompanyIdsMutation)
  const { whiteLabelData } = useWhiteLabel()

  const accentColor = whiteLabelData.accentColor

  const customStyle = {
    ...accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}
  }

  const renderaccentColor = accentColor ? 'text-[var(--accent-color)]' : 'text-coral'

  const isPinned = pinnedCompanyIds.includes(companyId)
  const newPinnedCompanyIds = isPinned
    ? pinnedCompanyIds.filter(s => s !== companyId)
    : pinnedCompanyIds.concat(companyId)

  return (
    <a style={customStyle} href="#" className={`icon icon-pin-solid text-lg ${isPinned ? renderaccentColor  : 'text-medwarmgray'}`} onClick={(e) => {
      e.preventDefault()
      togglePin({
        variables: {
          userId,
          companyIds: newPinnedCompanyIds,
        },
        optimisticResponse: {
          updateUserPinnedCompanyIds: !isPinned,
        },
        update: (cache, { data }) => {
          const d = cache.readQuery<GetCurrentSubscriptionRes>({
            query: getCurrentSubscriptionQuery,
            variables: { userId },
          })
          if (!d) return
          cache.writeQuery({
            query: getCurrentSubscriptionQuery,
            variables: { userId },
            data: produce(d, (draft) => {
              draft.user.pinnedCompanyIds = newPinnedCompanyIds
            }),
          })
        }
      })
    }} />
  )
}
