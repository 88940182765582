import { gql } from '@apollo/client'

export type ToggleUserFavoritedSubscriptionVars = {
  companyIds: string[];
  courseId: string;
  userId: string;
  toggled: boolean;
}

export const toggleUserFavoritedSubscriptionMutation = gql`
  mutation ToggleUserFavoritedSubscription($userId: String!, $companyIds: [String!]!, $courseId: String!, $toggled: Boolean!) {
    toggleUserFavoritedSubscription(userId: $userId, companyIds: $companyIds, courseId: $courseId, toggled: $toggled)
  }
`;