import Tippy from '@tippyjs/react'
import React from 'react'

const CalendarDay = ({ className, tooltip }: { className: string, tooltip?: string }) => {
  return (
    <div>
      <Tippy placement="bottom" content={tooltip} disabled={!tooltip}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={`pointer-events-auto ${className}`}>
          <path d="M120 0c4.4 0 8 3.6 8 8V64H320V8c0-4.4 3.6-8 8-8s8 3.6 8 8V64h48c35.3 0 64 28.7 64 64v48 16V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 176 128C0 92.7 28.7 64 64 64h48V8c0-4.4 3.6-8 8-8zM432 192H16V448c0 26.5 21.5 48 48 48H384c26.5 0 48-21.5 48-48V192zM112 120V80H64c-26.5 0-48 21.5-48 48v48H432V128c0-26.5-21.5-48-48-48H336v40c0 4.4-3.6 8-8 8s-8-3.6-8-8V80H128v40c0 4.4-3.6 8-8 8s-8-3.6-8-8z"/>
        </svg>
      </Tippy>
    </div>
  )
}

export default CalendarDay
