import React from 'react'

export const LoadingCourseItem = () => {
  return (
    <div className="w-full pr-4">
      <div className="w-full h-36 relative rounded-md skeleton-box" />

      <div className="pt-5">
        <div className="h-16">
          <div className="font-bold leading-6 h-6 skeleton-box rounded-md" />

          <div className="mt-2 h-4 skeleton-box" />
        </div>

        <div className="flex my-4">
          <div className="bg-lightwarmgray flex-1 h-2 rounded-md mr-4 relative skeleton-box" />
        </div>
      </div>
    </div>
  )
}
