import React, { useEffect, useRef, useState } from 'react'
import { Button } from './Button';
import { getAuthTokens } from 'actions/utils';
import { MiniLoader } from './MiniLoader';
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General';
import { track, trackButtonEnhanced } from 'utils/track';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import AliceCarousel from 'react-alice-carousel';
import { set } from 'cypress/types/lodash';
import Tippy from '@tippyjs/react';

type AiTextGenerationProps = {
  onChange: (value: string) => void;
  closeModal: () => void;
  userId?: string;
  courseId: string;
  chapterId?: string;
  actionId?: string;
  organizationId: string;
  existingDesc?: string;
  workspaceCanImproveText?: boolean;
}

const AiTextGeneration = ({ onChange, closeModal, userId, courseId, chapterId, actionId, existingDesc, organizationId, workspaceCanImproveText }: AiTextGenerationProps) => {
  if (!userId || !courseId) return null

  const existingDescription = existingDesc?.replace(/<div>|<\/div>|<br>/g, "")
  const token = getAuthTokens()
  const [aiBotResponse, setAiBotResponse] = useState<string[]>([])
  const [aiBotResponsePosition, setAiBotResponsePosition] = useState<number>(0)
  const [inputText, setInputText] = useState('')
  const [showAiTextResult, setshowAiTextResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const [countRefreshClicks, setCountRefreshClicks] = useState(1)
  const [refreshText, setRefreshText] = useState('')

  useEffect(() => {
    if (!inputText) {
      setRefreshText(`improve this: ${aiBotResponse}`)
    }
  }, [aiBotResponse])

  useEffect(() => {
    if (aiBotResponse.length > 1 ) {
      setAiBotResponsePosition(aiBotResponse.length - 1)
    }
  }, [aiBotResponse.length])

  const handleInputChange = (event) => {
    setInputText(event.target.value)
  };

  const handleImproveRequest = async () => {
    const apiUrl = `${BASE_API_URL}/api/v1/integrations/bot/getBotSuggestion/managedbots/ai_content_copilot/${userId}/${courseId}/${chapterId}/${actionId}`
    setLoading(true)
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-gnowbe-source': GNOWBE_SOURCE,
          'OrganizationId': organizationId,
        },
        body: JSON.stringify({ input: `Please improve the following text: ${existingDescription}`}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      setAiBotResponse(prev => prev.concat(data.output))
      if (!showAiTextResult) {
        setshowAiTextResult(true)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
      track({
        event: 'Improve Existing Ai Text Generated',
        variables: {
          programId: courseId,
          sessionId: chapterId || '',
          actionId: actionId || '',
          onScreen: 'Ai Text Generation Modal',
        },
      })
    }
  }

  const handleRequest = async (refresh?: string) => {
    const apiUrl = `${BASE_API_URL}/api/v1/integrations/bot/getBotSuggestion/managedbots/ai_content_copilot/${userId}/${courseId}/${chapterId}/${actionId}`
    setLoading(true)
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-gnowbe-source': GNOWBE_SOURCE,
          'OrganizationId': organizationId,
        },
        body: JSON.stringify({ input: refresh ? refresh : inputText }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      setAiBotResponse(prev => prev.concat(data.output))
      if (!showAiTextResult) {
        setshowAiTextResult(true)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
      track({
        event: 'New Ai Text Generated',
        variables: {
          programId: courseId,
          sessionId: chapterId || '',
          actionId: actionId || '',
          onScreen: 'Ai Text Generation Modal',
        },
      })
    }
  }

  const responsive = {
    10000000: {
      items: 1,
    },
    0: {
      items: 1,
    },
  };

  return (
    <div className="flex flex-col justify-center items-center group">
      {(!showButtons || !existingDescription) && <div className="bg-[#F9FAFB] rounded-lg p-5 font-medium text-deepgray my-6 w-full">
        {!loading && !showAiTextResult
          ? <div>
            {(!showButtons || !existingDescription) && <div>Simply click the button or describe what the text should be about.</div>}
          </div>
          : <div>
            {loading && <div className="space-y-3">
              <div className="animate-pulse w-full h-5 bg-gray-200 rounded"></div>
              <div className="animate-pulse w-3/4 h-5 bg-gray-200 rounded"></div>
              <div className="animate-pulse w-1/2 h-5 bg-gray-200 rounded"></div>
            </div>}
            {!loading && showAiTextResult && <div className="no-max-width">
              <AliceCarousel
                disableDotsControls
                responsive={responsive}
                activeIndex={aiBotResponsePosition + 1}
                onSlideChanged={(e) => {
                  aiBotResponse.filter((t, i) => {
                    if (i === e.item) {
                      setAiBotResponsePosition(i)
                    }
                  })
                }}
                onUpdated={(e) => {
                  setAiBotResponsePosition(aiBotResponse.length - 1)
                }}
                renderPrevButton={({ isDisabled }) => (
                  <button className="bg-lake-light disabled:bg-gray-50 disabled:cursor-not-allowed rounded-full shadow-md p-2" disabled={isDisabled}>
                    <ArrowLeftIcon className="h-4 w-4" />
                  </button>
                )}
                renderNextButton={({ isDisabled }) => (
                  <button className="bg-lake-light disabled:bg-gray-50 disabled:cursor-not-allowed rounded-full shadow-md p-2" disabled={isDisabled}>
                    <ArrowRightIcon className="h-4 w-4" />
                  </button>
                )}
                items={aiBotResponse.map((t, i) => (
                  <div key={i} className="w-full leading-relaxed font-normal">
                    {t}
                  </div>
                ))}
              />
            </div>}
            <div className="flex flex-row space-x-2 justify-end items-end mt-4">
              <Button text="Refresh" disabled={loading} onClick={() => {
                setCountRefreshClicks(countRefreshClicks + 1)
                track({
                  event: 'Button Refresh Clicked ' + countRefreshClicks + `${countRefreshClicks === 1 ? ' time' : ' times'}`,
                  variables: {
                    onScreen: 'Ai Text Generation Modal',
                  }
                })
                handleRequest(refreshText)
                }} />
              <Button text="Paste and Save" disabled={loading} onClick={() => {
                trackButtonEnhanced({
                  button: 'Paste and Save',
                  onScreen: 'Ai Text Generation Modal',
                }) 
                onChange(aiBotResponse[aiBotResponsePosition])
                closeModal()
                }} type="team"/>
            </div>
          </div>}
        </div>}
      {existingDescription && showButtons ? <div className="flex flex-row space-x-2 w-full justify-end items-end mt-4">
        {/* Wrapped the Button with a div to show the tooltip if Button is disabled */}
        <Tippy placement="top" content={'This feature is only available if enabled. Contact Gnowbe support to learn more.'} disabled={workspaceCanImproveText}>
          <div className="w-full">
            <Button id="improve-text-button" text="Improve Existing" onClick={() => {
              trackButtonEnhanced({
                button: 'Improve Existing',
                onScreen: 'Ai Text Generation Modal',
              })
              setShowButtons(false)
              setRefreshText(`improve this: ${existingDescription}`)
              handleImproveRequest()
              }} fullWidth={true} 
              disabled={!workspaceCanImproveText}
              />
          </div>
        </Tippy>
        <Button text="Generate New" onClick={() => {
          trackButtonEnhanced({
            button: 'Generate New',
            onScreen: 'Ai Text Generation Modal',
          })
          setShowButtons(false)
          }} type="primary" fullWidth={true}/>
      </div>
      : <div className="bg-[#CCEBEB] box-border w-full rounded-lg p-1 relative">
        <textarea className="block m-0 w-full h-[58px] resize-none outline-none border-0 rounded-lg py-4 pr-16 focus:ring-0 focus-visible:ring-0"
          onChange={(e) => handleInputChange(e)} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              setRefreshText(inputText)
              handleRequest()
              trackButtonEnhanced({
                button: 'Generate Icon',
                onScreen: 'Ai Text Generation Modal',
              })
            }
          }}></textarea>
        <Button
          className="absolute bottom-3.5 right-3.5 lg:right-6 p-0 border-none focus:ring-lake"
          svgIcon={<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="38" rx="4" fill="#89D1D2"/>
            <path d="M17.1606 20.8396L28.1606 9.8396M17.1606 20.8396L20.6602 27.8396C20.7041 27.9353 20.7745 28.0165 20.8632 28.0734C20.9518 28.1302 21.0549 28.1605 21.1602 28.1605C21.2655 28.1605 21.3686 28.1302 21.4573 28.0734C21.5459 28.0165 21.6164 27.9353 21.6602 27.8396L28.1606 9.8396M17.1606 20.8396L10.1602 17.3396C10.0645 17.2957 9.98335 17.2253 9.92647 17.1367C9.86959 17.048 9.83936 16.9449 9.83936 16.8396C9.83936 16.7343 9.86959 16.6312 9.92647 16.5425C9.98335 16.4539 10.0645 16.3835 10.1602 16.3396L28.1606 9.8396" stroke="#163B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>}
          onClick={() => {
            trackButtonEnhanced({
              button: 'Generate Icon',
              onScreen: 'Ai Text Generation Modal',
            })
            setRefreshText(inputText)
            handleRequest()
          }}
          disabled={loading || !inputText}
          text=''
        />
      </div>}
    </div>
  )
}

export default AiTextGeneration;