import * as logger from '../logger'
import { showAlert } from '../components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from '../constants/General'
import { ActionKeyData } from '../models/utils'
import { checkHttpStatus, getAuthTokens } from './utils'
import { refetch } from 'utils/functions'
import { track } from 'utils/track'

export interface ShareKeyData extends ActionKeyData {userId: string}

export function likeShare(share: ShareKeyData, like: boolean) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/${like ? 'liked' : 'unliked'}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify(share),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then(async (response) => {
    if (response.error) {
      return showAlert(`Error - ${response.error.message}`, 'danger')
    }

    track({
      event: 'Group Share Liked',
      variables: {
        company_id: share.companyId,
        programId: share.courseId,
      },
    })
  })
  .catch((err) => {
    logger.error(`likes_actions: likeShare error: ${err.message}`, err)
  })
}

export function shareLikeSeen(data: ActionKeyData) {
  if (!data || !data.chapterId || !data.actionId) return

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/like/seen`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId: data.companyId,
      courseId: data.courseId,
      chapterId: data.chapterId,
      actionId: data.actionId,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      return showAlert(`Error - ${response.error.message}`, 'danger')
    }


  })
  .catch((err) => {
    logger.error(`likes_actions: shareLikeSeen error: ${err.message}`, err)
  })
}
