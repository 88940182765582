import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'
import { Action } from 'graphql/schemas/action/Action'

export type ActionsForCourse = {
  chapters: {
    id: string;
    courseId: string;
    order: number;
    actions: Action[];
  }[];
}

export const getActionsForCourseQuery = gql`
  ${actionFragment}

  query GetActionsForCourse ($companyId: String, $courseId: String!, $returnAllAssessments: Boolean) {
    chapters: getChaptersForCourse (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments) {
      id
      courseId
      order
      actions {
        ...ActionFragment
      }
    }
  }
`
