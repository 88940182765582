import { gql } from '@apollo/client'

export type ImportPPTCourseVars = {
  courseId: string;
  pptUrl: string;
}

export const importPPTCourseMutation = gql`
  mutation ImportPPTCourse ($courseId: String!, $pptUrl: String!) {
    importPPTCourse (courseId: $courseId, pptUrl: $pptUrl)
  }
`
