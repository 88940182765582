import { gql } from '@apollo/client'

export type GetMyReferralBannerDataRes = {
  ref: {
    referrerName: string;
    daysLeftToJoin: number;
    discountPercent: number;
  };
}

export const getMyReferralBannerDataQuery = gql`
  query GetMyReferralBannerData {
    ref: getMyReferralBannerData {
      referrerName
      daysLeftToJoin
      discountPercent
    }
  }
`
