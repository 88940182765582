import React, { useEffect, useRef } from 'react'

const OutsideAlerter = (props: { children: any, alert: boolean, classname?: string, onAlert: () => void }) => {
  const _wrapperRef = useRef<any>(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const handleClickOutside = (e) => {
    if (_wrapperRef && !_wrapperRef.current.contains(e.target) && props.alert) {
      props.onAlert()
    }
  }

  return (
    <div ref={_wrapperRef} className={props.classname || ''}>
      {props.children}
    </div>
  )
}

export default OutsideAlerter
