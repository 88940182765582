import { gql } from '@apollo/client'

export type UpdateUserPinnedCompanyIdsVars = {
  userId: string;
  companyIds: string[];
}

export const updateUserPinnedCompanyIdsMutation = gql`
  mutation UpdateUserPinnedCompanyIds ($userId: String!, $companyIds: [String!]!) {
    updateUserPinnedCompanyIds (userId: $userId, companyIds: $companyIds)
  }
`
