import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { showModal } from 'components/utils/CustomModal'
import { changeCurrentCourse, updateMeta } from 'actions/users_actions'
import { CourseKeyData } from 'models/utils'
import { client, updateAppCache } from 'app'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { Button } from 'components/utils/Button'
import PublishDialog from 'components/course/Publish'
import { showDrawer } from 'components/utils/Drawer'
import { useAppState } from 'graphql/hooks/useAppState'
import { useQuery } from '@apollo/client'
import { UpsellModal } from 'components/modals/UpsellModal'
import ShareProgramTabs from 'components/utils/ShareProgramTabs'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetCourseSubDataRes, GetCourseSubDataVars, getCourseSubDataQuery, GetCourseDetailsRes, getCourseDetailsQuery, GetCourseDetailsVars } from 'graphql/queries/course/getCourseWithData'
import { isAgent } from 'graphql/schemas/user/User'
import { GetExploreCoursesRes, GetExploreCoursesVars, getExploreCoursesQuery } from 'graphql/queries/explore/getExplorescreenData'
import { useLocation, useNavigate } from 'react-router-dom'
import { CompanyPicker } from '../course-list/Header'
import { getCourseCompanies } from 'graphql/schemas/user/UserSubscription'
import { BASE_DASHBOARD_URL } from 'constants/General'
import { checkMeta, isColorDark } from 'utils/functions'
import { GetUserSubscriptions2Res, GetUserSubscriptionsVars, getUserSubscriptions2Query } from 'graphql/queries/user/getUserSubscriptions'
import { useWhiteLabel } from 'context/whiteLabel'

type GnowbefyButtonProps = {
  back?: boolean;
  home?: boolean;
  navbar?: boolean;
  yellow?: boolean;
  isEditing: boolean;
  courseId: string;
  currentSubscription: string;
  urlCourseKey: CourseKeyData|null;
  collapsed?: boolean;
  email?: string;
  isPublisher: boolean;
  isIndependentEditor: boolean;
  fullWidth?: boolean;
  onPublish: () => void;
  subData: any;
  companyId: string;
  metadata?: any;
}

const SendNotifications = ({ courseId, close, creatorInOrganizations, isAgent }: { courseId: string, close: () => void, creatorInOrganizations: any, isAgent: boolean }) => {
  const appData = client.readQuery<AppState>({ query: getAppStateQuery })
  const { data: companiesData, loading: companiesLoading } = useQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>(getUserSubscriptions2Query, { variables: {
    userId: appData?.appState.loggedInAs.uid || '',
    archived: false,
  }})

  if (companiesLoading || !companiesData) {
    return null
  }
  const companies = getCourseCompanies(companiesData ,courseId || '')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [showContent, setShowContent] = useState(false)
  const liveCompanies = companies.filter(c => !['test'].includes(String(c.companyType)))
  const organizationId = companies.find(c => c.companyType !== 'test' && c.id === selectedCompany)?.organizationId
  const isCreatorOfRedirectedOrg = creatorInOrganizations.includes(organizationId)

  useEffect(() => {
    if (companiesLoading || !companiesData) return
    if (liveCompanies.length !== 0) {
      setSelectedCompany(liveCompanies[0].id)
    }
  }, [])


  const canSendMessages = isCreatorOfRedirectedOrg || isAgent

  if (liveCompanies.length === 0) {
    return (
      <div>{intl.get('program_not_distributed_to_any_group')}</div>
    )
  }
  if (!canSendMessages) {
    return (
      <div className="text-center">
        <div className="mb-9">
          <img className="w-auto inline-block h-12" src="/images/icon-envelope.svg" />
        </div>
        <div className="font-bold text-2xl px-3 mb-2">{intl.get('not_allowed_to_send_notifications_1')}</div>
        <p className="px-3">{intl.get('not_allowed_to_send_notifications_2')}</p>
      </div>
    )
  }

  return (
    <div>
      <div>{intl.get('send_notifications_1')}</div>
      <CompanyPicker companies={companies} setSelectedCompany={setSelectedCompany} setShowContent={setShowContent} selectedCompany={selectedCompany} />
      <div>
        {showContent && (
          <div>
            <div className="my-4">{intl.get('send_notifications_2')}</div>
            <div className="flex items-center justify-end gap-4" data-test="send-notif-modal-btn">
              <Button disabled={!selectedCompany} text={intl.get('not_now')} onClick={() => close()}/>
              <a className="btn-v2 primary cursor-pointer" href={`${BASE_DASHBOARD_URL}/organization/${organizationId}/company/${selectedCompany}#messaging`} target="_blank" onClick={() => {
                close()
              }}>{intl.get('send')}</a>
            </div>
          </div>
            )}
      </div>
    </div>
  )
}

const GnowbefyButton = ({ back, home, navbar, isEditing, courseId, currentSubscription, urlCourseKey, collapsed, isPublisher, isIndependentEditor, onPublish, fullWidth, subData, companyId, metadata }: GnowbefyButtonProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const appState = useAppState()
  const { whiteLabelData } = useWhiteLabel()
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [subCompanyId, subCourseId] = currentSubscription.split('-')
  const isOnCurrentCourseDetails = !urlCourseKey || (subCompanyId === urlCourseKey.companyId && subCourseId === urlCourseKey.courseId)
  const [showNewDot, setshowNewDot] = useState(!checkMeta(metadata, '/general/sendNotificationsFromWebClicked'))

  const { data: courseSubData } = useQuery<GetCourseSubDataRes, GetCourseSubDataVars>(getCourseSubDataQuery, {
    skip: !appData || !subData || !subData.user.subscriptions.includes(`${companyId}-${courseId}`),
    variables: {
      companyId,
      courseId,
      userId: subData.user.id,
    },
    fetchPolicy: appData?.appState.isEditing ? 'cache-first' : 'cache-and-network',
  })


  const { data: courseData } = useQuery<GetCourseDetailsRes, GetCourseDetailsVars>(getCourseDetailsQuery, {
    skip: !appData || !subData,
    variables: { courseId },
    fetchPolicy: appData?.appState.isEditing ? 'cache-first' : 'cache-and-network',
  })

  const { data: coursesData } = useQuery<GetExploreCoursesRes, GetExploreCoursesVars>(getExploreCoursesQuery, {
    skip: !appData,
    variables: { userId: appData?.appState.loggedInAs.uid || '' },
  })

  const primaryColor = whiteLabelData.primaryColor
  const bgColor = whiteLabelData.bgColor

  const course = courseData && courseData.course || coursesData?.res.subscriptions.find(s => s.companyId === companyId && s.courseId === courseId)?.course || null

  if (!course) {
    return (
      <div>No program found.</div>
    )
  }

  const isAgentOfCurrentCourse = isAgent(subData.user, courseSubData?.subscription.company.organizationId, courseSubData?.subscription.company.id)
  const canInviteToCurrentCourse = courseSubData?.subscription.company.peerInvitationMode === 'approval' || courseSubData?.subscription.company.peerInvitationMode === 'open' || isAgentOfCurrentCourse
  const isEditorOfOpenCourse = subData?.user.editorCourses.includes(course.id)

  const toggleEditMode = () => {
    updateAppCache('isEditing', !isEditing)

    track({ event: '"Edit" Button Clicked' })

    const [cmpId, crsId] = currentSubscription.split('-')

    if (isEditing && courseId !== crsId) {
      showModal({
        title: 'Switch Program?',
        content: 'You switched to another program (in another tab or on your phone). Would you like to switch here as well?',
        primaryText: 'Switch to the new program',
        primaryAction: () => window.location.reload(),
        secondaryText: 'Stay on this program',
      })
    }

    if (urlCourseKey && !isEditing) {
      changeCurrentCourse({ courseKey: urlCourseKey, landing: `/details/${urlCourseKey.courseId}`, enableEditing: true })
    }
  }

  const cn = classNames({
    'gnowbefy-btn': true,
    'w-full': fullWidth,
    selected: isEditing && isOnCurrentCourseDetails,
  })

  if (collapsed) {
    return (
      <Tippy placement="right" content={isEditing ? 'Switch to View Mode' : 'Switch to Edit Mode'}>
        <button className={`icon ${isEditing ? 'icon-eye-light' : 'icon-edit-light'} font-bold text-lg w-12 h-12 rounded-md hover:text-coral`} onClick={toggleEditMode} />
      </Tippy>
    )
  }

  if (navbar) {
    return (
      <div className="flex flex-col md:flex-row gap-3">
        {(location.pathname.includes(`details/${courseId}`) || location.pathname.includes('learn')) && <Button
          id="send-notifications"
          text={intl.get('send_notifications')}
          type="edit"
          icon="icon-envelope-light"
          className={`${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
          style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
          showNewDot={showNewDot}
          onClick={() => {
            const modal = showModal({
              title: `${intl.get('send_notifications')}`,
              component: <SendNotifications courseId={courseId} close={() => modal.close()} creatorInOrganizations={subData?.user?.creatorInOrganizations} isAgent={isAgentOfCurrentCourse} />,
              hideFooter: true,
            })
            updateMeta('sendNotificationsFromWebClicked', 'true', true)
            setshowNewDot(false)
          }}
          tooltip={intl.get('send_notifications_tooltip')}
        />}
        <Button
          id={isEditing ? 'menu_save_view' : ''}
          text={!isEditing ? intl.get('menu_edit') : intl.get('view_group')}
          type="edit"
          icon={isEditing ? 'icon-eye-light' : 'icon-edit-light'}
          className={`${fullWidth ? 'w-full' : ''} ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
          style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
          onClick={() => {
            trackButton({
              button: 'edit_chapter_icon',
              on_screen: 'session',
            })

            trackButtonEnhanced({
              button: !isEditing ? intl.get('menu_edit') : 'Save And View',
              onScreen: !isEditing ? 'Edit Button' : 'Save And View Button',
            })

            toggleEditMode()
          }}
        />

        {(isPublisher || isIndependentEditor) && (
          <div className="flex flex-row">
            <Button
              text={intl.get('invite_co_curator_title')}
              tooltip={intl.get('invite_co_curator_tooltip')}
              icon="icon-users-solid"
              className={`${fullWidth ? 'w-full' : ''} ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
              style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
              onClick={() => {

                if (isIndependentEditor && !sessionStorage.getItem('share_upsell_shown')) {
                  sessionStorage.setItem('share_upsell_shown', 'true')
                  const modal = showModal({
                    title: intl.get('home_group_invite'),
                    component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
                    onlyContent: true,
                    className: 'add-action-modal',
                  })
                  return
                }

                trackButton({
                  button: 'share_course',
                  on_screen: 'three_dots',
                })

                trackButtonEnhanced({
                  button: 'Share Program',
                  onScreen: 'Invite Co-curator Button',
                })

                const modal = showModal({
                  title: intl.get('program_share_sandbox'),
                  component: (<>
                    <ShareProgramTabs canInvite={canInviteToCurrentCourse} company={courseSubData?.subscription.company} course={course} groupName={courseSubData?.subscription.company.name || 'Group'} isAgent={isAgentOfCurrentCourse} isEditorOfOpenCourse={isEditorOfOpenCourse} isPublisher={isPublisher} isIndependentEditor={isIndependentEditor} isGnowbefyButton={true} location={location.pathname} isEditing={isEditing} close={() => modal.close()} />
                  </>),
                  secondaryButton: false,
                  primaryText: intl.get('global_close'),
                  hideFooter: true,
                })
              }}
            />
          </div>
          // <ShareButton courseId={courseId} location="header_menu" />
        )}

        {isEditing && (isPublisher || isIndependentEditor) && (
          <Button
            text={intl.get('share_text')}
            id="publish-program-button"
            type="team"
            icon="icon-share-light"
            style={primaryColor ? { '--primary-color': primaryColor } : {}}
            className={`flex-1 px-3 ${fullWidth ? 'w-full mt-2' : ''} ${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'} ${primaryColor === bgColor && 'shadow-md'}`}
            onClick={() => {
              trackButton({
                button: 'publish',
                on_screen: 'session',
                user_id: appState?.loggedInAs.uid,
              })

              trackButtonEnhanced({
                button: 'Share',
                onScreen: 'Share Button (Session)',
              })

              navigate('/home/publish#share')
            }}
          />
        )}
      </div>
    )
  }

  if (home) {
    if (back) return null

    if (!isEditing) {
      return (
        <Button
          text={intl.get('menu_edit')}
          type="team"
          icon="icon-edit-light"
          fullWidth={true}
          onClick={toggleEditMode}
        />
      )
    }

    return (
      <div className="space-y-2">
        <Button
          text={intl.get('share_text')}
          icon="icon-upload-light"
          fullWidth={true}
          onClick={() => {
            trackButton({
              button: 'publish_course',
              on_screen: 'edit_session',
            })

            trackButtonEnhanced({
              button: 'Share',
              onScreen: 'Share Button (Session - Edit)',
            })

            const drawer = showDrawer({
              footer: { hide: true },
              maxWidth: 'max-w-3xl',
              component: <PublishDialog currentCompanyType={null} refetchQuery={() => null} onClose={() => drawer?.current.close()} />,
              fromRight: true,
            })
          }}
        />

        <Button
          text={intl.get('edit_program_details')}
          icon="icon-library-light"
          fullWidth={true}
          onClick={() => navigate(`/details/${currentSubscription.split('-')[1]}`)}
        />
      </div>
    )
  }

  return (
    <a className={cn} onClick={() => toggleEditMode()}>
      <span className="icon-navbar" />
      {isEditing && isOnCurrentCourseDetails
        ? <span className="navbar-label">{intl.get('menu_save_view')}</span>
        : <span className="navbar-label hidden lg:inline">{intl.get('menu_edit')}</span>
      }
    </a>
  )
}

export default GnowbefyButton
