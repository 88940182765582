import { gql } from '@apollo/client'
import { progressFragment } from 'graphql/fragments/Progress'
import { studyFragment } from 'graphql/fragments/Study'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'

export type GQLStudyForSubscriptionResponse = {
  study: UserStudy[];
}

export type GQLStudyForSubscription2Response = {
  study: UserStudy[];
  sub: UserSubscription;
}

export type GQLStudyForSubscriptionVariables = {
  companyId: string;
  courseId: string;
  userId: string;
}

export const getStudyForSubscriptionQuery = gql`
  ${studyFragment}

  query GetStudyForSubscription ($companyId: String!, $courseId: String!, $userId: String!) {
    study: getStudyForSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      ...StudyFragment
    }
  }
`

export const updateStudyQuery = gql`
  ${timestampsFragment}

  query UpdateStudy ($companyId: String!, $courseId: String!, $userId: String!) {
    study: getStudyForSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      companyId
      courseId
      chapterId
      actionId
      answer
      thumbnail
      sharedAt
      viewed
      rewardsSeen
      contentType
      isAssessmentCorrect
      retriesBlocked
      reminder {
        id
        at
      }
      timestamps {
        ...TimestampsFragment
      }
    }
  }
`

export const resetStudyQuery = gql`
  ${progressFragment}

  query ResetStudy ($companyId: String!, $courseId: String!, $userId: String!) {
    study: getStudyForSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      companyId
      courseId
      chapterId
      actionId
      answer
      thumbnail
      contentType
      sharedAt
      viewed
      isAssessmentCorrect
      retriesBlocked
      reminder {
        id
        at
      }
    }
    sub: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      progress {
        ...ProgressFragment
      }
    }
  }
`
