import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { rewardFragment } from 'graphql/fragments/Reward'

export const actionFragment = gql`
  ${timestampsFragment}
  ${rewardFragment}

  fragment ActionFragment on Action {
    id
    courseId
    chapterId
    order
    userAction
    title
    description
    placeholderAnswer
    imageUrl
    contentUrl
    contentUrlOrg
    contentUrlOrgEnabled
    contentUrl_720p
    contentUrlThumbnail
    contentUrlAlt
    audioUrl
    singleChoice
    interactionTime
    mcPoolingEnabled
    hints
    lives
    rewards {
      ...RewardFragment
    }
    timestamps {
      ...TimestampsFragment
    }
    # todo(rudik): fetch only for curators
    invisible
    contentType
    assessmentsNotStarted
    manualReviewMode
  }
`

export const editorActionFragment = gql`
  fragment EditorActionFragment on Action {
    invisible
    contentType
    manualReviewMode
  }
`
