import React from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/utils/Button'
import { copyToClipboard } from 'utils/functions'
import { useNavigate } from 'react-router-dom'

export const ExploreBanner = ({ name, type, url }: { name: string, type: 'explore'|'pending'|'refer'|'ad', url?: string }) => {
  if (type === 'refer') {
    if (!url) return null

    return (
      <div className="bg-blue-200 px-6 py-4 rounded-md my-8">
        <div className="flex flex-1 items-center">
          <div className="flex-1 flex flex-col">
            <div className="text-lg font-bold">{intl.get('gnowbelearn_referral_title', { 0: name })}</div>
            <div>
              {intl.get('gnowbelearn_referral_text')}
              <a href={url} target="_blank" className="font-bold text-coral underline ml-1">{intl.get('global_learn_more')}</a>
            </div>

            <div className="mt-4 flex space-x-4">
              <input type="text" className="form-input flex-1" value={url} onClick={(e) => {
                const el = e.target as HTMLInputElement
                el.focus()
                el.select()
              }} onChange={(e) => {
                e.preventDefault()
              }} />
              <Button type="primary" text={intl.get('global_copy_link')} onClick={() => copyToClipboard(url)} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'ad') {
    return (
      <div className="bg-[#656CFF] px-6 py-4 rounded-md my-8">
        <div className="flex flex-1 items-center">
          <div className="flex-1 flex flex-row items-center text-white">
            <div className="flex-1">
              <div className="text-lg font-bold">{intl.get('hello')}, {name}!</div>
              <div>{intl.get('banner_starter_promo_text')}</div>
            </div>

            <div>
              <Button type="ad" text={intl.get('global_find_out_more')} onClick={() => window.open('https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=banner&utm_campaign=springsale')} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'pending') {
    return (
      <div className="bg-yellow-200 px-6 py-4 rounded-md my-8">
        <div className="flex flex-1 items-center">
          <div className="flex-1 flex flex-col">
            <div className="text-lg font-bold">{intl.get('gnowbelearn_explore_pending_title')}</div>
            <div>{intl.get('gnowbelearn_explore_pending_text', { 0: 'support@gnowbe.com' })}</div>
          </div>
        </div>
      </div>
    )
  }

  const navigate = useNavigate()

  return (
    <div className="bg-yellow-200 px-6 py-4 rounded-md mb-8">
      <div className="flex flex-1 items-center">
        <div className="flex-1 flex flex-col">
          <div className="text-lg font-bold">Hello, {name}!</div>
          <div>{intl.get('gnowbelearn_explore_text')}</div>
        </div>

        <div>
          <Button type="primary" text={intl.get('gnowbelearn_explore_button')} onClick={() => navigate('/home/explore')} />
        </div>
      </div>
    </div>
  )
}
