import * as logger from '../logger'
import { BASE_API_URL, GNOWBE_SOURCE } from '../constants/General'
import { CourseKeyData } from '../models/utils'
import { checkHttpStatus, getAuthTokens } from './utils'
import { ShareKeyData } from './likes_actions'
import { refetch } from 'utils/functions'
import { track } from 'utils/track'

export function wallSeen(data: CourseKeyData) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/walls/${data.companyId}-${data.courseId}/seen`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, true))
  .then((response) => {
    track({
      event: 'Group Opened',
      variables: {
        company_id: data.companyId,
        programId: data.courseId,
      },
    })
    return response.json()
  })
  .catch((err) => {
    logger.error(`users_actions: wallSeen error: ${err.message}`, err)
  })
}

export function wallRemove(data: ShareKeyData) {
  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/walls/${data.companyId}-${data.courseId}/post/${data.chapterId}-${data.actionId}-${data.userId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  .then(response => checkHttpStatus(response, true))
  .then((response) => {
    track({
      event: 'Group Share Removed',
      variables: {
        company_id: data.companyId,
        programId: data.courseId,
      },
    })
    return response.json()
  })
  .catch((err) => {
    logger.error(`users_actions: wallSeen error: ${err.message}`, err)
  })
}
