import { gql } from '@apollo/client'

export type ExportCourseContentVars = {
  courseId: string;
}

export const exportCourseContentQuery = gql`
  query ExportCourseContent ($courseId: String!) {
    exportCourseContent (courseId: $courseId) {
      availableAt
      emailTo
      exportId
    }
  }
`
