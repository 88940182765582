import React from 'react'
import intl from 'react-intl-universal'
import { format } from 'date-fns'
import PopoverMenu from 'components/utils/PopoverMenu'
import PopoverListener from 'components/utils/Popover'
import { saveNote } from 'actions/courses_actions'
import { AdvancedEditable } from 'components/utils/AdvancedEditable'
import { useQuery } from '@apollo/client'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetOrganizationsRes, GetOrganizationsVars, getOrganizationsQuery } from 'graphql/queries/organization/getOrganziations'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import { useWhiteLabel } from 'context/whiteLabel'

interface ChapterDescriptionProps {
  firstName: string;
  description: string;
  notes: string;
  companyId: string;
  courseId: string;
  chapterId: string;
  isEditing: boolean;
  greetingsDisabled: boolean;
  update: (v: string) => void;
  rtlDir?: React.CSSProperties | {} | undefined;
}

export const ChapterDescription = (props: ChapterDescriptionProps) => {

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })
  const activeWorkspaceId = useActiveWorkspaceId()

  const workspaceCanGenerateText = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('generateTextAi')
  const workspaceCanImproveText = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('improveTextAi')

  const { whiteLabelData } = useWhiteLabel()

  const { chapterId, firstName, description, isEditing, notes, update } = props
    let greeting = [intl.get('good_evening')]
    const currentTime = parseInt(format(Date.now(), 'HH'))
    if (currentTime >= 5 && currentTime < 12) {
      greeting = [intl.get('good_morning')]
    } else if (currentTime >= 12 && currentTime < 16) {
      greeting = [intl.get('good_afternoon')]
    } else if (currentTime >= 16 && currentTime < 23) {
      greeting = [intl.get('good_evening')]
    } else {
      greeting = [intl.get('hello')]
    }

  const addToNotes = (text: string) => {
    const { notes, companyId, courseId, chapterId } = props
    if (text !== '') {
      let newNote = notes || ''
      newNote += `${
        notes === '' ? '\n' : ''
      }{{chapter:notes:${chapterId}:${encodeURIComponent(text)}}}\n`
      saveNote(newNote, { companyId, courseId }, true)
    }
  }
  return (
    <div className="mt-8">
      {!props.greetingsDisabled &&
        <div className="session-description mb-6">
          {greeting[Math.floor(Math.random() * greeting.length)]} {firstName},
        </div>
      }

      <PopoverMenu isEditing={isEditing} addToNotes={addToNotes}>
        <PopoverListener triggerClass="explainer">
          <AdvancedEditable
            key={`${chapterId}-chapter-description-${isEditing ? 'editing' : 'learning'}`}
            id={`chapter-description-${chapterId}`}
            defaultValue={description}
            placeholder={intl.get('chapter_placeholder_type_description')}
            isEditing={isEditing}
            additionalFormats={['blockquote']}
            update={update}
            notes={notes}
            divClassName="session-description"
            help="firehelp_chapter_description"
            showAiGenerateText={workspaceCanGenerateText}
            courseId={props.courseId}
            chapterId={chapterId}
            organizationId={activeWorkspaceId}
            workspaceCanImproveText={workspaceCanImproveText}
            divDirection={props.rtlDir}
          />
        </PopoverListener>
      </PopoverMenu>
    </div>
  )
}
