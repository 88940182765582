import { gql } from '@apollo/client'
import { UserProfile } from 'graphql/schemas/user/User'

export type SearchChatMembersRequest = {
  skip: number;
  limit: number;
  searchText: string;
  urn: string;
}

export type SearchChatMembersResponse = {
  members: {
    id: string,
    profile: Pick<UserProfile, 'fullName'|'imageUrl'>,
  }[];
}

export const searchChatMembersQuery = gql`
  query SearchChatMembers ($skip: Int!, $limit: Int!, $searchText: String!, $urn: String!) {
    members: searchChatMembers (skip: $skip, limit: $limit, searchText: $searchText, urn: $urn) {
      id
      profile {
        id
        fullName
        imageUrl
      }
    }
  }
`
