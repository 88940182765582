import { gql } from '@apollo/client'

export const listenChatChannelChangesSubscription = gql`
  subscription ChatChannelChanges ($urn: String!) {
    listenChatChannelChanges (urn: $urn) {
      ... on ChatMessageAdded {
        urn
        message {
          id
          urn
          userId
          user {
            id
            profile {
              id
              firstName
              lastName
              fullName
              imageUrl
            }
          }
          message
          createdAt
          edited
          deleted
        }
      }

      ... on ChatMessageDeleted {
        urn
        messageId
      }

      ... on ChatChannelMemberAdded {
        urn
        member {
          userId
          user {
            id
            profile {
              id
              firstName
              lastName
              fullName
              imageUrl
            }
          }
        }
      }
    }
  }
`
