import { useWhiteLabel } from 'context/whiteLabel'
import React from 'react'
import intl from 'react-intl-universal'

export const ChapterOrder = ({ order, courseTitle, isEditor }: { order: number, courseTitle: string, isEditor?: boolean }) => {
  const { whiteLabelData } = useWhiteLabel()
  const [isFixed, setIsFixed] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false)
  const scrollContainer = document.getElementById('learn-main')
  const stickContainer = document.querySelector('.stick-container')
  const areWhiteLabelColorsSet = whiteLabelData?.primaryColor || whiteLabelData?.accentColor || whiteLabelData?.bgColor

  const title: any = React.useRef()

  function isElementVisible (el, holder) {
    holder = holder || document.body
    const { top, bottom, height } = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()
    // be sure to add enough space to holderRect.top to change the position earlier
    return top <= holderRect.top
      ? (holderRect.top + 220) - top <= height
      : bottom - holderRect.bottom <= height
  }

  React.useEffect(() => {
    if (isEditor) return
    if (scrollContainer) {
      setIsVisible(isElementVisible(stickContainer, scrollContainer))
      scrollContainer.addEventListener('scroll', handleOnScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [scrollContainer])

  const handleOnScroll = () => {
    const rect = title?.current?.getBoundingClientRect()
    setIsVisible(isElementVisible(stickContainer, scrollContainer))

    if (rect.top === 20) {
      setIsFixed(true)
    }
    if (isVisible) {
      setIsFixed(false)
    }
  }

  return (
    <div ref={title} id="session-number" className={`session-number mt-4 md:mt-0 text-teak z-[99999] ${isFixed && !isVisible && !isEditor ? 'sm:relative md:fixed fixed-session-number' : !isEditor ? 'md:sticky top-5': ''}`}>
      <div className={`scroller ${areWhiteLabelColorsSet && 'text-deepgray'}`}>
        <div className="course-title">{intl.get('session_number', { 0: order })}</div>
        <div>{courseTitle}</div>
      </div>
    </div>
  )
}
