import React, { useEffect, useRef, useState } from 'react'
import { produce } from 'immer'
import { useMutation } from '@apollo/client'
import { showAssessmentResults } from 'components/sliders/Assessments'
import { endChapterAssessmentsMutation } from 'graphql/mutations/chapter/endChapterAssessments'
import { startChapterAssessmentsMutation, StartChapterAssessmentsRes, StartChapterAssessmentsVars } from 'graphql/mutations/chapter/startChapterAssessments'
import { getActionsForCourseQuery } from 'graphql/queries/action/getActionsForCourseQuery'
import { getEngagementsForSubscriptionQuery } from 'graphql/queries/user/getEngagementsForSubscription'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { Course } from 'graphql/schemas/course/Course'
import { chapterAssessmentsEnded, UserEngagement } from 'graphql/schemas/user/UserEngagement'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import * as logger from 'logger'
import { useNavigate } from 'react-router'
// import { startSupervision, stopSupervision } from 'utils/supervisor'

export type ChapterStatus = {status: 'nolimit'} |
  {status: 'not_started', timeLimit: number} |
  {status: 'starting', timeLimit: number } |//, proctoring: {token: string}|null} |
  {status: 'started', startedAt: number, timeLimit: number } | //, proctoring: {token: string}|null} |
  {status: 'ended'}

export type AssessmentsSupervisorCtxType = {
  initialized: boolean,
  chapters: Map<string, ChapterStatus>,
  startForChapter: (chapterId: string) => Promise<void>,
}

export const AssessmentsSupervisorCtx = React.createContext<AssessmentsSupervisorCtxType|null>(null)

type AssessmentsSupervisorProps = {
  userId: string, companyId: string, course: Course, chapters: Chapter[],
  progress: UserProgress|null,
  engagements: UserEngagement[]|null,
  isEditing: boolean,
  isEditor: boolean,
  children: any,
}

export const AssessmentsSupervisor = (propsIn: AssessmentsSupervisorProps) => {
  const navigate = useNavigate()
  const propsRef = useRef(propsIn)
  propsRef.current = propsIn

  const supervisorStateRef = useRef<AssessmentsSupervisorCtxType>({
    initialized: false,
    chapters: new Map(),
    startForChapter: () => Promise.resolve(), // This is initiated later to real function
  })
  const timersRef = useRef(new Map<string, any>())

  const [supervisorState, setSupervisorState] = useState(supervisorStateRef.current)

  const setSupervisorStateWrapper = (state: AssessmentsSupervisorCtxType) => {
    supervisorStateRef.current = state
    setSupervisorState(state)
  }

  const [startChapterAssessmentsMut] = useMutation<StartChapterAssessmentsRes, StartChapterAssessmentsVars>(startChapterAssessmentsMutation)

  // const [startChapterProctoringMut] = useMutation<StartChapterProctoringRes, StartChapterProctoringVars>(startChapterProctoringMutation)

  const [endChapterAssessmentsMut] = useMutation(endChapterAssessmentsMutation)

  const setTimer = (timerId: string, timer) => {
    const exTimer = timersRef.current.get(timerId)
    exTimer && clearTimeout(exTimer)
    timersRef.current.set(timerId, timer)
  }

  const cleanTimer = (timerId: string) => {
    const exTimer = timersRef.current.get(timerId)
    if (exTimer) {
      clearTimeout(exTimer)
      timersRef.current.delete(timerId)
    }
  }

  const checkAndSetAssessmentsTimer = () => {
    const chapters = propsRef.current.chapters.filter(c => c.assessmentMode?.enabled)
    const engagements = propsRef.current.engagements?.filter(e => e && e.assessmentsStartedAt > 0)
    let highestTimeLeft = 0
    for (const chapter of chapters) {
      const engagement = engagements?.find(c => c.chapterId === chapter.id)
      if (chapter.assessmentMode?.timeLimit && engagement?.assessmentsStartedAt && !engagement.assessmentsEnded &&
        chapter.assessmentMode.timeLimit >= (Date.now() - engagement.assessmentsStartedAt)
      ) {
        const timeLeft = chapter.assessmentMode.timeLimit - (Date.now() - engagement.assessmentsStartedAt)
        if (timeLeft > highestTimeLeft) {
          highestTimeLeft = timeLeft
        }
      }
    }

    if (!highestTimeLeft) return

    cleanTimer('showAssessmentsResScreen')
    setTimer('showAssessmentsResScreen', setTimeout(() => {
      showAssessmentResults({
        userId: propsRef.current.userId,
        companyId: propsRef.current.companyId,
        course: propsRef.current.course,
        isEditor: propsRef.current.isEditor,
      })
    }, highestTimeLeft))
  }

  const startAssessments = async (chapterId: string) => {
    await startChapterAssessmentsMut({
      variables: {
        companyId: propsRef.current.companyId, courseId: propsRef.current.course.id, chapterId,
        returnAllAssessments: propsRef.current.isEditor,
      },
      update: (proxy, { data: mutationRes }) => {
        checkAndSetAssessmentsTimer()
        const q1 = {
          query: getActionsForCourseQuery,
          variables: {
            companyId: propsRef.current.companyId,
            courseId: propsRef.current.course.id,
            returnAllAssessments: propsRef.current.isEditor,
          },
        }
        const data: any = proxy.readQuery(q1)
        if (!data) return
        proxy.writeQuery({
          ...q1,
          data: produce(data, (draft) => {
            const index = draft.chapters.findIndex(c => c.id === chapterId)
            if (index !== -1) draft.chapters[index].actions = mutationRes?.startChapterAssessments
          }),
        })

        const q2 = {
          query: getEngagementsForSubscriptionQuery,
          variables: {
            companyId: propsRef.current.companyId,
            courseId: propsRef.current.course.id,
            userId: propsRef.current.userId,
          },
        }
        const data2: any = proxy.readQuery(q2)
        if (!data2) return

        proxy.writeQuery({
          ...q2,
          data: produce(data2, (draft) => {
            const eng = draft.engagement.find(e => e.chapterId === chapterId)
            if (eng) {
              eng.assessmentsStartedAt = eng.assessmentsStartedAt || Date.now()
            }
          }),
        })
      },
    })
  }

  const resetForChapter = (chapterId: string) => {
    const chapter = propsRef.current.chapters.find(ch => ch.id === chapterId)
    setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
      draft.chapters.set(chapterId, {
        status: chapter?.assessmentMode?.timeLimit ? 'not_started' : 'nolimit',
        timeLimit: chapter?.assessmentMode?.timeLimit || 0,
      })
    }))
  }

  const stopForChapterRef = useRef(async (chapterId: string, type: 'ended'|'stopped') => {
    const status = supervisorStateRef.current.chapters.get(chapterId)
    const eng = propsRef.current.engagements?.find(e => e.chapterId === chapterId)
    cleanTimer(chapterId)
    if (type === 'ended' && !eng?.assessmentsEnded) {
      await endChapterAssessmentsMut({variables: {companyId: propsRef.current.companyId, courseId: propsRef.current.course.id, chapterId}})
        .catch(err => {
          throw err
        })
    }
    // if (status?.status === 'started' && status.proctoring?.token) {
    //   stopSupervision(chapterId, status.proctoring.token)
    //     .catch(err => {
    //       throw err
    //     })
    // }
    if (status?.status === 'started' || status?.status === 'starting') {
      if (type === 'ended' || eng?.assessmentsEnded || propsRef.current.progress?.assessmentsCompleted) {
        setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
          draft.chapters.set(chapterId, {
            status: 'ended',
          })
        }))
      } else {
        const chapter = propsRef.current.chapters.find(ch => ch.id === chapterId)
        setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
          draft.chapters.set(chapterId, {
            status: 'not_started',
            timeLimit: chapter?.assessmentMode?.timeLimit || 0,
          })
        }))
      }
    }
  })

  // const autoStopForChapterIn = (chapterId: string, autoEndIn: number) => {
  //   setTimer(chapterId, setTimeout(() => stopForChapterRef.current(chapterId, 'ended'), autoEndIn))
  // }

  const startForChapterRef = useRef(async (chapterId: string) => {
    const chapter = propsRef.current.chapters.find(ch => ch.id === chapterId)
    if (!chapter) throw new Error('Session does not exist.')
    if (!chapter.assessmentMode?.timeLimit) throw new Error('Session is not time limited.')

    const engagement = propsRef.current.engagements?.find(e => e.chapterId === chapterId)

    const status: ChapterStatus = supervisorStateRef.current.chapters.get(chapterId) ||
      {status: 'not_started', timeLimit: chapter.assessmentMode.timeLimit}
    if (status.status !== 'not_started') {
      throw new Error('Actions are already loading, please wait a second.')
    }

    const assessmentsStartedAt = engagement?.assessmentsStartedAt

    setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
      draft.chapters.set(chapterId, {
        status: 'starting',
        timeLimit: chapter.assessmentMode?.timeLimit || 0,
        // proctoring: chapter.assessmentMode?.proctoring ? {token: ''} : null,
      })
    }))

    // if (chapter.assessmentMode?.proctoring) {
    //   // validate proctoring not started for some other chapter already
    //   const hasProctoringStartedInDiffCh = Array.from(supervisorStateRef.current.chapters.entries()).some(([chId, status]) => {
    //     return chId !== chapterId &&
    //       (status.status === 'starting' || status.status === 'started') &&
    //       status.proctoring
    //   })
    //   if (hasProctoringStartedInDiffCh) {
    //     throw new Error('Proctoring session already running in another session')
    //   }
    //   try {
    //     const startProctRes = await startChapterProctoringMut({
    //       variables: {companyId: propsRef.current.companyId, courseId: propsRef.current.course.id, chapterId}
    //     })
    //     if (supervisorStateRef.current.chapters.get(chapterId)?.status !== 'starting') {
    //       return
    //     }
    //     const autoEndIn = (assessmentsStartedAt || Date.now()) + (chapter.assessmentMode?.timeLimit || 0) - Date.now()
    //     if (startProctRes?.data?.assessment.proctoringJWT) {
    //       setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
    //         draft.chapters.set(chapterId, {
    //           status: 'starting',
    //           timeLimit: chapter.assessmentMode?.timeLimit || 0,
    //           proctoring: {token: startProctRes?.data?.assessment.proctoringJWT || ''},
    //         })
    //       }))
    //       await startSupervision(chapterId, startProctRes.data.assessment.proctoringJWT, autoEndIn)
    //       if (supervisorStateRef.current.chapters.get(chapterId)?.status !== 'starting') {
    //         await stopSupervision(chapterId, startProctRes.data.assessment.proctoringJWT)
    //         return
    //       }
    //       await startAssessments(chapterId)
    //       setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
    //         draft.chapters.set(chapterId, {
    //           status: 'started',
    //           startedAt: assessmentsStartedAt || Date.now(),
    //           timeLimit: chapter.assessmentMode?.timeLimit || 0,
    //           proctoring: {token: startProctRes?.data?.assessment.proctoringJWT || ''},
    //         })
    //       }))
    //       const autoEndIn2 = (assessmentsStartedAt || Date.now()) + (chapter.assessmentMode?.timeLimit || 0) - Date.now()
    //       autoStopForChapterIn(chapterId, autoEndIn2)
    //     }
    //   } catch (err) {
    //     // Reset back the state
    //     setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
    //       draft.chapters.set(chapterId, {
    //         status: 'not_started',
    //         timeLimit: chapter.assessmentMode?.timeLimit || 0,
    //       })
    //     }))
    //     throw err
    //   }
    // } else {
      try {
        if (!assessmentsStartedAt) {
          await startAssessments(chapterId)
          if (supervisorStateRef.current.chapters.get(chapterId)?.status !== 'starting') {
            return
          }
          setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
            draft.chapters.set(chapterId, {
              status: 'started',
              startedAt: Date.now(),
              timeLimit: chapter.assessmentMode?.timeLimit || 0,
              // proctoring: null,
            })
          }))
        }
      } catch (err) {
        // Reset back the state
        setSupervisorStateWrapper(produce(supervisorStateRef.current, (draft) => {
          draft.chapters.set(chapterId, {
            status: 'not_started',
            timeLimit: chapter.assessmentMode?.timeLimit || 0,
          })
        }))
        throw err
      }
    // }
  })

  // Initialize state
  useEffect(() => {
    if (propsRef.current.isEditing) {
      // for (const [chapterId, status] of supervisorStateRef.current.chapters) {
      //   if (status.status === 'started' && status.proctoring?.token) {
      //     stopSupervision(chapterId, status.proctoring.token)
      //       .catch(err => {
      //         logger.error('Error when initializint time limited assessments: stopping supervision unsuccessfull', chapterId, err)
      //       })
      //   }
      // }
      setSupervisorStateWrapper({
        initialized: true,
        chapters: new Map(),
        startForChapter: startForChapterRef.current,
      })
      return
    }
    // TODO: uross - edge case to handle
    // - supervisor is started
    // - but page was refreshed and assessmentes are ended in between
    // - supervisor won't be explicitly stopped
    const autoStartForChapters: string[] = []
    setSupervisorStateWrapper({
      initialized: true,
      chapters: propsRef.current.chapters.reduce(
        (acc, ch) => {
          if (!ch.assessmentMode?.timeLimit) {
            return acc
          }
          const eng = propsRef.current.engagements?.find(e => e.chapterId === ch.id)
          // if (ch.assessmentMode.proctoring && eng?.assessmentsStartedAt && !chapterAssessmentsEnded(ch, eng, propsRef.current.progress)) {
          //   autoStartForChapters.push(ch.id)
          // }
          return acc.set(ch.id, {
            status: !eng?.assessmentsStartedAt
              ? 'not_started'
              : chapterAssessmentsEnded(ch, eng, propsRef.current.progress)
                ? 'ended'
                // : ch.assessmentMode.proctoring
                  // ? 'not_started' // autostart will set to 'starting'
                  : 'started',
            startedAt: eng?.assessmentsStartedAt || 0,
            timeLimit: ch.assessmentMode.timeLimit,
            // proctoring: ch.assessmentMode.proctoring ? {token: ''} : null,
          })
        },
        new Map<string, ChapterStatus>(),
      ),
      startForChapter: startForChapterRef.current,
    })
    if (autoStartForChapters.length > 1) {
      logger.error('Error when initializing time limited assessments: started for more than 1 session', autoStartForChapters)
      return
    }
    for (const chapterId of autoStartForChapters) {
      startForChapterRef.current(chapterId).catch((err) => {
        logger.error('Error when initializing time limited assessments: error on start', chapterId, err)
      })
    }
  }, [propsRef.current.isEditing])

  // Assessments could be started from somewhere else, let's make sure to refresh.
  useEffect(() => {
    for (const eng of propsRef.current.engagements || []) {
      if (!eng.assessmentsStartedAt) continue
      const status = supervisorStateRef.current.chapters.get(eng.chapterId)
      if (status?.status === 'not_started') {
        startForChapterRef.current(eng.chapterId).catch(err => {
          logger.error('Error when starting time limited assessments when starting because started on different platform', eng.chapterId, err)
        })
      }
    }
  }, [(propsRef.current.engagements || []).map(eng => eng.assessmentsStartedAt).length])

  // If assessments ended for chapter, let's make sure we stop them
  // Or if reset (not ended anymore), let's make sure we reset
  useEffect(() => {
    for (const [chapterId, status] of supervisorStateRef.current.chapters.entries()) {
      const eng = propsRef.current.engagements?.find(e => e.chapterId === chapterId)
      if (eng?.assessmentsEnded) {
        stopForChapterRef.current(chapterId, 'stopped').catch(err => {
          logger.error('Error when stopping time limited assessments because of assessmentsEnded change', chapterId, err)
        })
      } else {
        if (eng?.assessmentsStartedAt && status.status !== 'started' && status.status !== 'starting') {
          startForChapterRef.current(chapterId).catch(err => {
            logger.error('Error when starting time limited assessments because of assessmentsEnded change', chapterId, err)
          })
        }
        else if (status.status === 'ended') {
          resetForChapter(chapterId)
        }
      }
    }
  }, [(propsRef.current.engagements || []).map(eng => eng.assessmentsEnded).length])

  // If assessments completed on course level make sure stop all supervision and update state
  useEffect(() => {
    if (propsRef.current.progress?.assessmentsCompleted) {
      for (const [chapterId, status] of supervisorStateRef.current.chapters.entries()) {
        stopForChapterRef.current(chapterId, 'stopped').catch(err => {
          logger.error('Error when stopping time limited assessments because of assessmentsCompleted change', chapterId, err)
        })
      }
    }
  }, [propsRef.current.progress?.assessmentsCompleted])

  // Cleanup
  useEffect(() => {
    return () => {
      for (const chapterId of timersRef.current.keys()) {
        cleanTimer(chapterId)
      }
      // for (const [chapterId, status] of supervisorStateRef.current.chapters.entries()) {
      //   if (status.status !== 'starting' && status.status !== 'started') continue
      //   stopForChapterRef.current(chapterId, 'stopped').catch(err => {
      //     logger.error('Error when stopping time limited assessments because of unload', chapterId, err)
      //   })
      // }
    }
  }, [])

  return <AssessmentsSupervisorCtx.Provider value={supervisorState}>
    {propsRef.current.children}
  </AssessmentsSupervisorCtx.Provider>
}
