import { gql } from '@apollo/client'
import { actionFragment } from 'graphql/fragments/Action'
import { chapterFragment } from 'graphql/fragments/Chapter'

export type ListenChaptersForCourseChangesRes = {
  listenChaptersForCourseChanges: any;
}

export const listenChaptersForCourseChangesListener = gql`
  ${chapterFragment}
  ${actionFragment}

  subscription ListenChaptersForCourseChanges ($companyId: String, $courseId: String!, $returnAllAssessments: Boolean) {
    listenChaptersForCourseChanges (companyId: $companyId, courseId: $courseId, returnAllAssessments: $returnAllAssessments, version: 2) {
      ... on ActionTimestampsUpdated {
        courseId
        chapterId
        actionId
        timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
      }

      ... on ChapterCreated {
        chapter {
          ...ChapterFragment
        }
      }

      ... on ChapterUpdated {
        chapter {
          ...ChapterFragment
        }
      }

      ... on ChapterTimestampsUpdated {
        courseId
        chapterId
        timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
      }

      ... on ChapterRemoved {
        id
        courseId
      }

      ... on ChapterNoChange {
        courseId
      }

      ... on ChapterOrderUpdated {
        id
        courseId
        order
      }

      ... on ActionOrderUpdated {
        id
        chapterId
        courseId
        order
      }

      ... on ActionUpdated {
        action {
          ...ActionFragment
        }
      }
    }
  }
`
