import { gql } from '@apollo/client'
import { User } from 'graphql/schemas/user/User'

type UserInvited = {
  email: string;
  employeeId: string|null;
  firstName: string|null;
  lastName: string|null;
}

type RoleUsersRes = {
  invitedUsers: UserInvited[];
  users: Pick<User, 'id'|'email'|'profile'>[];
}

export type GetCourseEditorsRes = {
  res: RoleUsersRes;
}

export type GetCourseEditorsVars = {
  organizationId?: string;
  courseId: string;
}

export const getCourseEditorsQuery = gql`
  query GetCourseEditors ($organizationId: String, $courseId: String!) {
    res: getCourseEditors (organizationId: $organizationId, courseId: $courseId) {
      users {
        id
        email
        profile {
          fullName
        }
      }
    }
  }
`
