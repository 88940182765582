import { gql } from '@apollo/client'
import { progressFragment } from 'graphql/fragments/Progress'
import { UserProgress } from 'graphql/schemas/user/UserProgress'

export const getNotesQuery = gql`
  ${progressFragment}

  query UpdateNotesQuery ($companyId: String!, $courseId: String!, $userId: String!) {
    getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      progress {
        ...ProgressFragment
      }
    }
  }
`

export type GetProgressRes = {
  getUserSubscription: {
    id: string;
    userId: string;
    companyId: string;
    progress: UserProgress;
  };
}

export const getProgressQuery = gql`
  ${progressFragment}

  query UpdateProgressQuery ($companyId: String!, $courseId: String!, $userId: String!) {
    getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      userId
      companyId
      progress {
        ...ProgressFragment
      }
    }
  }
`
