import Tippy from '@tippyjs/react'
import React from 'react'
import intl from 'react-intl-universal'

export const NewTag = ({ x, y, p, count, showLabel='true' }: { x?: 'left-0'|'right-0'|'left-32', y?: 'top-0'|'top-2'|'top-5', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <Tippy placement="top" content={count && intl.get('global_new')} disabled={!count}>
      <span className={`bg-coral absolute uppercase ml-2 inline-block px-2 py-0.5 text-white text-xs rounded-xl font-medium left- ${x ? x : 'left-0'} ${y ? y : 'top-0'} ${p ? p : 'absolute'}`}>
        {showLabel === 'true'
          ? count ? `${count} ${intl.get('global_new')}` : `${intl.get('global_new')}`
          : count
        }
      </span>
    </Tippy>
  )
}

export const UpdatedTag = ({ x, y, p, count, showLabel='true' }: { x?: 'left-0'|'right-0', y?: 'top-0'|'top-2', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <Tippy placement="top" content={count && 'Updated'} disabled={!count}>
      <span className={`bg-[#ED8054] absolute uppercase ml-2 inline-block px-2 py-0.5 text-white text-xs rounded-xl font-medium ${x ? x : 'left-0'} ${y ? y : 'top-0'} ${p ? p : 'absolute'}`}>
        {showLabel === 'true'
          ? count ? `${count} ${intl.get('updated_tag')}` : `${intl.get('updated_tag')}`
          : count
        }
      </span>
    </Tippy>
  )
}

export const CompletedTag = ({ x, y, p, count, showLabel='true' }: { x?: 'left-0'|'right-0', y?: 'top-0'|'top-2', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <Tippy placement="top" content={count && intl.get('completed')} disabled={!count}>
      <div className={`absolute uppercase ml-2 inline-block px-2 py-0.5 bg-actions-assessment text-white text-xs rounded-xl font-medium ${x ? x : 'left-0'} ${y ? y : 'top-0'} ${p ? p : 'absolute'}`}>
        {showLabel === 'true'
          ? count ? `${count} ${intl.get('completed')}` : `${intl.get('completed')}`
          : count
        }
      </div>
    </Tippy>
  )
}

export const RedoAssessments = ({ status, x, y, p, count, showLabel='true' }: { status: {status: 'failed'|'can-retake'},  x?: 'left-0'|'right-0', y?: 'top-0'|'top-2', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <Tippy placement="top"
      content={count && status.status === 'can-retake' ? `${count} ${intl.get('redo_assessment_global')}` : count && status.status === 'failed' ? `${count} ${intl.get('assessment_failed_global')}` : count} disabled={!count}>
      <div className={`absolute uppercase ml-2 inline-block px-2 py-0.5 bg-white text-coral text-xs rounded-xl font-medium ${x ? x : 'left-0'} ${y ? y : 'top-0'} ${p ? p : 'absolute'}`}>
        {showLabel === 'true'
          ? count
            ? status.status === 'can-retake' ? `${count} ${intl.get('redo_assessment_global')}` : `${count} ${intl.get('assessment_failed_global')}`
            : status.status === 'failed' ? `${intl.get('assessment_failed_global')}` : `${intl.get('redo_assessment_global')}`
          : count
        }
      </div>
    </Tippy>
  )
}

export const LanguageTag = ({ x, y, p, language, showLabel='true' }: { language,  x?: 'left-0'|'right-0', y?: 'bottom-0'|'bottom-2', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <Tippy placement="top"
      content={<div className="capitalize">{language}</div>} disabled={!language}>
      <div className={`absolute ml-2 inline-block px-2 py-0.5 bg-white font-bold text-xs rounded-xl border border-[#4A4A4A]/[.32] ${x ? x : 'right-2'} ${y ? y : 'bottom-0'} ${p ? p : 'absolute'}`}>
        <div className="flex flex-row space-x-1">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99333 0.333328C3.31333 0.333328 0.333328 3.31999 0.333328 6.99999C0.333328 10.68 3.31333 13.6667 6.99333 13.6667C10.68 13.6667 13.6667 10.68 13.6667 6.99999C13.6667 3.31999 10.68 0.333328 6.99333 0.333328ZM11.6133 4.33333H9.64666C9.43801 3.50763 9.12904 2.71059 8.72666 1.95999C9.94274 2.37866 10.9674 3.22114 11.6133 4.33333ZM6.99999 1.69333C7.55333 2.49333 7.98666 3.37999 8.27333 4.33333H5.72666C6.01333 3.37999 6.44666 2.49333 6.99999 1.69333ZM1.84 8.33333C1.73333 7.90666 1.66666 7.45999 1.66666 6.99999C1.66666 6.53999 1.73333 6.09333 1.84 5.66666H4.09333C4.03999 6.10666 3.99999 6.54666 3.99999 6.99999C3.99999 7.45333 4.03999 7.89333 4.09333 8.33333H1.84ZM2.38666 9.66666H4.35333C4.56666 10.5 4.87333 11.3 5.27333 12.04C4.05597 11.6236 3.03055 10.7805 2.38666 9.66666ZM4.35333 4.33333H2.38666C3.03055 3.21945 4.05597 2.37637 5.27333 1.95999C4.87095 2.71059 4.56198 3.50763 4.35333 4.33333ZM6.99999 12.3067C6.44666 11.5067 6.01333 10.62 5.72666 9.66666H8.27333C7.98666 10.62 7.55333 11.5067 6.99999 12.3067ZM8.55999 8.33333H5.43999C5.37999 7.89333 5.33333 7.45333 5.33333 6.99999C5.33333 6.54666 5.37999 6.09999 5.43999 5.66666H8.55999C8.61999 6.09999 8.66666 6.54666 8.66666 6.99999C8.66666 7.45333 8.61999 7.89333 8.55999 8.33333ZM8.72666 12.04C9.12666 11.3 9.43333 10.5 9.64666 9.66666H11.6133C10.9674 10.7788 9.94274 11.6213 8.72666 12.04ZM9.90666 8.33333C9.95999 7.89333 9.99999 7.45333 9.99999 6.99999C9.99999 6.54666 9.95999 6.10666 9.90666 5.66666H12.16C12.2667 6.09333 12.3333 6.53999 12.3333 6.99999C12.3333 7.45999 12.2667 7.90666 12.16 8.33333H9.90666Z" fill="#4A4A4A"/>
          </svg>
          <div className="capitalize">{showLabel === 'true' && language}</div>
        </div>
      </div>
    </Tippy>
  )
}

export const AssessmentsFailed = ({ x, y, p, count, showLabel='true' }: { x?: 'left-0'|'right-0', y?: 'top-0'|'top-2', p?: 'relative'|'absolute', count?: '', showLabel?: 'true'|'false'}) => {
  return (
    <div className={`absolute uppercase ml-2 inline-block px-2 py-0.5 bg-white text-coral text-xs rounded-xl font-medium ${x ? x : 'left-0'} ${y ? y : 'top-0'} ${p ? p : 'absolute'}`}>
      {showLabel === 'true'
        ? count ? `${count} ${intl.get('assessment_failed_global')}` : `${intl.get('assessment_failed_global')}`
        : count
      }
    </div>
  )
}

