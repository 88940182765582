import { gql } from '@apollo/client'
import { Reviewer, ProgressReviewStatus } from 'graphql/schemas/review/Review'

export const submitCourseForReviewMutation = gql`
  mutation SubmitCourseForReview($companyId: String!, $courseId: String!, $userId: String!, $endorser: ReviewerInput) {
    submitCourseForReview(companyId: $companyId, courseId: $courseId, userId: $userId, endorser: $endorser)
  }
`

export type UpsertProgressReviewerArgs = {
  companyId: string;
  courseId: string;
  userId: string;
  reviewer: Reviewer;
}

export type UpsertProgressReviewerRes = {
  reviewer: Reviewer;
}

export type SubmitCourseForReviewArgs = {
  companyId: string;
  courseId: string;
  userId: string;
  endorser?: Reviewer|null;
}

export type SubmitCourseForReviewRes = {
  submitCourseForReview: ProgressReviewStatus;
}
