import React from 'react'
import { Button } from 'components/utils/Button'

const WelcomeModal = ({ startTour, skipTour }) => {
  return (
    <div className="pt-4 pb-2">
      <img className="mx-auto" src="/images/welcome_to_gnowbe.svg" />
      <div className="my-4 font-noto-sans text-2xl text-center font-bold">Welcome to Gnowbe!</div>
      <p className="text-center">Hey, looks like you're new here. Would you like to go through a quick tour of our features?</p>
      <div className="space-y-4 mt-4 text-center">
        <div>
          <Button type="primary" text="Let’s get started!" onClick={startTour} />
        </div>
        {/* <div><Button type="text" text="Skip for now" onClick={skipTour} /></div> */}
      </div>
    </div>
  )
}

export default WelcomeModal
