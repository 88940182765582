import { gql } from '@apollo/client'

export const deleteActionMutation = gql`
  mutation DeleteAction ($courseId: String!, $chapterId: String!, $actionId: String!) {
    deleteAction (courseId: $courseId, chapterId: $chapterId, actionId: $actionId) {
      id
      chapterId
      courseId
    }
  }
`
