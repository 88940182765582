import { gql } from '@apollo/client'
import { homescreenFragment, homescreenChangesFragment } from 'graphql/fragments/Home'
import { HomescreenCourseCategory, HomescreenCompanyCategory, Homescreen } from 'graphql/queries/home/getHomescreenData'

export type ListenHomescreenDataChangesVars = {
  oldQueryStateId: string;
  returnInitial: boolean;
  userId: string;
  includeExplore?: boolean;
  organizationId?: string;
}

export type ListenHomescreenDataChangesRes = {
  listenHomescreenDataChanges: Homescreen&HomescreenChanges&{__typename};
}

export type HomescreenData = {
  homescreenData: Homescreen&HomescreenChanges&{__typename};
}

export type HomescreenChanges = {
  updatedCourseCategories: HomescreenCourseCategory[];
  updatedCompanyCategories: HomescreenCompanyCategory[];
  queryStateId?: string;
}

export type HomescreenNoChange = {
  userId: string;
}

export const listenHomescreenDataChangesListener = gql`
  ${homescreenFragment}
  ${homescreenChangesFragment}

  subscription ListenHomescreenDataChanges ($userId: String!, $returnInitial: Boolean, $oldQueryStateId: String, $includeExplore: Boolean, $organizationId: String) {
    listenHomescreenDataChanges (userId: $userId, returnInitial: $returnInitial, oldQueryStateId: $oldQueryStateId, includeExplore: $includeExplore, organizationId: $organizationId) {
      ...HomescreenFragment
      ...HomescreenChangesFragment
    }
  }
`
