import { useMutation } from '@apollo/client'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { updateMeta } from 'actions/users_actions'
import intl from 'react-intl-universal'
import { Button } from 'components/utils/Button'
import { useAppState } from 'graphql/hooks/useAppState'
import { createFreeTierOrganizationMutation, CreateFreeTierOrganizationVars } from 'graphql/mutations/organization/createFreeTierOrganization'
import { isCreatorInWorkspace } from 'graphql/schemas/user/User'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { track } from 'utils/track'

const ExploreSlider = ({close, setActiveWorkspaceId, setShowingTour, fullName}) => {
  const appData = useAppState()
  const isWorkspaceCreator = isCreatorInWorkspace()

  const _sliderRef = useRef<any>(null)
  const [slideNum, setSlideNum] = useState(0)
  const [createOrg] = useMutation<{ res: string }, CreateFreeTierOrganizationVars>(createFreeTierOrganizationMutation)
  const slidesDetails = [
    {
      img: '/images/new-tour-launcher-1st.svg',
      title: 'Explore and learn',
      subtitle: <p className="p-0">As a <span className="font-bold">Learner</span>, you can explore various programs across different topics and fields of interests. Just <span className="font-bold">click on any program card</span> that interests you and <span className="font-bold">start learning</span> straightaway!</p>,
    },
    {
      img: '/images/gnowbe-create-workspace.png',
      title: 'Workspaces',
      subtitle: <p className="p-0">In order to create or go through a program you must be in a <span className="font-bold">workspace</span>. If you were invited to a workspace, any programs you create here will be viewable by anyone else in the workspace. <br />If you are <span className="font-bold">new</span> and not in a workspace, you will need to <span className="font-bold">create</span> your own workspace by clicking the button below. Otherwise, you can continue the tour.</p>,
    },
    {
      img: '/images/gnowbe-create-workspace.png',
      title: 'Workspaces',
      subtitle: <p className="p-0">Click on the workspaces drop down at any point in time to <span className="font-bold">switch</span> between workspaces. <br />You will only be able to see or edit the programs that are part of the workspace that is selected, and can also create new workspaces if needed.</p>,
    },
    {
      img: '/images/new-tour-launcher-2nd.svg',
      title: 'Create and share',
      subtitle: <p className="p-0">If you want to try your hand at creating a program for your Learners, the “<span className="font-bold">Create</span>” button can be found throughout the Gnowbe website. You can <span className="font-bold">share your program</span> whenever you’re done!</p> ,
    },
    {
      img: '/images/new-tour-launcher-3rd.svg',
      title: 'That’s it!',
      subtitle: <p className="p-0">With Gnowbe, you have the ability to both learn and create for others. And with that, you’re all set!</p>,
      instructions: <p className="p-0">If you’re interested in program creation after creating your own Workspace, click on the “{isWorkspaceCreator ? <span className="font-bold">Create</span> : <span className="font-bold">Create my Workspace</span>}” button below to go through the process step by step. Otherwise, click on “<span className="font-bold">Learn and Explore</span>” to explore Gnowbe on your own.</p>,
    },
  ]
  const settings = {
    dots: false,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const isLastSlide = slideNum === slidesDetails.length - 1

  return (
    <div>
      <Slider ref={_sliderRef} {...settings} afterChange={setSlideNum}>
        {slidesDetails.map((item, i) => {
          return (
            <div key={i}>
              <img src={item.img} className="mt-4 mx-auto rounded-md h-64" />
              <div className="text-center text-2xl mt-8 font-medium">{item.title}</div>
              <div className={`mx-10 mt-4 ${!item.instructions ? 'mb-5' : 'mb-2'}`}>
                {item.subtitle}
              </div>
              {item.instructions && <div className="mb-5 mx-10">
                {item.instructions}
              </div>
              }
            </div>
          )
        })}
      </Slider>
      {slideNum === 0 || slideNum === 2 || slideNum === 3
        ? <div className={`flex ${slideNum === 0 ? 'justify-end' : 'justify-between'}`}>
          {slideNum === 0
          ? ''
          : <Button
              text="Back"
              onClick={() => _sliderRef.current && _sliderRef.current.slickPrev()}
            />
          }
            <Button
              type="primary"
              text={slideNum === 0 ? 'Next (1/4)' : slideNum === 2 ? 'Next (3/4)' : 'Next (4/4)'}
              svgRightIcon={<ArrowRightIcon className="w-4 h-4" />}
              rightIcon="ml-2"
              onClick={() => {
                if (_sliderRef.current) {
                  if (isLastSlide) {
                    return close()
                  }
                  _sliderRef.current.slickNext()
                }
              }}
            />
          </div>
        : <div/>
      }
      {slideNum === 1
        ? <div className="flex justify-between">
            <div className="w-[140px]">
              <Button
                text="Back"
                onClick={() => _sliderRef.current && _sliderRef.current.slickPrev()}
              />
            </div>
            <Button
              type="team"
              text={`+ ${intl.get('create_workspace')}`}
              disabled={isWorkspaceCreator}
              onClick={() => {
                if (!appData?.loggedInAs.uid) return

                createOrg({
                  variables: {
                    organizationName: `${fullName}'s Workspace`,
                    userId: appData.loggedInAs.uid,
                  },
                  onCompleted: (data) => {
                    setActiveWorkspaceId(data.res)
                    updateMeta('activeWorkspaceId', data.res, true)
                    track({
                      event: 'Workspace Created',
                      variables: {
                        onScreen: 'Welcome Slider',
                        changedToWorkspaceId: data.res,
                      }
                    })
                  },
                  onError: error => console.log(error),
                })
              }}
            />
            <Button
              type="primary"
              text="Next (2/4)"
              svgRightIcon={<ArrowRightIcon className="w-4 h-4" />}
              rightIcon="ml-2"
              onClick={() => {
                if (_sliderRef.current) {
                  if (isLastSlide) {
                    return close()
                  }
                  _sliderRef.current.slickNext()
                }
              }}
            />
          </div>
        : <div/>
      }

      {(slideNum === 4 && isWorkspaceCreator) && <div className="flex flex-col items-center justify-center">
        <Button
          type="primary"
          text="Go through tutorial"
          className="mb-1"
          onClick={() => {
            setShowingTour(true)
            if (_sliderRef.current) {
              if (isLastSlide) {
                return close()
              }
              _sliderRef.current.slickNext()
            }
          }}
        />
        <Button
          text="Explore on my own"
          onClick={() => {
            close()
          }}
        />
      </div>}
      {(slideNum === 4 && !isWorkspaceCreator) && <div className="text-center">
        <Button
          type="team"
          text={`+ ${intl.get('create_workspace')}`}
          disabled={isWorkspaceCreator}
          className="mr-2"
          onClick={() => {
            if (!appData?.loggedInAs.uid) return

            createOrg({
              variables: {
                organizationName: `${fullName}'s Workspace`,
                userId: appData.loggedInAs.uid,
              },
              onCompleted: (data) => {
                setActiveWorkspaceId(data.res)
                updateMeta('activeWorkspaceId', data.res, true)
                setTimeout(() => {
                  setShowingTour(true)
                }, 500,
                )
                track({
                  event: 'Workspace Created',
                  variables: {
                    onScreen: 'Welcome Slider',
                    changedToWorkspaceId: data.res,
                  }
                })
                close()
              },
              onError: error => console.log(error),
            })
          }}
        />
        <Button
          text="Learn and Explore"
          onClick={() => {
            close()
          }}
        />
      </div>}
    </div>
  )
}

export default ExploreSlider
