import React from 'react'
import { generateMongoId } from 'utils/random'

export function showPopover(el: any, content?: string, image?: string, className?: string | null, customId?: string) {
  const elClassName = className || 'gnowbe-popover'
  let id, popover, img, text, arrowSpan

  id = el.dataset.id || customId || generateMongoId(6)

  popover = document.getElementById(id)

  if (!popover) {
    popover = document.createElement('div')
    popover.id = id
    popover.classList.add(elClassName)
    popover.onclick = () => hidePopover(id)
    document.body.appendChild(popover)
  }

  if (image || el.dataset.image) {
    // create cropping div
    img = new Image(250, 150)
    img.src = 'https://' + (image || el.dataset.image)
    popover.appendChild(img)
  }

  text = document.createElement('div')
  text.innerHTML = content || el.dataset.content
  popover.appendChild(text)

  arrowSpan = document.createElement('span')
  arrowSpan.classList.add('arrow')
  arrowSpan.style.top = popover.offsetHeight - 5 + 'px'
  popover.appendChild(arrowSpan)

  let pos = el.getBoundingClientRect()
  let xPos = pos.left + window.scrollX - popover.offsetWidth / 2 + el.offsetWidth / 2
  let yPos = pos.top + window.scrollY - popover.offsetHeight - 8
  popover.style.left = xPos + 'px'
  popover.style.top = yPos + 'px'

  setTimeout(() => {
    popover.style.opacity = '1'
  }, 200)

  // remove tooltip after 20s if it stays on the screen
  // this can be caused by hovering over a tip too quickly, before the final id is set
  setTimeout(() => {
    if (popover) {
      hidePopover(popover.id)
    }
  }, 20000)
}

export function hidePopover(id: string) {
  let popover = document.getElementById(id)
  if (popover) {
    popover.style.opacity = '0'
    setTimeout(() => {
      try {
        // bullshit
        if (popover && document.body.contains(popover)) {
          document.body.removeChild(popover)
        }
      } catch (err) {
        // https://stackoverflow.com/questions/21926083/failed-to-execute-removechild-on-node
      }
    }, 200)
  }
}

export class PopoverWrapper extends React.PureComponent<{ content: string, elClassName?: string, children }, {}> {
  private _ref
  render() {
    const { content, elClassName } = this.props
    const id = generateMongoId(6)
    return (
      <span
        ref={ref => this._ref = ref}
        data-id={id}
        onMouseOver={e => showPopover(this._ref, content, undefined, elClassName)}
        onMouseOut={() => hidePopover(id)}>
        {this.props.children}
      </span>
    )
  }
}

export default class PopoverListener extends React.PureComponent<{ triggerClass: string, children }, {}> {
  mouseOver = (e) => {
    if (!e || e && e.target && e.target.className !== this.props.triggerClass) return
    showPopover(e.target)
  }
  mouseOut = (e) => {
    if (!e || e && e.target && e.target.className !== this.props.triggerClass) return
    hidePopover(e.target.dataset.id)
  }
  render() {
    return (
      <div
        className="popover-listener"
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}>
        {this.props.children}
      </div>
    )
  }
}
