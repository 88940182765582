import { gql } from '@apollo/client'
import { timestampsUpdateFragment, timestampsFragment } from './Timestamps'
import { Timestamps, timestampsFactory } from 'graphql/schemas/user/UserProgress'
import { Review } from 'graphql/schemas/review/Review'

export const reviewFragment = gql`
  fragment ReviewFragment on Review {
    id
    score
    feedback
    role
    reviewedAt
    reviewerId
  }
`

export const reviewerFragment = gql`
  fragment ReviewerFragment on Reviewer {
    id
    name
    email
    role
  }
`

export const studyReviewFragment = gql`
  ${timestampsFragment}

  fragment StudyReviewFragment on StudyReview {
    id
    chapterId
    actionId
    assessments {
      id
      reviewerId
      score
      feedback
    }
    timestamps {
      ...TimestampsFragment
    }
  }
`

export const progressReviewFragment = gql`
  ${reviewFragment}
  ${reviewerFragment}

  fragment ProgressReviewFragment on ProgressReview {
    id
    userId
    companyId
    courseId
    status
    assessmentScore
    assessmentPassed
    reviews {
      ...ReviewFragment
    }
    reviewers {
      ...ReviewerFragment
    }
  }
`

export const progressReviewUpdatedFragment = gql`
  ${reviewFragment}
  ${reviewerFragment}

  fragment ProgressReviewUpdatedFragment on ProgressReviewUpdated {
    userId
    companyId
    courseId
    status
    addedReviewers {
      ...ReviewerFragment
    }
    updatedReviewers {
      ...ReviewerFragment
    }
    removedReviewers
    addedReviews {
      ...ReviewFragment
    }
    updatedReviews {
      ...ReviewFragment
    }
    removedReviews
    assessmentScore
    assessmentPassed
  }
`

export const studyReviewUpdatedFragment = gql`
  ${reviewFragment}

  fragment StudyReviewUpdatedFragment on StudyReviewUpdated {
    id
    companyId
    courseId
    chapterId
    actionId
    addedReviews {
      ...ReviewFragment
    }
    updatedReviews {
      ...ReviewFragment
    }
    removedReviews
  }
`

export const studyReviewTimestampsUpdatedFragment = gql`
  ${timestampsUpdateFragment}

  fragment StudyReviewTimestampsUpdatedFragment on StudyReviewTimestampsUpdated {
    id
    companyId
    courseId
    chapterId
    actionId
    timestamps {
      ...TimestampsUpdateFragment
    }
  }
`

export const progressReviewTimestampsUpdatedFragment = gql`
  ${timestampsUpdateFragment}

  fragment ProgressReviewTimestampsUpdatedFragment on ProgressReviewTimestampsUpdated {
    userId
    companyId
    courseId
    timestamps {
      ...TimestampsUpdateFragment
    }
  }
`


export type GetStudyReviewsForSubscriptionArgs = {
  companyId: string;
  courseId: string;
  userId: string;
}

export type GetStudyReviewsForSubscriptionRes = {
  reviews: StudyReview[];
}

export type StudyReview = {
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  chapterId: string;
  actionId: string;
  assessments: Review[];
  timestamps: Timestamps;
  createdAt: number;
  updatedAt: number;
}

export function studyReviewFactory(data?: Partial<StudyReview>): StudyReview&{__typename: string} {
  return {
    id: data?.id || '',
    userId: data?.userId || '',
    companyId: data?.companyId || '',
    courseId: data?.courseId || '',
    chapterId: data?.chapterId || '',
    actionId: data?.actionId || '',
    assessments: data?.assessments || [],
    timestamps: data?.timestamps || timestampsFactory(),
    createdAt: 0,
    updatedAt: 0,
    __typename: 'StudyReview',
  }
}
