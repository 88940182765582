import { useQuery } from '@apollo/client'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetUserSubscriptions2Res, GetUserSubscriptionsVars, getUserSubscriptions2Query, UserSubscriptionBasic } from 'graphql/queries/user/getUserSubscriptions'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { AppState } from '../app/State'
import { UserBasic } from './User'

export type UserSubscription = {
  id: string;
  userId: string;
  companyId: string;
  courseId: string;
  isArchived: boolean;
  isLocked: boolean;
  isGnowbeLearn: boolean;

  gnowbeLearnAvailableAt: number|null;
  gnowbeLearnBundleIds: string[]|null;
  company: Company|null;
  course: Course|null;
  progress: UserProgress|null;
}

export type SubscriptionUserBasic = {
  userId: string;
  companyId: string;
  courseId: string;
  completion: number;
  user: UserBasic;
}



export const getCourseCompanies = (data: GetUserSubscriptions2Res, courseId: string, isArchived?: boolean,) => {
  let companies: Map<string, Company[]> = new Map()
  data?.response.subscriptions.filter(s => s.courseId === courseId).forEach(s => {
    if (!(companies.get(s.courseId) || []).some(c => c && c.id === s.companyId)) {
      const cmp = data?.response.companies.find(cmp => cmp.id === s.companyId)
      if (!cmp) return
      companies.set(s.courseId, (companies.get(s.courseId) || []).concat(cmp))
    }
  })
  return companies.get(courseId) || []
}

export const getCourseProgresses = (data: GetUserSubscriptions2Res, courseId: string) => {
  let sortedData = data?.response.subscriptions.filter(s => s.courseId === courseId).reduce((subs, curr) => {
    if (!subs.some(sub => sub?.courseId === curr.courseId)) {
      const courses = data?.response.subscriptions.filter(sub => sub.courseId === curr.courseId)
      const progresses = courses.map(s => s.progress)
      return progresses
    }

    return subs
  }, [] as (UserProgress|null)[])

  return sortedData
}