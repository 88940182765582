import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { ChaptersUnlocked, CourseDifficulty } from 'graphql/schemas/course/Course'

export type CourseCreateVars = {
  courseCreate: {
    title: string;
    titleEnglish?: string;
    tagline?: string;
    description?: string;
    imageUrl?: string;
    imageUrlAlt?: string;
    curatorName?: string;
    curatorTitle?: string;
    curatorCompany?: string;
    curatorBio?: string;
    curatorPhotoUrl?: string;
    curatorSignatureDisabled?: boolean;
    curatorChatEnabled?: boolean;
    allowUnlimitedDaySkipping?: boolean;
    chaptersUnlocked?: ChaptersUnlocked;
    category?: string;
    categoryText?: string;
    skills?: string[];
    difficulty?: CourseDifficulty;
    type?: 'gnowbe'|'scorm';
    status?: string;
    tags?: string[];
    cpeHours?: number;
    language?: string;
    canRetakeActionAssessments?: boolean;
    maxActionAssessmentsRetakes?: number;
    showActionAssessmentsResultsPerAction?: boolean;
    passingGrade?: number; // % needed to pass assessments
    graduationGrade?: number; // % needed to request the certificate
    workbookDisabled?: boolean;
    allowMediaDownload?: boolean;
    denyContentCopy?: boolean;
    contentPartnerId?: string;
    manualReviews?: boolean;
    allowPublicRatingResults?: boolean;
    organizationId?: string;
  };
  createFirstChapter?: boolean;
}

export const createCourseMutation = gql`
  ${timestampsFragment}

  mutation CreateCourse ($courseCreate: CourseCreate!, $createFirstChapter: Boolean) {
    createCourse (courseCreate: $courseCreate, createFirstChapter: $createFirstChapter) {
      id
      title
      chaptersCount
      chaptersUnlocked
      curatorName
      curatorPhotoUrl
      imageUrl
      timestamps {
        ...TimestampsFragment
      }
      testCompany {
        id
        accessCodes
      }
    }
  }
`
