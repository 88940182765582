import React, { useRef } from 'react'
import intl from 'react-intl-universal'
import AliceCarousel from 'react-alice-carousel'
import { HomescreenCourseCategory } from 'graphql/queries/home/getHomescreenData'
import { GetCurrentSubscriptionRes } from 'graphql/queries/user/getUser'
import { CourseItem } from './CourseItem'
import { LoadingCourseItem } from './LoadingCourseItem'
import { CategoryHeader } from './CategoryHeader'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import { isColorDark, nl2br } from 'utils/functions'
import { isAgent } from 'graphql/schemas/user/User'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { useWhiteLabel } from 'context/whiteLabel'

export const HomeCategory = ({ subData, category, activeSub, favoritedSubscriptions, setTab }: { subData: GetCurrentSubscriptionRes, category: HomescreenCourseCategory|null, activeSub?: string, favoritedSubscriptions: string[], setTab?: (id: string) => void }) => {
  const { whiteLabelData } = useWhiteLabel()
  const accentColor = whiteLabelData.accentColor
  const bgColor = whiteLabelData.bgColor

  const customStyle = {
    ...accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}
  }

  if (!category) {
    return (
      <div>
        <CategoryHeader title={intl.get('home_category_course_recent')} />

        <div className="grid grid-cols-2 md:grid-cols-5 mt-3">
          <LoadingCourseItem />
          <LoadingCourseItem />
          <LoadingCourseItem />
        </div>
      </div>
    )
  }

  if (category.id === 'active_group') return null
  if (category.id === 'explore' && !subData.user.features.includes('show_explore_tab')) return null

  // scrolling does not work if 6 programs (1 more than shown)
  // navigating with BACK on HOME does not go back
  if (category.id === 'favorited' && category.subscriptions.length <= 0) {
    return (
      <div>
        <div className="flex justify-between mr-4">
          <CategoryHeader title={intl.get(`home_category_course_${category.id}`)} />
        </div>

        <div className="w-full mt-2 text-center py-12">
          <div className={`${bgColor && isColorDark(bgColor) ? 'text-[#9A9A9A]' : bgColor && !isColorDark(bgColor) ? 'text-[#535353]' : 'text-gray-400'} tracking-wide text-sm`}>{intl.get('home_my_list_empty')}</div>
        </div>
      </div>
    )
  }

  if (category.subscriptions.length <= 0) return null

  const carousel = useRef<any>(null)
  const buttonsRef = useRef<any>(null)

  const responsive = {
    0: { items: 2 },
    1000: { items: 3 },
    1024: { items: 4 },
    1350: { items: 5 },
  }

  const uniqueCourses = category.subscriptions.reduce((subs, curr) => {
    if (!subs.some(sub => sub.courseId === curr.courseId)) {
      const courses = category.subscriptions.filter(sub => sub.courseId === curr.courseId)
      const companies = courses.map(sub => sub.company)
      const progresses = category.subscriptions.map(s => s.progress)
      let maxProgress = 0
      courses.map(s => { if ((s.progress?.completion || 0) > maxProgress) maxProgress = s.progress?.completion || 0 })
      let curr2: UserSubscription & { companies: any[], maxProgress: number, progresses: (UserProgress|null)[] } = JSON.parse(JSON.stringify(curr))
      curr2.companies = companies
      curr2.maxProgress = maxProgress
      curr2.progresses = progresses
      return subs.concat(curr2)
    }
    return subs
  }, [] as (UserSubscription&{companies: any[], maxProgress: number, progresses: (UserProgress|null)[]})[])

  let items = uniqueCourses
    .filter(s => s.course && s.company)
    .map((subscription) => {
    if (!subscription?.course) return null

    const subKey = `${subscription.companyId}-${subscription.courseId}`
    const isLiked = favoritedSubscriptions.includes(subKey)
    return (
      <CourseItem
        key={subscription.id}
        company={subscription.company!}
        course={subscription.course}
        userId={subscription.userId}
        liked={isLiked}
        isActive={!!(activeSub && activeSub === subKey)}
        isAgent={isAgent(subData.user, subscription.company?.organizationId, subscription.companyId)}
        isEditor={subData.user.editorCourses.includes(subscription.courseId)}
        paddingRight={true}
        isGnowbeLearn={subscription.isGnowbeLearn}
      />
    )
  })

  const changeTab = () => {
    if (!setTab) return

    if (category.id === 'explore') {
      trackButtonEnhanced({
        button: 'View All (Explore)',
        onScreen: 'Home',
      })
      trackButton({
        button: 'view_explore',
        on_screen: 'home',
      })
      return setTab('explore')
    }
    if (category.id === 'recent') {
      trackButtonEnhanced({
        button: 'View All (Recent)',
        onScreen: 'Home',
      })
      trackButton({
        button: 'view_recent',
        on_screen: 'home',
      })
      return setTab('programs#all')
    }
    if (category.id === 'editor') {
      trackButtonEnhanced({
        button: 'View All (Curated)',
        onScreen: 'Home',
      })
      trackButton({
        button: 'view_curated',
        on_screen: 'home',
      })
    }
    if (category.id === 'favorited') {
      trackButtonEnhanced({
        button: 'View All (Liked)',
        onScreen: 'Home',
      })
      trackButton({
        button: 'view_my_programs',
        on_screen: 'home',
      })
    }
    setTab(`programs#${category.id}`)
  }

  const showViewMoreProgramsCard = ['recent', 'favorited', 'editor', 'explore'].includes(category.id)

  const viewMoreProgramsCard = (
    <div
      tabIndex={0}
      className="border border-[#e9dec4] rounded-md w-[90%] text-center flex flex-col justify-center items-center font-bold h-[361px] cursor-pointer text-deepgray"
      onClick={changeTab}>
      <div className="mb-2 leading-normal text-deepgray">{category.id === 'explore' ? intl.get('explore_more') : <span dangerouslySetInnerHTML={{ __html: nl2br(intl.get('view_more_programs')) }} />}</div>

      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block text-deepgray" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
      </svg>
    </div>
  )

  if (showViewMoreProgramsCard) {
    items = items.concat(viewMoreProgramsCard)
  }

  return (
    <div style={customStyle}>
      <div className="flex justify-between mr-4">
        <CategoryHeader title={intl.get(`home_category_course_${category.id}`)} />

        <div className="flex items-center space-x-4">
          {!['new', 'active_group'].includes(category.id) && (
            <button className={`text-sm font-bold ${isColorDark(whiteLabelData.bgColor) ? 'text-zinc-50': 'text-deepgray'} ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}`} onClick={changeTab}>
              {category.id === 'explore' ? intl.get('home_view_explore') : intl.get('home_view_all')}
            </button>
          )}
        </div>
      </div>

      <div className="w-full mt-2 relative group/soroundingDiv">

        <AliceCarousel
          ref={carousel}
          mouseTracking
          disableDotsControls
          onInitialized={(e) => {
            if (!buttonsRef?.current) return
            buttonsRef.current.updateState('next', e.isNextSlideDisabled)
          }}
          onSlideChanged={(e) => {
            if (!buttonsRef?.current) return
            buttonsRef.current.updateState('prev', e.isPrevSlideDisabled)
            buttonsRef.current.updateState('next', e.isNextSlideDisabled)
          }}
          responsive={responsive}
          items={items}
          renderNextButton={({isDisabled}) => {
            return <div className="absolute invisible group-hover/soroundingDiv:visible right-5 bottom-1/2 z-10">
              <button className={`bg-lightgray py-2 px-4 rounded-full ${isDisabled ? 'hidden' : 'hover:bg-gray-500'}`} onClick={() => carousel.current.slideTo(carousel.current.state.activeIndex + carousel.current.state.itemsInSlide)}>
                <svg width="10" height="25" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.62988 8.6543C9.62988 8.47266 9.59473 8.30566 9.52441 8.15332C9.4541 8.00098 9.34863 7.85449 9.20801 7.71387L2.44922 1.0957C2.2207 0.873047 1.94238 0.761719 1.61426 0.761719C1.40332 0.761719 1.20703 0.814453 1.02539 0.919922C0.84375 1.02539 0.697266 1.16895 0.585938 1.35059C0.480469 1.52637 0.427734 1.72266 0.427734 1.93945C0.427734 2.26758 0.550781 2.55469 0.796875 2.80078L6.81738 8.6543L0.796875 14.5078C0.550781 14.7598 0.427734 15.0469 0.427734 15.3691C0.427734 15.5918 0.480469 15.791 0.585938 15.9668C0.697266 16.1426 0.84375 16.2832 1.02539 16.3887C1.20703 16.4941 1.40332 16.5469 1.61426 16.5469C1.94238 16.5469 2.2207 16.4355 2.44922 16.2129L9.20801 9.59473C9.35449 9.4541 9.45996 9.30762 9.52441 9.15527C9.59473 8.99707 9.62988 8.83008 9.62988 8.6543Z" fill="white"/>
                </svg>
              </button>
            </div>
          }}
          renderPrevButton={({isDisabled}) => {
            return <div className="absolute invisible group-hover/soroundingDiv:visible left-5 bottom-1/2 z-10">
              <button className={`bg-lightgray py-2 px-4 rounded-full ${isDisabled ? 'hidden' : 'hover:bg-gray-500'}`} onClick={() => carousel.current.slideTo(carousel.current.state.activeIndex - carousel.current.state.itemsInSlide)}>
                <svg width="10" height="25" viewBox="0 0 10 17" fill="none">
                  <path d="M0.370118 8.3457C0.370118 8.52734 0.405274 8.69433 0.475587 8.84668C0.545899 8.99902 0.651368 9.14551 0.791993 9.28613L7.55078 15.9043C7.7793 16.127 8.05762 16.2383 8.38574 16.2383C8.59668 16.2383 8.79297 16.1855 8.97461 16.0801C9.15625 15.9746 9.30273 15.8311 9.41406 15.6494C9.51953 15.4736 9.57227 15.2773 9.57227 15.0605C9.57227 14.7324 9.44922 14.4453 9.20313 14.1992L3.18262 8.3457L9.20313 2.49219C9.44922 2.24023 9.57227 1.95312 9.57227 1.63086C9.57227 1.4082 9.51953 1.20898 9.41406 1.0332C9.30274 0.857422 9.15625 0.716797 8.97461 0.611328C8.79297 0.505859 8.59668 0.453125 8.38574 0.453125C8.05762 0.453125 7.7793 0.564453 7.55078 0.787109L0.791993 7.40527C0.645509 7.5459 0.54004 7.69238 0.475587 7.84473C0.405274 8.00293 0.370118 8.16992 0.370118 8.3457Z" fill="white"/>
                </svg>
              </button>
            </div>
          }}
        />
      </div>
    </div>
  )
}
