import { gql } from '@apollo/client'
import { courseFragment } from 'graphql/fragments/Course'
import { Course } from 'graphql/schemas/course/Course'

export type GetCourseRes = {
  course: Course;
}

export type GetCourseVars = {
  courseId: string;
}

export const getCourseQuery = gql`
  ${courseFragment}

  query GetCourse ($courseId: String!) {
    course: getCourse (courseId: $courseId) {
      ...CourseFragment
    }
  }
`

export const getCourseTitleQuery = gql`
  query GetCourse ($courseId: String!) {
    course: getCourse (courseId: $courseId) {
      id
      title
    }
  }
`
