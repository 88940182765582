import jwtDecode from 'jwt-decode'
import { produce } from 'immer'
import { client } from 'app'
import { showAlert } from 'components/utils/Alert'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { getStudyForSubscriptionQuery } from 'graphql/queries/user/getStudyForSubscription'
import { Action } from 'graphql/schemas/action/Action'
import * as logger from 'logger'
import { FilepickerMetadata } from 'models/file_models'
import { checkHttpStatus, getAuthTokens } from 'actions/utils'
import { userStudyFactory } from 'graphql/schemas/user/UserStudy'
import { refetch } from 'utils/functions'
import { track } from 'utils/track'

export function actionViewed(action: Action, companyId: string, isEditing: boolean) {
  if (isEditing) return

  const token = getAuthTokens()
  const authTokenPayload: any = token ? jwtDecode(token) : {}

  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      courseId: action.courseId,
      userId: authTokenPayload.uid,
    },
  })

  if (data) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        courseId: action.courseId,
        userId: authTokenPayload.uid,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
        if (index !== -1) {
          draft.study[index].viewed = draft.study[index].viewed + 1
          draft.study[index].timestamps.updatedAt = Number(new Date())
        }
        else {
          draft.study.push(userStudyFactory({
            companyId,
            id: `${authTokenPayload.uid}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
            courseId: action.courseId,
            chapterId: action.chapterId,
            actionId: action.id,
            viewed: 1,
          }))
        }
      }),
    })
  }

  const revert = () => {
    if (!data) return

    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        courseId: action.courseId,
        userId: authTokenPayload.uid,
      },
    })
  }

  refetch(`${BASE_API_URL}/api/v1/studies/viewed`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.warn(`actions_actions: actionViewed error: ${err.message}`, err)
  })
}

export function actionTimeSpent(action: Action, companyId: string, secondsSpent: number) {
  if (!action) return

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/time_spent`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId,
      secondsSpent,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      //
    }
  })
  .catch((err) => {
    logger.warn(`actions_actions: actionTimeSpent error: ${err.message}`, err)
  })
}

export function actionSnooze(progress: { companyId: string, courseId: string, chapterId: string, actionId: string }, userId: string, reminderAt: number) {
  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      userId,
      companyId: progress.companyId,
      courseId: progress.courseId,
    },
  })

  if (data) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        userId,
        companyId: progress.companyId,
        courseId: progress.courseId,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === progress.chapterId && s.actionId === progress.actionId)
        if (index !== -1) draft.study[index].reminder = { id: '', at: reminderAt, __typename: 'ReminderData' }
      }),
    })
  }

  const revert = () => {
    if (!data) return

    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        userId,
        companyId: progress.companyId,
        courseId: progress.courseId,
      },
    })
  }

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/reminder`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      reminderAt,
      companyId: progress.companyId,
      courseId: progress.courseId,
      chapterId: progress.chapterId,
      actionId: progress.actionId,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`actions_actions: actionSnooze error: ${err.message}`, err)
  })
}

export function actionSaved(action: Action, companyId: string, userId: string, answer?: string, retriesBlocked?: boolean, secondsUsed?: number) {
  return new Promise((result, reject) => {
    const token = getAuthTokens()
    const isWordPuzzle = action.contentType === 'word_puzzle'
    const answerData = {
      answer,
      companyId,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
      contentType: action.contentType,
    }
    if (isWordPuzzle) {
      answerData['secondsUsed'] = secondsUsed || 0
      answerData['retriesBlocked'] = retriesBlocked || false
    }
    refetch(`${BASE_API_URL}/api/v1/studies/answered`, {
      retries: 5,
      retryDelay: 5000,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify(answerData),
    })
    .then(res => checkHttpStatus(res, true))
    .then(res => res.json())
    .then((res) => {
      if (res.error) {
        showAlert(`Error - ${res.error.message}`, 'danger')
        return result({ error: true })
      }

      const data = client.readQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          userId,
          courseId: action.courseId,
        },
      })

      if (!data) return

      client.writeQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          userId,
          courseId: action.courseId,
        },
        data: produce(data, (draft) => {
          const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
          if (index !== -1) {
            draft.study[index].answer = answer || draft.study[index].answer
            draft.study[index].viewed = draft.study[index].viewed + 1 || 1
            draft.study[index].timestamps.updatedAt = Number(new Date())
            if (isWordPuzzle) {
              draft.study[index].secondsUsed = secondsUsed
              draft.study[index].retriesBlocked = retriesBlocked
            }
          }
          else {
            const optimisticData = {
              companyId,
              answer,
              id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
              courseId: action.courseId,
              chapterId: action.chapterId,
              actionId: action.id,
              viewed: 1,
            }
            if (isWordPuzzle) {
              optimisticData['secondsUsed'] = secondsUsed || 0
              optimisticData['retriesBlocked'] = retriesBlocked || false
            }
            draft.study.push(userStudyFactory(optimisticData))
          }
        }),
      })

      result({ error: false })
    })
    .catch((err) => {
      showAlert(`Unknown Error - ${err.message}`, 'danger')
      logger.error(`actions_actions: actionSaved error: ${err.message}`, err)
      result({ error: true })
    })
  })
}

export function blockRetries(action: Action, companyId: string, userId: string, answer: string, retriesBlocked: boolean) {
  return new Promise((result, reject) => {
    const token = getAuthTokens()
    refetch(`${BASE_API_URL}/api/v1/studies/answered`, {
      retries: 5,
      retryDelay: 5000,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-gnowbe-source': GNOWBE_SOURCE,
      },
      body: JSON.stringify({
        retriesBlocked,
        answer,
        companyId,
        courseId: action.courseId,
        chapterId: action.chapterId,
        actionId: action.id,
        contentType: action.contentType,
      }),
    })
    .then(res => checkHttpStatus(res, true))
    .then(res => res.json())
    .then((res) => {
      if (res.error) {
        showAlert(`Error - ${res.error.message}`, 'danger')
        return result({ error: true })
      }

      const data = client.readQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          userId,
          courseId: action.courseId,
        },
      })

      if (!data) return

      client.writeQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          userId,
          courseId: action.courseId,
        },
        data: produce(data, (draft) => {
          const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
          if (index !== -1) {
            draft.study[index].viewed = draft.study[index].viewed + 1 || 1
            draft.study[index].retriesBlocked = draft.study[index].retriesBlocked || true
            draft.study[index].timestamps.updatedAt = Number(new Date())
          }
          else {
            draft.study.push(userStudyFactory({
              companyId,
              retriesBlocked,
              answer,
              id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
              courseId: action.courseId,
              chapterId: action.chapterId,
              actionId: action.id,
              viewed: 1,
            }))
          }
        }),
      })

      result({ error: false })
    })
    .catch((err) => {
      showAlert(`Unknown Error - ${err.message}`, 'danger')
      logger.error(`actions_actions: actionSaved error: ${err.message}`, err)
      result({ error: true })
    })
  })
}

export function actionShared(action: Action, companyId: string, userId: string, answer: string, thumbnail: string, secondsUsed?: number) {
  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      userId,
      courseId: action.courseId,
    },
  })

  if (data) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
        if (index !== -1) {
          draft.study[index].answer = answer || draft.study[index].answer
          draft.study[index].sharedAt = Number(new Date())
        }
        else {
          draft.study.push(userStudyFactory({
            companyId,
            answer,
            id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
            courseId: action.courseId,
            chapterId: action.chapterId,
            actionId: action.id,
            sharedAt: Number(new Date()),
            secondsUsed: secondsUsed || 0,
          }))
        }
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
    })
  }

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/shared`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      answer,
      companyId,
      thumbnail,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
      contentType: action.contentType,
      title: action.title,
      secondsUsed: secondsUsed || 0,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`actions_actions: actionShared error: ${err.message}`, `(${err})`)
  })
}

export function actionUnshared(action: Action, companyId: string, userId: string, agent?: boolean) {
  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      userId,
      courseId: action.courseId,
    },
  })

  if (data && !agent) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
        if (index !== -1) {
          draft.study[index].sharedAt = null
        }
        else {
          draft.study.push(userStudyFactory({
            companyId,
            sharedAt: null,
            id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
            courseId: action.courseId,
            chapterId: action.chapterId,
            actionId: action.id,
            viewed: 1,
          }))
        }
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
    })
  }

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/unshared`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId,
      userId,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`actions_actions: actionUnshared error: ${err.message}`, err)
  })
}

export function actionPhotoUploaded(action: Action, companyId: string, userId: string, fileData: FilepickerMetadata) {
  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      userId,
      courseId: action.courseId,
    },
  })

  if (data) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
        if (index !== -1) {
          draft.study[index].answer = fileData.url || draft.study[index].answer
        }
        else {
          draft.study.push(userStudyFactory({
            companyId,
            id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
            answer: fileData.url,
            sharedAt: null,
            courseId: action.courseId,
            chapterId: action.chapterId,
            actionId: action.id,
            viewed: 1,
          }))
        }
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
    })
  }

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/photo/data`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      actionKey: {
        companyId,
        courseId: action.courseId,
        chapterId: action.chapterId,
        actionId: action.id,
      },
      filepickerMetadata: fileData,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`actions_actions: actionPhotoUploaded error: ${err.message}`, err)
  })
}

export function actionPhotoDeleted(action: Action, companyId: string, userId: string) {
  const data = client.readQuery({
    query: getStudyForSubscriptionQuery,
    variables: {
      companyId,
      userId,
      courseId: action.courseId,
    },
  })

  if (data) {
    client.writeQuery({
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
      data: produce(data, (draft) => {
        const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
        if (index !== -1) {
          draft.study[index].answer = ''
          draft.study[index].sharedAt = null
        }
        else {
          draft.study.push(userStudyFactory({
            companyId,
            id: `${userId}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
            answer: '',
            sharedAt: null,
            courseId: action.courseId,
            chapterId: action.chapterId,
            actionId: action.id,
            viewed: 1,
          }))
        }
      }),
    })
  }

  const revert = () => {
    client.writeQuery({
      data,
      query: getStudyForSubscriptionQuery,
      variables: {
        companyId,
        userId,
        courseId: action.courseId,
      },
    })
  }

  const token = getAuthTokens()
  refetch(`${BASE_API_URL}/api/v1/studies/photo`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      revert()
    }
  })
  .catch((err) => {
    revert()
    logger.error(`actions_actions: actionPhotoDeleted error: ${err.message}`, err)
  })
}

export function actionCertificate(action: Action, companyId: string, fullName: string, certSigner: string) {
  const token = getAuthTokens()
  const authTokenPayload: any = token ? jwtDecode(token) : {}
  refetch(`${BASE_API_URL}/api/v1/studies/certificate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
    body: JSON.stringify({
      companyId,
      courseId: action.courseId,
      chapterId: action.chapterId,
      actionId: action.id,
      userFullName: fullName,
      certSigner: certSigner !== '-' ? certSigner : '',
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (response.error) {
      showAlert(response.error.message, 'danger')
    }

    if (!response.error) {
      const data = client.readQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          courseId: action.courseId,
          userId: authTokenPayload.uid,
        },
      })

      if (!data) return

      client.writeQuery({
        query: getStudyForSubscriptionQuery,
        variables: {
          companyId,
          courseId: action.courseId,
          userId: authTokenPayload.uid,
        },
        data: produce(data, (draft) => {
          const index = draft.study.findIndex(s => s.chapterId === action.chapterId && s.actionId === action.id)
          if (index !== -1) {
            draft.study[index].answer = response.data.url || draft.study[index].answer
            draft.study[index].sharedAt = Number(new Date())
          }
          else {
            draft.study.push(userStudyFactory({
              companyId,
              id: `${authTokenPayload.uid}-${companyId}-${action.courseId}-${action.chapterId}-${action.id}`,
              answer: response.data.url,
              courseId: action.courseId,
              chapterId: action.chapterId,
              actionId: action.id,
              viewed: 1,
            }))
          }
        }),
      })

      track({
        event: 'Certificate Requested',
        variables : {
          programId: action.courseId,
          company_id: companyId,
        },
      })
      
    }
  })
  .catch((err) => {
    logger.error(`actions_actions: actionCertificate error: ${err.message}`, err)
  })
}
