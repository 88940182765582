import { gql } from '@apollo/client'
import { timestampsFragment, timestampsUpdateFragment } from './Timestamps'
import { progressReviewFragment } from './Reviews'

export const progressFragment = gql`
  ${timestampsFragment}
  ${progressReviewFragment}

  fragment ProgressFragment on Progress {
    id
    currentDay
    completion
    startedAt
    assessmentsGrade
    assessmentsCompleted
    assessmentsRetakeNum
    hasAssessments
    engagement
    notes
    numOfCompletedChapters
    numOfActions
    numOfCompletedActions
    teamCurrentDay
    lastVisitAt
    lastActiveAt
    timestamps {
      ...TimestampsFragment
    }
    review {
      ...ProgressReviewFragment
    }
    reviewTimestamps {
      ...TimestampsFragment
    }
  }
`

export const progressTimestampsUpdatedFragment = gql`
  ${timestampsUpdateFragment}

  fragment ProgressTimestampsUpdatedFragment on ProgressTimestampsUpdated {
    id
    companyId
    courseId
    timestamps {
      ...TimestampsUpdateFragment
    }
  }
`
