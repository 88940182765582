import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { GetCurrentSubscriptionRes } from 'graphql/queries/user/getUser'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { PeerInvite } from 'components/navbar/course-list/Item'
import { showModal } from 'components/utils/CustomModal'
import { CourseItem } from './CourseItem'
import { PinCompany } from './CompanyPin'
import { isAgent } from 'graphql/schemas/user/User'
import { stringSlicer } from 'utils/functions'
import { fetchCompanyData, ResponseData } from 'graphql/queries/company/getCompanyLandingPage'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { BASE_DASHBOARD_URL } from 'constants/General'
import { useWhiteLabel } from 'context/whiteLabel'

type CompanyBoxProps = {
  isActive: boolean;
  subData: GetCurrentSubscriptionRes;
  company: Company;
  courses: (Course&UserProgress)[]|undefined;
  expanded: string|null;
  pinnable: boolean;
  archive?: boolean;
  onExpand: (org: string|null) => void;
}

export const CompanyBox = ({ isActive, subData, company, courses, expanded, pinnable, archive, onExpand }: CompanyBoxProps) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [companyLandingPageData, setCompanyData] = useState<ResponseData | null>(null)
  const { whiteLabelData } = useWhiteLabel()

  const accentColor = whiteLabelData.accentColor

  const customStyle = {
    ...accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}
  }

  useEffect(() => {
    if (company.id === null) return

    const fetchData = async () => {
      try {
        const data = await fetchCompanyData(company.id)
        setCompanyData(data)
      } catch (err: any) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [company.id])

  const companyKey = `${company.organizationId}-${company.id}`
  const groupName = company.groupName || company.name
  const organizationName = company.organizationName
  const isExpanded = expanded === companyKey
  const canInvite = !archive && (company.peerInvitationMode === 'approval' || company.peerInvitationMode === 'open' || isAgent(subData.user, company.organizationId, company.id))
  const landingPageEnabled = companyLandingPageData?.data?.company?.settings?.landingPageEnabled
  const landingPageUrl = companyLandingPageData?.data?.company?.landingPageUrl
  const landingPageBannerImage = companyLandingPageData?.data?.company?.settings?.settings?.bannerImage
  const organizationId = company.organization?.id

  return (
    <div id={`${company.organizationId}-${company.id}`} style={customStyle}>
      <div className="px-6 py-4 bg-white shadow-sm flex flex-col md:flex-row justify-between rounded-md text-deepgray">
        <div className="relative">
          <div className="flex">
            <img className="w-24 h-14 rounded-lg mr-4" src={landingPageEnabled ? landingPageBannerImage : undefined}/>
            <div>
              <div className="font-bold text-lg">{stringSlicer(groupName, 35)}</div>
              <div className="text-sm">{organizationName}</div>
            </div>
          </div>

          {!archive && (
            <div className="absolute right-0 top-0 md:hidden">
              <a href="#" tabIndex={-1} className={`icon icon-angle-right-light w-5 text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'} cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`} onClick={(e) => {
                e.preventDefault()
                onExpand(isExpanded ? null : companyKey)
              }} />

              {pinnable && (
                <div>
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:items-center mt-4 md:mt-0 space-y-4 md:space-y-0 md:space-x-10">
          <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
            <div className="flex items-center">
              <span className="inline-block mr-1.5">
                <svg xmlns="http://www.w3.org/2000/svg" className={`w-3 h-4 md:w-auto ${accentColor ? `fill-[var(--accent-color)]` : 'fill-lake'}`} viewBox="0 0 448 512">
                  <path d="M448 336v-288C448 21.49 426.5 0 400 0H96C42.98 0 0 42.98 0 96v320c0 53.02 42.98 96 96 96h320c17.67 0 32-14.33 32-31.1c0-11.72-6.607-21.52-16-27.1v-81.36C441.8 362.8 448 350.2 448 336zM143.1 128h192C344.8 128 352 135.2 352 144C352 152.8 344.8 160 336 160H143.1C135.2 160 128 152.8 128 144C128 135.2 135.2 128 143.1 128zM143.1 192h192C344.8 192 352 199.2 352 208C352 216.8 344.8 224 336 224H143.1C135.2 224 128 216.8 128 208C128 199.2 135.2 192 143.1 192zM384 448H96c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32h288V448z">
                  </path>
                </svg>
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button className={`text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}`}  onClick={() => {
                    onExpand(isExpanded ? null : companyKey)
                  }}>
                    Programs
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {landingPageEnabled ? (
                <span className="inline-block mr-1.5">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-5 md:w-auto ${accentColor ? `fill-[var(--accent-color)]` : 'fill-lake'}`} viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M12 6.5a9.77 9.77 0 0 1 8.82 5.5c-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12A9.77 9.77 0 0 1 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5z"></path>
                  </svg>
                </span>
              ) : (
                <span className="inline-block mr-1.5">
                  <img src="/images/view-eye-gray-icon.svg" className="w-4 h-5 md:w-auto" />
                </span>
              )}

              <div className="flex items-center">
                <div className="font-bold">
                  <a
                    className={landingPageEnabled ? `text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}` : 'text-gray-400 cursor-not-allowed pointer-events-none'}
                    href={landingPageUrl}
                    data-test="landing-page-btn"
                    target='_blank' rel="noreferrer">
                    Landing Page
                  </a>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <span className={`inline-block mr-1.5 ${canInvite ? 'text-lake' : 'text-gray-400'}`}>
                <i className={`icon icon-users-solid w-8 md:w-auto ${accentColor && `text-[var(--accent-color)]`}`} />
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button className={canInvite ? `text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}` : 'text-gray-400 cursor-not-allowed'} onClick={() => {
                    if (!canInvite) return

                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: (
                        <PeerInvite
                          companyId={company.id}
                          isAgent={isAgent}
                          onClose={() => modal.close()}
                        />
                      ),
                      secondaryButton: false,
                      primaryText: intl.get('global_close'),
                    })
                  }}>
                    {intl.get('share_text')}
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <span className="inline-block mr-1.5">
                {canInvite ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-5 md:w-auto ${accentColor ? `fill-[var(--accent-color)]` : 'fill-lake'}`} viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                  </svg>
                  )
                : (
                  <Cog6ToothIcon className="w-4 h-5 md:w-auto" />
                )}
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button disabled={!canInvite} className={canInvite ? `text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'}` : 'text-gray-400 cursor-not-allowed'} onClick={() => {
                    if (!canInvite) return
                    window.open(`${BASE_DASHBOARD_URL}/organization/${organizationId}/company/${company.id}?scrollTo=settings-company-landing-pages#settings`, '_blank')
                  }}>
                    Settings
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          {!archive && (
            <>
              {pinnable && (
                <div className="hidden md:block">
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}

              <div className="hidden md:block">
                <button
                  className={`icon icon-angle-right-light text-deepgray ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-coral'} cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`}
                  onClick={() => onExpand(isExpanded ? null : companyKey)}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {courses && isExpanded && (
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:grid-cols-8 gap-4">
          {courses.map((course) => {
            if (!course) return null
            const subKey = `${company.id}-${course.id}`
            const isLiked = subData.user.favoritedSubscriptions.includes(subKey)
            const progress = (course as any).progress
            return (
              <CourseItem
                key={subKey}
                userId={subData.user.id}
                company={company}
                course={course}
                liked={isLiked}
                isArchived={!!archive}
                isActive={isActive}
                isAgent={isAgent(subData.user, company.organizationId, company.id)}
                isEditor={subData.user.editorCourses.includes(course.id)}
                paddingRight={false}
                dontShowGroups={true}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
