import { gql } from '@apollo/client'
import { homescreenFragment } from 'graphql/fragments/Home'
import { Company } from 'graphql/schemas/company/Company'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'

export type Homescreen = {
  courseCategories: HomescreenCourseCategory[];
  companyCategories: HomescreenCompanyCategory[];
  queryStateId?: string;
}

export type HomescreenCourseCategory = {
  id: 'recent'|'new'|'favorited'|'active_group'|'editor'|'explore';
  title: string;
  order: number;
  subscriptions: UserSubscription[];
}

export type HomescreenCompanyCategory = {
  id: string;
  title: string;
  order: number;
  companies: Company[];
}

export const getHomescreenData = gql`
  ${homescreenFragment}

  query GetHomescreenData {
    homescreenData @client {
      ...HomescreenFragment
    }
  }
`
