import React, { useRef, useEffect, useState, Fragment } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import { gql, useMutation, useQuery } from '@apollo/client'
import { uniq } from 'ramda'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetCourseAvailabilityRes, getCourseAvailabilityQuery } from 'graphql/queries/course/getCourseWithData'
import { Organization } from 'graphql/schemas/organization/Organization'
import { getUserQuery } from 'graphql/queries/user/getUser'
import { BASE_DASHBOARD_URL, BASE_URL } from 'constants/General'
import { Company } from 'graphql/schemas/company/Company'
import { Button } from 'components/utils/Button'
import { showAlert, Alert } from 'components/utils/Alert'
import { copyToClipboard, getMeta } from 'utils/functions'
import Toggle from 'components/utils/Toggle'
import { useNavigate } from 'react-router-dom'
import { history } from 'utils/history'

export type GetOrganizationsAllowedToPublishToRes = {
  organizations: {
    id: string;
    name: string;
  }[];
}

export const getOrganizationsAllowedToPublishToQuery = gql`
  query GetOrganizationsAllowedToPublishTo ($userId: String!) {
    organizations: getOrganizationsAllowedToPublishTo (userId: $userId) {
      id
      name
    }
  }
`

export type GetOrganizationCompaniesAllowedToPublishToRes = {
  companies: {
    id: string;
    name: string;
    groupName: string | null;
  }[];
}

export const getOrganizationCompaniesAllowedToPublishToQuery = gql`
  query GetOrganizationCompaniesAllowedToPublishTo ($organizationId: String!, $userId: String!) {
    companies: getOrganizationCompaniesAllowedToPublishTo (organizationId: $organizationId, userId: $userId) {
      id
      name
      groupName
    }
  }
`

export type ToggleCourseOrganizationAvailabilityVars = {
  organizationId: string;
  courseId: string;
  addRemove: 'add' | 'remove';
}

export type ToggleCourseOrganizationAvailabilityRes = {
  toggle: {
    addRemove: 'add' | 'remove';
    courseId: string;
    organizationId: string;
    organization: Organization;
  };
}

export const toggleCourseOrganizationAvailabilityMutation = gql`
  mutation ToggleCourseOrganizationAvailability ($organizationId: String!, $courseId: String!, $addRemove: AddRemove!) {
    toggle: toggleCourseOrganizationAvailability (organizationId: $organizationId, courseId: $courseId, addRemove: $addRemove) {
      organizationId
      courseId
      addRemove
    }
  }
`

type UpdateCourseAvailabilityVars = {
  courseId: string;
  publishToOrganizations?: string[];
  unpublishFromOrganizations?: string[];
  publishToCompanies?: string[];
  unpublishFromCompanies?: string[];
}

type UpdateCourseAvailabilityRes = {
  id: string;
  title: string;
  availableToOrganizations: string[];
  availableToCompanies: string[];
}

export const updateCourseAvailabilityMutation = gql`
  mutation UpdateCourseAvailability ($courseId: String!, $publishToOrganizations: [String!], $unpublishFromOrganizations: [String!], $publishToCompanies: [String!], $unpublishFromCompanies: [String!]) {
    update: updateCourseAvailability (courseId: $courseId, publishToOrganizations: $publishToOrganizations, unpublishFromOrganizations: $unpublishFromOrganizations, publishToCompanies: $publishToCompanies, unpublishFromCompanies: $unpublishFromCompanies) {
      id
      title
      availableToOrganizations
      availableToCompanies
    }
  }
`

type GnowbeUserCourseNotificationsUpdate = {
  distributionStatusUpdates: boolean;
}

export type UpdateUserCourseNotificationsVars = {
  courseId: string;
  userId: string;
  update: GnowbeUserCourseNotificationsUpdate;
}

export type UpdateUserCourseNotificationsRes = boolean

export const updateUserCourseNotificationsMutation = gql`
  mutation UpdateUserCourseNotifications ($courseId: String!, $userId: String!, $update: GnowbeUserCourseNotificationsUpdate!) {
    update: updateUserCourseNotifications (courseId: $courseId, userId: $userId, update: $update)
  }
`

export type OrganizationAllowPublishToRes = {
  res: {
    allowedToPublishToOrg: boolean;
    organization: Organization;
    companies: Company[];
  }[];
}

export const getOrganizationsOrCmpsAllowedToPublishToQuery = gql`
  query GetOrganizationsOrCmpsAllowedToPublishTo ($courseId: String!, $userId: String!) {
    res: getOrganizationsOrCmpsAllowedToPublishTo (courseId: $courseId, userId: $userId) {
      allowedToPublishToOrg
      organization {
        id
        name
        disabled
      }
      companies {
        id
        name
        disabled
      }
    }
  }
`

const PublishDialog = ({ v2, currentCompanyType, refetchQuery, onClose }: { v2?: boolean, currentCompanyType, refetchQuery: () => void, onClose: () => void }) => {
  const navigate = history.navigate || useNavigate()

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''

  const { data: userData } = useQuery(getUserQuery, {
    skip: !appData,
    variables: { userId },
    fetchPolicy: 'cache-and-network',
  })

  const { data: courseData, refetch } = useQuery<GetCourseAvailabilityRes>(getCourseAvailabilityQuery, {
    skip: !appData,
    variables: { courseId },
    fetchPolicy: 'network-only',
  })

  const { data: pubData, error, refetch: refetchPubData } = useQuery<OrganizationAllowPublishToRes, { courseId: string, userId: string }>(getOrganizationsOrCmpsAllowedToPublishToQuery, {
    variables: { courseId, userId },
  })

  const [toggle] = useMutation<UpdateCourseAvailabilityRes, UpdateCourseAvailabilityVars>(updateCourseAvailabilityMutation)
  const [toggleNotifications] = useMutation<UpdateUserCourseNotificationsRes, UpdateUserCourseNotificationsVars>(updateUserCourseNotificationsMutation)

  const [showModal, setShowModal] = useState(false)
  const [testingExpanded, setTestingExpanded] = useState(false)
  const [enabledNotifications, setEnabledNotifications] = useState(userData?.user.editorCourseNotifications?.find(c => c.courseId === courseId)?.distributionStatusUpdates || false)
  const [publishedTo, setPublishedTo] = useState<{ to: string[], from: string[], diff: { to: string[], from: string[] } }>({ to: [], from: [], diff: { to: [], from: [] } })
  const [step, setStep] = useState(0)
  const [availableTo, setAvailableTo] = useState((courseData?.course.availableToCompanies || []).concat(courseData?.course.availableToOrganizations || []))
  const firstRender = useRef(true)
  const _ref = useRef<any>(null)
  const _debounce = useRef<any>(null)

  const testCompany = courseData?.course.testCompany
  const accessCodes = testCompany?.accessCodes || []
  const shareUrl = `${BASE_URL}/d/joinGroup/${accessCodes[0] || ''}`

  useEffect(() => {
    if (firstRender.current && courseData?.course.availableToOrganizations) {
      firstRender.current = true
      setAvailableTo([...courseData.course.availableToOrganizations, ...courseData.course.availableToCompanies])
      setPublishedTo({
        to: [...courseData.course.availableToOrganizations, ...courseData.course.availableToCompanies],
        from: [],
        diff: { to: [], from: [] },
      })
    }
  }, [courseData?.course.availableToOrganizations])

  useEffect(() => {
    if (publishedTo.diff.to.length === 0 && publishedTo.diff.from.length === 0) return

    _debounce.current = clearTimeout(_debounce.current)
    _debounce.current = setTimeout(() => {
      const variables = {
        courseId,
        publishToOrganizations: publishedTo.diff.to.filter(o => !o.startsWith('c-')),
        unpublishFromOrganizations: publishedTo.diff.from.filter(o => !o.startsWith('c-')),
        publishToCompanies: publishedTo.diff.to.filter(o => o.startsWith('c-')).map(c => c.substring(2)),
        unpublishFromCompanies: publishedTo.diff.from.filter(o => o.startsWith('c-')).map(c => c.substring(2)),
      }

      toggle({ variables })

      setTimeout(() => {
        refetchQuery()
      }, 1000)

      setPublishedTo({
        to: availableTo,
        from: [],
        diff: { to: [], from: [] },
      })
    }, 1000)
  }, [publishedTo])

  const titleCn = classNames({
    'uppercase font-bold text-actions-multiple_choice': true,
  })

  let publishToOrgs = pubData?.res.filter(o => !o.organization.disabled) || []
  if (currentCompanyType !== 'test') {
    publishToOrgs = publishToOrgs.filter(o => o.organization.id === getMeta(userData.user.metadata, 'activeWorkspaceId'))
  }

  return (
    <div ref={_ref} className={v2 ? undefined : 'p-8'}>
      {!v2 && (
        <button className="mb-8 font-medium" onClick={() => {
          if (publishedTo.diff.to.length !== 0 || publishedTo.diff.from.length !== 0) {
            return setShowModal(true)
          }
          onClose()
        }}>
          <XMarkIcon className="w-5 h-5 inline-block hover:text-coral" />
        </button>
      )}

      <DiscardModal
        show={showModal}
        close={(discard) => {
          setShowModal(false)
          if (discard) {
            onClose()
          }
        }}
      />

      {step === 0 && (
        <>
          {!v2 && (
            <>
              <div className="text-4xl text-actions-multiple_choice">
                {intl.get('publish_program_title')}
              </div>

              <div className="mt-8">{intl.get('publish_course_text_1')}</div>

              {shareUrl && (
                <div className="border-t border-b border-medwarmgray my-6 py-4">
                  <div className="flex items-center">
                    <div className="flex-1">
                      <button className={titleCn} onClick={() => setTestingExpanded(!testingExpanded)}>
                        {intl.get('program_testing_text')}
                      </button>
                    </div>

                    <div>
                      <button className="text-sm" onClick={() => setTestingExpanded(!testingExpanded)}>
                        See {testingExpanded ? 'less' : 'more'} <ChevronDownIcon className={`w-3 h-3 inline-block transition ${testingExpanded ? 'rotate-180' : ''}`} />
                      </button>
                    </div>
                  </div>

                  <div className={`mt-4 ${testingExpanded ? '' : 'hidden'}`}>
                    {intl.get('publish_course_text_2')}

                    <div className="flex mt-4 space-x-4">
                      <input className="bg-lightwarmgray border-medwarmgray rounded flex-1 px-3 py-2" onClick={(e) => {
                        const el = e.target as HTMLInputElement
                        el.focus()
                        el.select()
                      }} value={shareUrl} onChange={(e) => {
                        e.preventDefault()
                      }} />

                      <Button
                        text={intl.get('global_copy_link')}
                        icon="icon-paste-light"
                        onClick={() => copyToClipboard(shareUrl)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className={titleCn}>{intl.get('publishing_details_text')}</div>

              <div className="my-4">
                <div className="font-bold text-sm mb-2 text-medgray">{intl.get('course_name')}</div>
                <p className="text-deepgray pb-0">{courseData?.course.title || intl.get('loading')}</p>
              </div>
            </>
          )}

          <>
            {!v2 && <div className="font-bold text-sm mb-2 text-medgray">{intl.get('organizations_text')}</div>}

            {!pubData
              ? error
                ? <Alert variant="warning" onClose={() => null}>
                  <div ref={(ref) => {
                    if (!ref) return
                    ref.innerHTML = ref.innerHTML.replace('{0}', `<a target="_blank" href="${BASE_DASHBOARD_URL}/program/${courseId}/status?continueAs=${encodeURIComponent(appData?.appState.loggedInAs.email || '')}" class="font-medium text-coral underline">${intl.get('gnowbe_dashboard')}</a>`)
                  }}>{intl.getHTML('publish_unable_to_publish', { 0: '{0}' })}</div>
                </Alert>
                : <div>{intl.get('loading')}</div>
              : <ul id="organization-select" className="list-none m-0 text-deepgray border border-gray-200 rounded-md">
                  {publishToOrgs.map((p, i) => {
                    return (
                      <li key={p.organization.id} className={`px-4 py-3 ${i % 2 !== 0 ? 'bg-gray-100' : undefined}`}>
                        {p.allowedToPublishToOrg
                          ? <PublishToOrganization
                              id={p.organization.id}
                              name={p.organization.name}
                              defaultChecked={publishedTo.to.includes(p.organization.id)}
                              onChange={(e: any) => {
                                // const addRemove = e.target.checked ? 'add' : 'remove'
                                const addRemove = e ? 'add' : 'remove'
                                if (addRemove === 'add') {
                                  setPublishedTo({
                                    to: uniq(publishedTo.to.concat(p.organization.id)),
                                    from: publishedTo.from.filter(o => o !== p.organization.id),
                                    diff: {
                                      to: uniq(!availableTo.includes(p.organization.id)
                                        ? publishedTo.diff.to.concat(p.organization.id)
                                        : publishedTo.diff.to.filter(o => o !== p.organization.id)),
                                      from: publishedTo.diff.from.filter(o => o !== p.organization.id),
                                    },
                                  })
                                  setAvailableTo(availableTo.concat(p.organization.id))
                                }
                                else {
                                  setPublishedTo({
                                    to: publishedTo.to.filter(o => o !== p.organization.id),
                                    from: uniq(publishedTo.from.concat(p.organization.id)),
                                    diff: {
                                      to: publishedTo.diff.to.filter(o => o !== p.organization.id),
                                      from: uniq(availableTo.includes(p.organization.id)
                                        ? publishedTo.diff.from.concat(p.organization.id)
                                        : publishedTo.diff.from.filter(o => o !== p.organization.id)),
                                    },
                                  })
                                  setAvailableTo(availableTo.filter(s => s !== p.organization.id))
                                }
                              }}
                            />
                          : <PublishToCompanies
                              pub={p}
                              publishedTo={publishedTo}
                              onChange={(e, c) => {
                                // const addRemove = e.target.checked ? 'add' : 'remove'
                                const addRemove = e ? 'add' : 'remove'
                                const companyId = `c-${c.id}`
                                if (addRemove === 'add') {
                                  setPublishedTo({
                                    to: uniq(publishedTo.to.concat(c.id)),
                                    from: publishedTo.from.filter(o => o !== c.id),
                                    diff: {
                                      to: uniq(!availableTo.includes(c.id)
                                        ? publishedTo.diff.to.concat(companyId)
                                        : publishedTo.diff.to.filter(o => o !== companyId)),
                                      from: publishedTo.diff.from.filter(o => o !== companyId),
                                    },
                                  })
                                  setAvailableTo(availableTo.concat(c.id))
                                }
                                else {
                                  setPublishedTo({
                                    to: publishedTo.to.filter(o => o !== c.id),
                                    from: uniq(publishedTo.from.concat(c.id)),
                                    diff: {
                                      to: publishedTo.diff.to.filter(o => o !== companyId),
                                      from: uniq(availableTo.includes(c.id)
                                        ? publishedTo.diff.from.concat(companyId)
                                        : publishedTo.diff.from.filter(o => o !== companyId)),
                                    },
                                  })
                                  setAvailableTo(availableTo.filter(s => s !== c.id))
                                }
                              }}
                            />
                        }
                      </li>
                    )
                  })}
                </ul>
            }
          </>

          {!v2 && (
            <div className="mt-8 text-center">
              <Button
                type="team"
                text={intl.get('global_save')}
                disabled={publishedTo.diff.to.length === 0 && publishedTo.diff.from.length === 0}
                onClick={() => {
                  const variables = {
                    courseId,
                    publishToOrganizations: publishedTo.diff.to.filter(o => !o.startsWith('c-')),
                    unpublishFromOrganizations: publishedTo.diff.from.filter(o => !o.startsWith('c-')),
                    publishToCompanies: publishedTo.diff.to.filter(o => o.startsWith('c-')).map(c => c.substring(2)),
                    unpublishFromCompanies: publishedTo.diff.from.filter(o => o.startsWith('c-')).map(c => c.substring(2)),
                  }
                  toggle({ variables })
                  setStep(1)
                }}
              />
            </div>
          )}
        </>
      )}

      {step === 1 && (
        <div>
          <div className="text-4xl text-actions-multiple_choice">
            {intl.get('publish_pending_title')}
          </div>

          <div className="mt-8 mb-4">{intl.get('publish_pending_text_1')}</div>
          <div ref={(ref) => {
            if (!ref) return
            ref.innerHTML = ref.innerHTML.replace('{0}', `<a target="_blank" href="${BASE_DASHBOARD_URL}/program/${courseId}/status?continueAs=${encodeURIComponent(appData?.appState.loggedInAs.email || '')}" class="font-medium text-coral underline">${intl.get('gnowbe_dashboard')}</a>`)
          }}>{intl.getHTML('publish_pending_text_2', { 0: '{0}' })}</div>

          <div className="px-8 py-6 rounded-md my-6 mx-12 space-y-3" style={{ backgroundColor: '#f4f0e5' }}>
            <div className="flex"><div className="font-bold w-36 mr-1">{intl.get('course_name')}:</div> {courseData?.course.title}</div>
            <div className="flex"><div className="font-bold w-36 mr-1">{intl.get('course_id')}:</div> {courseData?.course.id}</div>
            {publishedTo.diff.to.length >= 1 && (
              <div className="flex">
                <div className="font-bold w-36 mr-1">{intl.get('published_to')}:</div>
                <ul className="space-y-1">
                  {publishedTo.diff.to.sort((a, b) => a.localeCompare(b)).map((org, i) => {
                    if (org.startsWith('c-')) {
                      const cmps = pubData?.res.reduce((o: Company[], i) => o = o.concat(i.companies), []).filter(Boolean) || []
                      const cmp = cmps.find(c => c.id === org.substring(2)) || null
                      return <li key={i}>{cmp?.groupName || cmp?.name || 'Unknown'}</li>
                    }
                    const orgName = pubData?.res.find(o => o.organization.id === org) || null
                    return <li key={i}>{orgName?.organization.name || 'Unknown'}</li>
                  })}
                </ul>
              </div>
            )}

            {publishedTo.diff.from.length >= 1 && (
              <div className="flex">
                <div className="font-bold w-36 mr-1">{intl.get('unpublished_from')}:</div>
                <ul className="space-y-1">
                  {publishedTo.diff.from.sort((a, b) => a.localeCompare(b)).map((org, i) => {
                    if (org.startsWith('c-')) {
                      const cmps = pubData?.res.reduce((o: Company[], i) => o = o.concat(i.companies), []).filter(Boolean) || []
                      const cmp = cmps.find(c => c.id === org.substring(2)) || null
                      return <li key={i}>{cmp?.groupName || cmp?.name || 'Unknown'}</li>
                    }
                    const orgName = pubData?.res.find(o => o.organization.id === org) || null
                    return <li key={i}>{orgName?.organization.name || 'Unknown'}</li>
                  })}
                </ul>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-3">
            <input
              id="publish-email-notify"
              name="publish-email-notify"
              type="checkbox"
              defaultChecked={enabledNotifications}
              className="focus:ring-yellow-500 h-4 w-4 text-yellow-600 border-gray-300 rounded"
              onChange={e => setEnabledNotifications(e.target.checked)}
            />

            <div className="flex-1">
              <label htmlFor="publish-email-notify" className="cursor-pointer m-0">{intl.get('publish_pending_notify')}</label>
            </div>
          </div>

          <div className="my-8 space-y-3 flex flex-col">
            <Button className="w-96 mx-auto" type="team" text={intl.get('save_and_close')} onClick={() => {
              showAlert(intl.get('publish_program_sent'))
              toggleNotifications({
                variables: {
                  courseId,
                  userId,
                  update: {
                    distributionStatusUpdates: enabledNotifications,
                  },
                },
              })
              onClose()
            }} />

            <Button className="w-96 mx-auto" text={intl.get('save_and_home')} onClick={() => {
              showAlert(intl.get('publish_program_sent'))
              toggleNotifications({
                variables: {
                  courseId,
                  userId,
                  update: {
                    distributionStatusUpdates: enabledNotifications,
                  },
                },
              })
              onClose()
              navigate('/home')
            }} />
          </div>

          <div className="text-center">
            <button className="font-medium text-actions-multiple_choice" onClick={() => {
              refetch()
              setStep(0)
            }}>{intl.get('publish_other_org')}</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PublishDialog

const PublishToOrganization = ({ id, name, defaultChecked, onChange }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="font-bold">{name}</div>

      <div>
        <Toggle
          id={`org-${id}`}
          defaultChecked={defaultChecked}
          invertLabel={true}
          label={defaultChecked ? intl.get('manage_program_share_available') : intl.get('manage_program_share_unavailable')}
          style={{ margin: 0, background: 'transparent', display: 'flex', gap: '.5rem', fontSize: '.85rem', color: '#999' }}
          onChange={onChange}
        />
      </div>
    </div>
  )
  // return (
  //   <div className="flex items-center space-x-3">
  //     <input
  //       id={`org-${id}`}
  //       name={`org-${id}-published`}
  //       type="checkbox"
  //       checked={defaultChecked}
  //       className="focus:ring-yellow-500 h-4 w-4 -mt-0.5 text-yellow-600 border-gray-300 rounded"
  //       onChange={onChange}
  //     />

  //     <div className="flex-1">
  //       <label htmlFor={`org-${id}`} className="cursor-pointer m-0">{name}</label>
  //     </div>
  //   </div>
  // )
}

const PublishToCompanies = ({ pub, publishedTo, onChange }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="flex-col">
      <button id="notAllowedToPublishToOrg-button" className="flex items-center justify-between font-bold" onClick={() => setExpanded(!expanded)}>
        {/* <div className="border border-gray-300 rounded flex w-4 h-4 mr-3 -mt-0.5 items-center justify-center">
          <MinusSmIcon className="text-gray-300" />
        </div> */}

        {pub.organization.name}

        <ChevronDownIcon className={`ml-2 w-3 h-3 inline-block transition ${expanded ? 'rotate-180' : ''}`} />
      </button>

      <div className={`ml-4 mt-3 space-y-2 ${expanded ? '' : 'hidden'}`}>
        {pub.companies.map((c) => {
          return (
            <PublishToCompany
              key={c.id}
              id={c.id}
              name={c.name}
              defaultChecked={publishedTo.to.includes(c.id)}
              onChange={e => onChange(e, c)}
            />
          )
        })}
      </div>
    </div>
  )
}

const PublishToCompany = ({ id, name, defaultChecked, onChange }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="font-bold">{name}</div>

      <div>
        <Toggle
          id={`cmp-${id}`}
          defaultChecked={defaultChecked}
          label={defaultChecked ? intl.get('manage_program_share_available') : 'manage_program_share_unavailable'}
          style={{ margin: 0, background: 'transparent', display: 'flex', gap: '.5rem', fontSize: '.85rem', color: '#999' }}
          invertLabel={true}
          onChange={onChange}
        />
      </div>
    </div>
  )
  // return (
  //   <div className="flex items-center space-x-3">
  //     <input
  //       id={`org-${id}`}
  //       name={`org-${id}-published`}
  //       type="checkbox"
  //       checked={defaultChecked}
  //       className="focus:ring-yellow-500 h-4 w-4 -mt-0.5 text-yellow-600 border-gray-300 rounded ml-6"
  //       onChange={onChange}
  //     />

  //     <div className="flex-1">
  //       <label htmlFor={`org-${id}`} className="cursor-pointer m-0">{name}</label>
  //     </div>
  //   </div>
  // )
}

const DiscardModal = ({ show, close }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        open={show}
        className="fixed inset-0 overflow-y-auto custom-modal-design post"
        style={{ zIndex: 1061 }}
        onClose={() => close(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <DialogBackdrop className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" onClick={() => close(false)} />
          </TransitionChild>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:w-full px-5 pt-6 pb-5 sm:p-6 sm:max-w-xl">
              <DialogTitle
                as="h3"
                className="text-2xl font-bold font-noto-sans text-gray-900">
                {intl.get('discard_changes_title')}
              </DialogTitle>

              <div className="mt-6 mb-8">
                {intl.get('discard_changes_text')}
              </div>

              <div className="flex">
                <div className="flex-1" />
                <div className="space-x-4">
                  <Button className="secondary-button" text={intl.get('global_cancel')} onClick={() => close(false)} />
                  <Button className="primary-button" text={intl.get('global_discard')} type="danger" onClick={() => close(true)} />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}
