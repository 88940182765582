import Tippy from '@tippyjs/react'
import { MoreButton } from 'components/home/MoreButton'
import { useWhiteLabel } from 'context/whiteLabel'
import { fetchCompanyData, ResponseData } from 'graphql/queries/company/getCompanyLandingPage'
import { ChaptersUnlocked } from 'graphql/schemas/course/Course'
import { lighten } from 'polished'
import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { useLocation, useNavigate } from 'react-router-dom'
import { stringSlicer } from 'utils/functions'
import { track } from 'utils/track'

type MiniCourseDetailsProps = {
  isEditing: boolean;
  companyId: string;
  companyName: string;
  courseId: string;
  courseTitle: string;
  completion: number;
  canInvite: boolean;
  isEditor: boolean;
  isAgent: boolean;
  isSandbox: boolean;
  courseType: ChaptersUnlocked|undefined;
  rtlDir?: React.CSSProperties | {} | undefined;
}

export const MiniCourseDetails = ({ isEditing, companyId, companyName, courseId, courseTitle, completion, canInvite, isEditor, isAgent, isSandbox, courseType, rtlDir }: MiniCourseDetailsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { whiteLabelData } = useWhiteLabel()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [companyLandingPageData, setCompanyData] = useState<ResponseData | null>(null)

  const accentColor = whiteLabelData.accentColor
  const primaryColor = whiteLabelData.primaryColor

  const customStyle = {
    ...(accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}),
    ...(primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}),
  }

  useEffect(() => {
    if (companyId === null) return

    const fetchData = async () => {
      try {
        const data = await fetchCompanyData(companyId)
        setCompanyData(data)
      } catch (err: any) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [companyId])
  let courseTypeName = courseType === 'unlocked'
    ? intl.get('course_unlocked_mode')
    : courseType === 'daily'
      ? intl.get('course_daily_mode')
      : intl.get('course_streak_mode')

  const landingPageEnabled = companyLandingPageData?.data?.company?.settings?.landingPageEnabled
  const landingPageUrl = companyLandingPageData?.data?.company?.landingPageUrl

  return (
    <>
      <div className="text-sm font-medium flex" style={customStyle}>
        {isEditing
          ? <div>{intl.get('program')} (<Tippy placement="right" content="Click here to change learning mode."><button onClick={() => navigate('/home/publish')}>{courseTypeName}</button></Tippy>)</div>
          : <>
              {landingPageEnabled ? <div className="flex-1">
                <a data-test="session-landing-page-btn" className={`flex truncate mr-4 cursor-pointer ${accentColor ? 'text-[var(--accent-color)]': 'text-actions-multiple_choice'}`} href={`${landingPageUrl}`} target="_blank" rel="noreferrer">
                  <span className="flex mr-1.5 items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-5 md:w-auto ${accentColor ? `fill-[var(--accent-color)]` : 'fill-lake'}`} viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0V0z" fill="none"></path>
                      <path d="M12 6.5a9.77 9.77 0 0 1 8.82 5.5c-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12A9.77 9.77 0 0 1 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5z"></path>
                    </svg>
                  </span>
                  {stringSlicer(companyName, 20)}
                  </a>
              </div>
            : <div className="text-actions-multiple_choice flex-1 truncate mr-4">{stringSlicer(companyName, 20)}</div>}
              <div className="relative mt-0.5">
                <MoreButton
                  companyId={companyId}
                  courseId={courseId}
                  courseTitle={courseTitle || 'Program'}
                  canInvite={canInvite}
                  isEditor={isEditor}
                  isAgent={isAgent}
                  isSandbox={isSandbox}
                  groupName={companyName}
                />
              </div>
            </>
        }
      </div>

      <div className="text-lg mt-1 flex text-deepgray" style={customStyle}>
        <button
          style={{...rtlDir }}
          className={`flex-1 ${(rtlDir && Object.keys(rtlDir).length === 0) && 'text-left'} ${accentColor ? `hover:text-[var(--accent-color)]` : 'hover:text-actions-multiple_choice'} font-bold ${location.pathname.includes('/details') ? 'underline' : ''}`}
          onClick={() => {
            track({
              event: 'Program Title Clicked',
              variables: {
                onScreen: 'Learn',
              }
            })
            navigate(`/details/${courseId}`)
          }}>
          {courseTitle}
        </button>
      </div>

      {!isEditing && (
        <div className="flex items-center mt-3" style={customStyle}>

          <div className="rounded-md relative h-2 flex-1" style={{ backgroundColor: whiteLabelData.accentColor ? lighten(0.4, whiteLabelData.accentColor) : '#F9F7F2' }}>
            <span className="inline-block h-2 rounded-md absolute transition-all" style={{ width: `${completion}%`, backgroundColor: whiteLabelData.accentColor || '#5ec3c5' }} />
          </div>

          <div className="ml-3 text-sm">
            {completion}%
          </div>
        </div>
      )}
    </>
  )
}
