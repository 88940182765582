import React from 'react'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import { getAppStateQuery } from '../../graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery, getUserAndActiveCourseQuery } from '../../graphql/queries/user/getUser'
import { User } from 'graphql/schemas/user/User'

interface IfHasRoleProps {
  roles: string[];
  emptyElement: any;
  children: any;
  role: any;
}

class IfHasRole extends React.Component<IfHasRoleProps, {}> {
  childrenElements() {
    return typeof this.props.children === 'string'
      ? <span>{this.props.children}</span>
      : this.props.children == null
        ? null
        : this.props.children
  }

  emptyElement() {
    if (this.props.emptyElement == null) return null
    return React.createElement(this.props.children['type'] || 'span') // Always return empty element of the same type (ex. circle for svg)
  }

  render() {
    const { roles, role } = this.props

    const checkRoles = role.split('|')
    const hasRole = checkRoles.some(roleInner => roles.indexOf(roleInner) >= 0)

    return (hasRole ? this.childrenElements() : this.emptyElement()) as any
  }
}

const withUser: React.ComponentClass<any, any> = compose(
  graphql(getAppStateQuery, {
    name: 'app'
  }),
  graphql(getCurrentSubscriptionQuery, {
    skip: (props: any) => !props.app.appState.loggedInAs.uid || !props.app.appState.currentCompanyIdCourseId,
    name: 'userQuery',
    options: (props: any) => {
      const [companyId, courseId] = props.app.appState.currentCompanyIdCourseId.split('-')
      return ({
        variables: {
          userId: props.app.appState.loggedInAs.uid,
        },
        errorPolicy: 'all',
      })
    }
  })
)(p => {
  if (!p.app || !p.userQuery || !p.userQuery.user) {
    return <div>Oops...</div>
  }

  const user: User = p.userQuery.user

  return (
    <IfHasRole
      roles={user.roles}
      role={p.role}
      children={p.children}
      emptyElement={p.emptyElement}
    />
  )
})

export default withUser