import React from 'react'
import { withRouter } from './withRouterWrapper'
import { Location } from 'react-router-dom'

class ScrollToTop extends React.Component<{location?: Location<any>, children?: any}, {}> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const el = document.getElementById('home-main') || document.getElementById('learn-main')
      if (!el) return
      el.scrollTo && el.scrollTo(0, 0)
      // window.scrollTo && window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)