import React from 'react'
import classNames from 'classnames'

type CommentsCounterProps = {
  count: number;
  lastCommentAt: number;
  commentsLastSeenAt: number;
  onClick?: () => void;
}

const CommentsCounter = (props: CommentsCounterProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()

    props.onClick && props.onClick()
  }

  const { count, lastCommentAt, commentsLastSeenAt } = props

  const cn = classNames({
    'icon-comment-light font-bold': commentsLastSeenAt >= lastCommentAt || !count || (!commentsLastSeenAt && !lastCommentAt),
    'icon-comment-solid': lastCommentAt > commentsLastSeenAt,
  })

  const acn = classNames({
    red: lastCommentAt > commentsLastSeenAt,
    'flex items-center comments-counter space-x-1.5': true,
  })

  return (
    <div className="inline-block">
      <button className={acn} onClick={handleClick}>
        <span className={cn}></span>
        <span className="counter">{count}</span>
      </button>
    </div>
  )
}

export default CommentsCounter
