import React, { useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { useMutation, useQuery } from '@apollo/client'
import { getScormLaunchLinkMutation, GetScormLaunchLinkRes, GetScormLaunchLinkVars } from 'graphql/mutations/scorm/createLaunchLink'
import { getEngagementsForSubscriptionQuery, GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars } from 'graphql/queries/user/getEngagementsForSubscription'
import InfoBox from 'components/utils/InfoBox'
import OpenInApp from 'components/OpenInApp'
import { getSignedValue } from 'actions/files'
import { Alert } from 'components/utils/Alert'
import { BASE_URL } from 'constants/General'
import { Course } from 'graphql/schemas/course/Course'
import { isSessionDismissed, setSessionDismissed } from 'utils/functions'
import 'react-circular-progressbar/dist/styles.css'
import { showAssessmentResults } from './sliders/Assessments'
import { useNavigate } from 'react-router-dom'

type NoSessionAlertProps = {
  isEditing: boolean;
}

export const NoSessionAlert = ({ isEditing }: NoSessionAlertProps) => {
  const navigate = useNavigate()
  if (isEditing) {
    return (
      <InfoBox
        emoji="😢"
        title='This Program has no Session. Add one by clicking on the "+ Add Session" button in the bottom left corner of the screen.'
        text={<img src="/images/screenshots/add-session.png" className="w-full h-full object-cover" />}
      />
    )
  }

  return (
    <InfoBox
      emoji="😢"
      title="This Program has no Session."
      text={
        <p className="m-0 p-0 leading-relaxed">
          Ask the curator to create one, or change the Program<br/>
          by clicking on this <button onClick={() => navigate('/home/programs')} className="underline font-bold text-coral" >link</button>.
        </p>
      }
    />
  )
}

type AssessmentsCompletedDialogProps = {
  userId: string,
  isEditor: boolean,
  companyId: string,
  course: Course,
  chapterId: string,
  assessmentPassed: boolean,
  canRetakeAssessments: boolean,
}

export const AssessmentsCompletedDialog = ({ userId, isEditor, companyId, course, chapterId, assessmentPassed, canRetakeAssessments }: AssessmentsCompletedDialogProps) => {
  // const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data: engagementData } = useQuery<GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars>(getEngagementsForSubscriptionQuery, {
    // skip: !appData,
    variables: { companyId, courseId: course.id, userId },
  })

  const defaultDismissed = isSessionDismissed('assessment-complete-dialog')
  const [isDismissed, setIsDismissed] = useState(defaultDismissed)

  if (isDismissed) return null

  let message = assessmentPassed ? intl.get('assessment_right_drawer_passed_text') : intl.get('you_didn_t_pass_the_assessment')
  let variant: 'info'|'success'|'danger' = 'info'
  const engagement = engagementData?.engagement.find(e => e.chapterId === chapterId)
  // const assessmentsHasProctoring = !!engagement?.assessmentsHasProctoring
  // const assessmentsProctorResults = engagement?.assessmentsProctorResults

  // if (assessmentsHasProctoring) {
  //   if (!assessmentsProctorResults || assessmentsProctorResults.status === 'no_results') {
  //     message = intl.get('proctoring_awaiting_results')
  //   }
  //   else if (assessmentPassed && assessmentsProctorResults.status === 'accepted') {
  //     message = intl.get('assessment_right_drawer_passed_text')
  //     variant = 'success'
  //   }
  //   else if (assessmentPassed && assessmentsProctorResults.status === 'rejected') {
  //     message = intl.get('proctoring_rejected')
  //     variant = 'danger'
  //   }
  // }

  return (
    <Alert
      variant={variant}
      className="flex items-center justify-center m-0 rounded-none sm:rounded-md sm:my-4 pb-3 shadow-md"
      onClose={() => {
        setSessionDismissed('assessment-complete-dialog')
        setIsDismissed(true)
      }}>
      <div className="text-center">
        {message}
        <br/>
        <a href="#" className="text-qa" onClick={(e) => {
          e.preventDefault()
          showAssessmentResults({userId, companyId, course, isEditor, skipProgress: true })
        }}>{intl.get('show_assessment_grade')}</a> {canRetakeAssessments && <span>(&amp; {intl.get('reset_score').toLowerCase()})</span>}
      </div>
    </Alert>
  )
}

export const ScormProgram = ({ companyId, course }: { companyId: string, course: Course }) => {
  const signedUrl = course.imageUrl && getSignedValue(course.imageUrl) || ''
  const ref = useRef<any>(null)

  const openRequestedPopup = (url: string) => {
    ref.current = window.open(
      url,
      'ScormProgram',
      'resizable,scrollbars,status,width=1024,height=600'
    )
  }

  const [getScormLaunchLink, { data, loading }] = useMutation<GetScormLaunchLinkRes, GetScormLaunchLinkVars>(getScormLaunchLinkMutation, {
    variables: {
      companyId,
      courseId: course.id,
      redirectUrl: `${BASE_URL}/scorm/register`,
    },
    onCompleted: (data) => {
      openRequestedPopup(data.getScormLaunchLink)
    },
  })

  return (
    <>
      <OpenInApp />

      <div className="container stick-container">
        <div className="w-full">
          <section className="session-block mt-0 sm:mt-4 text-center">
            <h2 className="text-teak text-base uppercase font-noto-sans tracking-wider mt-4 sm:mt-0">
              {intl.get('scorm_hosted_on_gnowbe')}
            </h2>

            <h1 className="mt-6 sm:mt-8 text-2xl sm:text-3xl">{course.title}</h1>

            {signedUrl && (
              <div className="image-holder w-48 h-48 sm:w-60 sm:h-60 mx-auto max-w-full">
                <img
                  className="image w-full h-full"
                  src={signedUrl}
                />
              </div>
            )}

            {course.description && <div className="text-left mt-8 break-words session-description" dangerouslySetInnerHTML={{ __html: course.description }} />}
          </section>

          <section className="mx-4 sm:mx-0">
            <div className="action-item rounded-md shadow-lg px-8 py-6 sm:px-12 sm:py-8" tabIndex={-1} onClick={() => getScormLaunchLink()}>
              <div className="flex">
                <aside className="action-type w-3/12 sm:w-2/12">
                  <span className="type-name text-teak">{intl.get('useraction_scorm')}</span>
                </aside>

                <section className="action-content w-9/12 sm:w-10/12">
                  <span className="action-title">
                    {loading
                      ? 'Loading SCORM program...'
                      : 'Play SCORM program'
                    }
                  </span>
                </section>
              </div>

              <div className="mt-4">
                <img src="/images/buttons/btn-scorm-play.svg" className="mx-auto" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
