import React from 'react'
import intl from 'react-intl-universal'
import { showModal } from 'components/utils/CustomModal'
import { createMarkup } from 'utils/functions'
import { localeFormatDistanceStrict } from 'utils/dateFunctions'
import { Post } from 'graphql/schemas/group/Post'
import CommentsCounter from 'components/group/CommentsCounter'
import LikesCounter from 'components/group/LikesCounter'
import { ShareKeyData } from 'actions/likes_actions'
import { actionUnshared } from 'actions/actions_actions'
import { UserBasic } from 'graphql/schemas/user/User'
import { wallRemove } from 'actions/walls_actions'
import { augmentName } from 'utils/utils'
import { getSignedValue } from 'actions/files'
import ProfilePicture from 'components/utils/ProfilePicture'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { withRouter } from 'components/utils/withRouterWrapper'
import { NavigateFunction } from 'react-router-dom'

type WallItemProps = {
  share: Post;
  visibleShare: boolean;
  user: UserBasic;
  userId: string;
  isAgent: boolean;
  shareChapterOrder: number|null;
  filter?: string;
  hideMeta?: boolean;
  isNew?: boolean;

  router: {
    location: {
      pathname: string;
      search: string;
      state?: any;
      hash: string;
    };
    navigate: NavigateFunction;
  };
  onShareSelected: (post: Post) => void;
  clearMasonryCache: () => void;
  closeShare: () => void;
}

class WallItem extends React.Component<WallItemProps, { showVideo?: boolean }> {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
    }
    this.closeShare = this.closeShare.bind(this)
    this.removeShare = this.removeShare.bind(this)
  }

  truncateMarkup(html: any) {
    let truncated = html.length >= 203 ? html.substring(0, 200) : html
    truncated += html.length >= 203 ? `... <span class="text-medgray">${intl.get('read_more')}</span>` : ''
    truncated = createMarkup(truncated, true)
    return truncated
  }

  gotoUser(userId: string) {
    this.props.router.navigate(`/profile/${userId}`)
  }

  closeShare() {
    this.props.closeShare && this.props.closeShare()
  }

  unShare(share: Post, e: React.SyntheticEvent<any>) {
    e.stopPropagation()
    showModal({
      title: intl.get('global_are_you_sure_header'),
      content: intl.get('unshare_confirmation_text'),
      primaryText: intl.get('unshare_confirmation_button'),
      primaryAction: () => {
        const action = {
          courseId: share.courseId,
          chapterId: share.chapterId,
          id: share.actionId,
        }
        actionUnshared(action as any, share.companyId, this.props.user.id)
      },
    })
  }

  removeShare(share: Post) {
    showModal({
      title: intl.get('global_are_you_sure_header'),
      component: intl.getHTML('remove_share_confirmation_text'),
      primaryText: intl.get('remove_share_confirmation_button'),
      primaryAction: () => {
        const shareKey: ShareKeyData = {
          companyId: share.companyId,
          courseId: share.courseId,
          chapterId: share.chapterId,
          actionId: share.actionId,
          userId: share.userId,
        }
        wallRemove(shareKey)
      },
    })
  }

  render() {
    const { share, user, userId, isAgent, shareChapterOrder, isNew } = this.props

    const post = share

    const shareKey = { companyId: post.companyId, courseId: post.courseId, chapterId: post.chapterId, actionId: post.actionId, userId: post.userId }
    const shareKeyString = `${post.companyId}-${post.courseId}-${post.chapterId}-${post.actionId}-${post.userId}`

    const signedPhoto = getSignedValue(post.answer)
    const signedThumb = getSignedValue(post.thumbnail)

    const showMeta = post.title !== 'Post' && !this.props.hideMeta
    const mtop = showMeta ? '1rem': 0

    const photoStyle = { marginTop: mtop, height: 205, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${signedPhoto})` }
    const thumbStyle = { height: 205, position: 'relative' as 'relative', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${signedThumb})`, marginTop: mtop }
    const commentStyle = { margin: '1rem 1rem 0', lineHeight: '1.5rem', wordBreak: 'break-word' as 'break-word' }
    const playButtonStyle = { position: 'absolute' as 'absolute', left: '50%', marginLeft: '-33px', top: '50%', marginTop: '-33px' }

    if (!user) return null

    const itemCls = classNames({
      'journey-item-v2': true,
      'journey-item-content bg-deadyellow border border-lightwarmgray rounded-md': !isNew,
      // 'shadow-lg rounded-md': !isNew,
    })

    const contentCls = classNames({
      'journey-item-content': true,
      'bg-deadyellow border border-lightwarmgray rounded-md': isNew,
    })

    return (
      <div className={itemCls} key={shareKeyString} onClick={() => { this.props.onShareSelected(share) }}>
        <div className={contentCls}>
          <div className="content text-pretty">
            {showMeta && (
              <div className="text-teak font-medium p-4 pb-0 text-sm">
                {shareChapterOrder && <span>{intl.get('journey_session_number', { 0: shareChapterOrder })} &mdash; </span>}
                {augmentName(post.title, user)}
              </div>
            )}

            {(() => {
              switch (post.contentType) {
                case 'word_puzzle':
                  return (
                    <div style={commentStyle}><strong>{user.profile.fullName}</strong> solved it in {post.secondsUsed || 0} seconds</div>
                  )

                case 'photo':
                  if (!post.thumbnail) {
                    return <div style={photoStyle} />
                  }

                  return (
                    <div style={thumbStyle}>
                      <img src="/images/play-button.png" style={playButtonStyle} />
                    </div>
                  )
                case 'qa':
                case 'post_text':
                  return <div style={commentStyle} dangerouslySetInnerHTML={this.truncateMarkup(post.answer)} />
                case 'post_video':
                  return (
                    <div>
                      <div style={thumbStyle}>
                        <img src="/images/play-button.png" style={playButtonStyle} />
                      </div>
                      {post.comment ? <div style={commentStyle}>{post.comment}</div> : null}
                    </div>
                  )
                case 'post_image':
                  return (
                    <div>
                      <div style={photoStyle} />
                      {post.comment ? <div style={commentStyle}>{post.comment}</div> : null}
                    </div>
                  )
              }
            })()}
          </div>

          <div className="share-like mt-4 flex">
            <div className="flex-1 space-x-2">
              <CommentsCounter count={post.commentsCount} lastCommentAt={post.lastCommentAt || 0} commentsLastSeenAt={post.commentsLastSeenAt || 0} onClick={() => this.props.onShareSelected(share)} />
              <LikesCounter shareKey={shareKey} defaultCount={post.likesCount} defaultLikedByMe={post.likedByMe} />
            </div>

            <div>
              {userId === user.id && <span className="icon-trash-alt-light" title={intl.get('remove_from_group')} onClick={this.unShare.bind(this, share)} />}
              {userId !== user.id && isAgent && (
                <Tippy placement="left" content="Remove as Moderator">
                  <button className="icon-trash-alt-light ml-2 text-coral" onClick={(e) => {
                    e.stopPropagation()
                    this.removeShare(share)
                  }} />
                </Tippy>
              )}
            </div>
          </div>

          <div className="footer mx-4 px-0 py-2" onClick={(e) => { e.stopPropagation(); this.props.router.navigate(`/profile/${user.id}`) }}>
            <ProfilePicture
              userId={user.id}
              inUser={user}
            />

            <div className="meta ml-4 text-left">
              <div className="author">{user.profile.fullName && user.profile.fullName.substring(0, 50) + (user.profile.fullName.length > 53 ? '...' : '')}</div>
              <span className="time -mt-1">{localeFormatDistanceStrict(post.createdAt, Date.now())}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(WallItem)
