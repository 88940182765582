import { gql } from '@apollo/client'
import { companyFragment, subsDistributionParamsFragment } from 'graphql/fragments/Company'
import { courseFragment } from 'graphql/fragments/Course'
import { progressFragment } from 'graphql/fragments/Progress'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { billingServiceFragment } from 'graphql/schemas/organization/Organization'
import { UserProgress } from 'graphql/schemas/user/UserProgress'

export type UserSubscriptionBasic = {
  id: string;
  companyId: string;
  courseId: string;
  isArchived: boolean;
  isGnowbeLearn: boolean|null;
  isLocked?: boolean;
  gnowbeLearnBundleIds: string[]|null;
  progress: UserProgress|null;
}

export type GetUserSubscriptions2Res = {
  response: {
    subscriptions: UserSubscriptionBasic[];
    companies: Company[];
    courses: Course[];
  };
}

export type GetUserSubscriptionsVars = {
  archived: boolean;
  userId: string;
  organizationId?: string;
}

export const getUserSubscriptionsQuery = gql`
  ${subsDistributionParamsFragment}
  ${progressFragment}
  ${billingServiceFragment}

  query GetUserSubscriptions ($userId: String!, $archived: Boolean!) {
    subscriptions: getUserSubscriptions (userId: $userId, archived: $archived) {
      id
      companyId
      courseId
      isArchived
      isGnowbeLearn
      gnowbeLearnBundleIds
      company {
        id
        name
        groupName
        organizationId
        organizationName
        companyType
        usersCount
        tags
        greetingsDisabled
        accessCodes
        peerInvitationMode
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
        organization {
          id
          allowUserAnonymity
          featureFlags
          billingService {
            ...BillingServiceFragment
          }
        }
      }
      course {
        id
        title
        chaptersCount
        chaptersUnlocked
        curatorName
        curatorPhotoUrl
        curatorChatEnabled
        imageUrl
        isTemplate
        tags
        testCompany {
          id
          accessCodes
        }
        timestamps {
          createdAt
          createdAtTree
          updatedAt
          updatedAtTree
        }
      }
      progress {
        ...ProgressFragment
      }
    }
  }
`

export const getUserSubscriptions2Query = gql`
  ${subsDistributionParamsFragment}
  ${progressFragment}
  ${courseFragment}
  ${companyFragment}

  query GetUserSubscriptions ($userId: String!, $archived: Boolean!, $organizationId: String) {
    response: getUserSubscriptions2 (userId: $userId, archived: $archived, organizationId: $organizationId) {
      subscriptions {
        id
        companyId
        courseId
        isArchived
        isGnowbeLearn
        isLocked
        gnowbeLearnBundleIds
        progress {
          ...ProgressFragment
        }
      }
      companies {
        ...CompanyFragment
      }
      courses {
        ...CourseFragment
      }
    }
  }
`

export const getUserSubscriptionQuery = gql`
  query GetUserSubscription ($companyId: String!, $courseId: String!, $userId: String!) {
    subscription: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      company {
        organizationId
      }
    }
  }
`
