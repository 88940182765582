import { gql } from '@apollo/client'
import { timestampsFragment } from 'graphql/fragments/Timestamps'
import { studyReviewFragment } from './Reviews'

export const studyFragment = gql`
  ${timestampsFragment}
  ${studyReviewFragment}

  fragment StudyFragment on Study {
    id
    companyId
    courseId
    chapterId
    actionId
    rewardsSeen
    viewed
    answer
    # isCorrect
    thumbnail
    sharedAt
    contentType
    commentsCount
    likesCount
    likedByMe
    commentsLastSeenAt
    lastCommentAt
    isAssessmentCorrect
    tries
    secondsUsed
    retriesBlocked
    reminder {
      id
      at
    }
    timestamps {
      ...TimestampsFragment
    }
    review {
      ...StudyReviewFragment
    }
    reviewTimestamps {
      ...TimestampsFragment
    }
  }
`
