import intl from 'react-intl-universal'

export const welcomeTourSteps = [
  {
    selector: '#workspace-selector',
      content: 'You can quickly and easily switch between your workspaces. You will only be able to see or edit the programs that are part of the selected workspace.',
  },
  {
    selector: '#sidebar-search',
    content: 'Search all programs and their contents within.',
  },
  {
    selector: '#sidebar-programs',
    content: 'Browse all your programs.',
  },
  {
    selector: '#sidebar-groups',
    content: 'Browse all the groups you are a part of.',
  },
  {
    selector: '#sidebar-dashboard',
    content: 'Manage your programs, groups and users here.',
  },
  {
    selector: '#sidebar-explore',
    content: 'Explore GnowbeLearn™, our subscription-based library of Microlearning Courses.',
  },
  {
    selector: '#create-program-button',
    content: 'Create your own program by clicking on this button.',
  },
  {
    selector: '#question-mark',
    content: 'You can always access the Gnowbe guided tour here.',
    // action: () => console.log('asdfg'),
  },
]

export const explainWorkspaces = [  {
  selector: '#workspace-selector',
    content: 'Here is where you can switch between your workspaces.',
},
{
  selector: '#sidebar-search',
  content: 'Here is where you can search for all programs or content regardless of workspace.',
},
]

export const cancelTour = [
  {
    selector: '#tour-button',
    content: 'You can always access the Gnowbe guided tour here.',
    // action: () => console.log('asdfg'),
  },
]
