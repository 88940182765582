import { gql } from '@apollo/client'

export const profileFragment = gql`
  fragment ProfileFragment on User {
    id
    firstName
    lastName
    fullName
    imageUrl
  }
`

export const userBasicFragment = gql`
  fragment UserBasicFragment on User {
    id
    profile {
      id
      firstName
      lastName
      fullName
      imageUrl
    }
  }
`

export const userBasicExtraFragment = gql`
  fragment UserBasicExtraFragment on UserBasicExtra {
    id
    email
    profile {
      id
      firstName
      lastName
      fullName
      imageUrl
    }
  }
`
