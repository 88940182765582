import { gql } from '@apollo/client'

export type EnsureOrganizationForUserVars = {
  userId: string;
}

export const ensureOrganizationForUserMutation = gql`
  mutation EnsureOrganizationForUser ($userId: String!) {
    ensureOrganizationForUser (userId: $userId)
  }
`
