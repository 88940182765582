const colors = {
  neutral0: '#ffffff',
  neutral100: '#fafafa',
  neutral200: '#f1f9f9',
  neutral300: '#cccccc',
  neutral400: '#9f9f9f',
  neutral500: '#878787',
  neutral600: '#6f6f6f',
  neutral700: '#575757',
  neutral800: '#2B2B2B',
  neutral900: '#272727',
  mint50: '#f4faf9',
  mint100: '#d9e2e0',
  mint200: '#becac7',
  mint300: '#a3b2ae',
  mint400: '#89D1D2',
  mint500: '#5EC3C5',
  mint600: '#3BA4A5',
  mint700: '#296F70',
  mint800: '#163B3C',
  mint900: '#002218',
  green50: '#e3f6ee',
  green100: '#bbe9d4',
  green200: '#8ddbb9',
  green300: '#55ce9e',
  green400: '#00c389',
  green500: '#00b873',
  green600: '#00a868',
  green700: '#00965a',
  green800: '#00844e',
  green900: '#006437',
  amethyst50: '#eee9f8',
  amethyst100: '#d4c8ed',
  amethyst200: '#b9a4e1',
  amethyst300: '#9d7fd6',
  amethyst400: '#8762cc',
  amethyst500: '#7247c2',
  amethyst600: '#6841bc',
  amethyst700: '#5b39b3',
  amethyst800: '#5033aa',
  amethyst900: '#3d279c',
  semantics300: '#be73ef',
  semantics270: '#8c62f4',
  semantics240: '#5860f9',
  semantics210: '#51a6f4',
  semantics180: '#3cd6ce',
  semantics150: '#03cf95',
  semantics120: '#7ae26f',
  semantics90: '#e6df63',
  semantics60: '#f2a551',
  semantics30: '#f76357',
  red400: '#ED8054',
  red500: '#C5391B',
}

export default colors
