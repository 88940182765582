import React from 'react'

const InfoBox = ({ emoji, title, text }: { emoji: string, title?: string, text: any }) => {
  return (
    <div className="info-box">
      <p className="text-3xl mb-0 pb-6">{emoji}</p>
      {title && <p className="font-bold mb-0">{title}</p>}
      {text}
    </div>
  )
}

export default InfoBox
