// import { useWindowDimensions } from 'react-native'
// const { width, height } = useWindowDimensions()

// get window size inside functional component and pass here to get breakpoint

export const xl2 = (width: number) => width < 1535
export const xl = (width: number) => width < 1279
export const lg = (width: number) => width < 1023
export const md = (width: number) => width < 767
export const sm = (width: number) => width < 639
