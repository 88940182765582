import React, { Fragment, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import { useQuery } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { Popover } from 'react-tiny-popover'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { NotificationSchema, NotificationsResponse } from 'graphql/schemas/activity/Notification'
import { getSignedValue } from 'actions/files'
import { activitySeen, changeCurrentCourse, clearNotifications, clearNotificationsById, fetchChildNotifications, fetchParentNotifications } from 'actions/users_actions'
import { showModal } from 'components/utils/CustomModal'
import { localeFormatDistance } from 'utils/dateFunctions'
import { useNavigate } from 'react-router-dom'

export const Notifications = ({ fullPage, onOpen }: { fullPage?: boolean, onOpen?: () => void }) => {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState<NotificationSchema[] | undefined>(undefined)
  const [earlierNotifications, setEarlierNotifications] = useState<NotificationSchema[] | undefined>(undefined)

  useEffect(() => {
    fetchNotifications()

    if (fullPage) {
      activitySeen()
    }
  }, [])

  const fetchNotifications = async () => {
    const nots: NotificationsResponse = await fetchParentNotifications()
    const notificationsGrouped = (nots.payload?.inactiveSubscriptions || []).concat(nots.payload?.editorNotifications || []).concat(nots.payload?.privateChatNotifications || [])
    const newNotifications = notificationsGrouped.filter(n => n.timestamp + 60 * 60 * 24 * 1000 > Date.now()).sort((a, b) => b.timestamp - a.timestamp)
    const earlierNotifications = notificationsGrouped.filter(n => n.timestamp + 60 * 60 * 24 * 1000 <= Date.now()).sort((a, b) => b.timestamp - a.timestamp)
    setNotifications(newNotifications)
    setEarlierNotifications(earlierNotifications)
  }

  const clearNotifs = async () => {
    await clearNotifications(false)
    setNotifications((notifications || []).map(n => ({ ...n, seen: true })))
  }

  const hasNewNotifications = !!notifications?.length
  const hasEarlierNotifications = !!earlierNotifications?.length

  return (
    <div>
      {!fullPage && (
        <div className="flex items-center px-4 pt-3 pb-2">
          <div className="flex-1 font-bold text-lg">
            {intl.get('notifications_title')}
          </div>

          <button className="text-sm text-actions-multiple_choice underline font-medium" onClick={() => {
            onOpen && onOpen()
            navigate('/activity')
          }}>
            {intl.get('notifications_view_all')}
          </button>
        </div>
      )}

      <div className={`${!fullPage ? 'max-h-96 overflow-y-auto' : ''}`}>
        <div className="flex items-center px-4 py-2">
          <div className="flex-1 font-medium">{intl.get('global_new')}</div>

          {hasNewNotifications && !fullPage && (
            <button className="text-sm text-actions-multiple_choice font-medium" onClick={clearNotifs}>
              {intl.get('activity_mark_all_as_read')}
            </button>
          )}

          {fullPage && (
            <div className="space-x-4">
              {(hasNewNotifications || hasEarlierNotifications) && (
                <button className="text-sm text-actions-multiple_choice font-medium underline" onClick={clearNotifs}>
                  <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 h-5 inline-block mr-2"><path fill="currentColor" d="M480.1 128.1l-272 272C204.3 405.7 198.2 408 192 408s-12.28-2.344-16.97-7.031l-144-144c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L192 350.1l255-255c9.375-9.375 24.56-9.375 33.94 0S490.3 119.6 480.1 128.1z" /></svg>
                  {intl.get('activity_mark_all_as_read')}
                </button>
              )}

              <button className="text-sm text-actions-multiple_choice font-medium underline" onClick={() => navigate('/settings#notifications')}>

              <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 h-5 inline-block mr-2"><path fill="currentColor" d="M255.1 512c-56.05 0-75.99-11.33-75.99-35.94V436.5c-15.17-6.375-29.35-14.53-42.36-24.41l-34.3 19.78c-4.621 2.703-9.976 4.013-15.36 4.013c-36.71 0-76.25-92.87-76.25-108.6c0-10.85 5.806-21.34 15.71-27.07l34.17-19.72C60.52 272.1 59.99 264 59.99 255.1s.5313-16.13 1.625-24.47L27.42 211.8C17.53 206.1 11.73 195.6 11.73 184.7c0-12.58 37.98-108.7 76.2-108.7c5.417 0 10.82 1.338 15.52 4.111L137.6 99.88C150.6 90.01 164.8 81.85 179.1 75.47V35.91C179.1 4.335 215.7 0 256 0c39.71 0 75.1 4.083 75.1 35.91v39.56c15.17 6.375 29.35 14.53 42.36 24.41l34.3-19.78c4.621-2.703 9.981-4.013 15.37-4.013c36.47 0 76.24 92.55 76.24 108.6c0 10.85-5.806 21.34-15.71 27.07l-34.17 19.72c1.094 8.344 1.625 16.44 1.625 24.47s-.5313 16.13-1.625 24.47l34.19 19.75c9.895 5.703 15.7 16.19 15.7 27.05c0 12.59-37.98 108.7-76.21 108.7c-5.42 0-10.83-1.338-15.51-4.111l-34.19-19.72c-13.02 9.876-27.19 18.03-42.36 24.41v39.56C332 500.6 312.1 512 255.1 512zM140.9 373.2c35.92 30.82 52.34 34.36 71.05 41v61.85c14.11 2.344 28.82 3.727 43.76 3.727c14.95 0 30.13-1.383 45.19-4.571l-.9532-61c16.07-5.702 35.18-10.22 71.05-41l53.61 30.97c18.78-22.06 33.77-47.97 43.39-76.57l-52.94-30.56c2.745-14.99 4.859-25.43 4.859-39.07c0-10.95-1.364-23.97-4.859-43.06l53.46-30.85c-9.829-27.75-24.92-53.97-45.1-76.72l-52.43 31.41c-35.92-30.82-52.34-34.36-71.05-41V35.91c-14.11-2.344-28.82-3.727-43.76-3.727c-14.95 0-30.13 1.383-45.19 4.571l.9532 61C195.9 103.5 176.8 107.1 140.9 138.8L87.33 107.8c-18.99 22.31-34.08 48.53-43.69 77.47l53.24 29.66C94.14 229.9 92.02 240.4 92.02 253.1c0 10.95 1.364 23.97 4.859 43.06l-53.46 30.85c9.829 27.75 24.92 53.97 45.1 76.72L140.9 373.2zM256 351.1c-52.94 0-96-43.06-96-96S203.1 159.1 256 159.1s96 43.06 96 96S308.9 351.1 256 351.1zM256 191.1c-35.3 0-64 28.72-64 64S220.7 319.1 256 319.1s64-28.72 64-64S291.3 191.1 256 191.1z" /></svg>
                {intl.get('settings_notifications')}
              </button>
            </div>
          )}
        </div>

        {hasNewNotifications
          ? <div className="divide-y divide-lightwarmgray">
              {notifications?.map((notification, i) => {
                return (
                  <Notification key={`${i}-${notification.id}`} notification={notification} type="parent" />
                )
              })}
            </div>
          : <div className="pt-6 pb-4 text-sm text-gray-500 text-center">
              {intl.get('empty_notification_new')}
            </div>
        }

        <div className="flex items-center px-4 py-2 mt-4">
          <div className="flex-1 font-medium">Earlier</div>
        </div>

        {hasEarlierNotifications
          ? <>
              <div className="divide-y divide-lightwarmgray">
                {earlierNotifications.map((notification, i) => {
                  return (
                    <Notification key={`${i}-${notification.id}`} notification={notification} type="parent" />
                  )
                })}
              </div>
            </>
          : <div className="pt-6 pb-4 text-sm text-gray-500 text-center">
              {intl.get('empty_notification_earlier')}
            </div>
        }
      </div>
    </div>
  )
}

type NotificationType = 'parent'|'child'

const Notification = ({ notification, type, shadow, courseTitle }: { notification: NotificationSchema, type: NotificationType, shadow?: 'top'|'bottom'|null, courseTitle?: string }) => {
  const navigate = useNavigate()
  const { data: appData, loading: appLoading } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''

  const [isExpanded, setIsExpanded] = useState(false)
  const [seen, setSeen] = useState(notification.seen)

  useEffect(() => {
    setSeen(notification.seen)
  }, [notification.seen])

  let bgColor = 'bg-white'
  if (type === 'parent' && !seen) bgColor = 'bg-deadyellow'
  if (type === 'child' && !seen) bgColor = 'bg-[#f1ede1]'
  if (type === 'child' && seen) bgColor = 'bg-[#fcfbf8]'

  let imgSize = 'w-10 h-10'
  if (type === 'child') imgSize = 'w-7 h-7'

  let itemShadow = ''
  if (shadow === 'top') itemShadow = 'shadow-notification-top'
  if (shadow === 'bottom') itemShadow = 'shadow-notification-bottom'

  const handleClick = (n: NotificationSchema) => {
    if (!n) return

    if (n.type === 'COURSE_SUMMARY') {
      return setIsExpanded(!isExpanded)
    }

    let redirectUrl, newCompanyId, newCourseId = ''
    const urn = n.payload?.urn?.split('-') || []

    if (n.type === 'NEW_MESSAGES_COUNT') {
      if (urn[0] === 'share') {
        const str = `${urn[1]}-${urn[2]}-${urn[3]}-${urn[4]}-${urn[5]}`
        redirectUrl = `/learn/share/${str}`
      }
      else if (urn[0] === 'private') {
        const otherUserId = userId === urn[1] ? urn[2] : urn[1]
        redirectUrl = `/chat/${otherUserId}`
      }
      else if (urn[0] === 'talktoeditor') {
        const str = `${urn[1]}-${urn[2]}-${urn[3]}`
        redirectUrl = `/chat/${str}`
      }
      else {
        redirectUrl = `/learn/${urn[3]}/chat'}`
      }
    }
    else if (n.type === 'SHARE_COMMENT') {
      if (urn[0] === 'share') {
        const str = `${urn[1]}-${urn[2]}-${urn[3]}-${urn[4]}-${urn[5]}`
        redirectUrl = `/learn/share/${str}`
        newCompanyId = n.payload.companyId || urn[1]
        newCourseId = n.payload.courseId || urn[2]
      }
    }
    else if (n.type === 'NEW_SHARE') {
      redirectUrl = '/learn/group'
      newCompanyId = n.payload.companyId || urn[1]
      newCourseId = n.payload.courseId || urn[2]
    }
    else if (n.type === 'SHARE_LIKE') {
      const str = `${n.payload.companyId}-${n.payload.courseId}-${n.payload.chapterId}-${n.payload.actionId}-${n.payload.userId}`
      redirectUrl = `/learn/share/${str}`
      newCompanyId = n.payload.companyId || urn[1]
      newCourseId = n.payload.courseId || urn[2]
    }
    else if (n.type === 'CHAT_MENTION') {
      const str = `${urn[1]}-${urn[2]}-${urn[3]}-${urn[4]}-${urn[5]}`
      redirectUrl = `/learn/share/${str}`
      newCompanyId = urn[1]
      newCourseId = urn[2]
    }

    if (urn[0] && (urn[0] === 'private' || urn[0] === 'talktoeditor')) {
      return navigate(redirectUrl)
    }

    if (newCompanyId === companyId && newCourseId === courseId) {
      return navigate(redirectUrl)
    }

    switchCourse(newCompanyId, newCourseId, courseTitle, redirectUrl)
  }

  const switchCourse = (companyId: string, courseId: string, courseTitle: string|undefined, redirectUrl: string) => {
    showModal({
      title: intl.get('ask_change_course'),
      component: intl.getHTML('dialog_change_subscription_description', { 0: courseTitle || 'Program' }),
      primaryAction: () => {
        changeCurrentCourse({ courseKey: { companyId, courseId }, landing: redirectUrl })
      },
      primaryText: intl.get('yes'),
      secondaryText: intl.get('no'),
    })
  }

  const signedUrl = getSignedValue(notification.courseImageUrl || notification.imageUrl)
  const userRegex = notification.userFullName && new RegExp(notification.userFullName, 'gi') || null
  const courseTitleRegex = notification.courseTitle && new RegExp(notification.courseTitle, 'gi') || null
  let notificationText = notification.text
  if (userRegex) notificationText = notificationText.replace(userRegex, '<span class="font-bold">$&</span>')
  if (courseTitleRegex) notificationText = notificationText.replace(courseTitleRegex, '<span class="font-bold">$&</span>')

  return (
    <div className={`p-4 ${isExpanded ? 'pb-0' : 'pb-2.5'} ${bgColor} ${itemShadow}`}>
      <div className="flex space-x-2">
        <button className="flex w-full" onClick={() => handleClick(notification)}>
          <div className="w-12 shrink-0">
            <div className={`bg-lightwarmgray rounded-full bg-cover ${imgSize}`} style={{ background: `url(${signedUrl || undefined})` }} />
          </div>

          <div className="pl-1 flex-1 text-left">
            <div className={`${type === 'child' ? 'line-clamp-3' : ''}`} dangerouslySetInnerHTML={{ __html: notificationText }} />

            <div className="text-actions-multiple_choice text-sm">
              {type === 'parent' && notification.type === 'COURSE_SUMMARY' && (
                <span>
                  <strong>{notification.companyName}</strong> <span className="inline-block w-1 h-1 bg-actions-multiple_choice mx-1.5 mb-0.5 rounded-full" />
                </span>
              )} {localeFormatDistance(notification.timestamp, Date.now())}
            </div>
          </div>
        </button>

        {type === 'parent' && (
          <NotificationMenu
            id={notification.id}
            seen={seen}
            markSeen={() => setSeen(true)}
          />
        )}
      </div>

      {type === 'parent' && notification.type === 'COURSE_SUMMARY' && (
        <div>
          <div className="text-center">
            <button className={`font-bold text-sm my-2 ${isExpanded ? 'mb-[1.2rem]' : ''} text-actions-multiple_choice`} onClick={(e) => {
              e.stopPropagation()
              setIsExpanded(!isExpanded)
            }}>
              {isExpanded ? intl.getHTML('notifications_collapse_text') : intl.getHTML('notifications_expand_text')}
            </button>
          </div>

          {isExpanded && (
            <ChildNotifications cmpId={notification.payload.companyId} crsId={notification.payload.courseId} courseTitle={notification.courseTitle} seen={seen} />
          )}
        </div>
      )}
    </div>
  )
}

const ChildNotifications = ({ cmpId, crsId, courseTitle, seen }) => {
  const [notifications, setNotifications] = useState<NotificationsResponse>({})

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = async () => {
    const nots: NotificationsResponse = await fetchChildNotifications(cmpId, crsId)
    setNotifications(nots)
  }

  const activeNotifications = notifications?.payload?.activeSubscription || []

  useEffect(() => {
    const newNotifs = produce(notifications, (draft) => {
      if (!draft?.payload) return draft

      draft.payload?.activeSubscription.forEach((notif) => {
        notif.seen = true
      })
    })
    setNotifications(newNotifs)
  }, [seen])

  return (
    <div className="divide-y divide-lightwarmgray -mx-4">
      {activeNotifications.map((notification, i) => {
        return (
          <Notification key={`${i}-${notification.id}`} notification={notification} type="child"  shadow={i === 0 ? 'top' : i === activeNotifications.length - 1 ? 'bottom' : null} courseTitle={courseTitle} />
        )
      })}
    </div>
  )
}

const NotificationMenu = ({ id, seen, markSeen }: { id: string, seen: boolean, markSeen: () => void }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (seen) {
    return null
  }

  return (
    <Popover
      isOpen={isOpen}
      positions={['left', 'right']}
      padding={10}
      align="start"
      onClickOutside={() => setIsOpen(false)}
      containerClassName="z-50"
      content={() => (
        <Transition
          show={true}
          appear={true}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="w-52 custom-dropdown z-10">
            <button className="px-4 py-2 hover:bg-deadyellow w-full text-left" onClick={() => {
              clearNotificationsById(id)
              markSeen()
              setIsOpen(false)
            }}>
              {intl.get('activity_mark_as_read')}
            </button>
          </div>
        </Transition>
      )}>
        <button className="w-8 h-4" onClick={() => setIsOpen(!isOpen)}>
          <i className="icon icon-more-button mx-auto text-xs font-bold w-8 h-4 flex justify-center items-center m-auto hover:text-actions-multiple_choice" />
        </button>
      </Popover>
  )
}
