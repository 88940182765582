import React, { useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { useQuery } from '@apollo/client'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import { useNavigate } from 'react-router-dom'
import { useWhiteLabel } from 'context/whiteLabel'

export const SidebarMenu = ({ tab, menuExpanded, mobile, isEditing, workbookDisabled }) => {
  const navigate = useNavigate()
  const { whiteLabelData } = useWhiteLabel()
  const accentColor = whiteLabelData.accentColor
  const primaryColor = whiteLabelData.primaryColor

  const customStyle = {
    ...(accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}),
    ...(primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}),
  }

  const generateAccentColor = accentColor ? `group-hover:text-[var(--accent-color)]` : 'group-hover:text-actions-multiple_choice'
  const generatePrimaryBorder = primaryColor ? `border-[var(--primary-color)]` : 'border-actions-multiple_choice'

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [collapsed, setCollapsed] = useState(localStorage.getItem('gnowbe_submenu_expanded') === 'false' ? false : true)

  const arrowCls = classNames({
    'icon icon-angle-right-light inline-block text-gray-400 group-hover:text-gray-500': true,
    up: !collapsed,
    down: collapsed,
  })

  const navCls = classNames({
    'mt-5 bg-white text-center relative': true,
    'space-y-2': !(menuExpanded && collapsed && !mobile),
    'pb-2 border-b border-gray-300': menuExpanded,
    'flex flex-col md:flex-row justify-between items-center': menuExpanded && collapsed,
    // 'border-b border-gray-200': true,
  })

  const collapse = () => {
    localStorage.setItem('gnowbe_submenu_expanded', String(!collapsed))
    setCollapsed(!collapsed)
  }

  const click = (id: string|null, dest: string) => {
    if (id) {
      trackButton({
        button: id,
        on_screen: 'left_pane',
      })

      trackButtonEnhanced({
        button: id,
        onScreen: 'Session',
      })
    }

    navigate(dest)
  }

  const hideBorder = menuExpanded && collapsed && !mobile
  const hideTip = menuExpanded && !collapsed

  if (isEditing) {
    return (
      <nav className={navCls} style={customStyle}>
        <button className="sidebar-item group" onClick={() => click('Home', '/home')} aria-label="Return Home">
          <i className={`icon icon-home-light border-b-2 border-transparent ${generateAccentColor}`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`border-transparent ${generateAccentColor}`}>
              {intl.get('home_tab_home')}
            </span>
          )}
        </button>

        <button className="sidebar-item group" onClick={() => click('edit_course', `/details/${appData?.appState.currentCompanyIdCourseId}`)} aria-label="Program Details">
          <i className={`icon icon-programs-outline ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`border-transparent ${generateAccentColor}`}>
              {intl.get('edit_program_details')}
            </span>
          )}
        </button>

        <button className={`sidebar-item group ${['chapter', ':chapterId'].includes(tab) ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`}
          onClick={() => click('edit_chapter_btn', '/learn')} aria-label="Edit Sessions">
          <i className={`icon icon-session-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${['chapter', ':chapterId'].includes(tab) ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('program_edit_sessions')}
            </span>
          )}
        </button>

        <button className={`sidebar-item group ${tab.startsWith('resources') ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`} onClick={() => click(null, '/learn/resources')} aria-label="Add Resources">
          <i className={`icon icon-shopping-bag-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${tab.startsWith('resources') ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('menu_resources')}
            </span>
          )}
        </button>
      </nav>
    )
  }

  return (
    <nav className={navCls} style={customStyle}>
      <Tippy placement={hideBorder ? 'bottom' : 'right'} content={intl.get('home_tab_home')} disabled={hideTip}>
        <button className="sidebar-item group" onClick={() => click('Home', '/home')} aria-label="Return Home">
          <i className={`icon icon-home-light ${generateAccentColor} border-b-2 border-transparent`} data-test="return-home-btn"/>

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${tab.startsWith('home') ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('home_tab_home')}
            </span>
          )}
        </button>
      </Tippy>

      <Tippy placement={hideBorder ? 'bottom' : 'right'} content={intl.get('tab_item_title_session')} disabled={hideTip}>
        <button className={`sidebar-item group ${['chapter', ':chapterId'].includes(tab) ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`}
          onClick={() => click('Session', '/learn')} aria-label="Continue learning">
          <i className={`icon icon-session-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${['chapter', ':chapterId'].includes(tab) ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('tab_item_title_session')}
            </span>
          )}
        </button>
      </Tippy>

      <Tippy placement={hideBorder ? 'bottom' : 'right'} content={intl.get('tab_item_title_journey')} disabled={hideTip}>
        <button className={`sidebar-item group ${tab.startsWith('group') ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`} onClick={() => click('Group', '/learn/group')} aria-label="My Group" data-test="side-menu-group-btn">
          <i className={`icon icon-group-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${tab.startsWith('group') ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('tab_item_title_journey')}
            </span>
          )}
        </button>
      </Tippy>

      <Tippy placement={hideBorder ? 'bottom' : 'right'} content={workbookDisabled ? intl.get('workbook_disabled') : intl.get('workbook_description')} disabled={hideTip}>
        <button className={`sidebar-item group ${tab.startsWith('workbook') ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`} onClick={() => {
          if (workbookDisabled) return
          click('Workbook', '/learn/workbook')
        }} aria-label="My Workbook">
          <i className={`icon icon-book-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${tab.startsWith('workbook') ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('workbook')}
            </span>
          )}
        </button>
      </Tippy>

      <Tippy placement={hideBorder ? 'bottom' : 'right'} content={intl.get('my_progress')} disabled={hideTip}>
        <button className={`sidebar-item group ${tab.startsWith('progress') ? 'selected' : ''} ${hideBorder ? 'border-transparent' : ''}`} onClick={() => click('Progress', '/learn/progress')} aria-label="My Progress">
          <i className={`icon icon-chart-line-light ${generateAccentColor} border-b-2 border-transparent`} />

          {(menuExpanded && !collapsed || mobile) && (
            <span className={`${tab.startsWith('progress') ? generatePrimaryBorder : 'border-transparent'} ${generateAccentColor}`}>
              {intl.get('my_progress')}
            </span>
          )}
        </button>
      </Tippy>

      {menuExpanded && (
        <Tippy placement="bottom" content={collapsed ? 'Expand' : 'Collapse'}>
          <button className="border border-gray-400 w-4 h-4 hidden md:block rounded-full absolute bg-white group hover:border-gray-500 not-sr-only -bottom-2.5" style={{ left: '50%', marginLeft: '-0.5rem' }}
            onClick={() => {
              // trackButton({
              //   button: 'burger_menu',
              //   on_screen: 'left_pane',
              // })
              collapse()
            }} aria-label={`${collapsed ? 'Expand' : 'Collapse'} menu`}>
            <div className="flex flex-1 h-full justify-center items-center">
              <span className={arrowCls} />
            </div>
          </button>
        </Tippy>
      )}
    </nav>
  )
}
