import { gql } from '@apollo/client'

export type AppState = {
  appState: {
    currentCompanyIdCourseId: string;
    activeChapterId: string;
    isHelpEnabled: boolean;
    isEditing: boolean;
    isCreatingCourse: boolean;
    switchToAddedCourse: boolean;
    isLoading: boolean;
    isImportingPpt: boolean;
    search: string;
    currentTour: string;
    loggedInAs: {
      uid: string;
      email: string;
      token: string;
    };
    signatures: string;
    deeplinkCompanyId: string;
  },
}

export const getAppStateQuery = gql`
  query GetAppState {
    appState @client {
      currentCompanyIdCourseId
      activeChapterId
      isHelpEnabled
      isEditing
      isCreatingCourse
      switchToAddedCourse
      isLoading
      isDuplicatingCourse
      isImportingPpt
      search
      currentTour
      loggedInAs {
        uid
        email
        token
      }
      signatures
      deeplinkCompanyId
    }
  }
`

export const getLoggedInStatusQuery = gql`
  query GetAppState {
    appState @client {
      loggedInAs {
        uid
        email
        token
      }
    }
  }
`

export const getEditingStatusQuery = gql`
  query GetAppState {
    appState @client {
      isEditing
    }
  }
`

export const getActiveChapterIdQuery = gql`
  query GetAppState {
    appState @client {
      activeChapterId
    }
  }
`

export const getCurrentCompanyIdCourseIdQuery = gql`
  query GetAppState {
    appState @client {
      currentCompanyIdCourseId
    }
  }
`

export const getIsHelpEnabledQuery = gql`
  query GetAppState {
    appState @client {
      isHelpEnabled
    }
  }
`

export const getisCreatingCourseQuery = gql`
  query GetAppState {
    appState @client {
      isCreatingCourse
      isLoading
    }
  }
`

export const getIsDuplicatingCourseQuery = gql`
  query GetAppState {
    appState @client {
      isDuplicatingCourse
      isCreatingCourse
      isLoading
    }
  }
`

export const getSignaturesQuery = gql`
  query GetAppState {
    appState @client {
      signatures
    }
  }
`
