import { useQuery } from '@apollo/client'
import { changeCurrentCourse } from 'actions/users_actions'
import { useAppState } from 'graphql/hooks/useAppState'
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getUserQuery } from 'graphql/queries/user/getUser'
import { CompanyBasic } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { getMeta } from 'utils/functions'

type UserRoles = 'admin' | 'superEditor' | 'sales' | 'partner' | 'editor' | 'publisher' | 'independentEditor'
type DisabledNotifications = 'PUSH_PRIVATE_MSGS' | 'PUSH_CURATOR_MSGS' | 'PUSH_COMMENTS_LIKES' | 'PUSH_MENTIONS' | 'PUSH_DAILY_COURSE_MSGS' | 'PUSH_INACTIVITY' | 'PUSH_GROUP_AGENT_MSGS' | 'EMAIL_DAILY_COURSE_MSGS' | 'EMAIL_COURSE_COMPLETION_MSGS' | 'EMAIL_WEEKLY_JOURNEY' | 'EMAIL_ACTIVITY_NOTIFICATIONS' | 'EMAIL_GROUP_AGENT_MSGS' | 'EMAIL_MONTLY_AGENT_REPORT' | 'EMAIL_INVITATIONS'

export type UserBasic = {
  id: string;
  profile: UserProfile;
}

export type UserProfile = {
  fullName: string | null;
  firstName: string | null;
  lastName: string | null;
  imageUrl: string | null;
  timezone: number | null;
  currentSubscription: string | null;
}

export type UserBasicExtra = {
  id: string;
  profile: UserProfile;
  email: string;
  createdAt: number;
}

type UserPolicy = {
  id: string;
  type: string;
  title: string;
  description: string;
  version: number;
  documentUrl: string;
  agreedOn: number;
}

type GnowbeUserCourseNotifications = {
  userId: string;
  courseId: string;
  distributionStatusUpdates: boolean;
}

type UserCompanyAnonymitySettings = {
  companyId: string;
  anonymitySettings: string[];
}

type CompletedCourse = {
  userId: string;
  companyId: string;
  courseId: string;
  completedAt: number;
  invisible: boolean;
  course: Course;
  company: CompanyBasic;
}

type AuthProvider = {
  name: string;
  id: string;
  profileUrl: string;
  forbidEmailChange: boolean;
  forbidNameChange: boolean;
}

export type UserMetadata = {
  appInstalled: boolean;
  general: any;
}

type PurchaseOnetime = {
  bundleId: string;
  companyIds: string[];
}

export function purchaseSubscriptionFactory(data?: Partial<PurchaseSubscription>): PurchaseSubscription & { __typename: string } {
  return {
    ...data as any,
    __typename: 'PurchaseSubscription',
    billingCycle: data?.billingCycle || 'monthly' as 'monthly',
    bundleId: data?.bundleId || 'not-so-random-id',
    expiresAt: data?.expiresAt || Date.now() - 60 * 60 * 24 * 2,
    nextPaymentAt: data?.nextPaymentAt || Date.now() - 60 * 60 * 24 * 2,
    paymentType: data?.paymentType || 'stripe' as 'stripe',
    status: data?.status || 'cancelled' as 'cancelled',
    subscriptionId: data?.subscriptionId || 'asdf',
    trialExpiresAt: data?.trialExpiresAt || Date.now() - 60 * 60 * 24 * 5,
    price: data?.price || 0,
    priceCurrency: data?.priceCurrency || 'USD',
    inappProductId: data?.inappProductId || 'asdf',
    name: data?.name || 'asdf',
  }
}

export type PurchaseSubscription = {
  billingCycle: 'monthly' | 'yearly';
  bundleId: string;
  expiresAt: number | null;
  nextPaymentAt: number;
  paymentType: 'stripe' | 'appleInapp' | 'googleInapp' | 'skillsfuture';
  status: 'active' | 'cancelled' | 'pending';
  subscriptionId: string;
  trialExpiresAt: number | null;
  price: number;
  priceCurrency: string;
  inappProductId: string;
  name: string;
}

export type Purchases = {
  onetime: PurchaseOnetime[];
  subscriptions: PurchaseSubscription[];
}

export type Feature = 'show_extend_upsell'
  | 'show_gnowbelearn_banner'
  | 'show_explore_tab'
  // |'editor_has_proctoring'
  |'show_refer_banner'
  |'course-from_ppt'
  |'course-manualReviews'
  |'action-word_puzzle'
  |'action-assessment'
  |'action-certificate'
  |'user-hide_marketing'
  |'referrals'
  |'course-duplicateAndTranslateCourse'
  |'course-botAi'
  |'course-generateTextAi'
  |'course-generateImageAi'

type OnboardingCourse = {
  joinLink: string;
  title: string;
}

export type User = {
  id: string;
  email: string;
  profile: UserProfile;
  disabledNotifications: DisabledNotifications[];
  disabledSubsNotifications: string[];
  anonymitySettings: UserCompanyAnonymitySettings[];
  roles: UserRoles[];
  billingManagerOrganizations: string[];
  partnerOrganizations: string[];
  managerOrganizations: string[];
  editorOrganizations: string[];
  agentCompanies: string[];
  editorCompanies: string[];
  editorCourses: string[];
  mEditorCourses: string[];
  createdAt: number;
  subscriptions: string[];
  archivedSubscriptions: string[];
  completedCourses: CompletedCourse[];
  completedChaptersCount: number;
  completedActionsCount: number;
  secondsSpent: number;
  allowCourseCreation: boolean;
  policies: UserPolicy[];
  features: Feature[];
  favoritedSubscriptions: string[];
  pinnedCompanyIds: string[];
  editorCourseNotifications: GnowbeUserCourseNotifications[];
  purchases: Purchases;
  onboardingCourse: OnboardingCourse;

  phoneNumber: string | null;
  language: string | null;
  authProviders: AuthProvider[] | null;
  linkedInUrl: string | null;
  metadata: UserMetadata | null;
  creatorInOrganizations: string[],
  memberOfOrganizations: string[],
}

export const isSubscriptionActive = (sub: PurchaseSubscription) => {
  if (sub.status === 'active' || sub.status === 'cancelled' && sub.expiresAt && sub.expiresAt > Date.now()) return true
  return false
}

export const isAdmin = (user: User) => {
  if (!!user.managerOrganizations?.length) return true
  return false
}

export const isAgent = (user?: User, organizationId?: string, companyId?: string) => {
  if (!user) return false
  if (!organizationId && user.managerOrganizations.length > 0) return true
  if (organizationId && user.managerOrganizations.includes(organizationId)) return true
  if (organizationId && user.creatorInOrganizations.includes(organizationId)) return true
  if (!companyId && user.editorCompanies.length > 0) return true
  if (companyId && user.agentCompanies.includes(companyId)) return true
  return false
}

export const isAgentInAnyGroup = (user?: User) => {
  if (!user) return false
  return user.agentCompanies.length > 0
}

export const isIndependentCurator = (user: User) => {
  if (user.roles.includes('independentEditor')) return true
  return false
}

export const hasDashboardAccess = (user?: User) => {
  if (!user) return false
  if (user.roles?.some(r => r === 'superEditor' || r === 'sales' || r === 'publisher')) return true
  if (user.partnerOrganizations?.length > 0
    || user.managerOrganizations?.length > 0
    || user.agentCompanies?.length > 0
    || user.editorOrganizations?.length > 0
    || user.editorCompanies?.length > 0) return true
  return false
}

export function metadataFactory(data?: Partial<UserMetadata>): UserMetadata & { __typename: string } {
  return {
    ...data as any,
    __typename: 'User',
    appInstalled: data?.appInstalled || false,
    general: data?.general || {},
  }
}

export const hideMarketing = (user: User) => {
  if (!user.features.includes('user-hide_marketing')) return false
  if (user.managerOrganizations.length > 0 || user.agentCompanies.length > 0 || user.editorCompanies.length > 0 || user.editorOrganizations.length > 0 || user.editorCourses.length > 0) return false
  return true
}

export const isCreatorInWorkspace = () => {
  const appState = useAppState()
  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  return subData?.user.creatorInOrganizations.includes(activeWorkspaceId) || false
}

export const isBillingAdminInWorkspace = () => {
  const appState = useAppState()
  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const activeWorkspaceId = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  return subData?.user.billingManagerOrganizations.includes(activeWorkspaceId) || false
}

export const canCreateOwnOrg = () => {
  const appState = useAppState()

  const { data: subData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const { data: orgData, loading: orgLoading } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appState!.loggedInAs.uid,
    },
    errorPolicy: 'all',
  })
  return !orgData?.res.organizations.some(o => subData?.user.billingManagerOrganizations.includes(o.id) && o.product === 'FT')
}

export const switchUserToFirstEditorCourse = (user: User, enableEditing: boolean, landing: string) => {
  const editorCourse = user.editorCourses[0]
  if (!editorCourse) return
  const sub = user.subscriptions.find(e => e.includes(editorCourse))
  if (!sub) return
  const [companyId, courseId] = sub.split('-')
  changeCurrentCourse({
    courseKey: {
      companyId,
      courseId,
    },
    enableEditing,
    landing,
  })
}

export const userRoles = (user, currentWorkspaceId) => {
  let roles: String[] = []
  if (user?.billingManagerOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['billingManager'])
  if (user?.creatorInOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['creator'])
  if (user?.memberOfOrganizations.includes(currentWorkspaceId)) roles = roles.concat(['learner'])
  return roles || []
}
