import { format, formatDistance, formatDistanceStrict } from 'date-fns'
import { enUS, zhCN, nl, ko, bg, de, pt, sl, id, es, ja, fr, it, ru, sv, hu, pl, th, vi, ms, zhTW  } from 'date-fns/locale'
declare var window

const createLogger = (color, backgroundColor) => {
  const logger = (message, ...args) => {
    if (/localhost|web\.staging|web-beta/.exec(window.location.href)) {
      console.groupCollapsed(
        `[${format(Date.now(), 'HH:mm:ss')}] %c${message}`,
        `background-color: ${backgroundColor}; color: ${color}; padding: 2px 4px;`,
        ...args
      )
      console.groupEnd()
    }
  }

  return logger
}

/**
 * Displays a pretty console.log locally or on staging.
 * @param message Message to show
 */
export const slog = {
  d: createLogger('black', 'white'), // default
  s: createLogger('#fff', '#27ae60'), // success
  i: createLogger('#fff', '#2980b9'), // info
  w: createLogger('#fff', '#f39c12'), // warning
  e: createLogger('#fff', '#e74c3c'), // error
}

export const localeFormat = (date: number|Date, f: string) => {
  const locales = { enUS, zhCN, nl, ko, bg, de, pt, sl, id, es, ja, }

  return format(date, f, {
    locale: locales[window.__locale__.replace('en', 'enUS').replace('cn', 'zhCN')]
  })
}

export const localeFormatDistance = (dateFrom: number, dateTo: number) => {
  const locales = { enUS, zhCN, nl, ko, bg, de, pt, sl, id, es, ja, fr, it, ru, sv, hu, pl, th, vi, ms, zhTW }

  return formatDistance(dateFrom, dateTo, {
    addSuffix: true,
    locale: locales[window.__locale__.replace('en', 'enUS').replace('cn', 'zhCN').replace('tw', 'zhTW')]
  })
}

export const localeFormatDistanceStrict = (dateFrom: number, dateTo: number) => {
  const locales = { enUS, zhCN, nl, ko, bg, de, pt, sl, id, es, ja, fr, it, ru, sv, hu, pl, th, vi, ms, zhTW }

  return formatDistanceStrict(dateFrom, dateTo, {
    addSuffix: true,
    locale: locales[window.__locale__.replace('en', 'enUS').replace('cn', 'zhCN').replace('tw', 'zhTW')]
  })
}
